export const LOAD_COMMUNITY_SHOWCASES_PAGE = 'LOAD_COMMUNITY_SHOWCASES_PAGE'
export const LOAD_REVIEW_SHOWCASES_PAGE = 'LOAD_REVIEW_SHOWCASES_PAGE'
export const LOAD_SCHOOL_SHOWCASES_PAGE = 'LOAD_SCHOOL_SHOWCASES_PAGE'
export const LOAD_TAG_SHOWCASES_PAGE = 'LOAD_TAG_SHOWCASES_PAGE'
export const RESET_SHOWCASE_FILTERS = 'RESET_SHOWCASE_FILTERS'
export const RESET_SHOWCASE_SCHOOL = 'RESET_SHOWCASE_SCHOOL'
export const CHANGE_SHOWCASE_FILTER = 'CHANGE_SHOWCASE_FILTER'
export const CHANGE_SHOWCASE_FILTERS = 'CHANGE_SHOWCASE_FILTERS'
export const CHANGE_SHOWCASE_PAGE = 'CHANGE_SHOWCASE_PAGE'
export const CHANGE_SHOWCASE_SCHOOL = 'CHANGE_SHOWCASE_SCHOOL'
export const CHANGE_SHOWCASE_STATUS = 'CHANGE_SHOWCASE_STATUS'
export const CHANGE_SHOWCASE_STATIC = 'CHANGE_SHOWCASE_STATIC'
export const CHANGE_SHOWCASE_TAG = 'CHANGE_SHOWCASE_TAG'

export function resetShowcaseFilters() {
  return {
    type: RESET_SHOWCASE_FILTERS,
  }
}

export function resetShowcaseSchool() {
  return {
    type: RESET_SHOWCASE_SCHOOL,
  }
}

export function changeShowcaseFilter(key, value) {
  return {
    type: CHANGE_SHOWCASE_FILTER,
    payload: { key, value },
  }
}

export function changeShowcaseFilters(filters) {
  return {
    type: CHANGE_SHOWCASE_FILTERS,
    payload: filters,
  }
}

export function changeShowcasePage(page) {
  return {
    type: CHANGE_SHOWCASE_PAGE,
    payload: page,
  }
}

export function changeShowcaseSchool(schoolId) {
  return {
    type: CHANGE_SHOWCASE_SCHOOL,
    payload: schoolId,
  }
}

export function changeShowcaseStatus(status) {
  return {
    type: CHANGE_SHOWCASE_STATUS,
    payload: status,
  }
}

export function changeShowcaseStatic(isStatic) {
  return {
    type: CHANGE_SHOWCASE_STATIC,
    payload: isStatic,
  }
}

export function changeShowcaseTag(tag) {
  return {
    type: CHANGE_SHOWCASE_TAG,
    payload: tag,
  }
}

export function loadTagShowcasesPage(tag) {
  return {
    type: LOAD_TAG_SHOWCASES_PAGE,
    payload: tag,
  }
}

export function loadSchoolShowcasesPage(schoolId) {
  return {
    type: LOAD_SCHOOL_SHOWCASES_PAGE,
    payload: schoolId,
  }
}

export function loadReviewShowcasesPage() {
  return {
    type: LOAD_REVIEW_SHOWCASES_PAGE,
  }
}

export function loadCommunityShowcasesPage() {
  return {
    type: LOAD_COMMUNITY_SHOWCASES_PAGE,
  }
}

import getConfig from 'next/config'
import TagManager from 'react-gtm-module'

const { publicRuntimeConfig } = getConfig()

export const GTM_ID = publicRuntimeConfig.GTM_ID // eslint-disable-line prefer-destructuring

export const gtmInitialize = () => {
  return TagManager.initialize({ gtmId: GTM_ID })
}

export const trackEvent = (dataLayer) => {
  return TagManager.dataLayer({ dataLayer })
}

export const currentLocation = () => {
  return `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`
}

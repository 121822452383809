import { all, call, put, takeLatest } from 'redux-saga/effects'

import { MARK_STREAM_AS_READ } from './actions'

import { getActivityStreamService, markActivityStreamService } from './index'

export function* markStreamAsReadSaga({ payload: { data } }) {
  yield call(markActivityStreamService.requestSaga, { payload: { data } })
  yield put(getActivityStreamService.actions.request())
}

export default function* saga() {
  yield all([takeLatest(MARK_STREAM_AS_READ, markStreamAsReadSaga)])
}

import buildService from 'common/utils/build-service'
import { DELETE, GET, PATCH, POST } from 'common/requests'

export default buildService({
  type: GET,
  constantsPrefix: 'SUBJECTS',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/subjects?select=id,slug,name&order=name.asc',
  },
})

export const postSubjectService = buildService({
  type: POST,
  constantsPrefix: 'SUBJECTS',
  http: {
    createApiUrlSuffix: () => {
      return `/subjects`
    },
  },
})

export const deleteSubjectService = buildService({
  type: DELETE,
  constantsPrefix: 'SUBJECTS',
  http: {
    createApiUrlSuffix: ({ id }) => {
      return `/subjects?id=eq.${id}`
    },
  },
})

export const patchSubjectService = buildService({
  type: PATCH,
  constantsPrefix: 'SUBJECTS',
  http: {
    createApiUrlSuffix: ({ id }) => `/subjects?id=eq.${id}`,
  },
})

export const SUBMIT_CITY = 'SUBMIT_CITY'

export type CityPayload = {
  id?: string
  stateId: string
  name: string
}

export function submitCity(payload: CityPayload) {
  return {
    type: SUBMIT_CITY,
    payload,
  }
}

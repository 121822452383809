import _ from 'lodash'
import cn from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import lang from 'lang'
import { roles } from 'common/utils/determine-user'

import { updateCsvUsers, validationTypes } from './service/actions'

const Errors = (props) => {
  return _.map(props.errors, (error, index) => (
    <div key={index} className="cell medium-12 form-error is-visible">
      {error.message}
    </div>
  ))
}
Errors.propTypes = {
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
      type: PropTypes.string,
      row: PropTypes.number,
    }),
  ),
}

@connect(
  (state) => ({
    uploadedUsers: state.school.addSchoolUser.csvUploadForm.formData.users,
    personTypes: state.personTypes.items,
  }),
  (dispatch) => ({
    updateCsvUsers: (params) => dispatch(updateCsvUsers(params)),
  }),
)
export default class UserGridItem extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    updateCsvUsers: PropTypes.func,
    uploadedUsers: PropTypes.array,
    personTypes: PropTypes.array,
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    readOnly: false,
  }

  changeUserField(field, value) {
    const { user, index, uploadedUsers } = this.props
    uploadedUsers[index] = { ...user, [field]: value }
    this.props.updateCsvUsers({
      users: [...uploadedUsers],
    })
  }

  checkUserHandler() {
    const { user } = this.props
    return () => {
      this.changeUserField('selected', !user.selected)
    }
  }

  changeRoleHandler() {
    return (e) => {
      const role = e.target.value
      this.changeUserField('role', role)
    }
  }

  changePersonTypeHandler() {
    return (e) => {
      const personTypeId = e.target.value
      this.changeUserField('personTypeId', personTypeId)
    }
  }

  renderPersonTypes() {
    return (
      <select onChange={this.changePersonTypeHandler()}>
        {_.map(this.props.personTypes, (personType) => {
          const typeIndex = personType.name.replace(/\s/g, '_').toLowerCase()
          return (
            <option key={typeIndex} value={personType.id}>
              {lang.personTypes[typeIndex]}
            </option>
          )
        })}
      </select>
    )
  }

  renderRoles() {
    return (
      <select onChange={this.changeRoleHandler()}>
        <option value={roles.school.member}>{lang.userRoles[roles.school.member]}</option>
        <option value={roles.school.implementation}>
          {lang.userRoles[roles.school.implementation]}
        </option>
      </select>
    )
  }

  renderCheckbox() {
    const { user, readOnly } = this.props
    if (readOnly) {
      return null
    }

    return (
      <div
        tabIndex={0}
        role="checkbox"
        className="checkbox-cell cell shrink"
        aria-checked={user.selected}
        onKeyDown={this.checkUserHandler()}
        onClick={this.checkUserHandler()}>
        <input type="checkbox" checked={user.selected} onChange={this.checkUserHandler()} />
      </div>
    )
  }

  renderDropdowns() {
    if (this.props.readOnly) {
      return null
    }

    return (
      <>
        <div className="cell medium-2 role-cell">{this.renderPersonTypes()}</div>
        <div className="cell medium-3 role-cell">{this.renderRoles()}</div>
      </>
    )
  }

  render() {
    const { user, index } = this.props
    // All non-field specific errors
    const genericErrors = _.filter(
      user.errors,
      (error) => !_.includes(_.values(validationTypes), error.type),
    )
    return (
      <React.Fragment key={index}>
        <div
          role="listitem"
          className={cn('list-item grid-x grid-padding-x', {
            selected: user.selected,
          })}>
          {this.renderCheckbox()}
          <div className="cell medium-3 text-cell">
            <strong>{user.name}</strong>
            <Errors errors={_.filter(user.errors, { type: validationTypes.badName })} />
          </div>
          <div className="cell auto text-cell">
            {user.email}
            <Errors errors={_.filter(user.errors, { type: validationTypes.badEmail })} />
            <Errors errors={_.filter(user.errors, { type: validationTypes.existingEmail })} />
          </div>
          {this.renderDropdowns()}
        </div>
        <Errors errors={genericErrors} />
      </React.Fragment>
    )
  }
}

import { TOGGLE_STAFF_CHARTER_SURVEY_DETAILS } from './actions'

const initialState = () => ({
  surveyId: null,
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case TOGGLE_STAFF_CHARTER_SURVEY_DETAILS:
      return {
        ...state,
        surveyId: (action.payload && action.payload.surveyId) || initialState().surveyId, // in the case of no payload - transform to the initial state
      }

    default:
      return state
  }
}

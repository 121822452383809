import getConfig from 'next/config'
import * as Sentry from '@sentry/nextjs'
import createSagaMiddleware, { Task } from 'redux-saga'
import { Context, createWrapper, HYDRATE } from 'next-redux-wrapper'
import { AnyAction, applyMiddleware, createStore, Middleware } from 'redux'
import reduxSentryMiddleware from 'lib/redux-sentry-middleware'

import rootSaga from './saga'
import reducer, { AppState } from './reducer'

export interface SagaStore extends AppStore {
  sagaTask: Task
}

const hydratingReducer = (state: AppState | undefined, action: AnyAction) => {
  if (action.type === HYDRATE) {
    // Prevents the 2nd HYDRATE from rewritting the state.
    // Link to the code for the double HYDRATE - https://github.com/kirill-konshin/next-redux-wrapper/blob/44e0957ffa7ad97ef2061284b13df165adc02b76/packages/wrapper/src/index.tsx#L195-L209
    if (state?.root.user) return state
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    }
    return nextState
  }

  return reducer(state, action)
}

const { publicRuntimeConfig } = getConfig()

const bindMiddleware = (middleware: Middleware<any, AppState, any>[]) => {
  const customMiddleware: Middleware<any, AppState, any>[] = []
  if (
    publicRuntimeConfig.SERVER_ENV === 'production' ||
    publicRuntimeConfig.SERVER_ENV === 'staging'
  ) {
    customMiddleware.push(reduxSentryMiddleware(Sentry))
    return applyMiddleware(...middleware, ...customMiddleware)
  }
  const { composeWithDevTools } = require('redux-devtools-extension') // eslint-disable-line
  return composeWithDevTools(applyMiddleware(...middleware, ...customMiddleware))
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const makeStore = (_context: Context) => {
  // 1: Create the middleware
  const sagaMiddleware = createSagaMiddleware({
    onError: (error: Error) => {
      Sentry.captureException(error)
      // This onError handler is swallowing our saga error so we need to explicitly log it
      console.error(error) // eslint-disable-line
    },
  })

  // 2: Add an extra parameter for applying middleware:
  const store = createStore(hydratingReducer, bindMiddleware([sagaMiddleware]))

  // 3: Run your sagas on server
  ;(store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga)

  // 4: now return the store:
  return store
}

export type AppStore = ReturnType<typeof makeStore>

// export an assembled wrapper
export const wrapper = createWrapper<AppStore>(makeStore, { debug: false })

// https://react-redux.js.org/using-react-redux/usage-with-typescript

import { CHANGE_EMOTION_WORDS_FILTERS } from './actions'

const initialState = () => ({
  emotionWordsIds: [],
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_EMOTION_WORDS_FILTERS:
      return {
        ...state,
        emotionWordsIds: action.payload,
      }

    default:
      return state
  }
}

import _ from 'lodash'
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { validateField, validateFields } from 'common/validator'
import buildService from 'common/utils/build-service'
import { getJournalsService, patchJournalService, postJournalService } from 'services/journals'
import { modulesService } from 'services/modules'
import { changeModal } from 'services/actions'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  LOAD_JOURNAL_DETAILS_PAGE_DATA,
  resetJournalFormData,
  SUBMIT_JOURNAL,
  VALIDATE_JOURNAL_FIELD,
} from './actions'

export function* submitJournalSaga({ payload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const data = {
      person_id: payload.currentUserId.value,
      name: payload.name.value,
      content: payload.content.value,
    }

    let response = null
    if (payload.id.value) {
      response = yield call(patchJournalService.requestSaga, {
        payload: {
          data,
          urlParams: {
            id: payload.id.value,
          },
        },
      })
    } else {
      response = yield call(postJournalService.requestSaga, {
        payload: { data },
      })
    }
    if (response.error) return
    yield put(getJournalsService.actions.request({ urlParams: { personId: data.person_id } }))
    yield put(changeModal({ isOpen: false }))
    yield put(resetJournalFormData())
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export const deleteJournalService = buildService({
  type: 'DELETE',
  constantsPrefix: 'JOURNAL',
  http: {
    createApiUrlSuffix: ({ id }) => `/journals?id=eq.${id}`,
    *successHandler(journal) {
      yield put(getJournalsService.actions.removeItem({ id: journal.id }))
      yield put(changeModal({ isOpen: false }))
    },
  },
})

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export function* loadJournalPageDataSaga({ payload }) {
  yield call(getJournalsService.requestSaga, {
    payload,
  })
  yield call(modulesService.requestSaga, {
    payload,
  })
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_JOURNAL_FIELD, validateFieldSaga),
    takeLatest(SUBMIT_JOURNAL, submitJournalSaga),
    takeLatest(LOAD_JOURNAL_DETAILS_PAGE_DATA, loadJournalPageDataSaga),
    fork(deleteJournalService.saga),
  ])
}

import { all, put, select, takeEvery } from 'redux-saga/effects'

import { tabs } from 'common/routes'
import { getShowcaseByTagService, getShowcaseService } from 'services/showcase'

import {
  CHANGE_SHOWCASE_PAGE,
  CHANGE_SHOWCASE_SCHOOL,
  CHANGE_SHOWCASE_STATUS,
  CHANGE_SHOWCASE_TAG,
  LOAD_COMMUNITY_SHOWCASES_PAGE,
  LOAD_REVIEW_SHOWCASES_PAGE,
  LOAD_SCHOOL_SHOWCASES_PAGE,
  LOAD_TAG_SHOWCASES_PAGE,
  RESET_SHOWCASE_SCHOOL,
} from './actions'

export const defaultItemsPerPage = 6

const generateRange = (page) => {
  const from = page * defaultItemsPerPage
  return { from, to: from + defaultItemsPerPage - 1 }
}

export function* loadSchoolShowcasesSaga() {
  const { page, schoolId, status } = yield select((state) => state.showcase.filters)
  yield put(
    getShowcaseService.actions.request({
      range: generateRange(page),
      urlParams: {
        status,
        isStatic: false,
        schoolId: schoolId || undefined,
      },
    }),
  )
}

export function* loadCommunityShowcasesSaga() {
  const { page, status } = yield select((state) => state.showcase.filters)
  yield put(
    getShowcaseService.actions.request({
      range: generateRange(page),
      urlParams: {
        status,
        isStatic: true,
      },
    }),
  )
}

export function* loadTagShowcasesSaga() {
  const { page, tag } = yield select((state) => state.showcase.filters)
  yield put(
    getShowcaseByTagService.actions.request({
      range: generateRange(page),
      urlParams: {
        tag,
      },
    }),
  )
}

export function* loadShowcasesSaga() {
  const { tab } = yield select((state) => state.showcase.filters)

  switch (tab) {
    case tabs.showcase.mySchool:
    case tabs.showcase.review:
      yield* loadSchoolShowcasesSaga()
      break

    case tabs.showcase.rulerCommunity:
      yield* loadCommunityShowcasesSaga()
      break

    case tabs.showcase.explore:
      yield* loadTagShowcasesSaga()
      break

    default:
      break
  }
}

export default function* saga() {
  yield all([
    takeEvery(
      [
        CHANGE_SHOWCASE_SCHOOL,
        CHANGE_SHOWCASE_STATUS,
        CHANGE_SHOWCASE_PAGE,
        CHANGE_SHOWCASE_TAG,
        RESET_SHOWCASE_SCHOOL,
        LOAD_SCHOOL_SHOWCASES_PAGE,
        LOAD_TAG_SHOWCASES_PAGE,
        LOAD_REVIEW_SHOWCASES_PAGE,
        LOAD_COMMUNITY_SHOWCASES_PAGE,
      ],
      loadShowcasesSaga,
    ),
  ])
}

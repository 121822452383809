import { IncomingMessage } from 'http'

export const REQUEST_HTML_CONTENT = 'REQUEST_HTML_CONTENT'
export const PERSIST_HTML_CONTENT = 'PERSIST_HTML_CONTENT'

export function requestHtmlContent(payload: { req?: IncomingMessage; keys: string[] }) {
  return {
    type: REQUEST_HTML_CONTENT,
    payload,
  }
}

// @ts-expect-error define types of persist payload
export function persistHtmlContent(payload) {
  return {
    type: PERSIST_HTML_CONTENT,
    payload,
  }
}

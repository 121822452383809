import { User } from 'common/types'
import _ from 'lodash'

import userRoles from './user-roles'
import userLabels from './user-labels'

export const roles = userRoles

export const labels = userLabels

export const isLabel: { [key: string]: (label: string) => boolean } = {
  teacher: (label: string) => label === labels.teacher,
  staff: (label: string) => label === labels.staff,
  leadership: (label: string) => label === labels.leadership,
}

export const isUser: { [key: string]: (user: User) => boolean } = {
  rulerAdmin: (user) => user.role && user.role === roles.ruler.admin,
  rulerYceiAdmin: (user) => user.role && user.role === roles.ruler.yceiAdmin,
  rulerYceiMember: (user) => user.role && user.role === roles.ruler.member,
  rulerAnyAdmin: (user) =>
    isUser.rulerAdmin(user) || isUser.rulerYceiMember(user) || isUser.rulerYceiAdmin(user),
  rulerOnlyAdmins: (user) => isUser.rulerAdmin(user) || isUser.rulerYceiAdmin(user),
  districtAdmin: (user) => user.role && user.role === roles.district.admin,
  districtMember: (user) => user.role && user.role === roles.district.member,
  districtImplementationTeam: (user) => user.role && user.role === roles.district.implementation,
  schoolAdmin: (user) => user.role && user.role === roles.school.admin,
  schoolImplementationTeam: (user) => user.role && user.role === roles.school.implementation,
  districtRole: (user) => user.role && user.role.indexOf('ruler_district') >= 0,
  schoolRole: (user) => user.role && user.role.indexOf('ruler_school') >= 0,
  schoolMember: (user) => user.role && user.role === roles.school.member,
  schoolTeacher: (user) =>
    isUser.schoolMember(user) && user.person_type_name && isLabel.teacher(user.person_type_name)
      ? true
      : false,
  schoolStaff: (user) =>
    isUser.schoolMember(user) && user.person_type_name && isLabel.staff(user.person_type_name)
      ? true
      : false,
  schoolLeadership: (user) =>
    isUser.schoolMember(user) && user.person_type_name && isLabel.leadership(user.person_type_name)
      ? true
      : false,
}

export const isRole: { [key: string]: (role?: string) => boolean } = {
  rulerAdmin: (role) => (role ? role === roles.ruler.admin : false),
  districtType: (role) => (role ? _.startsWith(role, 'ruler_district') : false),
  schoolType: (role) => (role ? _.startsWith(role, 'ruler_school') : false),
  districtAdmin: (role) => (role ? role === roles.district.admin : false),
  districtMember: (role) => (role ? role === roles.district.member : false),
  districtImplementationTeam: (role) => (role ? role === roles.district.implementation : false),
  schoolAdmin: (role) => (role ? role === roles.school.admin : false),
  schoolMember: (role) => (role ? role === roles.school.member : false),
  schoolImplementationTeam: (role) => (role ? role === roles.school.implementation : false),
}

import es6promise from 'es6-promise'
import { all, fork, takeLatest } from 'redux-saga/effects'

import schoolsService, { searchSchoolsService } from 'page-modules/dashboard/schools/service'
import schoolsFiltersSaga from 'page-modules/dashboard/schools/service/saga'
import {
  getBookmarkedSchoolsService,
  getSchoolUsersService,
  getSchoolUserStatisticsService,
  schoolDetailsService,
} from 'page-modules/dashboard/school-details/service/'
import {
  getChartersPerAudienceService,
  getJournalPostsService,
  getMoodMeterCheckInsService,
  getRulerizeCommitmentsService,
  getSchoolCourseCompletionService,
  getSchoolHowWantToFeelService,
  getSchoolTopResourcesCsvService,
  getSchoolTopResourcesService,
  getTop5MoodMeterEmotionWordsService,
} from 'page-modules/dashboard/statistics/service'
import { FETCH_CURRENT_USER, LOG_OUT } from 'services/actions'
import { fetchCurrentUser, logOut } from 'services/saga'
import districtFormSaga from 'page-modules/dashboard/district-form/service/saga'
import districtsFiltersSaga from 'page-modules/dashboard/districts/service/saga'
import manageSchoolFormSaga from 'page-modules/dashboard/school-creation/service/saga'
import countriesService, { patchCountryService } from 'services/countries'
import userOnboardingFormSaga from 'services/onboarding/saga'
import { getTotalSchoolsService, postSchoolService } from 'services/schools'
import { postOnboardingService } from 'services/onboarding'
import {
  getTotalDistrictsService,
  postDistrictService,
} from 'page-modules/dashboard/districts/service'
import statesService, { patchStateService, postStateService } from 'services/states'
import citiesService, { patchCityService, postCityService } from 'services/cities'
import emotionWordsService, {
  deleteEmotionWordService,
  patchEmotionWordService,
  postEmotionWordService,
} from 'services/emotion-words'
import jobTitlesService, {
  deleteJobTitleService,
  patchJobTitleService,
  postJobTitleService,
} from 'services/job-titles'
import gradesService from 'services/grades'
import stateSaga from 'page-modules/states/service/saga'
import citySaga from 'page-modules/cities/service/saga'
import countrySaga from 'page-modules/countries/service/saga'
import subjectsService, {
  deleteSubjectService,
  patchSubjectService,
  postSubjectService,
} from 'services/subjects'
import phasesService from 'services/phases'
import offlineTrainingsService from 'services/offline-trainings'
import htmlContentSaga from 'common/services/html-content/saga'
import {
  moduleService,
  postActivityResponse,
  postModuleProgressService,
} from 'page-modules/module/service/'
import { modulesService } from 'services/modules'
import {
  getShowcaseBySlugService,
  getShowcaseByTagService,
  getShowcaseService,
  getTopShowcaseTagsService,
  postShowcaseService,
} from 'services/showcase'
import addShowcaseFormSaga from 'services/showcase/form/saga'
import filterShowcaseSaga from 'services/showcase/filter/saga'
import showcaseSaga from 'services/showcase/saga'
import {
  getSchoolNudgeSetService,
  getTopNudgeSetService,
  getTopNudgeStatisticsService,
} from 'services/nudges'
import moduleSaga from 'page-modules/module/service/saga'
import { courseService, coursesService } from 'page-modules/courses/service'
import districtsService, { searchDistrictsService } from 'services/districts'
import personRolesService from 'services/person-roles'
import personTypesService from 'services/person-types'
import {
  getBookmarkedDistrictsService,
  getDistrictDetailsService,
  getDistrictSchoolsListService,
  getDistrictSchoolsService,
  getDistrictUsersService,
} from 'page-modules/dashboard/district-details/service'
import {
  downloadMemberListService,
  getAdminUsersStatistics,
  getYceiUsersService,
  getYceiUsersStatistics,
} from 'page-modules/dashboard/ycei-members/service'
import addYceiUserFormSaga from 'page-modules/dashboard/ycei-members/service/saga'
import addDistrictUserFormSaga from 'page-modules/dashboard/district-details/service/saga'
import addSchoolUserFormSaga from 'page-modules/dashboard/school-details/service/saga'
import {
  getCollectionDetailsService,
  getCollectionResourcesService,
  getCollectionsService,
  getFavoritesService,
  getGlossaryService,
  getResourceDetailsService,
  postFavoriteService,
  postVisitResourceService,
  searchResourcesService,
} from 'page-modules/resources/service'
import {
  completeStrategyService,
  getAllStrategiesService,
  getStrategyTopicsService,
  signoutStrategyService,
  signupStrategyService,
} from 'page-modules/rulerize/service'
import { getMyStrategiesService } from 'services/strategies'
import {
  deleteUserService,
  downloadUserActivationLinksService,
  getActivationTokenService,
  getResetPasswordTokenService,
  getTotalPeopleService,
  getUserPreferences,
  patchUserService,
  postResendInvitation,
  postUserService,
  publicUserService,
  searchUsersService,
  userExistenceService,
} from 'services/users'
import usersSaga from 'services/users/saga'
import moodMeterSagas from 'page-modules/mood-meter/service'
import audienceTypesService from 'services/audience-types'
import { getAssessmentService, postAssessmentResultService } from 'page-modules/assessment/service'
import { getAssessmentsService } from 'services/assessments'
import { getJournalsService, patchJournalService, postJournalService } from 'services/journals'
import addJournalFormSaga from 'page-modules/journal/service/saga'
import charterSaga from 'page-modules/charter/service/saga'
import {
  deleteCharterService,
  getCharterDetailsService,
  getChartersService,
  getCharterTemplatesService,
  postCharterService,
} from 'page-modules/charter/service'
import updateUserSettingsSaga, { updateUserSettingsService } from 'page-modules/settings/service'
import { changePasswordService } from 'page-modules/settings/password/service'
import changePasswordSaga from 'page-modules/settings/password/service/saga'
import { saveEmotionWordsService } from 'page-modules/dashboard/emotion-words-form/service'
import {
  classroomGradeService,
  classroomGradesService,
  classroomGradeUnitsService,
  classroomLessonService,
  earlyChildhoodMaterialsService,
  elementarySchoolMaterialsService,
  middleSchoolsMaterialsService,
  postLessonProgressService,
  schoolCategoriesService,
  schoolCategoryService,
} from 'page-modules/curriculum/service'
import {
  staffCharterContributionsService,
  staffCharterCreateRevisionService,
  staffCharterMyContributionsService,
  staffCharterRevisionService,
  staffCharterWordCloudContributionsService,
} from 'page-modules/charter/staff-charter/service'
import {
  createStaffCharterSurveyService,
  getLatestStaffCharterSurveyService,
  getStaffCharterSurveyReportsService,
  getStaffCharterSurveysService,
  submitStaffCharterSurveyService,
} from 'page-modules/charter/staff-charter/survey/service'
import staffCharterSaga from 'page-modules/charter/staff-charter/service/saga'
import staffCharterSurveySaga from 'page-modules/charter/staff-charter/survey/service/saga'
import staffCharterSurveyFormSaga from 'page-modules/charter/staff-charter/survey/form/service/saga'
import staffCharterSurveyListSaga from 'page-modules/charter/staff-charter/survey/list/service/saga'
import classroomMaterialsSaga from 'page-modules/curriculum/service/saga'
import saveEmotionWordsSagas from 'page-modules/dashboard/emotion-words-form/service/saga'
import coursePageDataSaga from 'page-modules/courses/course-details/service/saga'
import assessmentPageDataSaga from 'page-modules/assessment/service/saga'
import manageUserFormSaga from 'page-modules/dashboard/users/service/saga'
import csvUploadUsersForm from 'page-modules/dashboard/users/upload-csv/service/saga'
import nudgesSaga from 'page-modules/roadmap/nudges/service/saga'
import submitFavoriteSaga from 'page-modules/resources/service/saga'
import contributeEmotionWordsSaga from 'page-modules/charter/staff-charter/contribute-emotion-words-form/service/saga'
import approveContributionsSaga from 'page-modules/charter/staff-charter/approve-contributions-form/service/saga'
import socketCommunicationSaga from 'common/services/socket-communication/saga'
import {
  getActivityStreamService,
  getAnnouncementsService,
  markActivityStreamService,
  postActivityStreamMessageService,
} from 'common/services/notifications'
import activityStreamSaga from 'common/services/notifications/saga'
import loginMainSaga from 'common/services/login/saga'
import registerSaga from 'page-modules/register/service/saga'
import resetPasswordSaga from 'page-modules/reset-password/service/saga'
import forgotPasswordSaga from 'page-modules/forgot-password/service/saga'
import activationSaga from 'page-modules/activation/service/saga'
import emotionWordFormSaga from 'page-modules/emotion-words/emotion-word-form/service/saga'
import jobTitleFormSaga from 'page-modules/job-titles/job-title-form/service/saga'
import subjectFormSaga from 'page-modules/subjects/subject-form/service/saga'
import announcementFormSaga from 'page-modules/announcements/announcement-form/service/saga'
import mixpanelSaga from 'common/services/mixpanel/saga'

es6promise.polyfill()

function* rootSaga() {
  yield all([
    takeLatest(LOG_OUT, logOut),
    takeLatest(FETCH_CURRENT_USER, fetchCurrentUser),
    fork(htmlContentSaga),
    fork(getYceiUsersService.saga),
    fork(getYceiUsersStatistics.saga),
    fork(getAdminUsersStatistics.saga),
    fork(addYceiUserFormSaga),
    fork(getBookmarkedSchoolsService.saga),
    fork(getBookmarkedDistrictsService.saga),
    fork(getDistrictDetailsService.saga),
    fork(getDistrictUsersService.saga),
    fork(getDistrictSchoolsService.saga),
    fork(getDistrictSchoolsListService.saga),
    fork(moduleService.saga),
    fork(modulesService.saga),
    fork(postShowcaseService.saga),
    fork(getTopShowcaseTagsService.saga),
    fork(getShowcaseService.saga),
    fork(getShowcaseBySlugService.saga),
    fork(getShowcaseByTagService.saga),
    fork(showcaseSaga),
    fork(addShowcaseFormSaga),
    fork(filterShowcaseSaga),
    fork(postModuleProgressService.saga),
    fork(postActivityResponse.saga),
    fork(moduleSaga),
    fork(courseService.saga),
    fork(coursesService.saga),
    fork(districtsService.saga),
    fork(schoolsService.saga),
    fork(schoolsFiltersSaga),
    fork(districtFormSaga),
    fork(districtsFiltersSaga),
    fork(postDistrictService.saga),
    fork(postOnboardingService.saga),
    fork(addDistrictUserFormSaga),
    fork(statesService.saga),
    fork(postStateService.saga),
    fork(patchStateService.saga),
    fork(jobTitlesService.saga),
    fork(deleteJobTitleService.saga),
    fork(postJobTitleService.saga),
    fork(patchJobTitleService.saga),
    fork(gradesService.saga),
    fork(getCharterTemplatesService.saga),
    fork(subjectsService.saga),
    fork(deleteSubjectService.saga),
    fork(patchSubjectService.saga),
    fork(postSubjectService.saga),
    fork(citiesService.saga),
    fork(postCityService.saga),
    fork(patchCityService.saga),
    fork(countriesService.saga),
    fork(patchCountryService.saga),
    fork(emotionWordsService.saga),
    fork(deleteEmotionWordService.saga),
    fork(postEmotionWordService.saga),
    fork(patchEmotionWordService.saga),
    fork(personRolesService.saga),
    fork(personTypesService.saga),
    fork(manageSchoolFormSaga),
    fork(userOnboardingFormSaga),
    fork(postSchoolService.saga),
    fork(schoolDetailsService.saga),
    fork(addSchoolUserFormSaga),
    fork(getSchoolUsersService.saga),
    fork(getSchoolUserStatisticsService.saga),
    fork(phasesService.saga),
    fork(offlineTrainingsService.saga),
    fork(getGlossaryService.saga),
    fork(getCollectionsService.saga),
    fork(getCollectionDetailsService.saga),
    fork(getCollectionResourcesService.saga),
    fork(postFavoriteService.saga),
    fork(getResourceDetailsService.saga),
    fork(searchResourcesService.saga),
    fork(getFavoritesService.saga),
    fork(postVisitResourceService.saga),
    fork(getAllStrategiesService.saga),
    fork(getMyStrategiesService.saga),
    fork(getStrategyTopicsService.saga),
    fork(signupStrategyService.saga),
    fork(signoutStrategyService.saga),
    fork(completeStrategyService.saga),
    fork(postUserService.saga),
    fork(searchUsersService.saga),
    fork(publicUserService.sagaTakeEvery),
    fork(userExistenceService.saga),
    fork(usersSaga),
    fork(patchUserService.saga),
    fork(deleteUserService.saga),
    fork(getUserPreferences.saga),
    fork(postResendInvitation.saga),
    fork(moodMeterSagas),
    fork(audienceTypesService.saga),
    fork(getAssessmentService.saga),
    fork(postAssessmentResultService.saga),
    fork(getAssessmentsService.saga),
    fork(getJournalsService.saga),
    fork(postJournalService.saga),
    fork(patchJournalService.saga),
    fork(addJournalFormSaga),
    fork(charterSaga),
    fork(getChartersService.saga),
    fork(getCharterDetailsService.saga),
    fork(deleteCharterService.saga),
    fork(postCharterService.saga),
    fork(updateUserSettingsService.saga),
    fork(updateUserSettingsSaga),
    fork(changePasswordService.saga),
    fork(changePasswordSaga),
    fork(saveEmotionWordsService.saga),
    fork(saveEmotionWordsSagas),
    fork(coursePageDataSaga),
    fork(assessmentPageDataSaga),
    fork(downloadMemberListService.saga),
    fork(getTotalSchoolsService.saga),
    fork(getTotalDistrictsService.saga),
    fork(getSchoolHowWantToFeelService.saga),
    fork(getTop5MoodMeterEmotionWordsService.saga),
    fork(getMoodMeterCheckInsService.saga),
    fork(getChartersPerAudienceService.saga),
    fork(getSchoolCourseCompletionService.saga),
    fork(getSchoolTopResourcesService.saga),
    fork(getSchoolTopResourcesCsvService.saga),
    fork(getJournalPostsService.saga),
    fork(getRulerizeCommitmentsService.saga),
    fork(manageUserFormSaga),
    fork(csvUploadUsersForm),
    fork(submitFavoriteSaga),
    fork(getActivationTokenService.saga),
    fork(getResetPasswordTokenService.saga),
    fork(downloadUserActivationLinksService.saga),
    fork(getTotalPeopleService.saga),
    fork(searchSchoolsService.saga),
    fork(searchDistrictsService.saga),
    fork(nudgesSaga),
    fork(getSchoolNudgeSetService.saga),
    fork(getTopNudgeSetService.saga),
    fork(getTopNudgeStatisticsService.saga),
    fork(classroomMaterialsSaga),
    fork(schoolCategoriesService.saga),
    fork(schoolCategoryService.saga),
    fork(classroomGradesService.saga),
    fork(classroomGradeService.saga),
    fork(classroomGradeUnitsService.saga),
    fork(classroomLessonService.saga),
    fork(postLessonProgressService.saga),
    fork(earlyChildhoodMaterialsService.saga),
    fork(middleSchoolsMaterialsService.saga),
    fork(elementarySchoolMaterialsService.saga),
    fork(staffCharterContributionsService.saga),
    fork(staffCharterCreateRevisionService.saga),
    fork(staffCharterMyContributionsService.saga),
    fork(staffCharterWordCloudContributionsService.saga),
    fork(staffCharterRevisionService.saga),
    fork(createStaffCharterSurveyService.saga),
    fork(submitStaffCharterSurveyService.saga),
    fork(getStaffCharterSurveyReportsService.saga),
    fork(getLatestStaffCharterSurveyService.saga),
    fork(getStaffCharterSurveysService.saga),
    fork(staffCharterSurveyListSaga),
    fork(staffCharterSaga),
    fork(staffCharterSurveySaga),
    fork(staffCharterSurveyFormSaga),
    fork(contributeEmotionWordsSaga),
    fork(approveContributionsSaga),
    fork(socketCommunicationSaga),
    fork(activityStreamSaga),
    fork(getActivityStreamService.saga),
    fork(postActivityStreamMessageService.saga),
    fork(markActivityStreamService.saga),
    fork(getAnnouncementsService.saga),
    fork(loginMainSaga),
    fork(registerSaga),
    fork(resetPasswordSaga),
    fork(forgotPasswordSaga),
    fork(activationSaga),
    fork(emotionWordFormSaga),
    fork(jobTitleFormSaga),
    fork(subjectFormSaga),
    fork(announcementFormSaga),
    fork(stateSaga),
    fork(citySaga),
    fork(countrySaga),
    fork(mixpanelSaga),
  ])
}

export default rootSaga

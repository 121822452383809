import buildService from 'common/utils/build-service'
import { GET, POST } from 'common/requests'
import { roles } from 'common/utils/determine-user'

export const getYceiUsersService = buildService({
  type: GET,
  constantsPrefix: 'YCEI_USERS',
  isArray: true,
  http: {
    createApiUrlSuffix: () => {
      const select = `id,name,email,role,person_type:person_types(id,name),office_phone,mobile_phone,deleted,created_at,photo_url,status`
      const role = `(role.eq.${roles.ruler.member},role.eq.${roles.ruler.yceiAdmin},role.eq.${roles.ruler.admin})`
      return `/persons?select=${select}&or=${role}`
    },
  },
})

export const getYceiUsersStatistics = buildService({
  type: POST,
  constantsPrefix: 'YCEI_USERS_STATISTICS',
  isArray: true,
  http: {
    createApiUrlSuffix: () =>
      '/rpc/total_people_by_type?v_role=eq._ycei_roles_total&v_type=eq._all_types_total',
    headers: {
      Accept: 'application/json',
      Prefer: 'params=single-object',
    },
  },
})

export const getAdminUsersStatistics = buildService({
  type: POST,
  constantsPrefix: 'ADMIN_USERS_STATISTICS',
  isArray: true,
  http: {
    createApiUrlSuffix: () =>
      `/rpc/total_people_by_type?v_role=eq.${roles.ruler.admin}&v_type=eq._all_types_total`,
    headers: {
      Accept: 'application/json',
      Prefer: 'params=single-object',
    },
  },
})

export const downloadMemberListService = buildService({
  type: POST,
  constantsPrefix: 'MEMBER_LIST',
  http: {
    createApiUrlSuffix: () => '/rpc/list_of_people',
    headers: {
      Accept: 'text/csv',
      Prefer: 'params=single-object',
    },
  },
})

export const CREATE_STAFF_CHARTER_SURVEY = 'CREATE_STAFF_CHARTER_SURVEY'
export const TOGGLE_STAFF_CHARTER_SURVEY_PREVIEW = 'TOGGLE_STAFF_CHARTER_SURVEY_PREVIEW'
export const CLOSE_STAFF_CHARTER_SURVEY = 'CLOSE_STAFF_CHARTER_SURVEY'
export const CREATE_STAFF_CHARTER_SURVEY_PREVIEW = 'CREATE_STAFF_CHARTER_SURVEY_PREVIEW'
export const PREVIEW_STAFF_CHARTER_SURVEY = 'PREVIEW_STAFF_CHARTER_SURVEY'
export const LOAD_STAFF_CHARTER_SURVEYS = 'LOAD_STAFF_CHARTER_SURVEYS'

export function createStaffCharterSurvey(data) {
  return {
    type: CREATE_STAFF_CHARTER_SURVEY,
    payload: data,
  }
}

export function toggleStaffCharterSurveyPreview(visible) {
  return {
    type: TOGGLE_STAFF_CHARTER_SURVEY_PREVIEW,
    payload: visible,
  }
}

export function closeStaffCharterSurvey(data) {
  return {
    type: CLOSE_STAFF_CHARTER_SURVEY,
    payload: data,
  }
}

export function createStaffCharterSurveyPreview(data) {
  return {
    type: CREATE_STAFF_CHARTER_SURVEY_PREVIEW,
    payload: data,
  }
}

export function previewStaffCharterSurvey() {
  return {
    type: PREVIEW_STAFF_CHARTER_SURVEY,
  }
}

export function loadStaffCharterSurveys() {
  return {
    type: LOAD_STAFF_CHARTER_SURVEYS,
  }
}

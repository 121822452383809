import {
  POST_UPDATE_USER_STRATEGIES_REQUEST,
  POST_UPDATE_USER_STRATEGIES_REQUEST_FAILURE,
  POST_UPDATE_USER_STRATEGIES_REQUEST_SUCCESS,
} from './actions'

export const initialState = () => ({
  items: [],
  loading: false,
  error: null,
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case POST_UPDATE_USER_STRATEGIES_REQUEST:
      return {
        ...state,
        items: [],
        loading: true,
        error: null,
      }

    case POST_UPDATE_USER_STRATEGIES_REQUEST_SUCCESS:
      return {
        ...state,
        items: action.payload.responses,
        loading: false,
      }

    case POST_UPDATE_USER_STRATEGIES_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }

    default:
      return state
  }
}

export const CHANGE_JOB_TITLE_FIELD = 'CHANGE_JOB_TITLE_FIELD'
export const SUBMIT_JOB_TITLE = 'SUBMIT_JOB_TITLE'
export const VALIDATE_JOB_TITLE_FIELD = 'VALIDATE_JOB_TITLE_FIELD'
export const ADD_JOB_TITLE_FIELD_ERROR = 'ADD_JOB_TITLE_FIELD_ERROR'
export const ADD_JOB_TITLE_FIELDS_ERRORS = 'ADD_JOB_TITLE_FIELDS_ERRORS'
export const CLEAR_JOB_TITLE_FIELD_ERROR = 'CLEAR_JOB_TITLE_FIELD_ERROR'
export const RESET_JOB_TITLE_FORM_DATA = 'RESET_JOB_TITLE_FORM_DATA'
export const PREDEFINE_JOB_TITLE_FORM_DATA = 'PREDEFINE_JOB_TITLE_FORM_DATA'
export const DELETE_JOB_TITLE = 'DELETE_JOB_TITLE'

export function changeJobTitleFormField(key, value) {
  return {
    type: CHANGE_JOB_TITLE_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function submitJobTitleFrom(payload) {
  return {
    type: SUBMIT_JOB_TITLE,
    payload,
  }
}

export function predefineJobTitleForm(payload) {
  return {
    type: PREDEFINE_JOB_TITLE_FORM_DATA,
    payload,
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_JOB_TITLE_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_JOB_TITLE_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_JOB_TITLE_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetJobTitleForm() {
  return {
    type: RESET_JOB_TITLE_FORM_DATA,
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_JOB_TITLE_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

export function deleteJobTitle({ jobTitleId }) {
  return {
    type: DELETE_JOB_TITLE,
    payload: { jobTitleId },
  }
}

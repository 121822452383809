import { CLOSE_NAV_DROPDOWN, OPEN_NAV_DROPDOWN } from './actions'

export const initialState = () => {
  return {
    openedDropdown: null,
  }
}

export default function root(state = initialState(), action) {
  switch (action.type) {
    case OPEN_NAV_DROPDOWN:
      return {
        ...state,
        openedDropdown: action.payload,
      }

    case CLOSE_NAV_DROPDOWN:
      return {
        ...state,
        openedDropdown: null,
      }

    default:
      return state
  }
}

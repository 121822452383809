import { AppState } from 'common/store/reducer'
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { changeModal } from 'services/actions'
import { requestHtmlContent } from 'common/services/html-content/actions'
import ExternalHtml from 'services/html-content/external-html'

const AFTER_LOGIN_POPUP_KEY = 'global/after-login-popup'
export const AFTER_LOGIN_POPUP_SHOWN_KEY = 'after-login-popup-shown'

const connector = connect(
  (state: AppState) => {
    return {
      htmlContentStore: state.htmlContent,
    }
  },
  { changeModal, requestHtmlContent },
)
export type AfterLoginPopupProps = ConnectedProps<typeof connector>

class AfterLoginPopup extends React.Component<AfterLoginPopupProps> {
  componentDidMount() {
    this.props.requestHtmlContent({ keys: [AFTER_LOGIN_POPUP_KEY] })
  }

  componentDidUpdate() {
    if (
      this.props.htmlContentStore[AFTER_LOGIN_POPUP_KEY] &&
      JSON.parse(localStorage.getItem(AFTER_LOGIN_POPUP_SHOWN_KEY) || 'false') !== true
    ) {
      // setTimeout(() => {
      this.props.changeModal({
        isOpen: true,
        content: (
          <div className="after-login-popup">
            <ExternalHtml slug={AFTER_LOGIN_POPUP_KEY} />
          </div>
        ),
        className: 'after-login-popup-modal',
        shouldCloseOnOverlayClick: false,
        onClose: () => {
          this.props.changeModal({ isOpen: false })
          localStorage.setItem(AFTER_LOGIN_POPUP_SHOWN_KEY, JSON.stringify(true))
        },
      })
      // }, 100)
    }
  }

  render() {
    return null
  }
}

export default connector(AfterLoginPopup)

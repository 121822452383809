import _ from 'lodash'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { changeModal } from 'services/actions'
import citiesService, { patchCityService, postCityService } from 'services/cities'
import { CityPayload, SUBMIT_CITY } from './actions'

export function* submitCitySaga({ payload }: { payload: CityPayload }) {
  const requestData = {
    ..._.omit(payload, 'stateId'),
    state_id: payload.stateId,
  }
  let cityResult
  if (payload.id) {
    // @ts-expect-error no saga types
    cityResult = yield call(patchCityService.requestSaga, {
      payload: {
        data: requestData,
        urlParams: {
          id: payload.id,
        },
      },
    })
  } else {
    // @ts-expect-error no saga types
    cityResult = yield call(postCityService.requestSaga, {
      payload: {
        data: requestData,
      },
    })
  }

  if (cityResult.error) {
    // handle error
  } else {
    // @ts-expect-error no saga types
    yield call(citiesService.requestSaga, {
      payload: { urlParams: { stateId: payload.stateId } },
    })
    yield put(
      changeModal({
        isOpen: false,
      }),
    )
  }
}

export default function* saga() {
  // @ts-expect-error no saga types
  yield all([takeLatest(SUBMIT_CITY, submitCitySaga)])
}

import _ from 'lodash'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import {
  deleteAnnouncementService,
  getActivityStreamService,
  getAnnouncementsService,
  postActivityStreamMessageService,
} from 'common/services/notifications'
import { changeModal } from 'services/actions'
import { translateResponseError } from 'common/utils'
import { validateField, validateFields } from 'common/validator'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  DELETE_ANNOUNCEMENT,
  resetAnnouncementForm,
  SUBMIT_ANNOUNCEMENT,
  VALIDATE_ANNOUNCEMENT_FIELD,
} from './actions'

const prepareData = (payload) => {
  const message = {
    title: payload.title.value,
    content: payload.content.value,
    sticky: true,
  }

  // Update of announcement
  if ('id' in payload) {
    return {
      activity_stream_id: payload.id.value,
      scope: 'auth', // post the message to all logged users
      message,
    }
  }

  return {
    scope: 'auth', // post the message to all logged users
    message,
  }
}

export function* submitAnnouncementSaga({ payload }) {
  const errors = validateFields(payload)
  if (_.isEmpty(errors)) {
    const data = prepareData(payload)
    const announcementResult = yield call(postActivityStreamMessageService.requestSaga, {
      payload: { data },
    })

    if (announcementResult.error) {
      yield put(
        addFieldError({
          field: 'base',
          error: translateResponseError(announcementResult.error),
        }),
      )
    } else {
      yield put(changeModal({ isOpen: false }))
      yield put(resetAnnouncementForm())
      // Update announcements list
      yield put(getAnnouncementsService.actions.request())
      // Update notifications panel
      yield put(getActivityStreamService.actions.request())
    }
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export function* deleteAnnouncementSaga({ payload: { announcementId } }) {
  const result = yield call(deleteAnnouncementService.requestSaga, {
    payload: {
      data: {
        activity_stream_id: announcementId,
      },
    },
  })

  if (!result.error) {
    yield put(changeModal({ isOpen: false }))
    // Update announcements list
    yield put(getAnnouncementsService.actions.request())
    // Update notifications panel
    yield put(getActivityStreamService.actions.request())
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_ANNOUNCEMENT_FIELD, validateFieldSaga),
    takeLatest(SUBMIT_ANNOUNCEMENT, submitAnnouncementSaga),
    takeLatest(DELETE_ANNOUNCEMENT, deleteAnnouncementSaga),
  ])
}

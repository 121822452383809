export const ADD_CHARTER_SURVEY_FIELD_ERROR = 'ADD_CHARTER_SURVEY_FIELD_ERROR'
export const ADD_CHARTER_SURVEY_FIELDS_ERRORS = 'ADD_CHARTER_SURVEY_FIELDS_ERRORS'
export const CHANGE_CHARTER_SURVEY_FORM_FIELD = 'CHANGE_CHARTER_SURVEY_FORM_FIELD'
export const CLEAR_CHARTER_SURVEY_FIELD_ERROR = 'CLEAR_CHARTER_SURVEY_FIELD_ERROR'
export const PREDEFINE_CHARTER_SURVEY_FORM_DATA = 'PREDEFINE_CHARTER_SURVEY_FORM_DATA'
export const RESET_CHARTER_SURVEY_FORM_DATA = 'RESET_CHARTER_SURVEY_FORM_DATA'
export const SUBMIT_STAFF_CHARTER_SURVEY = 'SUBMIT_STAFF_CHARTER_SURVEY'
export const VALIDATE_CHARTER_SURVEY_FIELD = 'VALIDATE_CHARTER_SURVEY_FIELD'

export function predefineSurveyForm(data) {
  return {
    type: PREDEFINE_CHARTER_SURVEY_FORM_DATA,
    payload: data,
  }
}

export function changeSurveyFormField(key, value) {
  return {
    type: CHANGE_CHARTER_SURVEY_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function submitStaffCharterSurvey(data) {
  return {
    type: SUBMIT_STAFF_CHARTER_SURVEY,
    payload: data,
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_CHARTER_SURVEY_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_CHARTER_SURVEY_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_CHARTER_SURVEY_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetSurveyFormData() {
  return {
    type: RESET_CHARTER_SURVEY_FORM_DATA,
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_CHARTER_SURVEY_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

import { put } from 'redux-saga/effects'

import buildService from 'common/utils/build-service'

import { initAnswers } from './actions'

export const getAssessmentService = buildService({
  type: 'GET',
  constantsPrefix: 'ASSESSMENT',
  http: {
    createApiUrlSuffix: (id) =>
      `/assessments?select=*,assessment_questions(*,assessment_choices(id,title,assessment_question_id))&slug=eq.${id}&assessment_questions.order=weight.asc&assessment_questions.assessment_choices.order=weight.asc`,
    headers: {
      Accept: 'application/vnd.pgrst.object+json',
    },
    *successHandler(assessment) {
      yield put(initAnswers(assessment.assessment_questions))
    },
  },
})

export const postAssessmentResultService = buildService({
  type: 'POST',
  constantsPrefix: 'ASSESSMENT_RESULT',
  http: {
    createApiUrlSuffix: () => `/rpc/assessment_submit`,
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

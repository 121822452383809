export const SUBMIT_STAFF_CHARTER_CONTRIBUTIONS = 'SUBMIT_STAFF_CHARTER_CONTRIBUTIONS'
export const CREATE_STAFF_CHARTER_REVISION = 'CREATE_STAFF_CHARTER_REVISION'
export const LOAD_STAFF_CHARTER_APPROVAL = 'LOAD_STAFF_CHARTER_APPROVAL'
export const CHANGE_EMOTION_WORDS_FILTERS = 'CHANGE_EMOTION_WORDS_FILTERS'
export const THROTTLED_LOAD_STAFF_CHARTER_REVISION = 'THROTTLED_LOAD_STAFF_CHARTER_REVISION'

export function submitStaffCharterContributions(data) {
  return {
    type: SUBMIT_STAFF_CHARTER_CONTRIBUTIONS,
    payload: data,
  }
}

export function createStaffCharterRevision(data) {
  return {
    type: CREATE_STAFF_CHARTER_REVISION,
    payload: data,
  }
}

export function loadStaffCharterApproval() {
  return {
    type: LOAD_STAFF_CHARTER_APPROVAL,
  }
}

export function changeEmotionWordsFilters(emotionWordsIds) {
  return {
    type: CHANGE_EMOTION_WORDS_FILTERS,
    payload: emotionWordsIds,
  }
}

export function throttledLoadStaffCharterRevision() {
  return {
    type: THROTTLED_LOAD_STAFF_CHARTER_REVISION,
  }
}

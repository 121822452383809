export const CHANGE_PROFILE_SETTINGS_FORM_FIELD = 'CHANGE_PROFILE_SETTINGS_FORM_FIELD'
export const SUBMIT_PHOTO_URL = 'SUBMIT_PHOTO_URL'
export const SUBMIT_PROFILE_SETTINGS = 'SUBMIT_PROFILE_SETTINGS'
export const VALIDATE_PROFILE_SETTINGS_FIELD = 'VALIDATE_PROFILE_SETTINGS_FIELD'
export const ADD_PROFILE_SETTINGS_FIELD_ERROR = 'ADD_PROFILE_SETTINGS_FIELD_ERROR'
export const ADD_PROFILE_SETTINGS_FIELDS_ERRORS = 'ADD_PROFILE_SETTINGS_FIELDS_ERRORS'
export const CLEAR_PROFILE_SETTINGS_FIELD_ERROR = 'CLEAR_PROFILE_SETTINGS_FIELD_ERROR'
export const RESET_PROFILE_SETTINGS_FORM_DATA = 'RESET_PROFILE_SETTINGS_FORM_DATA'
export const PREDEFINE_PROFILE_SETTINGS_FORM_DATA = 'PREDEFINE_PROFILE_SETTINGS_FORM_DATA'
export const PRISTINE_PROFILE_SETTINGS_FORM_DATA = 'PRISTINE_PROFILE_SETTINGS_FORM_DATA'

export function changeProfileSettingsFormField(key, value) {
  return {
    type: CHANGE_PROFILE_SETTINGS_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}
export function submitProfileSettings(payload) {
  return {
    type: SUBMIT_PROFILE_SETTINGS,
    payload,
  }
}

export function submitPhotoUrl(payload) {
  return {
    type: SUBMIT_PHOTO_URL,
    payload,
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_PROFILE_SETTINGS_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_PROFILE_SETTINGS_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_PROFILE_SETTINGS_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetProfileSettingsFormData() {
  return {
    type: RESET_PROFILE_SETTINGS_FORM_DATA,
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_PROFILE_SETTINGS_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

export function predefineProfileSettingsForm(user) {
  return {
    type: PREDEFINE_PROFILE_SETTINGS_FORM_DATA,
    payload: {
      email: user.email,
      name: user.name,
      officePhone: user.office_phone,
      mobilePhone: user.mobile_phone,
      jobTitle: user.job_title_id,
    },
  }
}

export function pristineProfileSettingsForm() {
  return {
    type: PRISTINE_PROFILE_SETTINGS_FORM_DATA,
  }
}

import { all, call, takeLatest } from 'redux-saga/effects'

import { courseService } from 'page-modules/courses/service'

import { LOAD_ASSESSMENT_DETAILS_PAGE_DATA } from './actions'

import { getAssessmentService } from './index'

export function* loadAssessmentPageDataSaga({ payload: { assessmentSlug, req } }) {
  const assessment = yield call(getAssessmentService.requestSaga, {
    payload: { req, urlParams: assessmentSlug },
  })
  yield call(courseService.requestSaga, {
    payload: { req, urlParams: { id: assessment.course_id } },
  })
}

export default function* saga() {
  yield all([takeLatest(LOAD_ASSESSMENT_DETAILS_PAGE_DATA, loadAssessmentPageDataSaga)])
}

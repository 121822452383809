import buildService from 'common/utils/build-service'
import { GET, PATCH, POST } from 'common/requests'

export const getJournalsService = buildService({
  type: GET,
  constantsPrefix: 'JOURNALS',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ personId }) => {
      if (personId) {
        return `/journals?person_id=eq.${personId}`
      }
      return '/journals'
    },
  },
})

export const postJournalService = buildService({
  type: POST,
  constantsPrefix: 'CREATE_JOURNAL',
  http: {
    createApiUrlSuffix: () => '/journals',
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

export const patchJournalService = buildService({
  type: PATCH,
  constantsPrefix: 'CREATE_JOURNAL',
  http: {
    createApiUrlSuffix: ({ id }) => `/journals?id=eq.${id}`,
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

import _ from 'lodash'
import { AnyAction } from 'redux'
import NProgress from 'nprogress'
import { NextPageContext } from 'next'
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { all, call, Effect, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { httpRequest } from 'services/saga'
import { getApiUrl } from 'services/selectors'
import { getJWT, trimData } from 'common/utils'
import {
  ApiRequestConfig,
  DELETE,
  GET,
  PATCH,
  POST,
  RequestOptions,
  requests,
} from 'common/requests'
import { StateAction } from 'services/actions'

/*
  Example usage:

  buildService({
    type: GET,
    constantsPrefix: 'CLASSROOM_GRADE_UNITS',
    isArray: true,
    http: {
      createApiUrlSuffix: ({ gradeSlug }) =>
        `/classroom_grade_units?classroom_grade_slug=eq.${gradeSlug}`,
    },
})

  Creates an object for making an AJAX request and storing the result. Creates actions, sagas and reducer in the Redux sense. The create object is called a service.
  Params:
    type - one of DELETE, GET, PATCH, POST from 'common/requests' . Signifies the type of the AJAX request

    constantsPrefix - String . Signifies the string that will be prefixed to the Redux actions generated by the this. It's the responsibility of the programmer to make sure no 2 services have the same constantsPrefix

    isArray - Bool . Signifies whether the initital state should be an empty array or an empty object. This should match the expected format of the result of the AJAX call. If the call returns an array of results isArray should be set to true.

    addItemActionType - String . The action type on which the state of this service's reducer will be extended with the item passed in the action's paylod. Example usage: connect the reducer to websocket event.

    transformItem - Function . Accepts one argument. The item or items are passed to this function and the result(s) are stored in the service's reducer.

    http.createApiUrlSuffix - Function . The result of this function will be suffixed to thr base api URL. Use this for specifying which URL the AJAX request should be directed at. Receives urlParams object which is derived from the action/saga payload.

    http.successHandler - Function . Called when the AJAX request has been successful. Receives the result of the request as a parameter. It's preferred to use a saga for using the result on a request and not the successHandler

    http.failureHandler - Funcion . Called when the AJAX request has failed. Receives the error of the request as a parameter. It's preferred to use a saga for adding a logic for handling the error and not the failureHandler

    http.headers - Object . Builds the headers to send with the AJAX request. The keys are used as name of the headers and values as the body of the headers.

    http.defaultData - Object . The data that will be passed to the request unless other data is specified when calling the actions.request

    forceProgress - Bool . Forces to (nearly)always show the progress bar for the request. By default the progress bar is shown for request with types different than GET.

    suppressProgress - Bool . Forces to always hide the progress bar for the request. This overrides the value of forceProgress
 */
export type Service<UrlParams, ResponseData, RequestData, Transformed> = {
  type: typeof GET | typeof POST | typeof PATCH | typeof DELETE
  constantsPrefix: string
  isArray?: boolean
  addItemActionType?: string
  transformItem?: (payload: ResponseData) => ResponseData | Transformed
  forceProgress?: boolean
  suppressProgress?: boolean
  http: {
    createApiUrlSuffix: (urlParams: UrlParams) => string
    successHandler?:
      | ((
          responseData: ResponseData,
          handlerData?: UrlParams | RequestData,
        ) => Generator<Effect, void, unknown>)
      | (() => void)
    failureHandler?:
      | ((
          error: AxiosError<{ message: string }>,
          requestData?: UrlParams | RequestData,
        ) => Generator<Effect, void, unknown>)
      | (() => void)
    headers?: AxiosRequestConfig['headers']
    requestOptions?: RequestOptions
    defaultData?: RequestData
    apiUrl?: string
  }
}
export type NoParams = Record<string, never>
type ServiceState<T> = {
  items?: T[]
  item?: T
  range?: DataRange
  error: Record<string, never>
  loading: boolean
  lastRequestSuccess: boolean
}
type RequestConstants = {
  [key in 'REQUEST' | 'REQUEST_SUCCESS' | 'REQUEST_FAILURE' | 'REMOVE_ITEM']: string
}
type DataRange =
  | {
      from: number
      to: number
      all: number
    }
  | Record<string, never>
type ServicePayload<UrlParams, ReqData> = {
  unauthorized: boolean
  req: NextPageContext['req']
  urlParams: UrlParams
  data: ReqData
  range: DataRange | undefined
}
type ServiceRequest<U, R> = {
  payload?: ServicePayload<U, R> | undefined
}
type ServiceActions<Req, Resp> = {
  request: (payload?: Req) => AnyAction
  requestSuccess: (data: Resp, dataRange: DataRange) => AnyAction
  requestFailure: (error: ApiResponseType) => AnyAction
  removeItem?: ({ id }: { id: string }) => AnyAction
}
export type ApiResponseType = { message: string }
export type ApiResponseError = AxiosError<ApiResponseType>

export default function buildService<
  UrlParams = NoParams,
  ResponseData = unknown,
  RequestData = unknown,
  Transformed = ResponseData,
>({
  type = GET,
  constantsPrefix,
  isArray = false,
  addItemActionType,
  transformItem = _.identity,
  http: {
    createApiUrlSuffix,
    successHandler = _.noop,
    failureHandler = _.noop,
    headers = {},
    defaultData = {} as RequestData,
    requestOptions,
    apiUrl,
  },
  forceProgress = false,
  suppressProgress = false,
}: Service<UrlParams, ResponseData, RequestData, Transformed>) {
  if (!constantsPrefix) {
    throw new Error('Providing constantsPrefix is mandatory')
  }

  if (!_.isFunction(createApiUrlSuffix)) {
    throw new Error('Providing a function for createApiUrlSuffix is mandatory')
  }

  const stateDataFieldName = isArray ? 'items' : 'item'
  const initialState: ServiceState<ResponseData | Transformed> = {
    [stateDataFieldName]: isArray ? [] : {},
    error: {},
    loading: false,
    lastRequestSuccess: false,
  }

  let reqType = requests.get

  switch (type) {
    case POST:
      reqType = requests.post
      break
    case PATCH:
      reqType = requests.patch
      break
    case DELETE:
      reqType = requests.delete
      break
    default:
      break
  }

  const constants: RequestConstants = {
    REQUEST: `${type}_${constantsPrefix}_REQUEST`,
    REQUEST_SUCCESS: `${type}_${constantsPrefix}_REQUEST_SUCCESS`,
    REQUEST_FAILURE: `${type}_${constantsPrefix}_REQUEST_FAILURE`,
    REMOVE_ITEM: `REMOVE_${constantsPrefix}_ITEM`,
  }
  const INTERNAL_REQUEST = `${type}_${constantsPrefix}_REQUEST_INTERNAL`
  const transformPayload = (payload: ResponseData | ResponseData[]) => {
    if (Array.isArray(payload)) {
      if (!isArray) {
        throw new Error('Response data is array but used as single item')
      }
      return payload.map(transformItem)
    }
    return transformItem(payload)
  }

  const actions: ServiceActions<
    Partial<Pick<ServicePayload<UrlParams, RequestData>, 'data' | 'urlParams'>>,
    ResponseData
  > = {
    request: (payload) => ({
      type: constants.REQUEST,
      payload,
    }),
    requestSuccess: (data, dataRange) => ({
      type: constants.REQUEST_SUCCESS,
      payload: { data, dataRange },
    }),
    requestFailure: (error) => ({
      type: constants.REQUEST_FAILURE,
      payload: { error },
    }),
  }
  const internalRequestAction = () => ({
    type: INTERNAL_REQUEST,
  })

  if (type === GET) {
    actions.removeItem = ({ id }) => ({
      type: constants.REMOVE_ITEM,
      payload: { id },
    })
  }

  const showProgress =
    ((forceProgress && typeof document !== 'undefined') || (process.browser && type !== GET)) &&
    !suppressProgress

  const reducer = (
    state = initialState,
    action: AnyAction,
  ): ServiceState<ResponseData | Transformed> => {
    // Short-fuse reducer when item action type is present
    if (isArray && addItemActionType && action.type === addItemActionType) {
      return {
        ...state,
        items: [
          ...(state.items ?? []),
          transformPayload(action.payload) as ResponseData | Transformed,
        ],
      }
    }

    switch (action.type) {
      case constants.REQUEST:
      case INTERNAL_REQUEST:
        if (showProgress) {
          NProgress.start()
        }
        return {
          ...state,
          error: {},
          loading: true,
          lastRequestSuccess: false,
        }

      case constants.REQUEST_SUCCESS:
        if (showProgress) {
          NProgress.done()
        }
        return {
          ...state,
          [stateDataFieldName]: transformPayload(action.payload.data),
          range: action.payload.dataRange,
          loading: false,
          lastRequestSuccess: true,
        }

      case constants.REQUEST_FAILURE:
        if (showProgress) {
          NProgress.done()
        }
        return {
          ...initialState, // reset the `stateDataFieldName` to it's default value.
          error: action.payload.error,
          loading: false,
          lastRequestSuccess: false,
        }

      case constants.REMOVE_ITEM:
        const clonedState = _.cloneDeep(state)
        if (_.isArray(state.items) && action.payload.id) {
          // @ts-expect-error property id is expected when dealing with removals
          const index = _.findIndex(state.items, { id: action.payload.id })
          if (index >= 0) {
            clonedState.items?.splice(index, 1)
          }
        } else {
          clonedState.item = {} as ResponseData | Transformed
        }
        return clonedState

      default:
        return state
    }
  }

  function* requestSaga(request: ServiceRequest<UrlParams, RequestData>) {
    const { payload } = request
    const {
      unauthorized = false,
      req,
      urlParams = {} as UrlParams,
      data = defaultData,
      range,
    }: ServicePayload<UrlParams, RequestData> = _.isUndefined(payload)
      ? ({} as ServicePayload<UrlParams, RequestData>)
      : payload
    let apiUrlString: ReturnType<typeof getApiUrl>
    if (apiUrl === undefined) {
      apiUrlString = yield select(getApiUrl)
    } else {
      apiUrlString = apiUrl
    }
    const requestHeaders = { ...headers }
    const jwt = getJWT({ req })
    if (typeof range?.to !== 'undefined') {
      requestHeaders.Range = `${_.isUndefined(range.from) ? 0 : range.from}-${range.to}`
      requestHeaders.Prefer = `count=exact${
        _.isUndefined(requestHeaders.Prefer) ? '' : `;${requestHeaders.Prefer}`
      }`
    }
    if (!unauthorized && jwt) {
      requestHeaders.Authorization = `Bearer ${jwt}`
    }
    const handlerData = type === DELETE || type === GET ? urlParams : trimData<RequestData>(data)
    const requestData: ApiRequestConfig<{ emails: string[] }> = {
      req: reqType,
      url: `${apiUrlString}${createApiUrlSuffix(urlParams)}`,
      headers: requestHeaders,
      options: requestOptions,
    }
    if (type === POST || type === PATCH) {
      requestData.data = trimData<RequestData>(data)
    }

    try {
      const { data: responseData, headers: responseHeaders } = yield call(httpRequest, requestData)
      let dataRange: DataRange = {}
      if (!_.isUndefined(responseHeaders['content-range'])) {
        const parsedRange = responseHeaders['content-range'].match(/(\d+)-(\d+)\/(\d+)/)
        if (parsedRange) {
          dataRange = {
            from: Number.parseInt(parsedRange[1], 10),
            to: Number.parseInt(parsedRange[2], 10),
            all: Number.parseInt(parsedRange[3], 10),
          }
        }
      }
      yield put(actions.requestSuccess(responseData, dataRange))
      yield successHandler(responseData, handlerData)
      return responseData
    } catch (error) {
      if (axios.isAxiosError(error)) {
        yield put(actions.requestFailure(error))
        // Pass the full error response to the failure handler
        yield failureHandler(error as ApiResponseError, handlerData)
        // Returns an error so others can consume it
      }
      return { error }
    }
  }

  return {
    constants,
    actions,
    reducer,
    *requestSaga<T, P>(params: StateAction<T, P>) {
      yield put(internalRequestAction())
      // @ts-expect-error no type yet
      return yield call(requestSaga, params)
    },
    *saga() {
      // @ts-expect-error no type yet
      yield all([takeLatest(constants.REQUEST, requestSaga)])
    },
    // Warning: Be sure to use addItemActionType when using sagaTakeEvery
    *sagaTakeEvery() {
      // @ts-expect-error no type yet
      yield all([takeEvery(constants.REQUEST, requestSaga)])
    },
  }
}

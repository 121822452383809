import React from 'react'
import PropTypes from 'prop-types'

/*
 * Inspired by https://github.com/danilowoz/react-content-loader
 * Online svg tool: http://danilowoz.com/create-content-loader/
 * */
const ContentLoader = ({ primaryColor = '#000', secondaryColor = '#fec', duration = '2s' }) => {
  const primaryOpacity = 0.06
  const secondaryOpacity = 0.12
  const gradientRatio = 0.5
  const keyTimes = `0; 0.5; 1`
  return (
    <svg role="img" version="1.1" viewBox="0 0 550 150" preserveAspectRatio="none">
      <rect
        x="0"
        y="0"
        width={550}
        height={150}
        clipPath={`url(#loader)`}
        style={{ fill: `url(#gradient)` }}
      />
      <defs>
        <clipPath id="loader">
          <rect x="10" y="22" rx="3" ry="3" width="300" height="18" />
          <rect x="10" y="54" rx="3" ry="3" width="180" height="12" />
          <rect x="10" y="80" rx="3" ry="3" width="450" height="6" />
          <rect x="10" y="100" rx="3" ry="3" width="480" height="6" />
          <rect x="10" y="120" rx="3" ry="3" width="340" height="6" />
        </clipPath>
        <linearGradient id="gradient">
          <stop offset="0%" stopColor={primaryColor} stopOpacity={primaryOpacity}>
            <animate
              attributeName="offset"
              values={`${-gradientRatio}; ${-gradientRatio}; 1`}
              keyTimes={keyTimes}
              dur={duration}
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="50%" stopColor={secondaryColor} stopOpacity={secondaryOpacity}>
            <animate
              attributeName="offset"
              values={`${-gradientRatio / 2}; ${-gradientRatio / 2}; ${1 + gradientRatio / 2}`}
              keyTimes={keyTimes}
              dur={duration}
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="100%" stopColor={primaryColor} stopOpacity={primaryOpacity}>
            <animate
              attributeName="offset"
              values={`0; 0; ${1 + gradientRatio}`}
              keyTimes={keyTimes}
              dur={duration}
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

ContentLoader.propTypes = {
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  duration: PropTypes.string,
}

export default ContentLoader

export const CHANGE_ACTIVATION_FORM_FIELD = 'CHANGE_ACTIVATION_FORM_FIELD'
export const ACTIVATION_REQUEST = 'ACTIVATION_REQUEST'
export const ACTIVATION_REQUEST_SUCCESS = 'ACTIVATION_REQUEST_SUCCESS'
export const ACTIVATION_REQUEST_FAILURE = 'ACTIVATION_REQUEST_FAILURE'
export const VALIDATE_ACTIVATION_FIELD = 'VALIDATE_ACTIVATION_FIELD'
export const ADD_ACTIVATION_FIELD_ERROR = 'ADD_ACTIVATION_FIELD_ERROR'
export const CLEAR_ACTIVATION_FIELD_ERROR = 'CLEAR_ACTIVATION_FIELD_ERROR'

export function changeLoginFormField(key, value) {
  return {
    type: CHANGE_ACTIVATION_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function activationRequest({ password, password2, token }) {
  return {
    type: ACTIVATION_REQUEST,
    payload: {
      password,
      password2,
      token,
    },
  }
}

export function activationFailure(errors) {
  return {
    type: ACTIVATION_REQUEST_FAILURE,
    errors,
  }
}

export function activationSuccess(userData) {
  return {
    type: ACTIVATION_REQUEST_SUCCESS,
    payload: {
      userData,
    },
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_ACTIVATION_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_ACTIVATION_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_ACTIVATION_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

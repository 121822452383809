import React from 'react'
import _ from 'lodash'
import { put } from 'redux-saga/effects'
import lang from 'lang'
import { Me, User } from 'common/types'
import { changeModal } from 'services/actions'
import { translateResponseError } from 'common/utils'
import { DELETE, GET, PATCH, POST } from 'common/requests'
import UserActivation from 'common/components/modals/user-activation'
import buildService, { ApiResponseError, NoParams } from 'common/utils/build-service'
import { getDistrictUsersService } from 'page-modules/dashboard/district-details/service'
import { getSchoolUsersService } from 'page-modules/dashboard/school-details/service'
import {
  addFieldError as addDistrictFieldError,
  resetDistrictUserFormData,
} from 'page-modules/dashboard/district-details/service/actions'
import {
  addSchoolAPIError,
  addFieldError as addSchoolFieldError,
  resetSchoolUserFormData,
} from 'page-modules/dashboard/school-details/service/actions'
import {
  addFieldError as addYceiFieldError,
  resetYceiUserFormData,
} from 'page-modules/dashboard/ycei-members/service/actions'
import { getYceiUsersService } from 'page-modules/dashboard/ycei-members/service'
import { addFieldError as addMoveUserFieldError } from 'page-modules/dashboard/users/service/actions'
import { predefineEmotionWordsForm } from 'page-modules/dashboard/emotion-words-form/service/actions'
import {
  changeProfileSettingsFormField,
  pristineProfileSettingsForm,
} from 'page-modules/settings/service/actions'

function consumePostPatchAPIError(error: ApiResponseError) {
  const message = translateResponseError(error, lang.dashboard.userForm)
  const err = { field: 'base', error: message }
  return err
}

export const postUserService = buildService<
  NoParams,
  { me: Me },
  {
    school_id: string | null
    district_id: string | null
  }
>({
  type: POST,
  constantsPrefix: 'CREATE_USER',
  http: {
    createApiUrlSuffix: () => '/rpc/new_account',
    headers: {
      Prefer: 'params=single-object',
    },
    *successHandler(responseData) {
      yield put(
        changeModal({
          isOpen: true,
          aria: {
            labelledby: 'user-activation-data-header',
          },
          className: 'small add-district-users',
          content: <UserActivation single user={responseData.me} />,
        }),
      )
    },
    *failureHandler(error, requestData) {
      const err = consumePostPatchAPIError(error)
      if (requestData?.school_id) {
        yield put(addSchoolAPIError(err))
      } else if (requestData?.district_id) {
        yield put(addDistrictFieldError(err))
      } else {
        yield put(addYceiFieldError(err))
      }
    },
  },
})

export const postUsersService = buildService({
  type: POST,
  constantsPrefix: 'CREATE_USERS',
  http: {
    createApiUrlSuffix: () => '/persons',
    headers: {
      Accept: 'application/json',
      // District/School admin are also able to create multiple school users
      // but they have restricted rights to /person table columns
      // that's why we prefer minimal response from postgrest as we're not consuming any of the result
      // https://trello.com/c/72TUS8fY/2233-fix-unable-to-invite-multiple-users-when-logged-in-as-a-school-admin#comment-5c502f9bc27490777e4ff7ae
      Prefer: 'return=minimal',
    },
    *successHandler() {
      yield put(
        changeModal({
          isOpen: true,
          aria: {
            labelledby: 'user-activation-data-header',
          },
          className: 'small add-district-users',
          content: <UserActivation single={false} />,
        }),
      )
    },
    *failureHandler(error) {
      const err = consumePostPatchAPIError(error)
      yield put(addSchoolAPIError(err))
    },
  },
})

export const toggleUserStatsService = buildService({
  type: PATCH,
  constantsPrefix: 'TOGGLE_USER_STATS',
  http: {
    createApiUrlSuffix: ({ id }) =>
      `/persons?select=id,name,email,district_id,school_id,role,person_type:person_types(id,name),status&id=eq.${id}`,
  },
})

export const patchUserService = buildService<
  { id: string },
  User,
  {
    school_id: string | null
    district_id: string | null
  }
>({
  type: PATCH,
  constantsPrefix: 'UPDATE_PERSON_USER',
  http: {
    createApiUrlSuffix: ({ id }) =>
      `/persons?select=id,name,email,district_id,school_id,role,person_type:person_types(id,name),status&id=eq.${id}`,
    *successHandler(data) {
      yield put(changeModal({ isOpen: false }))
      if (data.school_id) {
        yield put(resetSchoolUserFormData())
        yield put(
          getSchoolUsersService.actions.request({
            urlParams: { schoolId: data.school_id },
          }),
        )
      } else if (data.district_id) {
        yield put(resetDistrictUserFormData())
        yield put(
          getDistrictUsersService.actions.request({
            urlParams: { districtId: data.district_id },
          }),
        )
      } else {
        yield put(resetYceiUserFormData())
        yield put(getYceiUsersService.actions.request())
      }
    },
    *failureHandler(error, requestData) {
      const err = consumePostPatchAPIError(error)
      if (requestData && 'school_id' in requestData && requestData.school_id) {
        yield put(addSchoolFieldError(err))
      } else if (requestData && 'district_id' in requestData && requestData.district_id) {
        yield put(addDistrictFieldError(err))
      } else {
        yield put(addYceiFieldError(err))
      }
    },
  },
})

export const moveUserService = buildService<{ id: string }>({
  type: PATCH,
  constantsPrefix: 'MOVE_PERSON',
  http: {
    createApiUrlSuffix: ({ id }) =>
      `/persons?select=id,name,email,district_id,school_id,role,person_type:person_types(id,name),status&id=eq.${id}`,
    *failureHandler(error) {
      const err = consumePostPatchAPIError(error)
      yield put(addMoveUserFieldError(err))
    },
  },
})

export const deleteUserService = buildService<{ id: string }>({
  type: DELETE,
  constantsPrefix: 'DELETE_USER',
  http: {
    createApiUrlSuffix: ({ id }) => `/persons?select=id,name&id=eq.${id}`,
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/vnd.pgrst.array+json;nulls=stripped',
    },
    *successHandler() {
      yield put(changeModal({ isOpen: false }))
    },
  },
})

export const getUserPreferences = buildService<NoParams, Me>({
  type: GET,
  constantsPrefix: 'USER_PREFERENCES',
  http: {
    createApiUrlSuffix: () => `/rpc/me`,
    *successHandler(data) {
      const { emotion_words, grades, subjects } = data
      yield put(
        predefineEmotionWordsForm({
          emotionWords: _.map(emotion_words, (word) => ({
            id: word.id,
            name: word.name,
            actions: _.isEmpty(word.actions) ? [''] : word.actions,
          })),
        }),
      )
      yield put(changeProfileSettingsFormField('grades', _.map(grades, 'id')))
      yield put(changeProfileSettingsFormField('subjects', _.map(subjects, 'id')))
      yield put(pristineProfileSettingsForm())
    },
  },
})

export const postResendInvitation = buildService({
  type: POST,
  constantsPrefix: 'RESEND_INVITATION',
  http: {
    createApiUrlSuffix: () => `/rpc/resend_invitational_email`,
    headers: {
      Prefer: 'params=single-object',
    },
    *successHandler() {
      yield put(changeModal({ isOpen: false }))
    },
  },
})

export const getTotalPeopleService = buildService({
  type: POST,
  constantsPrefix: 'TOTAL_PEOPLE',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ v_type, v_role }) => {
      const role = v_role ? `v_role=eq.${v_role}&` : ''
      const type = v_type ? `v_type=eq.${v_type}` : ''
      return `/rpc/total_people_by_type?${role}${type}`
    },
    headers: {
      Accept: 'application/json',
      Prefer: 'params=single-object',
    },
  },
})

export const getActivationTokenService = buildService({
  type: POST,
  constantsPrefix: 'REQUEST_ACTIVATION_TOKEN',
  http: {
    createApiUrlSuffix: () => '/rpc/request_activation_token',
    headers: {
      Accept: 'application/json',
      Prefer: 'params=single-object',
    },
  },
})

export const getResetPasswordTokenService = buildService({
  type: POST,
  constantsPrefix: 'REQUEST_RESET_PASSWORD_TOKEN',
  http: {
    createApiUrlSuffix: () => '/rpc/request_reset_password_token',
    headers: {
      Accept: 'application/json',
      Prefer: 'params=single-object',
    },
  },
})

export const downloadUserActivationLinksService = buildService({
  type: POST,
  constantsPrefix: 'DOWNLOAD_ACTIVATION_LINKS',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/rpc/download_activation_links_in_school',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'text/csv',
    },
  },
})

export const searchUsersService = buildService<{
  searchString: string
  districtId: string
  schoolId: string
  limit: number
}>({
  type: POST,
  constantsPrefix: 'SEARCH_USERS',
  isArray: true,
  http: {
    requestOptions: {
      removeDefaultHeaders: true,
    },
    headers: {
      Prefer: 'params=single-object',
    },
    createApiUrlSuffix: () => {
      return `/rpc/find_users`
    },
  },
})

export const publicUserService = buildService<{ id: string }, User[]>({
  type: GET,
  constantsPrefix: 'PUBLIC_USER',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ id }) => {
      const where = `id=eq.${id}`
      const select = 'select=id,name,email,photo_url'
      return `/person?${where}&${select}`
    },
    headers: {
      Accept: 'application/json',
      Prefer: 'params=single-object',
    },
  },
  addItemActionType: 'GET_PUBLIC_USER_REQUEST_SUCCESS',
})

export const userExistenceService = buildService<{ emails: string[] }>({
  type: POST,
  constantsPrefix: 'PERSON_EMAIL_VALIDATION',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/rpc/check_users_exist',
    headers: {
      Accept: 'application/json',
      Prefer: 'params=single-object',
    },
  },
})

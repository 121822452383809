/*
  A hashmap of error messages of our API and their link to our lang.js strings

  { apiErrorMessage: langString }

*/

const ERROR_STRINGS = {
  person_email_key: 'existingUserError',
  person_email_ukey: 'existingUserError',
  person_email_check: 'invalidEmail',
  website_ck: 'invalidWebsite',
  url_t: 'invalidWebsite',
  url_check: 'invalidWebsite',
  person_name_check: 'fullNameError',
  'Invalid email/password': 'invalidEmailPassword',
  SST00: 'schoolDeactivatedError',
  DST00: 'districtDeactivatedError',
}

export default ERROR_STRINGS

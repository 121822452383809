import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export const POST = 'POST'
export const GET = 'GET'
export const PATCH = 'PATCH'
export const DELETE = 'DELETE'

type ApiRequest<T> = (config: ExtendedAxiosRequestConfig) => Promise<AxiosResponse<T>>
export type RequestOptions = { removeDefaultHeaders?: boolean }
type ExtendedAxiosRequestConfig = AxiosRequestConfig & { options?: RequestOptions }

export const requests = {
  get: async <T = any>({ url, headers = {} }: ExtendedAxiosRequestConfig) => {
    return axios.request<T>({
      method: GET,
      url,
      headers,
    })
  },
  post: async <T = any>({ url, data, headers = {}, options = {} }: ExtendedAxiosRequestConfig) => {
    return axios.request<T>({
      method: POST,
      data,
      url,
      headers: {
        ...(options.removeDefaultHeaders
          ? {}
          : {
              Prefer: 'return=representation',
              Accept: 'application/vnd.pgrst.object+json',
            }),
        ...headers,
      },
    })
  },
  patch: async <T = any>({ url, data, headers = {}, options = {} }: ExtendedAxiosRequestConfig) => {
    return axios.request<T>({
      method: PATCH,
      data,
      url,
      headers: {
        ...(options.removeDefaultHeaders
          ? {}
          : {
              Prefer: 'return=representation',
              Accept: 'application/vnd.pgrst.object+json',
            }),
        ...headers,
      },
    })
  },
  delete: async <T = any>({ url, headers = {}, options = {} }: ExtendedAxiosRequestConfig) => {
    return axios.request<T>({
      method: DELETE,
      url,
      headers: {
        ...(options.removeDefaultHeaders
          ? {}
          : {
              Prefer: 'return=representation',
              Accept: 'application/vnd.pgrst.object+json',
            }),
        ...headers,
      },
    })
  },
}

export type ApiRequestConfig<T> = { req: ApiRequest<T> } & ExtendedAxiosRequestConfig

import _ from 'lodash'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import {
  getLatestStaffCharterSurveyService,
  submitStaffCharterSurveyService,
} from 'page-modules/charter/staff-charter/survey/service'
import { validateField, validateFields } from 'common/validator'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  SUBMIT_STAFF_CHARTER_SURVEY,
  VALIDATE_CHARTER_SURVEY_FIELD,
} from './actions'

export function* submitStaffCharterSurveySaga({ payload: { data } }) {
  const errors = validateFields(data)

  if (!_.isEmpty(errors)) {
    yield put(addFieldsErrors(errors))
    return
  }

  yield call(submitStaffCharterSurveyService.requestSaga, {
    payload: { data: { emotion_words: data.emotionWords.value, actions: data.actions.value } },
  })
  yield put(getLatestStaffCharterSurveyService.actions.request())
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeLatest(SUBMIT_STAFF_CHARTER_SURVEY, submitStaffCharterSurveySaga),
    takeEvery(VALIDATE_CHARTER_SURVEY_FIELD, validateFieldSaga),
  ])
}

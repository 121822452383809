export const ANSWER_ASSESSMENT_QUESTION = 'ANSWER_ASSESSMENT_QUESTION'
export const INIT_ASSESSMENT_ANSWERS = 'INIT_ASSESSMENT_ANSWERS'
export const RESET_ASSESSMENT_ANSWERS = 'RESET_ASSESSMENT_ANSWERS'
export const LOAD_ASSESSMENT_DETAILS_PAGE_DATA = 'LOAD_ASSESSMENT_DETAILS_PAGE_DATA'

export function answerQuestion(question, choiceId) {
  return {
    type: ANSWER_ASSESSMENT_QUESTION,
    payload: {
      question,
      choiceId,
    },
  }
}

export function initAnswers(questions) {
  return {
    type: INIT_ASSESSMENT_ANSWERS,
    payload: {
      questions,
    },
  }
}

export function resetAnswers() {
  return {
    type: RESET_ASSESSMENT_ANSWERS,
  }
}

export function loadAssessmentPageData({ assessmentSlug, req }) {
  return {
    type: LOAD_ASSESSMENT_DETAILS_PAGE_DATA,
    payload: { assessmentSlug, req },
  }
}

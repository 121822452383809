import _ from 'lodash'

import {
  ADD_MOOD_METER_GROUP_FORM_FIELD_ERROR,
  ADD_MOOD_METER_GROUP_FORM_FIELDS_ERRORS,
  CHANGE_MOOD_METER_GROUP_FORM_FIELD,
  CLEAR_MOOD_METER_GROUP_FORM_FIELD_ERROR,
  PREDEFINE_MOOD_METER_GROUP_FORM_DATA,
  RESET_MOOD_METER_GROUP_FORM_DATA,
} from './actions'

export const DURATION_OPTIONS = {
  '25minutes': '25 minutes',
  '1hour': '1 hour',
  '3hours': '3 hours',
  '12hours': '12 hours',
  '24hours': '24 hours',
}
export const SHARED_IN_OPTIONS = { personal: 'personal', school: 'school' }

export const defaultFormData = (predefined = {}) =>
  _.merge(
    {
      groupName: '',
      audienceType: '',
      startTime: null,
      duration: DURATION_OPTIONS['25minutes'],
      sharedIn: SHARED_IN_OPTIONS.personal,
    },
    predefined,
  )

export const initialState = () => {
  return {
    data: defaultFormData(),
    errors: {},
  }
}

export default function root(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_MOOD_METER_GROUP_FORM_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.key]: action.payload.value,
        },
      }

    case ADD_MOOD_METER_GROUP_FORM_FIELDS_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      }

    case ADD_MOOD_METER_GROUP_FORM_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      }
    case CLEAR_MOOD_METER_GROUP_FORM_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: undefined,
        },
      }

    case RESET_MOOD_METER_GROUP_FORM_DATA:
      return {
        ...state,
        data: defaultFormData(),
        errors: {},
      }

    case PREDEFINE_MOOD_METER_GROUP_FORM_DATA:
      return {
        ...state,
        data: defaultFormData(action.payload),
        errors: {},
      }

    default:
      return state
  }
}

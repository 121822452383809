import _ from 'lodash'
import React from 'react'
import cn from 'classnames'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getYear } from 'date-fns'

import lang from 'lang'
import { cityWithCounty } from 'common/utils'
import Form from 'common/components/form'
import TrainingPicker from 'common/components/form/training/picker'
import {
  changeDistrictFormField,
  validateField,
} from 'page-modules/dashboard/district-form/service/actions'
import { defaultFormData } from 'page-modules/dashboard/district-form/service/reducer'
import statesService from 'services/states'
import citiesService from 'services/cities'
import countriesService from 'services/countries'

@connect(
  (state) => ({
    districtName: state.manageDistrict.form.formData.districtName,
    websiteUrl: state.manageDistrict.form.formData.websiteUrl,
    phoneNumber: state.manageDistrict.form.formData.phoneNumber,
    address: state.manageDistrict.form.formData.address,
    address2: state.manageDistrict.form.formData.address2,
    city: state.manageDistrict.form.formData.city,
    state: state.manageDistrict.form.formData.state,
    country: state.manageDistrict.form.formData.country,
    postalCode: state.manageDistrict.form.formData.postalCode,
    coachName: state.manageDistrict.form.formData.coachName,
    coachEmail: state.manageDistrict.form.formData.coachEmail,
    started_ruler_at: state.manageDistrict.form.formData.started_ruler_at,
    started_at_month: state.manageDistrict.form.formData.started_at_month,
    started_at_day: state.manageDistrict.form.formData.started_at_day,
    started_ruler: state.manageDistrict.form.formData.started_ruler,
    offlineTrainings: state.manageDistrict.form.formData.offlineTrainings,
    errors: state.manageDistrict.form.errors,
    availableStates: state.states.items,
    availableCities: state.cities.items,
    availableCountries: state.countries.items,
  }),
  (dispatch) => ({
    changeFormField: (key, value) => dispatch(changeDistrictFormField(key, value)),
    validateField: (field, value, validations) =>
      dispatch(validateField(typeof field === 'string' ? { field, value, validations } : field)),
    countriesRequest: (payload) => dispatch(countriesService.actions.request(payload)),
    getStatesByCountryRequest: (payload) => dispatch(statesService.actions.request(payload)),
    getCitiesByStateIdRequest: (payload) => dispatch(citiesService.actions.request(payload)),
  }),
)
export default class DistrictForm extends Form {
  static propTypes = {
    districtName: PropTypes.string,
    websiteUrl: PropTypes.string,
    phoneNumber: PropTypes.string,
    address: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    coachName: PropTypes.string,
    coachEmail: PropTypes.string,
    started_ruler_at: PropTypes.string,
    started_at_month: PropTypes.string,
    started_at_day: PropTypes.string,
    started_ruler: PropTypes.bool,
    countriesRequest: PropTypes.func,
    getStatesByCountryRequest: PropTypes.func,
    getCitiesByStateIdRequest: PropTypes.func,
    errors: PropTypes.object,
    availableStates: PropTypes.array,
    availableCities: PropTypes.array,
    slug: PropTypes.string,
    offlineTrainings: PropTypes.arrayOf(
      PropTypes.shape({
        offline_training_id: PropTypes.string,
        training_attended_at: PropTypes.string,
      }),
    ).isRequired,
  }

  static validations = {
    districtName: 'nonEmpty',
    websiteUrl: 'optional url',
    phoneNumber: 'optional phoneNumber',
    address: 'optional',
    city: 'nonEmpty',
    state: 'nonEmpty',
    country: 'nonEmpty',
    postalCode: 'optional postalCode',
    coachName: 'optional length160',
    coachEmail: 'optional email',
    offlineTrainings: {
      self: 'isArray',
      offline_training_id: ({ value, input, index }) => {
        const trainingDate = _.get(input, `offlineTrainings.value[${index}].training_attended_at`)
        return trainingDate && !value ? lang.dashboard.manageSchoolForm.selectTraining : null
      },
      training_attended_at: ({ value, input, index }) => {
        const training = _.get(input, `offlineTrainings.value[${index}].offline_training_id`)
        return training && !value ? lang.dashboard.manageSchoolForm.selectTrainingDate : null
      },
    },
  }

  componentDidMount() {
    // Not rendered by the server
    this.props.countriesRequest()
    if (this.props.country) {
      this.getStatesByCountry(this.props.country)
    }
    if (this.props.state) {
      this.getCitiesByState(this.props.state)
    }
  }

  createIsRulerDistrictHandler() {
    return (e) => {
      this.props.changeFormField('started_ruler', e.target.value === 'Yes')
    }
  }

  createStateHandler() {
    return (e) => {
      const stateId = e.target.value
      this.props.changeFormField('state', stateId)
      this.getCitiesByState(stateId)
    }
  }

  createCountryHandler() {
    return (e) => {
      const countryId = e.target.value
      this.props.changeFormField('country', countryId)
      this.props.changeFormField('state', '')
      this.getStatesByCountry(countryId)
    }
  }

  createCityHandler() {
    return (e) => {
      this.props.changeFormField('city', e.target.value)
    }
  }

  createYearHandler() {
    return (e) => {
      this.props.changeFormField('started_ruler_at', e.target.value)
    }
  }

  createMonthHandler() {
    return (e) => {
      this.props.changeFormField('started_at_month', e.target.value)
    }
  }

  createDayHandler() {
    return (e) => {
      this.props.changeFormField('started_at_day', e.target.value)
    }
  }

  getCitiesByState(stateId) {
    this.props.getCitiesByStateIdRequest({ urlParams: { stateId } })
  }

  getStatesByCountry(countryId) {
    this.props.getStatesByCountryRequest({ urlParams: { countryId } })
  }

  renderCoachDetails() {
    return (
      <div className="grid-x grid-margin-x">
        <div className="medium-6 cell">
          <label htmlFor="coach-name">{lang.dashboard.districtForm.coachName}</label>
          <input
            type="text"
            id="coach-name"
            value={this.props.coachName || ''}
            onChange={this.createFieldHandler('coachName')}
            onBlur={this.createFieldValidationHandler(
              'coachName',
              DistrictForm.validations.coachName,
            )}
          />
          <span className={cn('form-error', { 'is-visible': this.props.errors.coachName })}>
            {this.props.errors.coachName}
          </span>
        </div>
        <div className="medium-6 cell">
          <label htmlFor="coach-email">{lang.dashboard.districtForm.coachEmail}</label>
          <input
            type="text"
            id="coach-email"
            value={this.props.coachEmail || ''}
            onChange={this.createFieldHandler('coachEmail')}
            onBlur={this.createFieldValidationHandler(
              'coachEmail',
              DistrictForm.validations.coachEmail,
            )}
          />
          <span className={cn('form-error', { 'is-visible': this.props.errors.coachEmail })}>
            {this.props.errors.coachEmail}
          </span>
        </div>
      </div>
    )
  }

  renderDistrictAddress() {
    return (
      <fieldset>
        <legend>{lang.dashboard.locationForm.addressTitle}</legend>
        <div className="grid-x grid-margin-x">
          <div className="medium-6 cell">
            <label htmlFor="website-url">
              {lang.dashboard.locationForm.websiteLabel}
              <span className="option">{lang.optional}</span>
            </label>
            <input
              type="text"
              id="website-url"
              value={this.props.websiteUrl}
              onChange={this.createFieldHandler('websiteUrl')}
              onBlur={this.createFieldValidationHandler(
                'websiteUrl',
                DistrictForm.validations.websiteUrl,
              )}
            />
            <span className={cn('form-error', { 'is-visible': this.props.errors.websiteUrl })}>
              {this.props.errors.websiteUrl}
            </span>
          </div>
          <div className="medium-6 cell">
            <label htmlFor="phone-number">
              {lang.dashboard.locationForm.phoneNumberLabel}
              <span className="option">{lang.optional}</span>
            </label>
            <input
              type="text"
              id="phone-number"
              value={this.props.phoneNumber}
              onChange={this.createFieldHandler('phoneNumber')}
              onBlur={this.createFieldValidationHandler(
                'phoneNumber',
                DistrictForm.validations.phoneNumber,
              )}
            />
            <span className={cn('form-error', { 'is-visible': this.props.errors.phoneNumber })}>
              {this.props.errors.phoneNumber}
            </span>
          </div>
          <div className="medium-12 cell">
            <label htmlFor="country">{lang.dashboard.locationForm.countryLabel}</label>
            <select
              id="country"
              value={this.props.country}
              onChange={this.createCountryHandler()}
              onBlur={this.createFieldValidationHandler(
                'country',
                DistrictForm.validations.country,
              )}>
              <option value={defaultFormData().country} />
              {_.map(_.orderBy(this.props.availableCountries, 'name'), (country) => (
                <option value={country.code} key={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
            <span className={cn('form-error', { 'is-visible': this.props.errors.country })}>
              {this.props.errors.country}
            </span>
          </div>
          <div className="medium-12 cell">
            <label htmlFor="street-address">
              {lang.dashboard.locationForm.addressLabel}
              <span className="option">{lang.optional}</span>
            </label>
            <input
              type="text"
              id="street-address"
              value={this.props.address}
              onChange={this.createFieldHandler('address')}
              onBlur={this.createFieldValidationHandler(
                'address',
                DistrictForm.validations.address,
              )}
            />
            <span className={cn('form-error', { 'is-visible': this.props.errors.address })}>
              {this.props.errors.address}
            </span>
          </div>
          <div className="medium-12 cell">
            <label htmlFor="street-address-2">
              {lang.dashboard.locationForm.address2Label}
              <span className="option">{lang.dashboard.locationForm.address2LabelOptional}</span>
            </label>
            <input
              type="text"
              id="street-address-2"
              value={this.props.address2}
              onChange={this.createFieldHandler('address2')}
            />
          </div>
          <div className="medium-4 cell">
            <label htmlFor="state">{lang.dashboard.locationForm.stateLabel}</label>
            <select
              name=""
              id="state"
              value={this.props.state}
              onChange={this.createStateHandler()}
              onBlur={this.createFieldValidationHandler('state', DistrictForm.validations.state)}>
              <option value={defaultFormData().state} />
              {_.map(this.props.availableStates, (state) => (
                <option value={state.id} key={state.id}>
                  {state.name}
                </option>
              ))}
            </select>
            <span className={cn('form-error', { 'is-visible': this.props.errors.state })}>
              {this.props.errors.state}
            </span>
          </div>
          {_.isEmpty(this.props.state) ? null : (
            <div className="medium-4 cell">
              <label htmlFor="city">{lang.dashboard.locationForm.cityLabel}</label>
              <select
                name=""
                id="city"
                value={this.props.city}
                onChange={this.createCityHandler()}
                onBlur={this.createFieldValidationHandler('city', DistrictForm.validations.city)}>
                <option value={defaultFormData().city} />
                {_.map(_.orderBy(this.props.availableCities, 'name'), (city) => (
                  <option value={city.id} key={city.id}>
                    {cityWithCounty(city.name, city.county)}
                  </option>
                ))}
              </select>
              <span className={cn('form-error', { 'is-visible': this.props.errors.city })}>
                {this.props.errors.city}
              </span>
            </div>
          )}
          <div className="medium-4 cell">
            <label htmlFor="zip-code">
              {lang.dashboard.locationForm.postalCodeLabel}
              <span className="option">{lang.optional}</span>
            </label>
            <input
              type="text"
              id="zip-code"
              value={this.props.postalCode}
              onChange={this.createFieldHandler('postalCode')}
              onBlur={this.createFieldValidationHandler(
                'postalCode',
                DistrictForm.validations.postalCode,
              )}
            />
            <span className={cn('form-error', { 'is-visible': this.props.errors.postalCode })}>
              {this.props.errors.postalCode}
            </span>
          </div>
        </div>
      </fieldset>
    )
  }

  render() {
    return (
      <form action="">
        <span className={cn('form-error', { 'is-visible': this.props.errors.base })}>
          {this.props.errors.base}
        </span>
        <label htmlFor="district-name">{lang.dashboard.districtForm.districtNameLabel}</label>
        <input
          type="text"
          id="district-name"
          aria-describedby="passwordHelpText"
          value={this.props.districtName}
          onChange={this.createFieldHandler('districtName')}
          onBlur={this.createFieldValidationHandler(
            'districtName',
            DistrictForm.validations.districtName,
          )}
        />
        <span className={cn('form-error', { 'is-visible': this.props.errors.districtName })}>
          {this.props.errors.districtName}
        </span>
        <p className="help-text" id="districtNameHelp">
          <strong>{lang.dashboard.districtForm.districtNameHelp.text1}</strong>
          {lang.dashboard.districtForm.districtNameHelp.text2}
        </p>
        <fieldset>
          <legend>{lang.dashboard.districtForm.isDistrictQuesion}</legend>
          <input
            type="radio"
            name="ruler-district"
            value="Yes"
            id="ruler-district-yes"
            onChange={this.createIsRulerDistrictHandler()}
            checked={this.props.started_ruler}
          />
          <label className="text-capitalize" htmlFor="ruler-district-yes">
            {lang.yes}
          </label>
          <input
            type="radio"
            name="ruler-district"
            value="No"
            id="ruler-district-no"
            onChange={this.createIsRulerDistrictHandler()}
            checked={!this.props.started_ruler}
          />
          <label className="text-capitalize" htmlFor="ruler-district-no">
            {lang.no}
          </label>
        </fieldset>
        {this.props.started_ruler ? (
          <fieldset className="inline-controls">
            <legend>{lang.dashboard.districtForm.districtRulerStart}</legend>
            <select
              name="started_ruler_at"
              value={this.props.started_ruler_at}
              aria-label="Year"
              onChange={this.createYearHandler()}>
              {_.range(getYear(new Date()), 2011, -1).map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </select>
          </fieldset>
        ) : null}
        <TrainingPicker
          changeFormField={this.props.changeFormField}
          validateField={this.props.validateField}
          trainings={this.props.offlineTrainings}
          validations={DistrictForm.validations.offlineTrainings}
          errors={this.props.errors}
        />
        {this.renderCoachDetails()}
        {this.renderDistrictAddress()}
      </form>
    )
  }
}

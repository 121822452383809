import _ from 'lodash'
import React from 'react'
import { format } from 'date-fns'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import Router from 'next/router'

import { isUser, prependProtocolToURL } from 'common/utils'
import { validateField, validateFields } from 'common/validator'
import { urls } from 'common/routes'
import { changeModal } from 'services/actions'
import schoolsService from 'page-modules/dashboard/schools/service'
import { getTotalSchoolsService, postSchoolService } from 'services/schools'
import {
  getBookmarkedSchoolsService,
  schoolDetailsService,
} from 'page-modules/dashboard/school-details/service/'
import { getDistrictSchoolsService } from 'page-modules/dashboard/district-details/service'
import SchoolJourneyUploadCsvModal from 'page-modules/dashboard/users/upload-csv/school-journey-modal'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  SUBMIT_SCHOOL_FROM_DASHBOARD,
  SUBMIT_SCHOOL_FROM_DISTRICT_DETAILS_PAGE,
  SUBMIT_SCHOOL_FROM_SCHOOL_DETAILS_PAGE,
  SUBMIT_SCHOOL_FROM_SCHOOL_LISTING_PAGE,
  VALIDATE_SCHOOL_FIELD,
} from './actions'

const prepareData = (payload, user) => {
  let data = {
    name: payload.schoolName.value,
    city_id: payload.city.value,
    location: {
      website: payload.websiteUrl.value,
      address: payload.address.value,
      address2: payload.address2.value,
      phone: payload.phoneNumber.value,
      postal_code: payload.postalCode.value,
    },
    logo_url: payload.logoUrl.value,
    started_ruler: payload.started_ruler.value,
    grades: payload.schoolGrades.value,
    district_id: payload.schoolDistrict.value,
    phase_id: payload.phase.value === '' ? null : payload.phase.value,
    offline_trainings: payload.offlineTrainings.value,
    coach_name: payload.coachName.value,
    coach_email: payload.coachEmail.value,
  }
  if (data.started_ruler) {
    const year = new Date(payload.started_ruler_at.value, 0, 1)
    data.started_ruler_at = format(year, 'yyyy-MM-dd')
  }
  if (!isUser.schoolImplementationTeam(user)) {
    data.total_staff = payload.total_staff.value
  }
  if (payload.id && payload.id.value) {
    data = _.merge(data, {
      id: payload.id.value,
    })
  }

  // Users may forget to add 'http(s)://' protocol in website url
  // The backend is more strict with validations though https://github.com/liveSTORYBOARD/RULER/pull/565
  // Which means that if we send website url without proto we'll get not very friendly backend error
  // That's why we want to improve the UX by skipping this error
  // https://trello.com/c/cVBWmTzU/1881-improve-url-input-ux
  data.location.website = prependProtocolToURL(data.location.website)

  return data
}

export function* submitSchoolFromSchoolDetailsSaga({ payload }) {
  const user = yield select((state) => state.root.user)
  // The school implememtation team is not allowed
  // modify the total_staff field in the backend.
  // => remove the field
  if (isUser.schoolImplementationTeam(user)) {
    delete payload.total_staff
  }
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const data = prepareData(payload, user)
    const schoolResult = yield call(postSchoolService.requestSaga, {
      payload: { data },
    })
    if (schoolResult.error) return
    yield call(schoolDetailsService.requestSaga, {
      payload: { urlParams: { slug: schoolResult.slug } },
    })
  } else {
    yield put(addFieldsErrors(errors))
  }
}

function* afterSchoolCreationHandler(schoolPayload, schoolResult) {
  if (!schoolPayload.id || !schoolPayload.id.value) {
    yield call(() => {
      Router.push(urls.schoolDetails(schoolResult.slug))
    })
    yield put(
      changeModal({
        isOpen: true,
        shouldCloseOnOverlayClick: false,
        className: 'upload-csv-users',
        aria: { labelledby: 'upload-csv-users-header' },
        content: <SchoolJourneyUploadCsvModal />,
      }),
    )
  }
}

export function* submitSchoolFromSchoolListingSaga({ payload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const user = yield select((state) => state.root.user)
    const data = prepareData(payload, user)
    const schoolResult = yield call(postSchoolService.requestSaga, {
      payload: { data },
    })
    if (schoolResult.error) return
    yield afterSchoolCreationHandler(payload, schoolResult)
    yield call(schoolsService.requestSaga, {})
    if (isUser.districtAdmin(user)) {
      yield put(getTotalSchoolsService.actions.request({ data: { scope: 'district' } }))
    } else {
      yield put(getTotalSchoolsService.actions.request())
    }
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export function* submitSchoolFromDistrictDetailsSaga({ payload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const user = yield select((state) => state.root.user)
    const data = prepareData(payload, user)
    const schoolResult = yield call(postSchoolService.requestSaga, {
      payload: { data },
    })
    if (schoolResult.error) return
    yield afterSchoolCreationHandler(payload, schoolResult)
    yield call(getDistrictSchoolsService.requestSaga, {
      payload: { urlParams: { districtId: schoolResult.district_id } },
    })
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export function* submitSchoolFromDashboardSaga({ payload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const user = yield select((state) => state.root.user)
    const data = prepareData(payload, user)
    const schoolResult = yield call(postSchoolService.requestSaga, {
      payload: { data },
    })
    if (schoolResult.error) return
    yield afterSchoolCreationHandler(payload, schoolResult)
    yield call(getBookmarkedSchoolsService.requestSaga, {
      payload: { urlParams: {} },
    })
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_SCHOOL_FIELD, validateFieldSaga),
    takeLatest(SUBMIT_SCHOOL_FROM_SCHOOL_DETAILS_PAGE, submitSchoolFromSchoolDetailsSaga),
    takeLatest(SUBMIT_SCHOOL_FROM_SCHOOL_LISTING_PAGE, submitSchoolFromSchoolListingSaga),
    takeLatest(SUBMIT_SCHOOL_FROM_DISTRICT_DETAILS_PAGE, submitSchoolFromDistrictDetailsSaga),
    takeLatest(SUBMIT_SCHOOL_FROM_DASHBOARD, submitSchoolFromDashboardSaga),
  ])
}

import buildService from 'common/utils/build-service'
import { POST } from 'common/requests'

export const postDistrictService = buildService({
  type: POST,
  constantsPrefix: 'DISTRICT',
  http: {
    createApiUrlSuffix: () => '/rpc/district',
    headers: {
      Accept: 'application/json',
      Prefer: 'params=single-object',
    },
  },
})

export const getTotalDistrictsService = buildService({
  type: POST,
  constantsPrefix: 'TOTAL_DISTRICTS',
  http: {
    createApiUrlSuffix: () => '/rpc/total_districts_by_type',
    headers: {
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'params=single-object',
    },
  },
})

import _ from 'lodash'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { validateField, validateFields } from 'common/validator'
import { changeModal } from 'services/actions'
import { getTotalPeopleService, patchUserService, postUserService } from 'services/users'
import { postDistrictService } from 'page-modules/dashboard/districts/service'
import postBookmarkService from 'common/services/bookmark'
import { getTotalSchoolsService } from 'common/services/schools'

import {
  addFieldError,
  addFieldsErrors,
  BOOKMARK_DISTRICT,
  clearFieldError,
  LOAD_DISTRICT_DETAILS_PAGE,
  resetDistrictUserFormData,
  SUBMIT_DISTRICT_USER,
  TOGGLE_DISTRICT_STATUS,
  VALIDATE_DISTRICT_USER_FIELD,
} from './actions'

import { getDistrictDetailsService, getDistrictUsersService } from './index'

export function* submitDistrictUserSaga({ payload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const data = {
      email: payload.email.value,
      name: payload.fullName.value,
      district_id: payload.districtId.value,
      role: payload.roleId.value,
      person_type_id: payload.personTypeId.value || null,
      office_phone: payload.officePhone.value || null,
      mobile_phone: payload.mobilePhone.value || null,
      school_id: null,
    }

    if (payload.id.value) {
      // When updating users there is no need to change their school or district
      delete data.school_id
      delete data.district_id
      yield put(
        patchUserService.actions.request({
          data,
          urlParams: {
            id: payload.id.value,
          },
        }),
      )
    } else {
      const userResponse = yield call(postUserService.requestSaga, {
        payload: { data },
      })
      if (userResponse.error) return
      yield put(resetDistrictUserFormData())
      yield call(getDistrictUsersService.requestSaga, {
        payload: {
          urlParams: { districtId: userResponse.me.district_id },
        },
      })
      yield put(
        getTotalPeopleService.actions.request({
          data: { scope: 'district', district_id: userResponse.me.district_id },
          urlParams: { v_role: '_district_roles_total', v_type: '_all_types_total' },
        }),
      )
    }
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export function* toggleDistrictStatusSaga({ payload: { id, status } }) {
  const districtResult = yield call(postDistrictService.requestSaga, {
    payload: {
      data: { id, status },
    },
  })
  if (districtResult.error) return
  yield put(
    changeModal({
      isOpen: false,
    }),
  )
  yield put(getDistrictDetailsService.actions.request({ urlParams: { id } }))
}

export function* bookmarkDistrictSaga({ payload: { id, bookmarked, refreshMethod } }) {
  const bookmarkResult = yield call(postBookmarkService.requestSaga, {
    payload: {
      data: {
        what: 'district',
        what_id: id,
        mark_it: bookmarked,
      },
    },
  })
  if (bookmarkResult.error) return

  // Perform refresh of the background list
  if (_.isFunction(refreshMethod)) {
    // The passed function is already "dispatched". Wrapping it in a `put` or `call` will fire an additional(duplicate) action.
    refreshMethod()
  }
}

export function* loadDistrictDetailsPageSaga({ payload: { slug, req } }) {
  const district = yield call(getDistrictDetailsService.requestSaga, {
    payload: { req, urlParams: { slug } },
  })
  yield all([
    call(getTotalSchoolsService.requestSaga, {
      payload: {
        req,
        data: { scope: 'district', district_id: district.id },
      },
    }),
    yield call(getTotalPeopleService.requestSaga, {
      payload: {
        req,
        data: { scope: 'district', district_id: district.id },
        urlParams: { v_role: '_district_roles_total', v_type: '_all_types_total' },
      },
    }),
  ])
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_DISTRICT_USER_FIELD, validateFieldSaga),
    takeLatest(SUBMIT_DISTRICT_USER, submitDistrictUserSaga),
    takeLatest(TOGGLE_DISTRICT_STATUS, toggleDistrictStatusSaga),
    takeLatest(BOOKMARK_DISTRICT, bookmarkDistrictSaga),
    takeLatest(LOAD_DISTRICT_DETAILS_PAGE, loadDistrictDetailsPageSaga),
  ])
}

import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { format, isValid } from 'date-fns'

import lang from 'lang'
import offlineTrainingsService from 'services/offline-trainings'

import TrainingRecord from './record'

@connect(
  (state) => ({
    user: state.root.user,
    offlineTrainingTypes: state.offlineTrainings.items,
  }),
  (dispatch) => ({
    offlineTrainingsRequest: (payload) =>
      dispatch(offlineTrainingsService.actions.request(payload)),
  }),
)
export default class TrainingPicker extends React.Component {
  static propTypes = {
    errors: PropTypes.object,
    validations: PropTypes.object,
    changeFormField: PropTypes.func.isRequired,
    validateField: PropTypes.func.isRequired,
    offlineTrainingsRequest: PropTypes.func,
    trainings: PropTypes.arrayOf(
      PropTypes.shape({
        offline_training_id: PropTypes.string,
        training_attended_at: PropTypes.string,
      }),
    ).isRequired,
    offlineTrainingTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ).isRequired,
  }

  componentDidMount() {
    this.props.offlineTrainingsRequest()
  }

  get usedTrainingTypes() {
    const { trainings, offlineTrainingTypes } = this.props
    return _.intersection(
      _.map(trainings, 'offline_training_id'),
      _.map(offlineTrainingTypes, 'id'),
    )
  }

  addTrainingHandler() {
    return () => {
      const newTrainings = this.props.trainings
      if (newTrainings.length >= this.props.offlineTrainingTypes.length) {
        return
      }
      newTrainings.push({
        offline_training_id: null,
        training_attended_at: null,
      })
      this.props.changeFormField('offlineTrainings', newTrainings)
      this.forceUpdate()
    }
  }

  removeTrainingHandler(index) {
    return () => {
      const newTrainings = _.clone(this.props.trainings)
      newTrainings.splice(index, 1)
      this.props.changeFormField('offlineTrainings', newTrainings)
      this.props.validateField('offlineTrainings', newTrainings, this.props.validations)
    }
  }

  trainingChangeHandler(index, training) {
    return (e) => {
      const newTrainings = _.clone(this.props.trainings)
      training.offline_training_id = e.target.value
      newTrainings.splice(index, 1, training)
      this.props.changeFormField('offlineTrainings', newTrainings)
      this.props.validateField('offlineTrainings', newTrainings, this.props.validations)
    }
  }

  dateChangeHandler(index, training) {
    return (date) => {
      if (!isValid(date)) {
        return
      }
      const newTrainings = _.clone(this.props.trainings)
      training.training_attended_at = format(new Date(date), 'yyyy-MM-dd')
      newTrainings.splice(index, 1, training)
      this.props.changeFormField('offlineTrainings', newTrainings)
      this.props.validateField('offlineTrainings', newTrainings, this.props.validations)
    }
  }

  render() {
    const { trainings, offlineTrainingTypes } = this.props
    return (
      <fieldset className="inline-controls training-picker">
        <label>{lang.dashboard.manageSchoolForm.offlineTrainings}</label>
        <div className="grid-x">
          {_.map(trainings, (training, index) => {
            return (
              <TrainingRecord
                key={index}
                index={index}
                offlineTrainingTypes={offlineTrainingTypes}
                usedTrainingTypes={this.usedTrainingTypes}
                onTrainingChangeHandler={this.trainingChangeHandler(index, training)}
                onRemoveTrainingHandler={this.removeTrainingHandler(index, training)}
                onDateChangeHandler={this.dateChangeHandler(index, training)}
                errors={_.get(this.props.errors.offlineTrainings, index, {})}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...training}
              />
            )
          })}
        </div>
        <button
          className="button hollow small"
          disabled={trainings && trainings.length >= offlineTrainingTypes.length}
          type="button"
          onClick={this.addTrainingHandler()}>
          {lang.dashboard.manageSchoolForm.addTraining}
        </button>
      </fieldset>
    )
  }
}

import _ from 'lodash'
import cn from 'classnames'
import Papa from 'papaparse'
import React from 'react'
import PropTypes from 'prop-types'
import validator from 'validator'
import { connect } from 'react-redux'

import lang from 'lang'
import { changeModal } from 'services/actions'
import personTypesService from 'services/person-types'
import { labels } from 'common/utils/determine-user'

import { resetUploadedUsers, submitUploadedUsers, uploadCsvUsers } from './service/actions'

const emailHeaders = ['email', 'mail', 'emailaddress', 'mailaddress']
const nameHeaders = ['name', 'names', 'lastname', 'firstname', 'surname']

@connect(
  (state) => ({
    uploadedUsers: state.school.addSchoolUser.csvUploadForm.formData.users,
    personTypes: state.personTypes.items,
  }),
  (dispatch) => ({
    changeModal: (params) => dispatch(changeModal(params)),
    uploadCsvUsers: (params) => dispatch(uploadCsvUsers(params)),
    submitUploadedUsers: (params) => dispatch(submitUploadedUsers(params)),
    resetUploadedUsers: (params) => dispatch(resetUploadedUsers(params)),
    loadPersonTypes: (payload) => dispatch(personTypesService.actions.request(payload)),
  }),
)
export default class UsersCSVUploadControls extends React.Component {
  static propTypes = {
    changeModal: PropTypes.func,
    uploadCsvUsers: PropTypes.func,
    uploadedUsers: PropTypes.array,
    personTypes: PropTypes.array,
    submitUploadedUsers: PropTypes.func,
    resetUploadedUsers: PropTypes.func,
    loadPersonTypes: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.fileInput = React.createRef()
  }

  componentDidMount() {
    this.props.loadPersonTypes({ urlParams: { scope: 'school' } })
  }

  get csvParsed() {
    return this.props.uploadedUsers.length > 0
  }

  get parseSettings() {
    return {
      header: true,
      skipEmptyLines: true,
      transformHeader: (header) => {
        const normalizedHeader = _.replace(_.lowerCase(header), /[\s_-]+/g, '')
        // We test the uploaded headers for email naming or contents
        // This helps us in cases when user hasn't provided header row
        if (_.includes(emailHeaders, normalizedHeader) || validator.isEmail(header)) {
          return 'email'
        }
        return _.includes(nameHeaders, normalizedHeader) ? 'name' : null
      },
      complete: this.parseCompletedHandler(),
    }
  }

  closeModalHandler() {
    return () => {
      this.props.resetUploadedUsers()
      this.props.changeModal({ isOpen: false })
    }
  }

  submitUploadHandler() {
    return () => {
      // So far we just copy the uploaded users
      // In the further iterations we may edit the list manually
      this.props.submitUploadedUsers({
        users: _.filter(this.props.uploadedUsers, 'selected'),
      })
    }
  }

  parseCompletedHandler() {
    return (results) => {
      const users = _.map(results.data, (user, index) => {
        const rowErrors = _.filter(results.errors, { row: index })
        return {
          ...user,
          name: _.trim(user.name),
          email: _.toLower(_.trim(user.email)),
          selected: rowErrors.length === 0,
          // Default person type
          personTypeId: _.get(_.find(this.props.personTypes, { name: labels.teacher }), 'id'),
          errors: rowErrors,
        }
      })

      this.props.uploadCsvUsers({
        users: _.uniqBy(users, (user) => user.email),
      })
    }
  }

  fileChangeHandler() {
    return () => {
      // Reset errors before parsing new file
      this.props.resetUploadedUsers()
      // Parse new file
      try {
        const fileInput = this.fileInput.current.files[0]
        Papa.parse(fileInput, this.parseSettings)
      } catch (error) {
        // We might end up here if user uploads a file, then tries to upload another file
        // but cancels the request. Then the initial file is not readable anymore and
        // we don't have new file selected. In this scenario we reset the modal to it's
        // initial state
        this.props.resetUploadedUsers()
      }
    }
  }

  renderConfirmButton() {
    if (!this.csvParsed) {
      return null
    }
    return (
      <button
        type="button"
        className="button primary margin-right-1"
        disabled={
          _.filter(this.props.uploadedUsers, 'selected').length === 0 ||
          _.filter(this.props.uploadedUsers, (user) => user.selected && user.errors.length > 0)
            .length > 0
        }
        onClick={this.submitUploadHandler()}>
        {lang.dashboard.userForm.uploadCsv.confirmUpload}
      </button>
    )
  }

  renderFileInput() {
    return (
      <label
        htmlFor="file-input"
        className={cn('button file-label margin-right-1', {
          primary: !this.csvParsed,
          hollow: this.csvParsed,
        })}>
        <i aria-hidden="true" className="icon-file" />{' '}
        {this.csvParsed
          ? lang.dashboard.userForm.uploadCsv.replaceFile
          : lang.dashboard.userForm.uploadCsv.selectFile}
        <input
          type="file"
          id="file-input"
          accept=".csv,.txt,text/csv,text/plain,application/csv"
          ref={this.fileInput}
          onChange={this.fileChangeHandler()}
        />
      </label>
    )
  }

  render() {
    return (
      <>
        {this.renderConfirmButton()}
        {this.renderFileInput()}
      </>
    )
  }
}

import _ from 'lodash'
import React from 'react'
import cn from 'classnames'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getYear } from 'date-fns'
import dynamic from 'next/dynamic'
import getConfig from 'next/config'

import lang from 'lang'
import { cityWithCounty, isUser } from 'common/utils'
import Form from 'common/components/form'
import TrainingPicker from 'common/components/form/training/picker'
import {
  changeSchoolFormField,
  validateField,
} from 'page-modules/dashboard/school-creation/service/actions'
import { defaultFormData } from 'page-modules/dashboard/school-creation/service/reducer'
import statesService from 'services/states'
import citiesService from 'services/cities'
import gradesService from 'services/grades'
import phasesService from 'services/phases'
import countriesService from 'services/countries'
import districtsService from 'services/districts'

const { publicRuntimeConfig } = getConfig()

const ReactFilestack = dynamic(() => import('filestack-react'), { ssr: false })

@connect(
  (state) => ({
    user: state.root.user,
    schoolName: state.manageSchool.form.formData.schoolName,
    schoolDistrict: state.manageSchool.form.formData.schoolDistrict,
    schoolGrades: state.manageSchool.form.formData.schoolGrades,
    phase: state.manageSchool.form.formData.phase,
    websiteUrl: state.manageSchool.form.formData.websiteUrl,
    phoneNumber: state.manageSchool.form.formData.phoneNumber,
    address: state.manageSchool.form.formData.address,
    address2: state.manageSchool.form.formData.address2,
    city: state.manageSchool.form.formData.city,
    state: state.manageSchool.form.formData.state,
    country: state.manageSchool.form.formData.country,
    postalCode: state.manageSchool.form.formData.postalCode,
    logoUrl: state.manageSchool.form.formData.logoUrl,
    errors: state.manageSchool.form.errors,
    availableCountries: state.countries.items,
    availableStates: state.states.items,
    availableCities: state.cities.items,
    grades: state.grades.items,
    phases: state.phases.items,
    districts: state.districts.items,
    district: state.district.details.item,
    schoolDistrictName: state.manageSchool.form.formData.schoolDistrictName,
    offlineTrainings: state.manageSchool.form.formData.offlineTrainings,
    started_ruler_at: state.manageSchool.form.formData.started_ruler_at,
    started_ruler: state.manageSchool.form.formData.started_ruler,
    total_staff: state.manageSchool.form.formData.total_staff,
    coachName: state.manageSchool.form.formData.coachName,
    coachEmail: state.manageSchool.form.formData.coachEmail,
  }),
  (dispatch) => ({
    changeFormField: (key, value) => dispatch(changeSchoolFormField(key, value)),
    validateField: (field, value, validations) =>
      dispatch(validateField(typeof field === 'string' ? { field, value, validations } : field)),
    countriesRequest: (payload) => dispatch(countriesService.actions.request(payload)),
    getStatesByCountryRequest: (payload) => dispatch(statesService.actions.request(payload)),
    getCitiesByStateIdRequest: (payload) => dispatch(citiesService.actions.request(payload)),
    gradesRequest: (payload) => dispatch(gradesService.actions.request(payload)),
    phasesRequest: (payload) => dispatch(phasesService.actions.request(payload)),
    districtsRequest: (payload) => dispatch(districtsService.actions.request(payload)),
  }),
)
export default class SchoolForm extends Form {
  static propTypes = {
    user: PropTypes.object,
    schoolName: PropTypes.string,
    schoolDistrict: PropTypes.string,
    schoolGrades: PropTypes.array,
    websiteUrl: PropTypes.string,
    phoneNumber: PropTypes.string,
    address: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    logoUrl: PropTypes.string,
    country: PropTypes.string,
    countriesRequest: PropTypes.func,
    getStatesByCountryRequest: PropTypes.func,
    getCitiesByStateIdRequest: PropTypes.func,
    phasesRequest: PropTypes.func,
    errors: PropTypes.object,
    availableStates: PropTypes.array,
    availableCountries: PropTypes.array,
    availableCities: PropTypes.array,
    schoolDistrictName: PropTypes.string,
    offlineTrainings: PropTypes.arrayOf(
      PropTypes.shape({
        offline_training_id: PropTypes.string,
        training_attended_at: PropTypes.string,
      }),
    ).isRequired,
    started_ruler_at: PropTypes.string,
    coachName: PropTypes.string,
    coachEmail: PropTypes.string,
    started_ruler: PropTypes.bool,
    total_staff: PropTypes.number,
  }

  static validations = {
    schoolName: 'nonEmpty',
    schoolDistrict: 'nonEmpty',
    websiteUrl: 'optional url',
    phase: 'optional',
    phoneNumber: 'optional phoneNumber',
    address: 'optional',
    city: 'nonEmpty',
    state: 'nonEmpty',
    country: 'nonEmpty',
    postalCode: 'optional postalCode',
    schoolGrades: 'nonEmpty',
    started_ruler_at: 'nonEmpty',
    total_staff: 'nonEmpty number',
    coachName: 'optional length160',
    coachEmail: 'optional email',
    offlineTrainings: {
      self: 'isArray',
      offline_training_id: ({ value, input, index }) => {
        const trainingDate = _.get(input, `offlineTrainings.value[${index}].training_attended_at`)
        return trainingDate && !value ? lang.dashboard.manageSchoolForm.selectTraining : null
      },
      training_attended_at: ({ value, input, index }) => {
        const training = _.get(input, `offlineTrainings.value[${index}].offline_training_id`)
        return training && !value ? lang.dashboard.manageSchoolForm.selectTrainingDate : null
      },
    },
  }

  componentDidMount() {
    // Not rendered by the server
    this.props.countriesRequest()
    this.props.gradesRequest()
    this.props.phasesRequest()
    this.props.districtsRequest({ urlParams: { select: 'id,name,country_code' } })
    if (this.props.country) {
      this.getStatesByCountry(this.props.country)
    }
    if (this.props.state) {
      this.getCitiesByState(this.props.state)
    }
    if (this.props.schoolGrades.length === 0 && this.props.grades && this.props.grades.length > 0) {
      this.props.changeFormField(
        'schoolGrades',
        this.props.grades.map((grade) => grade.id),
      )
    }
  }

  componentDidUpdate(prevProps) {
    // Reset the state and city when a new country is selected.
    if (prevProps.country !== this.props.country && this.props.country) {
      this.getStatesByCountry(this.props.country)
      this.props.changeFormField('state', undefined)
      this.props.changeFormField('city', undefined)
    }
    if (prevProps.state !== this.props.state && this.props.state) {
      this.getCitiesByState(this.props.state)
    }

    if (
      this.props.schoolGrades.length === 0 &&
      (!prevProps.grades || prevProps.grades.length === 0) &&
      this.props.grades.length > 0
    ) {
      this.props.changeFormField(
        'schoolGrades',
        this.props.grades.map((grade) => grade.id),
      )
    }
  }

  createStateHandler() {
    return (e) => {
      const stateId = e.target.value
      this.props.changeFormField('state', stateId)
    }
  }

  createCountryHandler() {
    return (e) => {
      const countryId = e.target.value
      this.props.changeFormField('country', countryId)
    }
  }

  createCityHandler() {
    return (e) => {
      this.props.changeFormField('city', e.target.value)
    }
  }

  createPhaseHandler() {
    return (e) => {
      this.props.changeFormField('phase', e.target.value)
    }
  }

  createDistrictHandler() {
    return (e) => {
      this.props.changeFormField('schoolDistrict', e.target.value)
      if (e.target.value) {
        const countryId = _.find(this.props.districts, ['id', e.target.value]).country_code
        this.props.changeFormField('country', countryId)
      }
    }
  }

  createSchoolGradesHandler() {
    return (e) => {
      const schoolGrades = this.props.schoolGrades.slice() // copy array
      const gradeIndex = _.indexOf(schoolGrades, e.target.value)
      // toggle grade id in schoolGrades array
      if (gradeIndex >= 0) {
        schoolGrades.splice(gradeIndex, 1)
      } else {
        schoolGrades.push(e.target.value)
      }
      this.props.changeFormField('schoolGrades', schoolGrades)
      // Validate when the last checkbox is unselected, because there is no blur event for the checkbox input
      this.props.validateField('schoolGrades', schoolGrades, SchoolForm.validations.schoolGrades)
    }
  }

  createStartedRulerAtHandler() {
    return (e) => {
      this.props.changeFormField('started_ruler_at', e.target.value)
    }
  }

  createTotalStaffHandler() {
    return (e) => {
      this.props.changeFormField('total_staff', parseInt(e.target.value, 10))
    }
  }

  createIsRulerSchoolHandler() {
    return (e) => {
      this.props.changeFormField('started_ruler', e.target.value === 'Yes')
    }
  }

  getCitiesByState(stateId) {
    this.props.getCitiesByStateIdRequest({ urlParams: { stateId } })
  }

  getStatesByCountry(countryId) {
    this.props.getStatesByCountryRequest({ urlParams: { countryId } })
  }

  renderDistrictSchoolInput() {
    return (
      <>
        <label htmlFor="school-district">{lang.dashboard.manageSchoolForm.schoolDistrict}</label>
        <select
          name=""
          id="school-district"
          value={this.props.schoolDistrict}
          onChange={this.createDistrictHandler()}
          onBlur={this.createFieldValidationHandler(
            'schoolDistrict',
            SchoolForm.validations.schoolDistrict,
          )}>
          <option value={defaultFormData().schoolDistrict} />
          {!_.isEmpty(this.props.districts) ? (
            // School form is opened in schools listing page
            _.map(this.props.districts, (district) => (
              <option value={district.id} key={district.id}>
                {district.name}
              </option>
            ))
          ) : (
            // School form is opened in district details page
            <option value={this.props.schoolDistrict} key={this.props.schoolDistrict}>
              {this.props.schoolDistrictName}
            </option>
          )}
        </select>
        <span className={cn('form-error', { 'is-visible': this.props.errors.schoolDistrict })}>
          {this.props.errors.schoolDistrict}
        </span>
      </>
    )
  }

  renderGrades() {
    return (
      <fieldset className="inline-controls">
        <legend>{lang.dashboard.manageSchoolForm.gradesLabel}</legend>
        <div className="grades-list grid-x grid-margin-x small-up-4 medium-up-6 large-up-7">
          {_.map(this.props.grades, (grade) => (
            <label key={grade.id} className="cell" htmlFor={grade.id}>
              <input
                id={grade.id}
                type="checkbox"
                value={grade.id}
                checked={_.indexOf(this.props.schoolGrades, grade.id) >= 0}
                onChange={this.createSchoolGradesHandler()}
                onBlur={this.createFieldValidationHandler(
                  'schoolGrades',
                  SchoolForm.validations.schoolGrades,
                )}
              />
              {grade.name}
            </label>
          ))}
        </div>
        <span className={cn('form-error', { 'is-visible': this.props.errors.schoolGrades })}>
          {this.props.errors.schoolGrades}
        </span>
      </fieldset>
    )
  }

  renderGeneralInfo() {
    return (
      <>
        <label htmlFor="school-name">{lang.dashboard.manageSchoolForm.schoolNameLabel}</label>
        <input
          type="text"
          id="school-name"
          value={this.props.schoolName}
          onChange={this.createFieldHandler('schoolName')}
          onBlur={this.createFieldValidationHandler(
            'schoolName',
            SchoolForm.validations.schoolName,
          )}
        />
        <span className={cn('form-error', { 'is-visible': this.props.errors.schoolName })}>
          {this.props.errors.schoolName}
        </span>
        {this.renderDistrictSchoolInput()}
        {this.renderGrades()}
      </>
    )
  }

  renderSchoolProfile() {
    return (
      <>
        <fieldset>
          <legend>{lang.dashboard.manageSchoolForm.isRulerSchool}</legend>
          <input
            type="radio"
            name="ruler-school"
            value="Yes"
            id="ruler-school-yes"
            onChange={this.createIsRulerSchoolHandler()}
            checked={this.props.started_ruler}
          />
          <label className="text-capitalize" htmlFor="ruler-school-yes">
            {lang.yes}
          </label>
          <input
            type="radio"
            name="ruler-school"
            value="No"
            id="ruler-school-no"
            onChange={this.createIsRulerSchoolHandler()}
            checked={!this.props.started_ruler}
          />
          <label className="text-capitalize" htmlFor="ruler-school-no">
            {lang.no}
          </label>
        </fieldset>
        {this.props.started_ruler ? (
          <fieldset className="inline-controls">
            <label htmlFor="started_ruler_at">
              {lang.dashboard.manageSchoolForm.schoolRulerStart}
            </label>
            <select
              name="started_ruler_at"
              id="started_ruler_at"
              value={this.props.started_ruler_at}
              aria-label="Year"
              onChange={this.createStartedRulerAtHandler()}>
              <option value={defaultFormData().started_ruler_at} />
              {_.range(getYear(new Date()), 2011, -1).map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </select>
            <span
              className={cn('form-error', {
                'is-visible': this.props.errors.started_ruler_at,
              })}>
              {this.props.errors.started_ruler_at}
            </span>
          </fieldset>
        ) : null}
        <fieldset className="inline-controls">
          <label htmlFor="total_staff">{lang.dashboard.manageSchoolForm.totalStaff}</label>
          <select
            name="total_staff"
            id="total_staff"
            value={this.props.total_staff}
            disabled={isUser.schoolImplementationTeam(this.props.user)}
            onChange={this.createTotalStaffHandler()}>
            <option value={defaultFormData().total_staff} />
            {_.map(
              [
                { value: 10, text: '10 - 50' },
                { value: 50, text: '51 - 100' },
                { value: 100, text: '101 - 250' },
                { value: 250, text: '251 - 500' },
                { value: 500, text: '> 500' },
              ],
              (item) => (
                <option value={item.value} key={item.value}>
                  {item.text}
                </option>
              ),
            )}
          </select>
          <span
            className={cn('form-error', {
              'is-visible': this.props.errors.total_staff,
            })}>
            {this.props.errors.total_staff}
          </span>
        </fieldset>
        <TrainingPicker
          changeFormField={this.props.changeFormField}
          validateField={this.props.validateField}
          trainings={this.props.offlineTrainings}
          validations={SchoolForm.validations.offlineTrainings}
          errors={this.props.errors}
        />
        <fieldset className="inline-controls">
          <label htmlFor="school-phase">
            {lang.dashboard.manageSchoolForm.implementationPhase}
          </label>
          <select
            name=""
            id="school-phase"
            value={this.props.phase}
            onChange={this.createPhaseHandler()}
            onBlur={this.createFieldValidationHandler('phase', SchoolForm.validations.phase)}>
            <option value={defaultFormData().phase} />
            {_.map(this.props.phases, (phase) => (
              <option value={phase.id} key={phase.id}>
                {lang.dashboard.manageSchoolForm.phase} {phase.name}
              </option>
            ))}
          </select>
          <span className={cn('form-error', { 'is-visible': this.props.errors.phase })}>
            {this.props.errors.phase}
          </span>
        </fieldset>
        <div className="grid-x grid-margin-x">
          <div className="medium-6 cell">
            <label htmlFor="coach-name">{lang.dashboard.manageSchoolForm.coachName}</label>
            <input
              type="text"
              id="coach-name"
              value={this.props.coachName || ''}
              onChange={this.createFieldHandler('coachName')}
              onBlur={this.createFieldValidationHandler(
                'coachName',
                SchoolForm.validations.coachName,
              )}
            />
            <span className={cn('form-error', { 'is-visible': this.props.errors.coachName })}>
              {this.props.errors.coachName}
            </span>
          </div>
          <div className="medium-6 cell">
            <label htmlFor="coach-email">{lang.dashboard.manageSchoolForm.coachEmail}</label>
            <input
              type="text"
              id="coach-email"
              value={this.props.coachEmail || ''}
              onChange={this.createFieldHandler('coachEmail')}
              onBlur={this.createFieldValidationHandler(
                'coachEmail',
                SchoolForm.validations.coachEmail,
              )}
            />
            <span className={cn('form-error', { 'is-visible': this.props.errors.coachEmail })}>
              {this.props.errors.coachEmail}
            </span>
          </div>
        </div>
      </>
    )
  }

  renderSchoolAddress() {
    return (
      <fieldset>
        <legend>{lang.dashboard.manageSchoolForm.addressTitle}</legend>
        <div className="grid-x grid-margin-x">
          <div className="medium-6 cell">
            <label htmlFor="website-url">
              {lang.dashboard.locationForm.websiteLabel}
              <span className="option">{lang.optional}</span>
            </label>
            <input
              type="text"
              id="website-url"
              value={this.props.websiteUrl}
              onChange={this.createFieldHandler('websiteUrl')}
              onBlur={this.createFieldValidationHandler(
                'websiteUrl',
                SchoolForm.validations.websiteUrl,
              )}
            />
            <span className={cn('form-error', { 'is-visible': this.props.errors.websiteUrl })}>
              {this.props.errors.websiteUrl}
            </span>
          </div>
          <div className="medium-6 cell">
            <label htmlFor="phone-number">
              {lang.dashboard.locationForm.phoneNumberLabel}
              <span className="option">{lang.optional}</span>
            </label>
            <input
              type="text"
              id="phone-number"
              value={this.props.phoneNumber}
              onChange={this.createFieldHandler('phoneNumber')}
              onBlur={this.createFieldValidationHandler(
                'phoneNumber',
                SchoolForm.validations.phoneNumber,
              )}
            />
            <span className={cn('form-error', { 'is-visible': this.props.errors.phoneNumber })}>
              {this.props.errors.phoneNumber}
            </span>
          </div>
          <div className="medium-12 cell">
            <label htmlFor="country">{lang.dashboard.locationForm.countryLabel}</label>
            <select
              id="country"
              value={this.props.country}
              onChange={this.createCountryHandler()}
              onBlur={this.createFieldValidationHandler('country', SchoolForm.validations.country)}>
              <option value={defaultFormData().country} />
              {_.map(_.orderBy(this.props.availableCountries, 'name'), (country) => (
                <option value={country.code} key={country.code}>
                  {country.name}
                </option>
              ))}
            </select>
            <span className={cn('form-error', { 'is-visible': this.props.errors.country })}>
              {this.props.errors.country}
            </span>
          </div>
          <div className="medium-12 cell">
            <label htmlFor="street-address">
              {lang.dashboard.locationForm.addressLabel}
              <span className="option">{lang.optional}</span>
            </label>
            <input
              type="text"
              id="street-address"
              value={this.props.address}
              onChange={this.createFieldHandler('address')}
              onBlur={this.createFieldValidationHandler('address', SchoolForm.validations.address)}
            />
            <span className={cn('form-error', { 'is-visible': this.props.errors.address })}>
              {this.props.errors.address}
            </span>
          </div>
          <div className="medium-12 cell">
            <label htmlFor="street-address-2">
              {lang.dashboard.locationForm.address2Label}
              <span className="option">{lang.optional}</span>
            </label>
            <input
              type="text"
              id="street-address-2"
              value={this.props.address2}
              onChange={this.createFieldHandler('address2')}
            />
          </div>
          {_.isEmpty(this.props.country) ? null : (
            <div className="medium-4 cell">
              <label htmlFor="state">{lang.dashboard.locationForm.stateLabel}</label>
              <select
                name=""
                id="state"
                value={this.props.state}
                onChange={this.createStateHandler()}
                onBlur={this.createFieldValidationHandler('state', SchoolForm.validations.state)}>
                <option value={defaultFormData().state} />
                {_.map(this.props.availableStates, (state) => (
                  <option value={state.id} key={state.id}>
                    {state.name}
                  </option>
                ))}
              </select>
              <span className={cn('form-error', { 'is-visible': this.props.errors.state })}>
                {this.props.errors.state}
              </span>
            </div>
          )}
          {_.isEmpty(this.props.state) ? null : (
            <div className="medium-4 cell">
              <label htmlFor="city">{lang.dashboard.locationForm.cityLabel}</label>
              <select
                name=""
                id="city"
                value={this.props.city}
                onChange={this.createCityHandler()}
                onBlur={this.createFieldValidationHandler('city', SchoolForm.validations.city)}>
                <option value={defaultFormData().city} />
                {_.map(_.orderBy(this.props.availableCities, 'name'), (city) => (
                  <option value={city.id} key={city.id}>
                    {cityWithCounty(city.name, city.county)}
                  </option>
                ))}
              </select>
              <span className={cn('form-error', { 'is-visible': this.props.errors.city })}>
                {this.props.errors.city}
              </span>
            </div>
          )}
          <div className="medium-4 cell">
            <label htmlFor="zip-code">
              {lang.dashboard.locationForm.postalCodeLabel}
              <span className="option">{lang.optional}</span>
            </label>
            <input
              type="text"
              id="zip-code"
              value={this.props.postalCode}
              onChange={this.createFieldHandler('postalCode')}
              onBlur={this.createFieldValidationHandler(
                'postalCode',
                SchoolForm.validations.postalCode,
              )}
            />
            <span className={cn('form-error', { 'is-visible': this.props.errors.postalCode })}>
              {this.props.errors.postalCode}
            </span>
          </div>
        </div>
      </fieldset>
    )
  }

  renderSchoolLogo() {
    return (
      <ReactFilestack
        apikey={publicRuntimeConfig.FILESTACK_API}
        onSuccess={(response) => {
          this.props.changeFormField('logoUrl', response.filesUploaded[0].url)
        }}
        actionOptions={{
          transformations: {
            crop: {
              force: false,
            },
            circle: false,
          },
        }}
        customRender={({ onPick }) => (
          <button
            type="button"
            onClick={onPick}
            tabIndex="0"
            onKeyDown={onPick}
            className={cn('button hollow', { 'upload-image': this.props.logoUrl })}>
            <img src={this.props.logoUrl} alt={this.props.name} />
            {this.props.logoUrl
              ? lang.dashboard.manageSchoolForm.changeLogo
              : lang.dashboard.manageSchoolForm.uploadLogo}
          </button>
        )}
      />
    )
  }

  render() {
    return (
      <form action="">
        <span className={cn('form-error', { 'is-visible': this.props.errors.base })}>
          {this.props.errors.base}
        </span>
        {this.renderGeneralInfo()}
        {this.renderSchoolProfile()}
        {this.renderSchoolAddress()}
        {this.renderSchoolLogo()}
      </form>
    )
  }
}

import _ from 'lodash'
import React from 'react'
import { format } from 'date-fns'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { prependProtocolToURL, translateResponseError } from 'common/utils'
import { validateField, validateFields } from 'common/validator'
import DistrictFormModal from 'common/components/modals/district-form-modal'
import districtsService from 'services/districts'
import { changeModal } from 'services/actions'
import {
  getTotalDistrictsService,
  postDistrictService,
} from 'page-modules/dashboard/districts/service'
import {
  getBookmarkedDistrictsService,
  getDistrictDetailsService,
} from 'page-modules/dashboard/district-details/service'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  EDIT_DISTRICT_DETAILS,
  predefineDistrictForm,
  resetDistrictFormData,
  SUBMIT_DISTRICT_FROM_DASHBOARD,
  SUBMIT_DISTRICT_FROM_DISTRICT_DETAILS_PAGE,
  SUBMIT_DISTRICT_FROM_DISTRICTS_PAGE,
  VALIDATE_DISTRICT_FIELD,
} from './actions'

const prepareData = (payload) => {
  const data = {
    id: payload.id.value,
    slug: payload.slug.value,
    name: payload.districtName.value,
    city_id: payload.city.value,
    location: {
      website: payload.websiteUrl.value,
      address: payload.address.value,
      address2: payload.address2.value,
      phone: payload.phoneNumber.value,
      postal_code: payload.postalCode.value,
    },
    started_ruler: payload.started_ruler.value,
    coach_name: payload.coachName.value,
    coach_email: payload.coachEmail.value,
    offline_trainings: payload.offlineTrainings.value,
  }

  if (data.started_ruler) {
    const year = new Date(payload.started_ruler_at.value, 0, 1)
    data.started_ruler_at = format(year, 'yyyy-MM-dd')
  }

  // Users may forget to add 'http(s)://' protocol in website url
  // The backend is more strict with validations though https://github.com/liveSTORYBOARD/RULER/pull/565
  // Which means that if we send website url without proto we'll get not very friendly backend error
  // That's why we want to improve the UX by skipping this error
  // https://trello.com/c/cVBWmTzU/1881-improve-url-input-ux
  data.location.website = prependProtocolToURL(data.location.website)

  return data
}

export function* submitDistrictFromDistrictsPageSaga({ payload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const data = prepareData(payload)
    const districtResult = yield call(postDistrictService.requestSaga, {
      payload: {
        data,
      },
    })
    if (districtResult.error) {
      yield put(
        addFieldError({
          field: 'base',
          error: translateResponseError(districtResult.error),
        }),
      )
    } else {
      // Close modal and reset form
      yield put(changeModal({ isOpen: false }))
      yield put(resetDistrictFormData())

      yield put(districtsService.actions.request())
      yield put(getTotalDistrictsService.actions.request())
    }
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export function* submitDistrictFromDistrictDetailsPageSaga({ payload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const data = prepareData(payload)
    const districtResult = yield call(postDistrictService.requestSaga, {
      payload: {
        data,
      },
    })
    if (districtResult.error) {
      yield put(
        addFieldError({
          field: 'base',
          error: translateResponseError(districtResult.error),
        }),
      )
    } else {
      // Close modal and reset form
      yield put(changeModal({ isOpen: false }))
      yield put(resetDistrictFormData())

      yield put(districtsService.actions.request())
      yield put(getDistrictDetailsService.actions.request({ urlParams: { id: districtResult.id } }))
    }
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export function* submitDistrictFromDashboardSaga({ payload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const data = prepareData(payload)
    const districtResult = yield call(postDistrictService.requestSaga, {
      payload: {
        data,
      },
    })
    if (districtResult.error) {
      yield put(
        addFieldError({
          field: 'base',
          error: translateResponseError(districtResult.error),
        }),
      )
    } else {
      // Close modal and reset form
      yield put(changeModal({ isOpen: false }))
      yield put(resetDistrictFormData())
      yield call(getBookmarkedDistrictsService.requestSaga, { payload: { urlParams: {} } })
    }
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export function* editDistrictDetailsSaga({ payload: { districtId, submitDistrict } }) {
  const district = yield call(getDistrictDetailsService.requestSaga, {
    payload: { urlParams: { id: districtId } },
  })
  if (district.error) return
  const startedRulerAt = new Date(district.started_ruler_at)
  yield put(
    predefineDistrictForm({
      id: district.id,
      slug: district.slug,
      districtName: district.name,
      websiteUrl: district.location.website,
      phoneNumber: district.location.phone,
      address: district.location.address,
      address2: district.location.address2,
      state: district.city.state.id,
      country: district.city.state.country.code,
      city: district.city.id,
      postalCode: district.location.postal_code,
      started_ruler_at: format(startedRulerAt, 'yyyy'),
      started_ruler: district.started_ruler,
      coachName: district.coach_name,
      coachEmail: district.coach_email,
      offlineTrainings: _.map(district.district_offline_trainings, (training) =>
        _.pick(training, ['offline_training_id', 'training_attended_at']),
      ),
    }),
  )
  yield put(
    changeModal({
      isOpen: true,
      aria: {
        labelledby: 'add-new-district-header',
      },
      className: 'add-district full',
      content: <DistrictFormModal submitDistrict={submitDistrict} />,
    }),
  )
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_DISTRICT_FIELD, validateFieldSaga),
    takeLatest(SUBMIT_DISTRICT_FROM_DISTRICTS_PAGE, submitDistrictFromDistrictsPageSaga),
    takeLatest(
      SUBMIT_DISTRICT_FROM_DISTRICT_DETAILS_PAGE,
      submitDistrictFromDistrictDetailsPageSaga,
    ),
    takeLatest(SUBMIT_DISTRICT_FROM_DASHBOARD, submitDistrictFromDashboardSaga),
    takeLatest(EDIT_DISTRICT_DETAILS, editDistrictDetailsSaga),
  ])
}

const prependProtocolToURL = (url) => {
  if (!url) {
    return ''
  }
  if (!/http:\/\//.test(url) && !/https:\/\//.test(url)) {
    url = `http://${url}`
  }
  return url
}

export default prependProtocolToURL

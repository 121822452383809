import _ from 'lodash'

import {
  ADD_MOVE_USER_FIELD_ERROR,
  ADD_MOVE_USER_FIELDS_ERRORS,
  CHANGE_MOVE_USER_FORM_FIELD,
  CLEAR_MOVE_USER_FIELD_ERROR,
  RESET_MOVE_USER_FORM_DATA,
} from './actions'

export const defaultFormData = (predefined = {}) =>
  _.merge(
    {
      initialSchoolId: '',
      initialDistrictId: '',
      schoolId: '',
      districtId: '',
    },
    predefined,
  )

export const initialState = () => ({
  formData: defaultFormData(),
  errors: {},
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_MOVE_USER_FORM_FIELD:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.key]: action.payload.value,
        },
      }

    case ADD_MOVE_USER_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      }

    case ADD_MOVE_USER_FIELDS_ERRORS:
      return {
        ...state,
        errors: {
          ...action.payload.errors,
        },
      }

    case RESET_MOVE_USER_FORM_DATA:
      return {
        ...state,
        formData: defaultFormData(),
        errors: {},
      }

    case CLEAR_MOVE_USER_FIELD_ERROR:
      return {
        ...state,
        errors: {
          [action.payload.field]: undefined,
        },
      }

    default:
      return state
  }
}

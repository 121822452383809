export const LOAD_CATEGORY_DETAILS_PAGE_DATA = 'LOAD_CATEGORY_DETAILS_PAGE_DATA'
export const LOAD_GRADE_DETAILS_PAGE_DATA = 'LOAD_GRADE_DETAILS_PAGE_DATA'
export const LOAD_LESSON_DETAILS_PAGE_DATA = 'LOAD_LESSON_DETAILS_PAGE_DATA'
export const INIT_LESSON_SECTIONS = 'INIT_LESSON_SECTIONS'
export const SHOW_LESSON_SECTION = 'SHOW_LESSON_SECTION'
export const LESSON_SHOW_UP_TO_STEP = 'LESSON_SHOW_UP_TO_STEP'
export const SHOW_LESSON_OUTRO = 'SHOW_LESSON_OUTRO'

export function loadCategoryPageData({ categorySlug, req }) {
  return {
    type: LOAD_CATEGORY_DETAILS_PAGE_DATA,
    payload: { categorySlug, req },
  }
}

export function loadGradePageData({ gradeSlug, categorySlug, req }) {
  return {
    type: LOAD_GRADE_DETAILS_PAGE_DATA,
    payload: { gradeSlug, categorySlug, req },
  }
}

export function loadLessonPageData({ gradeSlug, lessonSlug, req }) {
  return {
    type: LOAD_LESSON_DETAILS_PAGE_DATA,
    payload: { gradeSlug, lessonSlug, req },
  }
}

export function initLessonSections({ sections }) {
  return {
    type: INIT_LESSON_SECTIONS,
    payload: { sections },
  }
}

export function showSection({ sectionId }) {
  return {
    type: SHOW_LESSON_SECTION,
    payload: { sectionId },
  }
}

export function showUpToStep({ stepId }) {
  return {
    type: LESSON_SHOW_UP_TO_STEP,
    payload: { stepId },
  }
}

export function showOutro() {
  return {
    type: SHOW_LESSON_OUTRO,
  }
}

import buildService from 'common/utils/build-service'
import { GET, PATCH } from 'common/requests'
import { Country } from 'common/types'

export default buildService<{ enabled?: boolean }, Country>({
  type: GET,
  constantsPrefix: 'COUNTRIES',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ enabled = true }: { enabled?: boolean }) => {
      let filter = ''
      if (enabled) {
        filter = '&status=eq.enabled'
      }
      return `/countries?select=*${filter}&order=name`
    },
  },
})

export const patchCountryService = buildService<{ code?: string }, Country>({
  type: PATCH,
  constantsPrefix: 'COUNTRY',
  http: {
    createApiUrlSuffix: ({ code }) => `/countries?code=eq.${code}`,
  },
})

import cookieParser from 'cookie'
import clientCookie from 'js-cookie'

const getJWT = ({ req } = {}) => {
  let jwt
  if (!process.browser && req && req.headers.cookie) {
    const cookies = cookieParser.parse(req.headers.cookie)
    jwt = cookies['ruler-jwt']
  } else {
    jwt = clientCookie.get('ruler-jwt')
  }
  return jwt
}

export default getJWT

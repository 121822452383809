import _ from 'lodash'
import buildService from 'common/utils/build-service'
import { POST } from 'common/requests'

export const ADD_ACTIVITY_STREAM_ITEM = 'ADD_ACTIVITY_STREAM_ITEM'

const transformActivityItem = (item) => ({
  ...item,
  eventType: item.event_key,
  createdAt: _.isDate(item.created_at) ? item.created_at : new Date(item.created_at),
  seenAt: item.seen_at && new Date(item.seen_at),
  scope: _.get(item, 'payload.scope', _.get(item, 'scope')),
})

export const getActivityStreamService = buildService({
  type: POST,
  constantsPrefix: 'ACTIVITY_STREAM',
  http: {
    createApiUrlSuffix: () => '/rpc/activity_stream',
    headers: {
      Accept: 'application/json',
      Prefer: 'params=single-object',
    },
  },
  isArray: true,
  addItemActionType: ADD_ACTIVITY_STREAM_ITEM,
  transformItem: transformActivityItem,
})

export const markActivityStreamService = buildService({
  type: POST,
  constantsPrefix: 'ACTIVITY_STREAM_MARK',
  http: {
    createApiUrlSuffix: () => '/rpc/activity_stream_mark_read',
    headers: {
      Accept: 'application/json',
      Prefer: 'params=single-object',
    },
  },
})

export const getAnnouncementsService = buildService({
  type: POST,
  constantsPrefix: 'ANNOUNCEMENTS',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/rpc/activity_stream',
    defaultData: {
      // Fetches all kind of activity stream events since this date
      from: '2022-01-01',
      // Override the limit, so we can receive more items and filter only announcements
      // Patching this until a proper `event_key` filter is available
      max_rows: 10,
      // Filter only scope `auth` as it includes minimal set of needed events
      // It will fetch `message.post`, `course.insert` and `resource.insert` events
      scope: 'auth',
    },
    headers: {
      Accept: 'application/json',
      Prefer: 'params=single-object',
    },
  },
  transformItem: transformActivityItem,
})

export const postActivityStreamMessageService = buildService({
  type: POST,
  constantsPrefix: 'ACTIVITY_STREAM_MESSAGE',
  http: {
    createApiUrlSuffix: () => '/rpc/activity_stream_post_message',
    headers: {
      Accept: 'application/json',
      Prefer: 'params=single-object',
    },
  },
})

export const deleteAnnouncementService = buildService({
  type: POST,
  constantsPrefix: 'DELETE_ANNOUNCEMENTS',
  http: {
    createApiUrlSuffix: () => `/rpc/activity_stream_delete_message`,
    headers: {
      Accept: 'application/json',
      Prefer: 'params=single-object',
    },
  },
})

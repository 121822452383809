import _ from 'lodash'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { validateField, validateFields } from 'common/validator'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  SUBMIT_CHARTER_FROM_DETAILS_PAGE,
  SUBMIT_CHARTER_FROM_LISTING_PAGE,
  VALIDATE_CHARTER_FIELD,
} from './actions'

import { getCharterDetailsService, getChartersService, postCharterService } from './index'

const prepareData = (payload) => {
  const data = {
    name: payload.name.value,
    audience_type_id: payload.type.value,
    content: '',
  }

  if (payload.isExistingCharter && payload.isExistingCharter.value) {
    // existing charter
    data.is_existing_charter = true
    data.existing_charter_url = payload.existingCharterUrl.value
  } else {
    // new charter
    data.emotion_words = _.map(payload.emotionWords.value, (word) => ({
      emotion_word_id: word.id,
      actions: (word && word.actions && _.compact(word.actions)) || [],
    }))
    data.charter_template = payload.template.value
    data.is_existing_charter = false
  }

  if (payload.grade && payload.grade.value) {
    data.grade_id = payload.grade.value
  }

  // If there is id we are updating a charter, instead of creating a new one.
  if (payload.id && payload.id.value) {
    data.id = payload.id.value
  }

  return data
}

function* submitCharterFromDetailsPageSaga({ payload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const data = prepareData(payload)

    const result = yield call(postCharterService.requestSaga, {
      payload: {
        data,
      },
    })
    if (result.error) return
    const userId = yield select((state) => state.root.user.id)
    yield call(getCharterDetailsService.requestSaga, {
      payload: {
        urlParams: { slug: result.slug, ownerId: userId },
      },
    })
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export function* submitCharterFromListingPageSaga({ payload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const data = prepareData(payload)

    const charterResult = yield call(postCharterService.requestSaga, {
      payload: {
        data,
      },
    })
    if (charterResult.error) return
    const userId = yield select((state) => state.root.user.id)
    const order = yield select((state) => state.charter.ui.order)
    yield call(getChartersService.requestSaga, {
      payload: {
        urlParams: { order, ownerId: userId },
      },
    })
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_CHARTER_FIELD, validateFieldSaga),
    takeLatest(SUBMIT_CHARTER_FROM_DETAILS_PAGE, submitCharterFromDetailsPageSaga),
    takeLatest(SUBMIT_CHARTER_FROM_LISTING_PAGE, submitCharterFromListingPageSaga),
  ])
}

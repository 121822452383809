import _ from 'lodash'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import lang from 'lang'
import { httpRequest } from 'services/saga'
import { getApiUrl } from 'services/selectors'
import { validateField, validateFields } from 'common/validator'
import { requests } from 'common/requests'

import {
  addFieldError,
  clearFieldError,
  FORGOT_PASSWORD_REQUEST,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  VALIDATE_FORGOT_PASSWORD_FIELD,
} from './actions'

function* forgotPasswordSaga({ payload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const apiURL = yield select(getApiUrl)
    try {
      yield call(httpRequest, {
        req: requests.post,
        url: `${apiURL}/rpc/forgot_password`,
        headers: {
          Prefer: 'params=single-object',
        },
        data: _.mapValues(payload, 'value'),
      })
      yield put(forgotPasswordSuccess())
    } catch (error) {
      yield put(
        forgotPasswordFailure({
          base: _.get(error, 'response.data.message', lang.unhandledServerError),
        }),
      )
    }
  } else {
    yield put(forgotPasswordFailure(errors))
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordSaga),
    takeEvery(VALIDATE_FORGOT_PASSWORD_FIELD, validateFieldSaga),
  ])
}

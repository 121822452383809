export const CHANGE_CHANGE_PASSWORD_FORM_FIELD = 'CHANGE_CHANGE_PASSWORD_FORM_FIELD'
export const SUBMIT_CHANGE_PASSWORD = 'SUBMIT_CHANGE_PASSWORD'
export const VALIDATE_CHANGE_PASSWORD_FIELD = 'VALIDATE_CHANGE_PASSWORD_FIELD'
export const ADD_CHANGE_PASSWORD_FIELD_ERROR = 'ADD_CHANGE_PASSWORD_FIELD_ERROR'
export const ADD_CHANGE_PASSWORD_FIELDS_ERRORS = 'ADD_CHANGE_PASSWORD_FIELDS_ERRORS'
export const CLEAR_CHANGE_PASSWORD_FIELD_ERROR = 'CLEAR_CHANGE_PASSWORD_FIELD_ERROR'
export const RESET_CHANGE_PASSWORD_FORM_DATA = 'RESET_CHANGE_PASSWORD_FORM_DATA'
export const PREDEFINE_CHANGE_PASSWORD_FORM_DATA = 'PREDEFINE_CHANGE_PASSWORD_FORM_DATA'

export function changePasswordFormField(key, value) {
  return {
    type: CHANGE_CHANGE_PASSWORD_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}
export function submitChangePassword(payload) {
  return {
    type: SUBMIT_CHANGE_PASSWORD,
    payload,
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_CHANGE_PASSWORD_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_CHANGE_PASSWORD_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_CHANGE_PASSWORD_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetChangePasswordFormData() {
  return {
    type: RESET_CHANGE_PASSWORD_FORM_DATA,
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_CHANGE_PASSWORD_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

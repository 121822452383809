import _ from 'lodash'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import statesService from 'services/states'

import { initialState } from './reducer'
import {
  CHANGE_SCHOOLS_COUNTRY_FILTER,
  CHANGE_SCHOOLS_DISTRICT_FILTER,
  CHANGE_SCHOOLS_PAGE,
  CHANGE_SCHOOLS_STATE_FILTER,
  changeSchoolsFilters,
  LOAD_SCHOOLS,
} from './actions'

import schoolsService from './index'

export const defaultItemsPerPage = 20

function* loadSchools() {
  const { countryCode, districtId, stateId, page } = yield select((state) => state.schoolsFilters)
  const from = page * defaultItemsPerPage

  yield put(
    schoolsService.actions.request({
      range: { from, to: from + defaultItemsPerPage - 1 },
      urlParams: { countryCode, districtId, stateId },
    }),
  )
}

function* changeSchoolsCountryFilterSaga({ payload }) {
  yield put(statesService.actions.request({ urlParams: { countryId: payload } }))
  const initialFields = initialState()
  yield put(changeSchoolsFilters(_.pick(initialFields, ['page', 'districtId', 'stateId'])))
  yield call(loadSchools)
}

function* changeSchoolsDistrictFilterSaga() {
  const initialFields = initialState()
  const districtId = select((state) => state.schoolsFilters.districtId)
  let fieldsToReset = ['page']
  // Don't reset country and state filters if the initial district filter value is selected.
  if (districtId !== initialFields.districtId) {
    fieldsToReset = fieldsToReset.concat(['countryCode', 'stateId'])
  }
  yield put(changeSchoolsFilters(_.pick(initialFields, fieldsToReset)))
  yield call(loadSchools)
}

function* changeSchoolsStateFilterSaga() {
  const initialFields = initialState()
  yield put(changeSchoolsFilters(_.pick(initialFields, ['page', 'districtId'])))
  yield call(loadSchools)
}

export default function* saga() {
  yield all([
    takeEvery(CHANGE_SCHOOLS_COUNTRY_FILTER, changeSchoolsCountryFilterSaga),
    takeEvery(CHANGE_SCHOOLS_DISTRICT_FILTER, changeSchoolsDistrictFilterSaga),
    takeEvery(CHANGE_SCHOOLS_STATE_FILTER, changeSchoolsStateFilterSaga),
    takeEvery(CHANGE_SCHOOLS_PAGE, loadSchools),
    takeEvery(LOAD_SCHOOLS, loadSchools),
  ])
}

import _ from 'lodash'
import { put } from 'redux-saga/effects'

import lang from 'lang'
import buildService from 'common/utils/build-service'
import { getJWT } from 'common/utils'
import { changeModal, FETCH_CURRENT_USER } from 'services/actions'
import { getUserPreferences } from 'services/users'
import { POST } from 'common/requests'

import { addFieldError, resetOnboardingFormData } from './actions'

export const postOnboardingService = buildService({
  type: POST,
  constantsPrefix: 'ONBOARDING',
  http: {
    createApiUrlSuffix: () => '/rpc/onboarding',
    headers: {
      Prefer: 'params=single-object',
    },
    *successHandler() {
      const jwt = getJWT()
      yield put({ type: FETCH_CURRENT_USER, payload: { jwt } })
      yield put(resetOnboardingFormData())
      yield put(getUserPreferences.actions.request())
    },
    *failureHandler(error) {
      yield put(
        addFieldError({
          field: 'base',
          error: _.get(error, 'response.data.message', lang.unhandledServerError),
        }),
      )
    },
  },
})

export const CHANGE_DISTRICT_USER_FORM_FIELD = 'CHANGE_DISTRICT_USER_FORM_FIELD'
export const SUBMIT_DISTRICT_USER = 'SUBMIT_DISTRICT_USER'
export const VALIDATE_DISTRICT_USER_FIELD = 'VALIDATE_DISTRICT_USER_FIELD'
export const ADD_DISTRICT_USER_FIELD_ERROR = 'ADD_DISTRICT_USER_FIELD_ERROR'
export const ADD_DISTRICT_USER_FIELDS_ERRORS = 'ADD_DISTRICT_USER_FIELDS_ERRORS'
export const CLEAR_DISTRICT_USER_FIELD_ERROR = 'CLEAR_DISTRICT_USER_FIELD_ERROR'
export const RESET_DISTRICT_USER_FORM_DATA = 'RESET_DISTRICT_USER_FORM_DATA'
export const PREDEFINE_DISTRICT_USER_FORM_DATA = 'PREDEFINE_DISTRICT_USER_FORM_DATA'
export const TOGGLE_DISTRICT_STATUS = 'TOGGLE_DISTRICT_STATUS'
export const BOOKMARK_DISTRICT = 'BOOKMARK_DISTRICT'
export const LOAD_DISTRICT_DETAILS_PAGE = 'LOAD_DISTRICT_DETAILS_PAGE'

export function changeDistrictUserFormField(key, value) {
  return {
    type: CHANGE_DISTRICT_USER_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}
export function submitDistrictUser({
  id,
  email,
  fullName,
  officePhone,
  mobilePhone,
  roleId,
  personTypeId,
  districtId,
}) {
  return {
    type: SUBMIT_DISTRICT_USER,
    payload: {
      id,
      email,
      fullName,
      officePhone,
      mobilePhone,
      roleId,
      personTypeId,
      districtId,
    },
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_DISTRICT_USER_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_DISTRICT_USER_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_DISTRICT_USER_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetDistrictUserFormData() {
  return {
    type: RESET_DISTRICT_USER_FORM_DATA,
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_DISTRICT_USER_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

export function predefineDistrictUserForm({
  id,
  email,
  fullName,
  officePhone,
  mobilePhone,
  roleId,
  personTypeId,
  districtId,
}) {
  return {
    type: PREDEFINE_DISTRICT_USER_FORM_DATA,
    payload: {
      id,
      email,
      fullName,
      officePhone,
      mobilePhone,
      roleId,
      personTypeId,
      districtId,
    },
  }
}

export function toggleDistrictStatus({ id, slug, status }) {
  return {
    type: TOGGLE_DISTRICT_STATUS,
    payload: { id, slug, status },
  }
}

export function bookmarkDistrict({ id, bookmarked, refreshMethod }) {
  return {
    type: BOOKMARK_DISTRICT,
    payload: { id, bookmarked, refreshMethod },
  }
}

export function loadDistrictDetailsPage({ slug, req }) {
  return {
    type: LOAD_DISTRICT_DETAILS_PAGE,
    payload: { slug, req },
  }
}

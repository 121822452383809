import _ from 'lodash'

import {
  ADD_MORE_FORMS,
  ADD_SCHOOL_USER_API_ERROR,
  ADD_SCHOOL_USER_FIELD_ERROR,
  ADD_SCHOOL_USER_FIELDS_ERRORS,
  CHANGE_SCHOOL_USER_FORM_FIELD,
  CLEAR_SCHOOL_USER_FIELD_ERROR,
  PREDEFINE_SCHOOL_USER_FORM_DATA,
  RESET_SCHOOL_USER_FORM_DATA,
} from './actions'

const defaultFormData = (predefined = {}) =>
  _.merge(
    {
      id: '',
      email: '',
      fullName: '',
      roleId: '',
      personTypeId: '',
    },
    _.pickBy(predefined, _.identity),
  )

const createForms = (startId = 0, count = 1) => {
  const state = {}
  _.times(count, (id) => {
    state[`school_user_form_${startId + id}`] = {
      formData: defaultFormData(),
      errors: {},
    }
  })
  return state
}

export const initialState = () => {
  return createForms()
}

const changeSingleForm = (state, payload) => {
  state = _.cloneDeep(state)

  state[payload.formId] = {
    formData: {
      ...state[payload.formId].formData,
      [payload.key]: payload.value,
    },
    errors: {
      ...state[payload.formId].errors,
    },
  }
  return state
}
const validateSingleField = (state, payload) => {
  state = _.cloneDeep(state)
  state[payload.formId] = {
    formData: {
      ...state[payload.formId].formData,
    },
    errors: {
      ...state[payload.formId].errors,
      [payload.field]: payload.error,
    },
  }
  return state
}

export default function root(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_SCHOOL_USER_FORM_FIELD:
      return changeSingleForm(state, action.payload)

    case ADD_SCHOOL_USER_FIELD_ERROR:
      return validateSingleField(state, action.payload)

    case ADD_SCHOOL_USER_API_ERROR: {
      const clonedState = _.cloneDeep(state)

      clonedState.school_user_form_0 = {
        formData: {
          ...state.school_user_form_0.formData,
        },
        errors: {
          ...state.school_user_form_0.errors,
          [action.payload.field]: action.payload.error,
        },
      }

      return clonedState
    }

    case ADD_SCHOOL_USER_FIELDS_ERRORS:
      return {
        ...state,
        [action.payload.formId]: {
          formData: {
            ...state[action.payload.formId].formData,
          },
          errors: {
            ...state[action.payload.formId].errors,
            ...action.payload.errors,
          },
        },
      }

    case CLEAR_SCHOOL_USER_FIELD_ERROR:
      return validateSingleField(state, { ...action.payload, error: undefined })

    case RESET_SCHOOL_USER_FORM_DATA:
      return initialState()

    case PREDEFINE_SCHOOL_USER_FORM_DATA:
      return {
        ...state,
        school_user_form_0: {
          formData: defaultFormData(action.payload),
          errors: {},
        },
      }

    case ADD_MORE_FORMS:
      return {
        ...state,
        ...createForms(Object.keys(state).length, action.payload.count),
      }

    default:
      return state
  }
}

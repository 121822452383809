import _ from 'lodash'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import lang from 'lang'
import { translateResponseError } from 'common/utils'
import { validateField, validateFields } from 'common/validator'
import { changeModal } from 'services/actions'
import emotionWordsService, {
  deleteEmotionWordService,
  patchEmotionWordService,
  postEmotionWordService,
} from 'services/emotion-words'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  DELETE_EMOTION_WORD,
  resetEmotionWordForm,
  SUBMIT_EMOTION_WORD,
  VALIDATE_EMOTION_WORD_FIELD,
} from './actions'

const prepareData = (payload) => {
  const data = {
    name: payload.name.value,
    definition: payload.definition.value,
    mood_meter: payload.moodMeter.value,
    charter: payload.charter.value,
    energy_lower: payload.energyLower.value,
    energy_higher: payload.energyHigher.value,
    pleasantness_higher: payload.pleasantnessHigher.value,
    pleasantness_lower: payload.pleasantnessLower.value,
    quadrant: '',
  }

  if (payload.id && payload.id.value) {
    data.id = payload.id.value
  }

  return data
}

export function* submitEmotionWordSaga({ payload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const data = prepareData(payload)
    let emotionWordResult
    if (data.id) {
      emotionWordResult = yield call(patchEmotionWordService.requestSaga, {
        payload: {
          data,
          urlParams: { id: data.id },
        },
      })
    } else {
      emotionWordResult = yield call(postEmotionWordService.requestSaga, {
        payload: {
          data,
        },
      })
    }
    if (emotionWordResult.error) {
      const status = _.get(emotionWordResult, 'error.response.status')
      if (status === 409) {
        yield put(
          addFieldError({
            field: 'base',
            error: lang.page.administration.emotionWords.emotionWordForm.nameAlreadyExistsError,
          }),
        )
      } else {
        yield put(
          addFieldError({
            field: 'base',
            error: translateResponseError(emotionWordResult.error),
          }),
        )
      }
    } else {
      yield put(
        changeModal({
          isOpen: false,
        }),
      )
      yield put(resetEmotionWordForm())
      yield put(emotionWordsService.actions.request())
    }
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export function* deleteEmotionWordSaga({ payload: { emotionWordId } }) {
  const result = yield call(deleteEmotionWordService.requestSaga, {
    payload: { urlParams: { id: emotionWordId } },
  })

  if (!result.error) {
    yield put(
      changeModal({
        isOpen: false,
      }),
    )
    yield put(emotionWordsService.actions.request())
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_EMOTION_WORD_FIELD, validateFieldSaga),
    takeLatest(SUBMIT_EMOTION_WORD, submitEmotionWordSaga),
    takeLatest(DELETE_EMOTION_WORD, deleteEmotionWordSaga),
  ])
}

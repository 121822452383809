import _ from 'lodash'
import cn from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import lang from 'lang'

import UserGridItem from './user-grid-item'
import UserGridHeader from './user-grid-header'

@connect((state) => ({
  uploadedUsers: state.school.addSchoolUser.csvUploadForm.formData.users,
  errors: state.school.addSchoolUser.csvUploadForm.errors,
}))
export default class UsersGridForm extends React.Component {
  static propTypes = {
    errors: PropTypes.object,
    uploadedUsers: PropTypes.array,
  }

  get csvParsed() {
    return this.props.uploadedUsers.length > 0
  }

  renderDescription() {
    return (
      !this.csvParsed && (
        <div className="upload-description margin-top-1">
          <p>{lang.dashboard.userForm.uploadCsv.description}</p>
          <div className="users-grid cell large-6">
            <UserGridHeader readOnly />
            <div className="rows">
              <UserGridItem
                readOnly
                index={0}
                user={{ name: 'Joe Graham', email: 'joe.graham@gmail.com' }}
              />
              <UserGridItem
                readOnly
                index={1}
                user={{ name: 'Hannah Harris', email: 'hannah.harris@gmail.com' }}
              />
            </div>
            <p className="download-sample">
              <a
                target="_blank"
                download="RULER_online_upload_users_sample.csv"
                rel="noopener noreferrer"
                href="https://ruleronlineimgs.s3.amazonaws.com/downloads/RULER_online_upload_users_sample.csv">
                {lang.dashboard.userForm.uploadCsv.downloadSample}
              </a>
            </p>
          </div>
        </div>
      )
    )
  }

  renderErrors() {
    const message = _.get(this.props.errors, 'base.message')
    return <div className={cn('form-error', { 'is-visible': !_.isEmpty(message) })}>{message}</div>
  }

  renderUsers() {
    const { uploadedUsers } = this.props

    if (uploadedUsers.length === 0) {
      return null
    }

    return (
      <div className="users-grid">
        <UserGridHeader />
        <div className="rows">
          {_.map(uploadedUsers, (user, index) => {
            return <UserGridItem key={index} user={user} index={index} />
          })}
        </div>
      </div>
    )
  }

  render() {
    return (
      <>
        {this.renderDescription()}
        {this.renderUsers()}
        {this.renderErrors()}
      </>
    )
  }
}

import { ServerResponse } from 'http'
import _ from 'lodash'
import clientCookie from 'js-cookie'
import { AxiosResponse } from 'axios'
import { call, put, select } from 'redux-saga/effects'

import mixpanel, { EVENTS } from 'lib/mixpanel'
import { User } from 'common/types'
import { updateUser } from 'services/actions'
import { getApiUrl } from 'services/selectors'
import { ApiRequestConfig, requests } from 'common/requests'

const oldRoles = [
  'School Faculty',
  'School Staff',
  'School Leadership',
  'School Implementation Team',
  'School Admin',
  'District Staff',
  'District Supervisory Committee',
  'District Admin',
  'RULER Admin',
]

const oldRolesRegExp = new RegExp(`role "(${_.join(oldRoles, '|')})" does not exist`)

const isJWTInvalid = (errorMessage: string) => {
  return (
    errorMessage === 'JWT expired' ||
    errorMessage === 'Invalid token' ||
    errorMessage === 'Session is wrong!' ||
    _.includes(errorMessage, 'CompactDecodeError') ||
    _.includes(errorMessage, 'JWSError') ||
    _.includes(errorMessage, 'JWSInvalidSignature') ||
    // If the users have a JWT with the old role make the JWT invalid.
    oldRolesRegExp.test(errorMessage)
  )
}

export function* logOut() {
  clientCookie.remove('ruler-jwt')
  mixpanel.track(EVENTS.LOGOUT)
  mixpanel.reset()
  yield put(updateUser({} as User))
  window.location.href = '/login'
}

// Generator<
//   CallEffect<AxiosResponse<T>> | CallEffect<ReturnType<typeof logOut>>,
//   AxiosResponse<T>,
//   AxiosResponse<T>
// >

// Make http requests like a saga superhero!
export function* httpRequest<T>({ req, url, data, headers, options }: ApiRequestConfig<T>) {
  try {
    const response: AxiosResponse<T> = yield call(req, { url, headers, data, options })
    return response
  } catch (err) {
    const errorMessage = _.get(err, 'response.data.message')
    const code = _.get(err, 'response.status')
    if (process.browser && (isJWTInvalid(errorMessage) || code === 401)) {
      yield call(logOut)
    }
    throw err
  }
}

export function* httpRequestWithoutRedirectionOnFailure<T>({
  req,
  url,
  data,
  headers,
}: ApiRequestConfig<T>) {
  const response: AxiosResponse<T> = yield call(req, { url, headers, data })
  return response
}
// : Generator<SelectEffect | CallEffect<AxiosResponse<User>>, void, string | AxiosResponse<User>>
// : Generator<CallEffect<AxiosResponse<User>> | SelectEffect, void, string>
// type HTTPResponse = SagaReturnType<typeof httpRequest>
export function* authenticateUser({
  payload: { jwt, res },
}: {
  payload: { jwt: string; res: ServerResponse }
}) {
  const apiURL: ReturnType<typeof getApiUrl> = yield select(getApiUrl)
  try {
    yield call(httpRequest, {
      req: requests.post,
      url: `${apiURL}/rpc/me`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    // User cookie is valid so we can redirect him directly to his dashboard
    res.writeHead(302, { Location: '/dashboard' })
    res.end()
  } catch (err) {
    // Invalid cookie, we won't do anything for the user
  }
}

export function* fetchCurrentUser({
  payload: { jwt, redirectOnInvalidToken = true, res = {} as ServerResponse },
}: {
  payload: { jwt: string; redirectOnInvalidToken: boolean; res: ServerResponse }
}) {
  const apiURL: ReturnType<typeof getApiUrl> = yield select(getApiUrl)
  try {
    const { data }: AxiosResponse<User> = yield call(httpRequest, {
      req: requests.post,
      url: `${apiURL}/rpc/me`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    yield put(updateUser(data))
  } catch (error) {
    const errorMessage = _.get(error, 'response.data.message')
    if (isJWTInvalid(errorMessage)) {
      if (!_.isEmpty(res)) {
        // fetchCurrentUser is invoked server side so we need to redirect to login
        const deletedCookie = `ruler-jwt=null; path=/; expires=${new Date().toUTCString()}`
        if (redirectOnInvalidToken) {
          // Because of some double calling we need to check if headers are sent.
          // Otherwise we get the non-breaking "Cannot set headers after they are sent to the client" error.
          //
          // Where is this double calling coming from? Don't know :|
          if (!res.headersSent) {
            res.writeHead(302, {
              'Set-Cookie': deletedCookie,
              Location: '/login',
            })
            res.end()
          }
        } else {
          res.setHeader('Set-Cookie', deletedCookie)
        }
      } else {
        // fetchCurrentUser is invoked client side so we need to logout the user
        yield call(logOut)
      }
    } else {
      res.writeHead(302, { Location: '/maintenance' })
      res.end()
      throw error
    }
  }
}

import { Action } from 'redux'

import { User } from 'common/types'

export const FAILURE = 'FAILURE'
export const ADD_VIEWER = 'ADD_VIEWER'
export const REMOVE_VIEWER = 'REMOVE_VIEWER'
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER'
export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER'
export const LOG_OUT = 'LOG_OUT'
export const UPDATE_USER = 'UPDATE_USER'
export const CHANGE_MODAL = 'CHANGE_MODAL'
export const CALCULATE_SCROLL_PROGRESS = 'CALCULATE_SCROLL_PROGRESS'

export function updateUser(user: User): StateAction<typeof UPDATE_USER, { user: User }> {
  return {
    type: UPDATE_USER,
    payload: { user },
  }
}

export function logOut(): Action<typeof LOG_OUT> {
  return {
    type: LOG_OUT,
  }
}

export interface ErrorAction<T, P> extends Action<T> {
  payload: P
}
export interface StateAction<T, P> extends Action<T> {
  payload: P
}

export interface ModalState {
  isOpen: boolean
  content?: React.ReactNode
  hideCloseButton?: boolean
  aria?: {
    labelledby?: string
  }
  className?: string
  shouldCloseOnOverlayClick?: boolean
  onClose?: () => void
  style?: CSSStyleDeclaration
}

export function changeModal({
  isOpen,
  hideCloseButton,
  content,
  aria,
  className,
  shouldCloseOnOverlayClick,
  onClose,
  style,
}: ModalState): StateAction<typeof CHANGE_MODAL, ModalState> {
  return {
    type: CHANGE_MODAL,
    payload: {
      isOpen,
      hideCloseButton,
      content,
      aria,
      className,
      shouldCloseOnOverlayClick,
      onClose,
      style,
    },
  }
}

export function calculateScrollProgress(): Action<typeof CALCULATE_SCROLL_PROGRESS> {
  return {
    type: CALCULATE_SCROLL_PROGRESS,
  }
}

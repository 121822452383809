import _ from 'lodash'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import lang from 'lang'
import { translateResponseError } from 'common/utils'
import { validateField, validateFields } from 'common/validator'
import { changeModal } from 'services/actions'
import jobTitlesService, {
  deleteJobTitleService,
  patchJobTitleService,
  postJobTitleService,
} from 'services/job-titles'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  DELETE_JOB_TITLE,
  resetJobTitleForm,
  SUBMIT_JOB_TITLE,
  VALIDATE_JOB_TITLE_FIELD,
} from './actions'

const prepareData = (payload) => {
  const data = {
    name: payload.name.value,
  }

  if (payload.id && payload.id.value) {
    data.id = payload.id.value
  }

  return data
}

export function* submitJobTitleSaga({ payload }) {
  const errors = validateFields(payload)
  if (_.isEmpty(errors)) {
    const data = prepareData(payload)
    let jobTitleResult
    if (data.id) {
      jobTitleResult = yield call(patchJobTitleService.requestSaga, {
        payload: {
          data,
          urlParams: { id: data.id },
        },
      })
    } else {
      jobTitleResult = yield call(postJobTitleService.requestSaga, {
        payload: {
          data,
        },
      })
    }
    if (jobTitleResult.error) {
      const status = _.get(jobTitleResult, 'error.response.status')
      if (status === 409) {
        yield put(
          addFieldError({
            field: 'base',
            error: lang.page.administration.jobTitles.jobTitleForm.nameAlreadyExistsError,
          }),
        )
      } else {
        yield put(
          addFieldError({
            field: 'base',
            error: translateResponseError(jobTitleResult.error),
          }),
        )
      }
    } else {
      yield put(
        changeModal({
          isOpen: false,
        }),
      )
      yield put(resetJobTitleForm())
      yield put(jobTitlesService.actions.request())
    }
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export function* deleteJobTitleSaga({ payload: { jobTitleId } }) {
  const result = yield call(deleteJobTitleService.requestSaga, {
    payload: { urlParams: { id: jobTitleId } },
  })

  if (!result.error) {
    yield put(
      changeModal({
        isOpen: false,
      }),
    )
    yield put(jobTitlesService.actions.request())
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_JOB_TITLE_FIELD, validateFieldSaga),
    takeLatest(SUBMIT_JOB_TITLE, submitJobTitleSaga),
    takeLatest(DELETE_JOB_TITLE, deleteJobTitleSaga),
  ])
}

import _ from 'lodash'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import { getApiUrl } from 'services/selectors'
import { validateField, validateFields } from 'common/validator'
import { requests } from 'common/requests'
import lang from 'lang'

import {
  addFieldError,
  clearFieldError,
  RESET_PASSWORD_REQUEST,
  resetPasswordFailure,
  resetPasswordSuccess,
  VALIDATE_RESET_PASSWORD_FIELD,
} from './actions'

function* resetPasswordSaga({ payload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const apiURL = yield select(getApiUrl)
    try {
      const response = yield call(requests.post, {
        url: `${apiURL}/rpc/reset_password`,
        headers: {
          Authorization: `Bearer ${payload.jwt.value}`,
          Prefer: 'params=single-object',
        },
        data: { new_password: payload.password.value },
      })
      yield put(resetPasswordSuccess())
      return response.data
    } catch (error) {
      yield put(
        resetPasswordFailure({
          base: _.get(error, 'response.data.message', lang.unhandledServerError),
        }),
      )
      return error
    }
  } else {
    yield put(resetPasswordFailure(errors))
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeLatest(RESET_PASSWORD_REQUEST, resetPasswordSaga),
    takeEvery(VALIDATE_RESET_PASSWORD_FIELD, validateFieldSaga),
  ])
}

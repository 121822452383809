const drawNumberLabel = (chartInstance, onHorizontalBar = true) => {
  const { ctx } = chartInstance
  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'
  ctx.fillStyle = 'rgba(0, 0, 0, 1)'
  chartInstance.data.datasets.forEach((dataset, i) => {
    const meta = chartInstance.controller.getDatasetMeta(i)
    meta.data.forEach((bar, index) => {
      const datasetData = dataset.data[index]
      const x = onHorizontalBar ? bar._model.x + 30 : bar._model.x
      const y = onHorizontalBar ? bar._model.y : bar._model.y - 15
      ctx.fillText(datasetData ? `${datasetData}` : '', x, y)
    })
  })
}

export default drawNumberLabel

export const CHANGE_SUBJECT_FIELD = 'CHANGE_SUBJECT_FIELD'
export const SUBMIT_SUBJECT = 'SUBMIT_SUBJECT'
export const VALIDATE_SUBJECT_FIELD = 'VALIDATE_SUBJECT_FIELD'
export const ADD_SUBJECT_FIELD_ERROR = 'ADD_SUBJECT_FIELD_ERROR'
export const ADD_SUBJECT_FIELDS_ERRORS = 'ADD_SUBJECT_FIELDS_ERRORS'
export const CLEAR_SUBJECT_FIELD_ERROR = 'CLEAR_SUBJECT_FIELD_ERROR'
export const RESET_SUBJECT_FORM_DATA = 'RESET_SUBJECT_FORM_DATA'
export const PREDEFINE_SUBJECT_FORM_DATA = 'PREDEFINE_SUBJECT_FORM_DATA'
export const DELETE_SUBJECT = 'DELETE_SUBJECT'

export function changeSubjectFormField(key, value) {
  return {
    type: CHANGE_SUBJECT_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function submitSubjectFrom(payload) {
  return {
    type: SUBMIT_SUBJECT,
    payload,
  }
}

export function predefineSubjectForm(payload) {
  return {
    type: PREDEFINE_SUBJECT_FORM_DATA,
    payload,
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_SUBJECT_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_SUBJECT_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_SUBJECT_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetSubjectForm() {
  return {
    type: RESET_SUBJECT_FORM_DATA,
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_SUBJECT_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

export function deleteSubject({ subjectId }) {
  return {
    type: DELETE_SUBJECT,
    payload: { subjectId },
  }
}

import buildService from 'common/utils/build-service'

export const staffCharterContributionsService = buildService({
  type: 'POST',
  constantsPrefix: 'STAFF_CHARTER_CONTRIBUTIONS',
  http: {
    createApiUrlSuffix: () => '/rpc/staff_charter_contributions',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const staffCharterMyContributionsService = buildService({
  type: 'GET',
  constantsPrefix: 'STAFF_CHARTER_MY_CONTRIBUTIONS',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/rpc/staff_charter_my_contributions',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/json',
    },
  },
})

export const staffCharterWordCloudContributionsService = buildService({
  type: 'POST',
  constantsPrefix: 'STAFF_CHARTER_WORD_CLOUD_CONTRIBUTIONS',
  http: {
    createApiUrlSuffix: () => '/rpc/staff_charter_contributions_word_cloud',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const staffCharterContributeService = buildService({
  type: 'POST',
  constantsPrefix: 'STAFF_CHARTER_CONTRIBUTE',
  http: {
    createApiUrlSuffix: () => '/rpc/staff_charter_contribute',
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

export const staffCharterRevisionService = buildService({
  type: 'POST',
  constantsPrefix: 'STAFF_CHARTER_REVISION',
  http: {
    createApiUrlSuffix: () => '/rpc/staff_charter_revision_get',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const staffCharterCreateRevisionService = buildService({
  type: 'POST',
  constantsPrefix: 'STAFF_CHARTER_REVISION_CREATE',
  http: {
    createApiUrlSuffix: () => '/rpc/staff_charter_revision_create',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const staffCharterPublishRevisionService = buildService({
  type: 'POST',
  constantsPrefix: 'STAFF_CHARTER_REVISION_PUBLISH',
  http: {
    createApiUrlSuffix: () => '/rpc/staff_charter_revision_publish',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

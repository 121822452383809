import _ from 'lodash'

import {
  ADD_DISTRICT_FIELD_ERROR,
  ADD_DISTRICT_FIELDS_ERRORS,
  CHANGE_DISTRICT_FORM_FIELD,
  CLEAR_DISTRICT_FIELD_ERROR,
  PREDEFINE_DISTRICT_FORM_DATA,
  RESET_DISTRICT_FORM_DATA,
} from './actions'

export const defaultFormData = (predefined = {}) =>
  _.merge(
    {
      districtName: '',
      websiteUrl: '',
      phoneNumber: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      slug: '',
      status: 'enabled',
      started_ruler_at: `${new Date().getFullYear()}`,
      started_ruler: true,
      offlineTrainings: [],
      coachName: '',
      coachEmail: '',
    },
    predefined,
  )

export const initialState = () => ({
  formData: defaultFormData(),
  errors: {},
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_DISTRICT_FORM_FIELD:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.key]: action.payload.value,
        },
      }

    case ADD_DISTRICT_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      }

    case ADD_DISTRICT_FIELDS_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      }

    case CLEAR_DISTRICT_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: undefined,
        },
      }

    case RESET_DISTRICT_FORM_DATA:
      return {
        ...state,
        formData: defaultFormData(),
        errors: {},
      }

    case PREDEFINE_DISTRICT_FORM_DATA:
      return {
        ...state,
        formData: defaultFormData(action.payload),
        errors: {},
      }

    default:
      return state
  }
}

export const SUBMIT_STATE = 'SUBMIT_STATE'

export type StatePayload = {
  id?: string
  country: string
  name: string
  abbr: string
}

export function submitState(payload: StatePayload) {
  return {
    type: SUBMIT_STATE,
    payload,
  }
}

export const CHANGE_APPROVE_CONTRIBUTIONS_FORM_FIELD = 'CHANGE_APPROVE_CONTRIBUTIONS_FORM_FIELD'
export const SUBMIT_APPROVE_CONTRIBUTIONS = 'SUBMIT_APPROVE_CONTRIBUTIONS'
export const VALIDATE_APPROVE_CONTRIBUTIONS_FIELD = 'VALIDATE_APPROVE_CONTRIBUTIONS_FIELD'
export const ADD_APPROVE_CONTRIBUTIONS_FIELD_ERROR = 'ADD_APPROVE_CONTRIBUTIONS_FIELD_ERROR'
export const ADD_APPROVE_CONTRIBUTIONS_FIELDS_ERRORS = 'ADD_APPROVE_CONTRIBUTIONS_FIELDS_ERRORS'
export const CLEAR_APPROVE_CONTRIBUTIONS_FIELD_ERROR = 'CLEAR_APPROVE_CONTRIBUTIONS_FIELD_ERROR'
export const RESET_APPROVE_CONTRIBUTIONS_FORM_DATA = 'RESET_APPROVE_CONTRIBUTIONS_FORM_DATA'
export const PREDEFINE_APPROVE_CONTRIBUTIONS_FORM_DATA = 'PREDEFINE_APPROVE_CONTRIBUTIONS_FORM_DATA'
export const INIT_STAFF_CHARTER_APPROVAL_EMOTION_WORDS = 'INIT_STAFF_CHARTER_APPROVAL_EMOTION_WORDS'

export function changeApproveContributionsFormField(key, value) {
  return {
    type: CHANGE_APPROVE_CONTRIBUTIONS_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function submitApproveContributions(data) {
  return {
    type: SUBMIT_APPROVE_CONTRIBUTIONS,
    payload: data,
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_APPROVE_CONTRIBUTIONS_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_APPROVE_CONTRIBUTIONS_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_APPROVE_CONTRIBUTIONS_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetApproveContributionFormData() {
  return {
    type: RESET_APPROVE_CONTRIBUTIONS_FORM_DATA,
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_APPROVE_CONTRIBUTIONS_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

export function predefineApproveContributionForm(data) {
  return {
    type: PREDEFINE_APPROVE_CONTRIBUTIONS_FORM_DATA,
    payload: data,
  }
}

export function initEmotionWords(words) {
  return {
    type: INIT_STAFF_CHARTER_APPROVAL_EMOTION_WORDS,
    payload: words,
  }
}

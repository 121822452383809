import {
  addHours,
  addMilliseconds,
  addMinutes,
  addSeconds,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInSeconds,
  format,
} from 'date-fns'

import getJWT from './get-jwt'
import translateResponseError from './translate-response-error'
import trimData from './trim-data'
import drawNumberLabel from './draw-number-label'
import prependProtocolToURL from './prepend-protocol-to-url'
import pagesNavigation from './pages-navigation'
import { isLabel, isRole, isUser } from './determine-user'
import replace from './string-replace'
import guard from './guard'
import { computeQuadrantColor, computeQuadrantColorName, MOOD_METER_COLORS } from './mood-meter'

// Common options for using https://github.com/fisshy/react-scroll
export const SCROLL_OPTIONS = {
  duration: 200,
  smooth: true,
}

export const cityWithCounty = (city, county) => `${city}${county ? `, ${county}` : ''}`

export {
  drawNumberLabel,
  getJWT,
  guard,
  isLabel,
  isRole,
  isUser,
  pagesNavigation,
  prependProtocolToURL,
  replace,
  translateResponseError,
  trimData,
  computeQuadrantColor,
  computeQuadrantColorName,
  MOOD_METER_COLORS,
}

export const formatDuration = (timeInt) => {
  // Remove timezone difference
  const base = new Date(0 + new Date().getTimezoneOffset() * 60000)
  const date = addMilliseconds(base, timeInt)
  return format(date, 'HH:mm:ss')
}

export const timeDuration = (timeString) => {
  // Parse time '00:00:00'
  const [hours, minutes, seconds] = timeString ? timeString.split(':') : [0, 0, 0]
  // Remove timezone difference
  const base = new Date(0 + new Date().getTimezoneOffset() * 60000)
  const date = addHours(addMinutes(addSeconds(base, seconds), minutes), hours)
  return {
    asSeconds: () => {
      return differenceInSeconds(date, base)
    },
    asMinutes: () => {
      return differenceInMinutes(date, base)
    },
    asHours: () => {
      return differenceInHours(date, base)
    },
    asMilliseconds: () => {
      return differenceInMilliseconds(date, base)
    },
  }
}

export const parseJwt = (token) => {
  try {
    return JSON.parse(window.atob(token.split('.')[1]))
  } catch (e) {
    return {}
  }
}

export const replaceS3path = (url) => {
  try {
    return `/api/media${new URL(url).pathname}`
  } catch (error) {
    return `/api/media${url}`
  }
}

export const replaceMediaLinksInContent = (content) => {
  // Matches:
  //
  // 1. "https://ruleronlineimgs.s3.amazonaws.com/media/Mood-Meter-Strategies-2.pdf?..."
  // 2. "https://s3.amazonaws.com/ruleronlineimgs/media/Mood-Meter-Strategies-2.pdf?..."
  // 3. "https://s3.amazonaws.com/ruleronlineimgs/media/Mood-Meter-Strategies-2.pdf"
  // 3. "https://ruleronlineimgs.s3.amazonaws.com/media/Mood-Meter-Strategies-2.pdf"
  // 4. "https://ruleronlineimgs.s3.amazonaws.com/media/Mood-Meter-Strategies-2.docx"
  // ...
  // N. "https://ruleronlineimgs.s3.amazonaws.com/media/Mood-Meter-Strategies-2.pptx"
  // ...
  const urlPattern =
    /"https:\/\/(ruleronlineimgs.)?s3.amazonaws.com\/(ruleronlineimgs\/)?media\/.*?\.(pdf|pptx|docx)\??.*?"/g
  return content.replace(urlPattern, (match) => {
    return `"${replaceS3path(match.slice(1, -1).replace('ruleronlineimgs/', ''))}"`
  })
}

import _ from 'lodash'
import React from 'react'
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { changeModal } from 'services/actions'
import { downloadCsv } from 'common/utils/download-csv'
import { downloadUserActivationLinksService } from 'services/users'
import {
  predefineDistrictUserForm,
  resetDistrictUserFormData,
} from 'page-modules/dashboard/district-details/service/actions'
import {
  predefineSchoolUserForm,
  resetSchoolUserFormData,
} from 'page-modules/dashboard/school-details/service/actions'
import {
  predefineYceiUserForm,
  resetYceiUserFormData,
} from 'page-modules/dashboard/ycei-members/service/actions'
import DistrictUserModal from 'page-modules/dashboard/district-details/district-user-modal'
import SchoolUserModal from 'page-modules/dashboard/school-details/school-users-modal'
import YceiUserModal from 'page-modules/dashboard/ycei-members/ycei-user-modal'
import personRolesService from 'services/person-roles'
import personTypesService from 'services/person-types'

import { OPEN_USER_MODAL } from './actions'

function* openDistrictUserModal(userData, district) {
  const typesAndRolesParams = { urlParams: { scope: 'district' } }
  yield put(personRolesService.actions.request(typesAndRolesParams))
  yield put(personTypesService.actions.request(typesAndRolesParams))
  if (!_.isEmpty(userData)) {
    yield put(predefineDistrictUserForm(userData))
  } else {
    yield put(resetDistrictUserFormData())
  }
  yield put(
    changeModal({
      isOpen: true,
      aria: {
        labelledby: 'add-new-district-user-header',
      },
      className: 'add-district-users',
      content: <DistrictUserModal edit={!_.isEmpty(userData)} district={district} />,
    }),
  )
}

function* openSchoolUserModal(userData, school, district) {
  const typesAndRolesParams = { urlParams: { scope: 'school' } }
  yield put(personRolesService.actions.request(typesAndRolesParams))
  yield put(personTypesService.actions.request(typesAndRolesParams))
  if (!_.isEmpty(userData)) {
    yield put(predefineSchoolUserForm(userData))
  } else {
    yield put(resetSchoolUserFormData())
  }
  yield put(
    changeModal({
      isOpen: true,
      aria: {
        labelledby: 'add-new-school-user-header',
      },
      className: 'add-school-users',
      content: <SchoolUserModal edit={!_.isEmpty(userData)} school={school} district={district} />,
    }),
  )
}

function* openYceiUserModal(userData) {
  yield put(personRolesService.actions.request({ urlParams: { scope: 'ruler,ycei' } }))
  yield put(personTypesService.actions.request({ urlParams: { scope: 'ycei' } }))
  if (!_.isEmpty(userData)) {
    yield put(predefineYceiUserForm(userData))
  } else {
    yield put(resetYceiUserFormData())
  }
  yield put(
    changeModal({
      isOpen: true,
      aria: {
        labelledby: 'add-new-ycei-user-header',
      },
      className: 'add-ycei-users',
      content: <YceiUserModal edit={!_.isEmpty(userData)} />,
    }),
  )
}

function* openUserModalSaga({ payload: { user = {}, district = {}, school = {} } = {} }) {
  let userData = {}
  if (!_.isEmpty(user)) {
    userData = {
      email: user.email,
      id: user.id,
      fullName: user.name,
      roleId: user.role,
      personTypeId: user.person_type_id || (user.person_type && user.person_type.id) || null,
      officePhone: user.office_phone,
      mobilePhone: user.mobile_phone,
    }
  }
  if (!_.isEmpty(district) && _.isEmpty(school)) {
    yield openDistrictUserModal(userData, district)
  } else if (!_.isEmpty(school)) {
    yield openSchoolUserModal(userData, school, district)
  } else {
    yield openYceiUserModal(userData)
  }
}

export function downloadActivationLinksSaga(actionData) {
  const csvData = actionData.payload.data
  downloadCsv(csvData, 'ruler-member-activation-links.csv')
}

export default function* saga() {
  yield all([
    takeEvery(OPEN_USER_MODAL, openUserModalSaga),
    takeLatest(
      downloadUserActivationLinksService.constants.REQUEST_SUCCESS,
      downloadActivationLinksSaga,
    ),
  ])
}

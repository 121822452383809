export const CHANGE_ANNOUNCEMENT_FIELD = 'CHANGE_ANNOUNCEMENT_FIELD'
export const SUBMIT_ANNOUNCEMENT = 'SUBMIT_ANNOUNCEMENT'
export const DELETE_ANNOUNCEMENT = 'DELETE_ANNOUNCEMENT'
export const VALIDATE_ANNOUNCEMENT_FIELD = 'VALIDATE_ANNOUNCEMENT_FIELD'
export const ADD_ANNOUNCEMENT_FIELD_ERROR = 'ADD_ANNOUNCEMENT_FIELD_ERROR'
export const ADD_ANNOUNCEMENT_FIELDS_ERRORS = 'ADD_ANNOUNCEMENT_FIELDS_ERRORS'
export const CLEAR_ANNOUNCEMENT_FIELD_ERROR = 'CLEAR_ANNOUNCEMENT_FIELD_ERROR'
export const PREDEFINE_ANNOUNCEMENT_FORM_DATA = 'PREDEFINE_ANNOUNCEMENT_FORM_DATA'
export const RESET_ANNOUNCEMENT_FORM_DATA = 'RESET_ANNOUNCEMENT_FORM_DATA'

export function changeAnnouncementFormField(key, value) {
  return {
    type: CHANGE_ANNOUNCEMENT_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function submitAnnouncementFrom(payload) {
  return {
    type: SUBMIT_ANNOUNCEMENT,
    payload,
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_ANNOUNCEMENT_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_ANNOUNCEMENT_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_ANNOUNCEMENT_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function predefineAnnouncementForm(payload) {
  return {
    type: PREDEFINE_ANNOUNCEMENT_FORM_DATA,
    payload,
  }
}

export function resetAnnouncementForm() {
  return {
    type: RESET_ANNOUNCEMENT_FORM_DATA,
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_ANNOUNCEMENT_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

export function deleteAnnouncement({ announcementId }) {
  return {
    type: DELETE_ANNOUNCEMENT,
    payload: { announcementId },
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { changeModal } from 'services/actions'
import lang from 'lang'
import { replace } from 'common/utils'

@connect(null, (dispatch) => ({
  changeModal: (params) => dispatch(changeModal(params)),
}))
class UserActivation extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    single: PropTypes.bool,
    changeModal: PropTypes.func,
  }

  closeModal() {
    this.props.changeModal({ isOpen: false })
  }

  render() {
    return (
      <div className="modal-wrapper">
        <div className="modal-header" id="user-activation-data-header">
          <h2>
            {this.props.single
              ? replace(lang.dashboard.userActivationData.title, [this.props.user.name])
              : lang.dashboard.userActivationData.multipleTitle}
          </h2>
        </div>
        <div className="modal-container">
          <p>
            {this.props.single
              ? lang.dashboard.userActivationData.text
              : lang.dashboard.userActivationData.multipleText}
          </p>
        </div>
        <div className="modal-footer">
          <button type="button" className="button ok" onClick={() => this.closeModal()}>
            {lang.okButton}
          </button>
        </div>
      </div>
    )
  }
}

export default UserActivation

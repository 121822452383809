import _ from 'lodash'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { validateField, validateFields } from 'common/validator'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  SUBMIT_EMOTION_WORDS,
  VALIDATE_EMOTION_WORDS_FIELD,
} from './actions'

import { saveEmotionWordsService } from './index'

export function* saveEmotionWordsSaga({ payload }) {
  const errors = validateFields(payload)

  if (!_.isEmpty(errors)) {
    return yield put(addFieldsErrors(errors))
  }

  // Add emotion words to request payload
  const data = {
    emotion_words: _.map(payload.emotionWords.value, (word) => ({
      emotion_word_id: word.id,
      actions: (word && word.actions && _.compact(word.actions)) || [],
    })),
  }

  const result = yield call(saveEmotionWordsService.requestSaga, {
    payload: { data },
  })

  const message = _.get(result, 'error.response.data.message')
  if (!_.isEmpty(message)) {
    yield put(addFieldError({ field: 'base', error: message }))
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_EMOTION_WORDS_FIELD, validateFieldSaga),
    takeLatest(SUBMIT_EMOTION_WORDS, saveEmotionWordsSaga),
  ])
}

import { put } from 'redux-saga/effects'

import buildService from 'common/utils/build-service'
import {
  initSections,
  showCongratulations,
  showUpToStep,
} from 'page-modules/module/service/actions'

export const moduleService = buildService({
  type: 'GET',
  constantsPrefix: 'MODULE_DETAILS',
  http: {
    createApiUrlSuffix: (slug) => `/module_details?slug=eq.${slug}`,
    headers: {
      Accept: 'application/vnd.pgrst.object+json',
    },
    *successHandler(module) {
      yield put(initSections(module.sections))
      if (module.user_progress) {
        yield put(showUpToStep(module.user_progress.last_step_id))
        if (module.user_progress.completed) {
          yield put(showCongratulations())
        }
      }
    },
  },
})

export const postModuleProgressService = buildService({
  type: 'POST',
  constantsPrefix: 'MODULE_PROGRESS',
  http: {
    createApiUrlSuffix: () => '/rpc/module_progress',
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

export const postActivityResponse = buildService({
  type: 'POST',
  constantsPrefix: 'ACTIVITY_RESPONSE',
  http: {
    createApiUrlSuffix: () => '/activity_responses',
    headers: {
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

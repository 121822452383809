import {
  ACTIVATION_REQUEST,
  ACTIVATION_REQUEST_FAILURE,
  ACTIVATION_REQUEST_SUCCESS,
  ADD_ACTIVATION_FIELD_ERROR,
  CHANGE_ACTIVATION_FORM_FIELD,
  CLEAR_ACTIVATION_FIELD_ERROR,
} from './actions'

export const initialState = () => {
  return {
    formData: {
      password: '',
      password2: '',
    },
    errors: {},
    loading: false,
  }
}

export default function reducer(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_ACTIVATION_FORM_FIELD:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.key]: action.payload.value,
        },
      }

    case ACTIVATION_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
      }

    case ACTIVATION_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case ACTIVATION_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      }

    case ADD_ACTIVATION_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      }

    case CLEAR_ACTIVATION_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: undefined,
        },
      }
    default:
      return state
  }
}

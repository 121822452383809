export const CHANGE_EMOTION_WORD_FIELD = 'CHANGE_EMOTION_WORD_FIELD'
export const SUBMIT_EMOTION_WORD = 'SUBMIT_EMOTION_WORD'
export const VALIDATE_EMOTION_WORD_FIELD = 'VALIDATE_EMOTION_WORD_FIELD'
export const ADD_EMOTION_WORD_FIELD_ERROR = 'ADD_EMOTION_WORD_FIELD_ERROR'
export const ADD_EMOTION_WORD_FIELDS_ERRORS = 'ADD_EMOTION_WORD_FIELDS_ERRORS'
export const CLEAR_EMOTION_WORD_FIELD_ERROR = 'CLEAR_EMOTION_WORD_FIELD_ERROR'
export const RESET_EMOTION_WORD_FORM_DATA = 'RESET_EMOTION_WORD_FORM_DATA'
export const PREDEFINE_EMOTION_WORD_FORM_DATA = 'PREDEFINE_EMOTION_WORD_FORM_DATA'
export const DELETE_EMOTION_WORD = 'DELETE_EMOTION_WORD'

export function changeEmotionWordFormField(key, value) {
  return {
    type: CHANGE_EMOTION_WORD_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function submitEmotionWordFrom(payload) {
  return {
    type: SUBMIT_EMOTION_WORD,
    payload,
  }
}

export function predefineEmotionWordForm(payload) {
  return {
    type: PREDEFINE_EMOTION_WORD_FORM_DATA,
    payload,
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_EMOTION_WORD_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_EMOTION_WORD_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_EMOTION_WORD_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetEmotionWordForm() {
  return {
    type: RESET_EMOTION_WORD_FORM_DATA,
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_EMOTION_WORD_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

export function deleteEmotionWord({ emotionWordId }) {
  return {
    type: DELETE_EMOTION_WORD,
    payload: { emotionWordId },
  }
}

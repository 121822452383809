import _ from 'lodash'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { validateField, validateFields } from 'common/validator'
import {
  staffCharterCreateRevisionService,
  staffCharterRevisionService,
} from 'page-modules/charter/staff-charter/service'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  SUBMIT_APPROVE_CONTRIBUTIONS,
  VALIDATE_APPROVE_CONTRIBUTIONS_FIELD,
} from './actions'

export function* submitApproveContributionsSaga({ payload }) {
  const errors = validateFields(payload)

  if (!_.isEmpty(errors)) {
    yield put(addFieldsErrors(errors))
  } else {
    yield put(clearFieldError({ field: 'approvedEmotionWords' }))
    const data = {
      school_id: payload.schoolId.value,
      emotion_words: _.map(payload.approvedEmotionWords.value, (approvedWord) => ({
        emotion_word_id: approvedWord.emotionWordId,
        actions: _.compact(approvedWord.actions),
      })),
    }

    yield call(staffCharterCreateRevisionService.requestSaga, { payload: { data } })
    yield put(staffCharterRevisionService.actions.request({}))
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_APPROVE_CONTRIBUTIONS_FIELD, validateFieldSaga),
    takeLatest(SUBMIT_APPROVE_CONTRIBUTIONS, submitApproveContributionsSaga),
  ])
}

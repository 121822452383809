export const CHANGE_SHOWCASE_FORM_FIELD = 'CHANGE_SHOWCASE_FORM_FIELD'
export const SUBMIT_SHOWCASE = 'SUBMIT_SHOWCASE'
export const VALIDATE_SHOWCASE_FIELD = 'VALIDATE_SHOWCASE_FIELD'
export const ADD_SHOWCASE_FIELD_ERROR = 'ADD_SHOWCASE_FIELD_ERROR'
export const ADD_SHOWCASE_FIELDS_ERRORS = 'ADD_SHOWCASE_FIELDS_ERRORS'
export const CLEAR_SHOWCASE_FIELD_ERROR = 'CLEAR_SHOWCASE_FIELD_ERROR'
export const RESET_SHOWCASE_FORM_DATA = 'RESET_SHOWCASE_FORM_DATA'
export const PREDEFINE_SHOWCASE_FORM_DATA = 'PREDEFINE_SHOWCASE_FORM_DATA'

export function changeShowcaseFormField(key, value) {
  return {
    type: CHANGE_SHOWCASE_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function submitShowcase(data) {
  return {
    type: SUBMIT_SHOWCASE,
    payload: data,
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_SHOWCASE_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_SHOWCASE_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_SHOWCASE_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetShowcaseFormData() {
  return {
    type: RESET_SHOWCASE_FORM_DATA,
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_SHOWCASE_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

export function predefineShowcaseFormData(data) {
  return {
    type: PREDEFINE_SHOWCASE_FORM_DATA,
    payload: data,
  }
}

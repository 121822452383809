import { ActionType } from '@redux-saga/types'
import { StateAction } from 'services/actions'
import { ValidationResult } from 'common/validator-types'
import { FieldNames, FormResults, FormValue, ValidateProps } from 'components/form'

import { LoginResponse } from './saga'

export const CHANGE_LOGIN_FORM_FIELD: ActionType = 'CHANGE_LOGIN_FORM_FIELD'
export const LOGIN_REQUEST: ActionType = 'LOGIN_REQUEST'
export const LOGIN_REQUEST_SUCCESS: ActionType = 'LOGIN_REQUEST_SUCCESS'
export const LOGIN_REQUEST_FAILURE: ActionType = 'LOGIN_REQUEST_FAILURE'
export const VALIDATE_LOGIN_FIELD: ActionType = 'VALIDATE_LOGIN_FIELD'
export const ADD_LOGIN_FIELD_ERROR: ActionType = 'ADD_LOGIN_FIELD_ERROR'
export const CLEAR_LOGIN_FIELD_ERROR: ActionType = 'CLEAR_LOGIN_FIELD_ERROR'

export function changeLoginFormField(
  key: string,
  value: FormValue,
): StateAction<typeof CHANGE_LOGIN_FORM_FIELD, { key: string; value: FormValue }> {
  return {
    type: CHANGE_LOGIN_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export type LoginFields = 'email' | 'password' | 'rememberMe'
export function loginRequest(
  payload: FormResults<LoginFields>,
): StateAction<typeof LOGIN_REQUEST, typeof payload> {
  return {
    type: LOGIN_REQUEST,
    payload,
  }
}

// @ts-expect-error errors type not defined
export function loginFailure(errors): StateAction<typeof LOGIN_REQUEST_FAILURE, typeof errors> {
  return {
    type: LOGIN_REQUEST_FAILURE,
    payload: {
      errors,
    },
  }
}

export function loginSuccess(
  userData: LoginResponse,
): StateAction<typeof LOGIN_REQUEST_SUCCESS, { userData: LoginResponse }> {
  return {
    type: LOGIN_REQUEST_SUCCESS,
    payload: {
      userData,
    },
  }
}

export function addFieldError(payload: {
  field: FieldNames
  error: ValidationResult
}): StateAction<typeof ADD_LOGIN_FIELD_ERROR, typeof payload> {
  return {
    type: ADD_LOGIN_FIELD_ERROR,
    payload,
  }
}

export function clearFieldError(payload: {
  field: FieldNames
}): StateAction<typeof CLEAR_LOGIN_FIELD_ERROR, typeof payload> {
  return {
    type: CLEAR_LOGIN_FIELD_ERROR,
    payload,
  }
}

export function validateField({
  field,
  value,
  validations,
}: ValidateProps<LoginFields>): StateAction<
  typeof VALIDATE_LOGIN_FIELD,
  ValidateProps<LoginFields>
> {
  return {
    type: VALIDATE_LOGIN_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Oval } from 'react-loader-spinner'

import lang from 'lang'
import { changeModal } from 'services/actions'
import SchoolForm from 'common/components/school-form'

@connect(
  (state) => ({
    user: state.root.user,
    schoolFormData: state.manageSchool.form.formData,
    loading: state.manageSchool.request.loading,
  }),
  (dispatch) => ({
    changeModal: (params) => dispatch(changeModal(params)),
  }),
)
export default class SchoolFormModal extends React.Component {
  static propTypes = {
    changeModal: PropTypes.func,
    submitSchool: PropTypes.func.isRequired,
    schoolFormData: PropTypes.object,
    loading: PropTypes.bool,
  }

  closeModal() {
    this.props.changeModal({ isOpen: false })
  }

  handleSaveClick() {
    return () => {
      const schoolData = _.mapValues(this.props.schoolFormData, (value, key) => {
        return {
          value,
          validations:
            key === 'started_ruler_at' && !this.props.schoolFormData.started_ruler
              ? ''
              : SchoolForm.validations[key],
        }
      })
      this.props.submitSchool(schoolData)
    }
  }

  render() {
    return (
      <div className="modal-wrapper">
        <div className="modal-header">
          <h2 id="add-new-school-header">
            {this.props.schoolFormData.id
              ? lang.dashboard.manageSchoolForm.updateTitle
              : lang.dashboard.manageSchoolForm.addTitle}
          </h2>
        </div>

        <div className="modal-container">
          <SchoolForm />
        </div>
        <div className="modal-footer">
          <div className="modal-button-wrapper">
            <button
              className="button save"
              disabled={this.props.loading}
              onClick={this.handleSaveClick()}
              type="button">
              {this.props.loading ? (
                <Oval
                  visible={true}
                  width="20"
                  height="20"
                  color="#0f4d92"
                  secondaryColor="#33a650"
                  strokeWidth={6}
                />
              ) : (
                lang.saveButton
              )}
            </button>
            <button type="button" className="button clear cancel" onClick={() => this.closeModal()}>
              {lang.cancelButton}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

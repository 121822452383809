import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import lang from 'lang'
import { changeModal } from 'services/actions'
import DistrictForm from 'page-modules/dashboard/district-form'

@connect(
  (state) => ({
    user: state.root.user,
    districtFormData: state.manageDistrict.form.formData,
    loading: state.manageDistrict.request.post.loading || state.district.details.loading,
  }),
  (dispatch) => ({
    changeModal: (params) => dispatch(changeModal(params)),
  }),
)
export default class DistrictFormModal extends React.Component {
  static propTypes = {
    changeModal: PropTypes.func,
    submitDistrict: PropTypes.func.isRequired,
    districtFormData: PropTypes.object,
    loading: PropTypes.bool,
  }

  closeModal() {
    this.props.changeModal({ isOpen: false })
  }

  handleSaveClick() {
    return () => {
      const schoolData = _.mapValues(this.props.districtFormData, (value, key) => {
        return {
          value,
          validations:
            key === 'started_ruler_at' && !this.props.districtFormData.started_ruler
              ? ''
              : DistrictForm.validations[key],
        }
      })
      this.props.submitDistrict(schoolData)
    }
  }

  render() {
    return (
      <div className="modal-wrapper">
        <div className="modal-header">
          <h2 id="add-new-district-header">
            {this.props.districtFormData.id
              ? lang.dashboard.districtForm.updateTitle
              : lang.dashboard.districtForm.addTitle}
          </h2>
        </div>

        <div className="modal-container">
          <DistrictForm districtName={this.props.districtFormData.name} />
        </div>
        <div className="modal-footer">
          <div className="modal-button-wrapper">
            <button
              type="button"
              className="button save"
              disabled={this.props.loading}
              onClick={this.handleSaveClick()}>
              {lang.saveButton}
            </button>
            <button type="button" className="button clear cancel" onClick={() => this.closeModal()}>
              {lang.cancelButton}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

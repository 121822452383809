// @ts-check

const tabs = {
  charter: {
    aboutStaffCharter: 'about-staff-charter',
    contributions: 'contributions',
    editStaffCharter: 'edit-staff-charter',
    manageSurveys: 'manage-surveys',
    myCharters: 'my',
    submitSurvey: 'submit-survey',
  },
  showcase: {
    mySchool: 'my-school',
    review: 'review',
    rulerCommunity: 'ruler-community',
    explore: 'explore',
  },
}
export default tabs

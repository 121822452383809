import _ from 'lodash'

import {
  ADD_CSV_UPLOAD_ERROR,
  RESET_UPLOADED_USERS,
  UPDATE_CSV_USERS,
  UPLOAD_CSV_USERS,
} from './actions'

export const defaultFormData = (predefined = {}) => _.merge({ users: [] }, predefined)

export const initialState = () => ({
  formData: defaultFormData(),
  errors: {
    base: {},
  },
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case UPLOAD_CSV_USERS:
    case UPDATE_CSV_USERS:
      return {
        ...state,
        formData: {
          ...state.formData,
          users: action.payload.users,
        },
      }

    case RESET_UPLOADED_USERS:
      return {
        ...state,
        formData: defaultFormData(),
        errors: {
          base: {},
        },
      }

    case ADD_CSV_UPLOAD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          base: action.payload,
        },
      }

    default:
      return state
  }
}

import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import lang from 'lang'

import { updateCsvUsers } from './service/actions'

@connect(
  (state) => ({
    uploadedUsers: state.school.addSchoolUser.csvUploadForm.formData.users,
  }),
  (dispatch) => ({
    updateCsvUsers: (params) => dispatch(updateCsvUsers(params)),
  }),
)
export default class UserGridHeader extends React.Component {
  static propTypes = {
    updateCsvUsers: PropTypes.func,
    uploadedUsers: PropTypes.array,
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    readOnly: false,
  }

  get selectedAll() {
    return _.every(this.props.uploadedUsers, 'selected')
  }

  toggleAllHandler() {
    return () => {
      this.props.updateCsvUsers({
        users: _.map(this.props.uploadedUsers, (user) => ({
          ...user,
          selected: !this.selectedAll,
        })),
      })
    }
  }

  renderCheckbox() {
    if (this.props.readOnly) {
      return null
    }

    return (
      <div className="checkbox-cell cell shrink">
        <input type="checkbox" checked={this.selectedAll} onChange={this.toggleAllHandler()} />
      </div>
    )
  }

  renderDropdowns() {
    if (this.props.readOnly) {
      return null
    }

    return (
      <>
        <div className="cell small-2 text-cell">
          {lang.dashboard.userForm.uploadCsv.headers.personType}
        </div>
        <div className="cell small-3 text-cell">
          {lang.dashboard.userForm.uploadCsv.headers.role}
        </div>
      </>
    )
  }

  render() {
    return (
      <div className="list-item header grid-x grid-padding-x">
        {this.renderCheckbox()}
        <div className="cell small-3 text-cell">
          {lang.dashboard.userForm.uploadCsv.headers.name}
        </div>
        <div className="cell auto text-cell">{lang.dashboard.userForm.uploadCsv.headers.email}</div>
        {this.renderDropdowns()}
      </div>
    )
  }
}

import _ from 'lodash'

import {
  ADD_CHARTER_FIELD_ERROR,
  ADD_CHARTER_FIELDS_ERRORS,
  CHANGE_CHARTER_FORM_FIELD,
  CLEAR_CHARTER_FIELD_ERROR,
  PREDEFINE_CHARTER_FORM_DATA,
  RESET_CHARTER_FORM_DATA,
} from './actions'

export const defaultFormData = (predefined = {}) =>
  _.merge(
    {
      type: '',
      grade: '',
      name: '',
      emotionWords: [],
      content: '',
      template: '',
      existingCharterUrl: '',
    },
    _.pickBy(predefined, _.identity),
  )

export const initialState = () => ({
  formData: defaultFormData(),
  errors: {},
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_CHARTER_FORM_FIELD:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.key]: action.payload.value,
        },
      }

    case ADD_CHARTER_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      }

    case ADD_CHARTER_FIELDS_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      }

    case CLEAR_CHARTER_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: undefined,
        },
      }

    case RESET_CHARTER_FORM_DATA:
      return {
        ...state,
        formData: defaultFormData(),
        errors: {},
      }

    case PREDEFINE_CHARTER_FORM_DATA:
      return {
        ...state,
        formData: defaultFormData(action.payload),
        errors: {},
      }

    default:
      return state
  }
}

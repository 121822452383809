import _ from 'lodash'

import {
  ANSWER_ASSESSMENT_QUESTION,
  INIT_ASSESSMENT_ANSWERS,
  RESET_ASSESSMENT_ANSWERS,
} from './actions'

export const initialState = () => ({})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case INIT_ASSESSMENT_ANSWERS:
      return _.reduce(
        action.payload.questions,
        (result, question) => {
          result[question.id] = {
            answered: false,
            choiceId: null,
          }

          return result
        },
        {},
      )

    case ANSWER_ASSESSMENT_QUESTION:
      return {
        ...state,
        [action.payload.question.id]: {
          ...state[action.payload.question.id],
          answered: true,
          choiceId: action.payload.choiceId,
        },
      }

    case RESET_ASSESSMENT_ANSWERS:
      return _.mapValues(state, () => ({
        answered: false,
        choiceId: null,
      }))

    default:
      return state
  }
}

import { AnyAction, combineReducers } from 'redux'
import _ from 'lodash'
import getConfig from 'next/config'
import { User } from 'common/types'
import { CALCULATE_SCROLL_PROGRESS, CHANGE_MODAL, ModalState, UPDATE_USER } from 'services/actions'
import navDropdownReducer from 'services/nav-dropdown/reducer'
import { postOnboardingService } from 'services/onboarding'
import { getTotalSchoolsService, postSchoolService } from 'services/schools'
import {
  getBookmarkedSchoolsService,
  getSchoolUsersService,
  getSchoolUserStatisticsService,
  schoolDetailsService,
} from 'page-modules/dashboard/school-details/service/'
import {
  getChartersPerAudienceService,
  getJournalPostsService,
  getMoodMeterCheckInsService,
  getRulerizeCommitmentsService,
  getSchoolCourseCompletionService,
  getSchoolHowWantToFeelService,
  getSchoolTopResourcesService,
  getTop5MoodMeterEmotionWordsService,
} from 'page-modules/dashboard/statistics/service'
import { saveEmotionWordsService } from 'page-modules/dashboard/emotion-words-form/service'
import schoolsService, { searchSchoolsService } from 'page-modules/dashboard/schools/service'
import schoolsFilters from 'page-modules/dashboard/schools/service/reducer'
import districtForm from 'page-modules/dashboard/district-form/service/reducer'
import manageSchoolForm from 'page-modules/dashboard/school-creation/service/reducer'
import moveUserForm from 'page-modules/dashboard/users/service/reducer'
import csvUploadUsersForm from 'page-modules/dashboard/users/upload-csv/service/reducer'
import countriesService, { patchCountryService } from 'services/countries'
import userOnboardingForm from 'services/onboarding/reducer'
import {
  getTotalDistrictsService,
  postDistrictService,
} from 'page-modules/dashboard/districts/service'
import districtsFilters from 'page-modules/dashboard/districts/service/reducer'
import addSchoolUserForm from 'page-modules/dashboard/school-details/service/reducer'
import addDistrictUserForm from 'page-modules/dashboard/district-details/service/reducer'
import moduleReducer from 'page-modules/module/service/reducer'
import {
  getBookmarkedDistrictsService,
  getDistrictDetailsService,
  getDistrictSchoolsListService,
  getDistrictSchoolsService,
  getDistrictUsersService,
} from 'page-modules/dashboard/district-details/service'
import {
  downloadMemberListService,
  getAdminUsersStatistics,
  getYceiUsersService,
  getYceiUsersStatistics,
} from 'page-modules/dashboard/ycei-members/service'
import addYceiUserForm from 'page-modules/dashboard/ycei-members/service/reducer'
import statesService, { patchStateService, postStateService } from 'services/states'
import gradesService from 'services/grades'
import subjectsService, {
  deleteSubjectService,
  patchSubjectService,
  postSubjectService,
} from 'services/subjects'
import citiesService, { patchCityService, postCityService } from 'services/cities'
import emotionWordsService, {
  deleteEmotionWordService,
  patchEmotionWordService,
  postEmotionWordService,
} from 'services/emotion-words'
import jobTitlesService, {
  deleteJobTitleService,
  patchJobTitleService,
  postJobTitleService,
} from 'services/job-titles'
import offlineTrainingsService from 'services/offline-trainings'
import htmlContentReducer from 'services/html-content/reducer'
import phasesService from 'services/phases'
import { modulesService } from 'services/modules'
import {
  getShowcaseBySlugService,
  getShowcaseByTagService,
  getShowcaseService,
  getTopShowcaseTagsService,
  postShowcaseService,
} from 'services/showcase'
import showcaseFormReducer from 'services/showcase/form/reducer'
import showcaseFilterReducer from 'services/showcase/filter/reducer'
import audienceTypesService from 'services/audience-types'
import { courseService, coursesService } from 'page-modules/courses/service'
import districtsService, { searchDistrictsService } from 'services/districts'
import personRolesService from 'services/person-roles'
import personTypesService from 'services/person-types'
import {
  getCollectionDetailsService,
  getCollectionResourcesService,
  getCollectionsService,
  getFavoritesService,
  getGlossaryService,
  getResourceDetailsService,
  postFavoriteService,
  postVisitResourceService,
  searchResourcesService,
} from 'page-modules/resources/service'
import { getAllStrategiesService, getStrategyTopicsService } from 'page-modules/rulerize/service'
import { getMyStrategiesService } from 'services/strategies'
import {
  deleteUserService,
  getActivationTokenService,
  getResetPasswordTokenService,
  getTotalPeopleService,
  getUserPreferences,
  patchUserService,
  postResendInvitation,
  postUserService,
  publicUserService,
  searchUsersService,
} from 'services/users'
import { updateUserSettingsService } from 'page-modules/settings/service'
import updateUserSettingsForm from 'page-modules/settings/service/reducer'
import {
  closeMoodMeterGroupService,
  deleteMoodMeterGroupsService,
  deleteMoodService,
  getGroupsSharedWithMeService,
  getMoodMeterGroupsService,
  getMoodsService,
  getMyGroupsService,
  patchMoodMeterGroupsService,
  postMoodMeterGroupService,
  postMoodService,
} from 'page-modules/mood-meter/service'
import { getMoodMeterGroupByShortCodeService } from 'page-modules/mood-meter/service/mood-meter-service'
import moodMeterPlotForm from 'page-modules/mood-meter/service/plot-form-reducer'
import moodMeterGroupForm from 'page-modules/mood-meter/service/group-form-reducer'
import { getAssessmentService, postAssessmentResultService } from 'page-modules/assessment/service'
import { getAssessmentsService } from 'services/assessments'
import { getJournalsService, patchJournalService, postJournalService } from 'services/journals'
import manageJournalForm from 'page-modules/journal/service/reducer'
import { deleteJournalService } from 'page-modules/journal/service/saga'
import assessmentAnswerseducer from 'page-modules/assessment/service/reducer'
import charterReducer from 'page-modules/charter/service/reducer'
import charterUIReducer from 'page-modules/charter/service/ui-reducer'
import {
  deleteCharterService,
  getCharterDetailsService,
  getChartersService,
  getCharterTemplatesService,
  postCharterService,
} from 'page-modules/charter/service'
import { changePasswordService } from 'page-modules/settings/password/service'
import changePasswordForm from 'page-modules/settings/password/service/reducer'
import emotionWordsForm from 'page-modules/dashboard/emotion-words-form/service/reducer'
import inGroupForm from 'page-modules/mood-meter/in-group/reducer'
import {
  classroomGradeService,
  classroomGradesService,
  classroomGradeUnitsService,
  classroomLessonService,
  earlyChildhoodMaterialsService,
  elementarySchoolMaterialsService,
  middleSchoolsMaterialsService,
  schoolCategoriesService,
  schoolCategoryService,
} from 'page-modules/curriculum/service'
import {
  staffCharterContributeService,
  staffCharterContributionsService,
  staffCharterCreateRevisionService,
  staffCharterRevisionService,
  staffCharterWordCloudContributionsService,
} from 'page-modules/charter/staff-charter/service'
import {
  getLatestStaffCharterSurveyService,
  getStaffCharterSurveyReportsService,
  getStaffCharterSurveysService,
} from 'page-modules/charter/staff-charter/survey/service'
import staffCharterFiltersReducer from 'page-modules/charter/staff-charter/service/reducer'
import charterSurveyForm from 'page-modules/charter/staff-charter/survey/form/service/reducer'
import charterSurveysList from 'page-modules/charter/staff-charter/survey/list/service/reducer'
import charterSurveyPreview from 'page-modules/charter/staff-charter/survey/service/reducer'
import contributeEmotionWordsForm from 'page-modules/charter/staff-charter/contribute-emotion-words-form/service/reducer'
import approveEmotionWords from 'page-modules/charter/staff-charter/approve-contributions-form/service/reducer'
import lessonProgressReducer from 'page-modules/curriculum/service/reducer'
import {
  getAllNudgeSetsService,
  getMyNudgeSetsService,
  getTopNudgeSetService,
  getTopNudgeStatisticsService,
} from 'services/nudges'
import socketCommunicationReducer from 'common/services/socket-communication/reducer'
import {
  deleteAnnouncementService,
  getActivityStreamService,
  getAnnouncementsService,
  postActivityStreamMessageService,
} from 'common/services/notifications'
import loginReducer from 'common/services/login/reducer'
import registerReducer from 'page-modules/register/service/reducer'
import resetPasswordReducer from 'page-modules/reset-password/service/reducer'
import forgotPasswordReducer from 'page-modules/forgot-password/service/reducer'
import activationReducer from 'page-modules/activation/service/reducer'
import emotionWordFormReducer from 'page-modules/emotion-words/emotion-word-form/service/reducer'
import jobTitleFormReducer from 'page-modules/job-titles/job-title-form/service/reducer'
import subjectFormReducer from 'page-modules/subjects/subject-form/service/reducer'
import announcementFormReducer from 'page-modules/announcements/announcement-form/service/reducer'

const { publicRuntimeConfig } = getConfig()

interface InitialState {
  error: null
  user: User | null
  buildId: string
  apiUrl: string
  wsUrl: string
  modal: ModalState
  pageScrollPercentage: number
}

export const initialState = (): InitialState => ({
  error: null,
  user: null,
  buildId: publicRuntimeConfig.BUILD_ID,
  apiUrl: publicRuntimeConfig.API_URL,
  wsUrl: publicRuntimeConfig.WS_URL,
  pageScrollPercentage: 0,
  modal: {
    isOpen: false,
    content: null,
    aria: {},
    className: '',
    shouldCloseOnOverlayClick: true,
  },
})
declare global {
  interface Window {
    jQuery: (...args: unknown[]) => {
      height: () => number
    }
  }
}

function root(state: InitialState = initialState(), action: AnyAction): InitialState {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload.user },
      }

    case CHANGE_MODAL:
      return {
        ...state,
        modal: action.payload,
      }

    case CALCULATE_SCROLL_PROGRESS:
      return {
        ...state,
        pageScrollPercentage: _.round(
          Math.max(
            0,
            Math.min(
              1,
              window.scrollY / (window.jQuery('body').height() - window.jQuery(window).height()),
            ),
          ) * 100,
        ),
      }

    default:
      return state
  }
}

export const combined = combineReducers({
  root,
  htmlContent: htmlContentReducer,
  navDropdown: navDropdownReducer,
  userSettings: combineReducers({
    form: combineReducers({
      profile: updateUserSettingsForm,
      changePassword: changePasswordForm,
    }),
    request: combineReducers({
      profile: updateUserSettingsService.reducer,
      changePassword: changePasswordService.reducer,
      emotionWords: saveEmotionWordsService.reducer,
    }),
  }),
  ycei: combineReducers({
    users: getYceiUsersService.reducer,
    addYceiUser: combineReducers({
      form: addYceiUserForm,
    }),
  }),
  userStatistics: combineReducers({
    ycei: getYceiUsersStatistics.reducer,
    admins: getAdminUsersStatistics.reducer,
  }),
  bookmarked: combineReducers({
    schools: getBookmarkedSchoolsService.reducer,
    districts: getBookmarkedDistrictsService.reducer,
  }),
  district: combineReducers({
    details: getDistrictDetailsService.reducer,
    users: getDistrictUsersService.reducer,
    schools: getDistrictSchoolsService.reducer,
    schools_list: getDistrictSchoolsListService.reducer,
    addDistrictUser: combineReducers({
      form: addDistrictUserForm,
    }),
  }),
  manageDistrict: combineReducers({
    form: districtForm,
    request: combineReducers({
      post: postDistrictService.reducer,
    }),
  }),
  statistics: combineReducers({
    rulerizeCommitments: getRulerizeCommitmentsService.reducer,
  }),
  school: combineReducers({
    details: schoolDetailsService.reducer,
    users: getSchoolUsersService.reducer,
    userStats: getSchoolUserStatisticsService.reducer,
    howWeWantToFeel: getSchoolHowWantToFeelService.reducer,
    top5MoodMeterEmotionWords: getTop5MoodMeterEmotionWordsService.reducer,
    moodMeterCheckIns: getMoodMeterCheckInsService.reducer,
    chartersPerAudience: getChartersPerAudienceService.reducer,
    courseCompletion: getSchoolCourseCompletionService.reducer,
    topResources: getSchoolTopResourcesService.reducer,
    journalPosts: getJournalPostsService.reducer,
    addSchoolUser: combineReducers({
      form: addSchoolUserForm,
      csvUploadForm: csvUploadUsersForm,
    }),
  }),
  users: combineReducers({
    post: postUserService.reducer,
    patch: patchUserService.reducer,
    delete: deleteUserService.reducer,
  }),
  moveUser: combineReducers({
    form: moveUserForm,
  }),
  manageSchool: combineReducers({
    form: manageSchoolForm,
    request: postSchoolService.reducer,
  }),
  userOnboarding: combineReducers({
    form: userOnboardingForm,
    request: postOnboardingService.reducer,
  }),
  userPreferences: getUserPreferences.reducer,
  resendInvitation: postResendInvitation.reducer,
  course: courseService.reducer,
  courses: coursesService.reducer,
  districts: districtsService.reducer,
  districtsFilters,
  downloadMemberListService: downloadMemberListService.reducer,
  totalSchools: getTotalSchoolsService.reducer,
  totalDistricts: getTotalDistrictsService.reducer,
  totalPeople: getTotalPeopleService.reducer,
  activationToken: getActivationTokenService.reducer,
  resetPasswordToken: getResetPasswordTokenService.reducer,
  schools: schoolsService.reducer,
  schoolsFilters,
  searchedSchools: searchSchoolsService.reducer,
  searchedDistricts: searchDistrictsService.reducer,
  searchedUsers: searchUsersService.reducer,
  publicUserProfiles: publicUserService.reducer,
  states: statesService.reducer,
  countries: countriesService.reducer,
  jobTitles: jobTitlesService.reducer,
  cities: citiesService.reducer,
  emotionWords: emotionWordsService.reducer,
  emotionWordsForm,
  grades: gradesService.reducer,
  charterTemplates: getCharterTemplatesService.reducer,
  subjects: subjectsService.reducer,
  phases: phasesService.reducer,
  offlineTrainings: offlineTrainingsService.reducer,
  personRoles: personRolesService.reducer,
  personTypes: personTypesService.reducer,
  glossary: getGlossaryService.reducer,
  collections: getCollectionsService.reducer,
  collection: combineReducers({
    details: getCollectionDetailsService.reducer,
    resources: getCollectionResourcesService.reducer,
  }),
  resource: combineReducers({
    details: getResourceDetailsService.reducer,
    search: searchResourcesService.reducer,
    request: combineReducers({
      postFavorite: postFavoriteService.reducer,
      postMarkAsRead: postVisitResourceService.reducer,
    }),
    favorites: getFavoritesService.reducer,
  }),
  strategies: combineReducers({
    all: getAllStrategiesService.reducer,
    my: getMyStrategiesService.reducer,
    topics: getStrategyTopicsService.reducer,
  }),
  moodMeter: combineReducers({
    forms: combineReducers({
      plot: moodMeterPlotForm,
      group: moodMeterGroupForm,
      ingroup: inGroupForm,
    }),
    myGroups: getMyGroupsService.reducer, // short info for groups of which I'm owner
    groups: getMoodMeterGroupsService.reducer,
    groupsSharedWithMe: getGroupsSharedWithMeService.reducer,
    group: getMoodMeterGroupByShortCodeService.reducer, // group details by short code
    moods: getMoodsService.reducer, // Here we'll have either own moods or group moods
    request: combineReducers({
      postMoodMeterPlot: postMoodService.reducer,
      deleteMood: deleteMoodService.reducer,
      postMoodMeterGroup: postMoodMeterGroupService.reducer,
      patchMoodMeterGroup: patchMoodMeterGroupsService.reducer,
      deleteMoodMeterGroup: deleteMoodMeterGroupsService.reducer,
      closeMoodMeterGroup: closeMoodMeterGroupService.reducer,
    }),
  }),
  modules: modulesService.reducer,
  showcase: combineReducers({
    list: getShowcaseService.reducer,
    listByTag: getShowcaseByTagService.reducer,
    details: getShowcaseBySlugService.reducer,
    filters: showcaseFilterReducer,
    form: showcaseFormReducer,
    topTags: getTopShowcaseTagsService.reducer,
    requests: combineReducers({
      post: postShowcaseService.reducer,
    }),
  }),
  module: moduleReducer,
  audienceTypes: audienceTypesService.reducer,
  assessment: combineReducers({
    details: getAssessmentService.reducer,
    answers: assessmentAnswerseducer,
    requests: combineReducers({
      postAssessmentResult: postAssessmentResultService.reducer,
    }),
  }),
  assessments: getAssessmentsService.reducer,
  journals: getJournalsService.reducer,
  journal: combineReducers({
    requests: combineReducers({
      post: postJournalService.reducer,
      patch: patchJournalService.reducer,
      delete: deleteJournalService.reducer,
    }),
    form: manageJournalForm,
  }),
  charter: combineReducers({
    details: getCharterDetailsService.reducer,
    form: charterReducer,
    ui: charterUIReducer,
    requests: combineReducers({
      post: postCharterService.reducer,
      delete: deleteCharterService.reducer,
    }),
  }),
  charters: getChartersService.reducer,
  nudges: combineReducers({
    mySets: getMyNudgeSetsService.reducer,
    allSets: getAllNudgeSetsService.reducer,
    nudgeSchoolStats: getTopNudgeSetService.reducer,
    nudgeStats: getTopNudgeStatisticsService.reducer,
  }),
  classroomMaterials: combineReducers({
    categories: schoolCategoriesService.reducer,
    category: schoolCategoryService.reducer,
    grades: classroomGradesService.reducer,
    grade: classroomGradeService.reducer,
    units: classroomGradeUnitsService.reducer,
    lesson: classroomLessonService.reducer,
    lessonProgress: lessonProgressReducer,
    elementarySchoolMaterials: elementarySchoolMaterialsService.reducer,
    earlyChildhoodMaterials: earlyChildhoodMaterialsService.reducer,
    middleSchoolMaterials: middleSchoolsMaterialsService.reducer,
  }),
  staffCharter: combineReducers({
    wordCloud: staffCharterWordCloudContributionsService.reducer,
    contributions: staffCharterContributionsService.reducer,
    contributeEmotionWordsForm,
    approveEmotionWords,
    details: combineReducers({
      charter: staffCharterRevisionService.reducer,
      filters: staffCharterFiltersReducer,
    }),
    requests: combineReducers({
      contribute: staffCharterContributeService.reducer,
      revise: staffCharterCreateRevisionService.reducer,
    }),
    survey: combineReducers({
      latest: getLatestStaffCharterSurveyService.reducer,
      surveys: getStaffCharterSurveysService.reducer,
      report: getStaffCharterSurveyReportsService.reducer,
      form: charterSurveyForm,
      preview: charterSurveyPreview,
      list: combineReducers({
        selected: charterSurveysList,
      }),
    }),
  }),
  socketCommunication: socketCommunicationReducer,
  notifications: combineReducers({
    list: getActivityStreamService.reducer,
    postMessage: postActivityStreamMessageService.reducer,
  }),
  announcements: combineReducers({
    list: getAnnouncementsService.reducer,
    form: announcementFormReducer,
    requests: combineReducers({
      delete: deleteAnnouncementService.reducer,
    }),
  }),
  login: loginReducer,
  register: registerReducer,
  resetPassword: resetPasswordReducer,
  forgotPassword: forgotPasswordReducer,
  activation: activationReducer,
  emotionWord: combineReducers({
    form: emotionWordFormReducer,
    requests: combineReducers({
      post: postEmotionWordService.reducer,
      delete: deleteEmotionWordService.reducer,
      patch: patchEmotionWordService.reducer,
    }),
  }),
  jobTitle: combineReducers({
    form: jobTitleFormReducer,
    requests: combineReducers({
      post: postJobTitleService.reducer,
      delete: deleteJobTitleService.reducer,
      patch: patchJobTitleService.reducer,
    }),
  }),
  subject: combineReducers({
    form: subjectFormReducer,
    requests: combineReducers({
      post: postSubjectService.reducer,
      delete: deleteSubjectService.reducer,
      patch: patchSubjectService.reducer,
    }),
  }),
  state: combineReducers({
    requests: combineReducers({
      post: postStateService.reducer,
      patch: patchStateService.reducer,
    }),
  }),
  city: combineReducers({
    requests: combineReducers({
      post: postCityService.reducer,
      patch: patchCityService.reducer,
    }),
  }),
  country: combineReducers({
    requests: combineReducers({
      patch: patchCountryService.reducer,
    }),
  }),
})

export default combined

export type AppState = ReturnType<typeof combined>

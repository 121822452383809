import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import lang from 'lang'
import { changeModal } from 'services/actions'
import SchoolUsersForm from 'page-modules/dashboard/school-details/users-form/form'
import SchoolUserMultipleForms from 'page-modules/dashboard/school-details/users-form'
import {
  addMoreForms,
  submitSchoolUser,
} from 'page-modules/dashboard/school-details/service/actions'

@connect(
  (state) => ({
    schoolUserFormData: state.school.addSchoolUser.form,
    loading: state.users.post.loading,
  }),
  (dispatch) => ({
    changeModal: (params) => dispatch(changeModal(params)),
    submitSchoolUser: (schoolUserFormData) => dispatch(submitSchoolUser(schoolUserFormData)),
    addMoreForms: (payload) => dispatch(addMoreForms(payload)),
  }),
)
export default class SchoolUserModal extends React.Component {
  static propTypes = {
    edit: PropTypes.bool,
    schoolUserFormData: PropTypes.object,
    loading: PropTypes.bool,
    school: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    district: PropTypes.shape({
      id: PropTypes.string,
    }),
    changeModal: PropTypes.func.isRequired,
    submitSchoolUser: PropTypes.func,
    addMoreForms: PropTypes.func,
  }

  get singleForm() {
    return _.keys(this.props.schoolUserFormData).length === 1
  }

  closeModal() {
    this.props.changeModal({ isOpen: false })
  }

  createAddSchoolUserHandler() {
    return () => {
      const data = _.compact(
        _.map(this.props.schoolUserFormData, ({ formData }, formId) => {
          if (formId === 'school_user_form_0' || !_.isEmpty(_.pickBy(formData, _.identity))) {
            const userData = _.mapValues(formData, (value, key) => ({
              value,
              validations: SchoolUsersForm.validations[key],
            }))
            userData.schoolId = { value: this.props.school.id, validations: '' }
            userData.districtId = { value: this.props.district.id, validations: '' }
            userData.formId = formId
            return userData
          }
        }),
      )
      this.props.submitSchoolUser(data)
    }
  }

  addMultipleUsersModalHandler() {
    return () => {
      // add 4 more forms to the state
      this.props.addMoreForms(4)
    }
  }

  renderAddMultipleFormsButtons() {
    if (this.props.edit || (!this.props.edit && !this.singleForm)) {
      return
    }

    return (
      <button type="button" className="button clear" onClick={this.addMultipleUsersModalHandler()}>
        {lang.dashboard.userForm.addMultipleUsers}
      </button>
    )
  }

  render() {
    let form = <SchoolUsersForm key="user_school_user_form_0" formId="school_user_form_0" />
    if (!this.singleForm) {
      form = <SchoolUserMultipleForms />
    }

    return (
      <div className="modal-wrapper">
        <div className="modal-header">
          <h2 id="add-new-school-user-header">
            {this.props.edit
              ? lang.dashboard.userForm.editTitle
              : `${lang.dashboard.userForm.title} ${this.props.school.name}`}
          </h2>
        </div>
        <div className="modal-container">
          {form}
          {this.renderAddMultipleFormsButtons()}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="button save"
            disabled={this.props.loading}
            onClick={this.createAddSchoolUserHandler()}>
            {lang.saveButton}
          </button>
          <button type="button" className="button clear cancel" onClick={() => this.closeModal()}>
            {lang.cancelButton}
          </button>
        </div>
      </div>
    )
  }
}

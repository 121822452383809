/**
 * When editing this file please keep the documentation up to date
 *
 * https://github.com/liveSTORYBOARD/RULER/wiki/CMS-Integration#tasksnudges-action-placeholders
 */

import { MoodMeterTabs, SettingsTabs, tabs, urls } from 'common/routes'

export default [
  {
    action: '[action_video_introduction]',
    url: () => urls.resourceDetails('video-ruler-online-introduction'),
  },
  {
    action: '[action_school_profile]',
    url: ({ school }: { school?: { slug: string } }) =>
      school ? urls.schoolDetails(school.slug) : '',
  },
  { action: '[action_journal]', url: () => urls.journal },
  { action: '[action_help_page]', url: () => 'https://help.ruler.online/' },
  { action: '[action_module_implementation_team]', url: () => '#' },
  {
    action: '[action_module_emotions_matter]',
    url: () => urls.moduleDetails('the-emotions-matter-mindset'),
  },
  {
    action: '[action_module_impact_of_emotions]',
    url: () => urls.moduleDetails('the-impact-of-emotions'),
  },
  { action: '[action_module_ruler_skills]', url: () => urls.moduleDetails('the-ruler-skills') },
  { action: '[action_module_ruler_approach]', url: () => urls.moduleDetails('the-ruler-approach') },
  { action: '[action_module_charter]', url: () => urls.moduleDetails('charter') },
  {
    action: '[action_how_i_want_to_feel_at_school]',
    url: () => urls.settings(SettingsTabs.emotionWords),
  },
  {
    action: '[action_module_mood_meter]',
    url: () => urls.moduleDetails('building-ruler-skills-with-the-mood-meter'),
  },
  { action: '[action_mood_meter]', url: () => urls.moodMeter() },
  { action: '[action_mood_meter_plot_group]', url: () => urls.moodMeter(MoodMeterTabs.myGroup) },
  {
    action: '[action_module_meta_moment]',
    url: () => urls.moduleDetails('responding-with-ones-best-self-using-the-meta-moment'),
  },
  { action: '[action_rulerize_tool]', url: () => urls.rulerize },
  {
    action: '[action_module_blueprint]',
    url: () => urls.moduleDetails('the-blueprint-building-empathy-and-perspective-taking'),
  },
  {
    action: '[action_resource_library_blueprint]',
    url: () => urls.collectionDetails('the-blueprint'),
  },
  {
    action: '[action_blueprint_reflection]',
    url: () => urls.resourceDetails('activity-practice-with-the-blueprint-reflection'),
  },
  {
    action: '[action_resource_positive_emotional_climate]',
    url: () =>
      urls.resourceDetails(
        'milestone-03-meeting-facilitation-guide-building-a-positive-emotional-climate-with-the-charter',
      ),
  },
  {
    action: '[action_module_classroom_implementation]',
    url: () => '#',
  },
  { action: '[action_curriculum_library]', url: () => urls.curriculum },
  {
    action: '[action_charter_tab_edit_staff_charter]',
    url: () => urls.charters(tabs.charter.editStaffCharter),
  },
  {
    action: '[action_charter_tab_contributions]',
    url: () => urls.charters(tabs.charter.contributions),
  },
]

import { all, call, takeLatest } from 'redux-saga/effects'

import { courseService } from 'page-modules/courses/service'
import { getAssessmentsService } from 'services/assessments'

import { LOAD_COURSE_DETAILS_PAGE_DATA } from './actions'

export function* loadCoursePageDataSaga({ payload: { courseSlug, req } }) {
  const course = yield call(courseService.requestSaga, {
    payload: { req, urlParams: { slug: courseSlug } },
  })
  yield call(getAssessmentsService.requestSaga, {
    payload: { req, urlParams: { courseId: course.id } },
  })
}

export default function* saga() {
  yield all([takeLatest(LOAD_COURSE_DETAILS_PAGE_DATA, loadCoursePageDataSaga)])
}

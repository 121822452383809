import { format } from 'date-fns'
import Papa from 'papaparse'

import buildService from 'common/utils/build-service'
import { POST } from 'common/requests'
import { downloadCsv } from 'common/utils/download-csv'

export const getSchoolHowWantToFeelService = buildService({
  type: POST,
  constantsPrefix: 'SCHOOL_HOW_WE_WANT_TO_FEEL',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/rpc/want_to_feel',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/json',
    },
  },
})

export const getTop5MoodMeterEmotionWordsService = buildService({
  type: POST,
  constantsPrefix: 'SCHOOL_TOP_5_MOOD_METER_EMOTION_WORDS',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/rpc/top5_words',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/json',
    },
  },
})

export const getMoodMeterCheckInsService = buildService({
  type: POST,
  constantsPrefix: 'SCHOOL_MOOD_METER_CHECK_INS',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/rpc/mm_check_ins',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/json',
    },
  },
})

export const getSchoolCourseCompletionService = buildService({
  type: POST,
  constantsPrefix: 'SCHOOL_COURSE_COMPLETION',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/rpc/course_completion',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/json',
    },
  },
})

export const getChartersPerAudienceService = buildService({
  type: POST,
  constantsPrefix: 'CHARTERS_PER_AUDIENCE',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/rpc/charters_per_audience',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/json',
    },
  },
})

export const getSchoolTopResourcesService = buildService({
  type: POST,
  constantsPrefix: 'SCHOOL_TOP_RESOURCES',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/rpc/top_resources',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/json',
    },
  },
})

export const getSchoolTopResourcesCsvService = buildService({
  type: POST,
  constantsPrefix: 'SCHOOL_TOP_RESOURCES_CSV',
  isArray: true,
  http: {
    createApiUrlSuffix: () =>
      '/rpc/top_resources?select=title,collections,visited,favorite,deleted&deleted=is.false',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'text/csv',
    },
    successHandler: (data) => {
      // PostgREST returns CSV with titles in alphabetical order, but the data is in the order of the select query
      const dataWithCorrectCSVTitles = data.replace(
        'collections,deleted,favorite,title,visited',
        'title,collections,visited,favorited,deleted',
      )
      const parsed = Papa.parse(dataWithCorrectCSVTitles, {
        header: true,
        transform: (value, header) => {
          if (header === 'collections' && value !== '') {
            return JSON.parse(value).map((item) => item.name)
          }
          return value
        },
      })
      parsed.data.forEach((item) => {
        delete item['deleted']
        item.collections = JSON.stringify(item.collections)
      })
      downloadCsv(
        Papa.unparse(parsed.data),
        `RULER-Online-resource-statistics_${format(new Date(), 'MM-dd-yyyy')}`,
      )
    },
  },
})

export const getJournalPostsService = buildService({
  type: POST,
  constantsPrefix: 'SCHOOL_JOURNALS_REPORT',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/rpc/journals_by_type',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/json',
    },
  },
})

export const getRulerizeCommitmentsService = buildService({
  type: POST,
  constantsPrefix: 'RULERIZE_COMMITMENTS',
  http: {
    createApiUrlSuffix: () => '/rpc/rulerize_commitments',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

import _ from 'lodash'
import cn from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { subYears } from 'date-fns'
import Flatpickr from 'react-flatpickr'

import lang from 'lang'

export default class TrainingRecord extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    errors: PropTypes.object,
    onRemoveTrainingHandler: PropTypes.func,
    onTrainingChangeHandler: PropTypes.func,
    onDateChangeHandler: PropTypes.func,
    offline_training_id: PropTypes.string,
    training_attended_at: PropTypes.string,
    usedTrainingTypes: PropTypes.array,
    offlineTrainingTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    ).isRequired,
  }

  render() {
    const { offline_training_id, training_attended_at } = this.props.errors

    return (
      <React.Fragment key={this.props.index}>
        <div className="cell medium-3 small-6">
          <label className="text-right " htmlFor="training_attended_at">
            <select
              name="offline_training_id"
              id="offline_training_id"
              onChange={this.props.onTrainingChangeHandler}
              value={this.props.offline_training_id || ''}>
              <option value="">Select Training</option>
              {_.map(this.props.offlineTrainingTypes, (item) => (
                <option
                  value={item.id}
                  key={item.id}
                  disabled={_.includes(this.props.usedTrainingTypes, item.id)}>
                  {item.name}
                </option>
              ))}
            </select>
          </label>
          <span className={cn('form-error', { 'is-visible': offline_training_id })}>
            {offline_training_id}
          </span>
        </div>
        <div className="cell medium-4 small-6">
          <Flatpickr
            value={this.props.training_attended_at || ''}
            placeholder={lang.page.moodMeter.selectDate}
            options={{
              dateFormat: 'Y-m-d',
              minDate: subYears(new Date(), 5), // five years ago
            }}
            onChange={(date) => {
              this.props.onDateChangeHandler(date[0])
            }}
          />
          <span className={cn('form-error', { 'is-visible': training_attended_at })}>
            {training_attended_at}
          </span>
        </div>
        <div className="cell medium-5">
          <button
            type="button"
            className="button hollow small"
            onClick={this.props.onRemoveTrainingHandler}>
            {lang.remove}
          </button>
        </div>
      </React.Fragment>
    )
  }
}

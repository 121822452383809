import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import lang from 'lang'
import { changeModal } from 'services/actions'
import { submitYceiUser } from 'page-modules/dashboard/ycei-members/service/actions'

import YceiUserForm from './ycei-user-form'

@connect(
  (state) => ({
    yceiUserFormData: state.ycei.addYceiUser.form.data,
    loading: state.users.post.loading,
  }),
  (dispatch) => ({
    changeModal: (params) => dispatch(changeModal(params)),
    submitYceiUser: (yceiUserFormData) => dispatch(submitYceiUser(yceiUserFormData)),
  }),
)
export default class YceiUserModal extends React.Component {
  static propTypes = {
    edit: PropTypes.bool,
    yceiUserFormData: PropTypes.object,
    loading: PropTypes.bool,
    district: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    changeModal: PropTypes.func.isRequired,
    submitYceiUser: PropTypes.func,
  }

  submitYceiUserFormHandler() {
    return () => {
      const userData = _.mapValues(this.props.yceiUserFormData, (value, key) => {
        return {
          value,
          validations: YceiUserForm.validations[key],
        }
      })
      this.props.submitYceiUser(userData)
    }
  }

  closeModal() {
    this.props.changeModal({ isOpen: false })
  }

  render() {
    return (
      <div className="modal-wrapper">
        <div className="modal-header">
          <h2 id="add-new-ycei-user-header">
            {this.props.edit
              ? lang.dashboard.userForm.editTitle
              : `${lang.dashboard.userForm.title} ${lang.yceiAcronym}`}
          </h2>
        </div>
        <div className="modal-container">
          <YceiUserForm />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="button save"
            disabled={this.props.loading}
            onClick={this.submitYceiUserFormHandler()}>
            {lang.saveButton}
          </button>
          <button type="button" className="button clear cancel" onClick={() => this.closeModal()}>
            {lang.cancelButton}
          </button>
        </div>
      </div>
    )
  }
}

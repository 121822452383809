import _ from 'lodash'

const pagesNavigation = (pagesCount, currentPage) => {
  if (!pagesCount) {
    return []
  }
  let pages = Array(pagesCount)
    .fill()
    .map((p, i) => i + 1)
  if (pagesCount > 7) {
    if (currentPage < 5) {
      pages = _.concat(_.slice(pages, 0, 5), [null, pagesCount])
    } else if (currentPage > pagesCount - 4) {
      pages = _.concat([1, null], _.slice(pages, -5))
    } else {
      pages = _.concat([1, null], _.slice(pages, currentPage - 3, currentPage + 2), [
        null,
        pagesCount,
      ])
    }
  }
  return pages
}

export default pagesNavigation

import _ from 'lodash'

import buildService from 'common/utils/build-service'
import { GET } from 'common/requests'

export default buildService({
  type: GET,
  constantsPrefix: 'HTML_CONTENT',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ key, keys, language = 'en' }) => {
      if (!key && !keys) {
        throw new Error('Use single `key` or multiple `keys` as params')
      }
      const lang = `&language=eq.${language}`
      if (key) {
        return `/html_content?select=key,content&key=eq.${key}${lang}`
      }
      const keySet = _.join(_.uniq(keys), ',')
      return `/html_content?select=key,content&key=in.(${keySet})${lang}`
    },
  },
})

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import lang from 'lang'
import { changeModal } from 'services/actions'

import { resetUploadedUsers } from './service/actions'
import UsersGridForm from './grid-form'
import UsersCSVUploadControls from './upload-controls'

@connect(
  (state) => ({
    uploadedUsers: state.school.addSchoolUser.csvUploadForm.formData.users,
  }),
  (dispatch) => ({
    changeModal: (params) => dispatch(changeModal(params)),
    resetUploadedUsers: (params) => dispatch(resetUploadedUsers(params)),
  }),
)
export default class SchoolJourneyUploadCsvModal extends React.Component {
  static propTypes = {
    changeModal: PropTypes.func,
    uploadedUsers: PropTypes.array,
    resetUploadedUsers: PropTypes.func,
  }

  componentWillUnmount() {
    this.props.resetUploadedUsers()
  }

  get csvParsed() {
    return this.props.uploadedUsers.length > 0
  }

  closeModalHandler() {
    return () => {
      this.props.changeModal({ isOpen: false })
    }
  }

  render() {
    return (
      <div className="users-file-upload">
        <div className="modal-header">
          <h2 id="upload-csv-users-header">{lang.dashboard.userForm.uploadCsv.title}</h2>
          <div className="callout success">
            {lang.dashboard.userForm.uploadCsv.schoolCreationSuccessful}
          </div>
          <p>{lang.dashboard.userForm.uploadCsv.uploadUsersInstructions}</p>
        </div>
        <div className="modal-container">
          <UsersGridForm />
        </div>
        <div className="modal-footer">
          <UsersCSVUploadControls />
          <button type="button" className="button hollow" onClick={this.closeModalHandler()}>
            {this.csvParsed ? lang.dashboard.userForm.uploadCsv.discard : lang.skipButton}
          </button>
        </div>
      </div>
    )
  }
}

import { all, call, put, takeEvery } from 'redux-saga/effects'

import { changeModal } from 'services/actions'

import { loadShowcasesSaga } from './filter/saga'
import { LIKE_SHOWCASE, TOGGLE_SHOWCASE_STATUS } from './actions'

import { likeShowcaseService, postShowcaseService } from './index'

export function* likeShowcaseSaga({ payload: { showcaseId } }) {
  yield call(likeShowcaseService.requestSaga, {
    payload: {
      data: { showcase_id: showcaseId },
    },
  })

  yield* loadShowcasesSaga()
}

export function* toggleShowcaseSaga({ payload: { showcaseId, schoolId = null, status } }) {
  yield call(postShowcaseService.requestSaga, {
    payload: {
      data: {
        id: showcaseId,
        school_id: schoolId,
        status,
      },
    },
  })

  yield* loadShowcasesSaga()
  yield put(changeModal({ isOpen: false }))
}

export default function* saga() {
  yield all([
    takeEvery(LIKE_SHOWCASE, likeShowcaseSaga),
    takeEvery(TOGGLE_SHOWCASE_STATUS, toggleShowcaseSaga),
  ])
}

import { all, call, put, takeLatest } from 'redux-saga/effects'
import { changeModal } from 'services/actions'
import statesService, { patchStateService, postStateService } from 'services/states'
import { StatePayload, SUBMIT_STATE } from './actions'

export function* submitStateSaga({ payload }: { payload: StatePayload }) {
  let stateResult
  if (payload.id) {
    // @ts-expect-error no saga types
    stateResult = yield call(patchStateService.requestSaga, {
      payload: {
        data: payload,
        urlParams: {
          id: payload.id,
        },
      },
    })
  } else {
    // @ts-expect-error no saga types
    stateResult = yield call(postStateService.requestSaga, {
      payload: {
        data: payload,
      },
    })
  }

  if (stateResult.error) {
    // handle error
  } else {
    // @ts-expect-error no saga types
    yield call(statesService.requestSaga, {
      payload: { urlParams: { countryId: payload.country } },
    })
    yield put(
      changeModal({
        isOpen: false,
      }),
    )
  }
}

export default function* saga() {
  // @ts-expect-error no saga types
  yield all([takeLatest(SUBMIT_STATE, submitStateSaga)])
}

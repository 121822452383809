export const UPLOAD_CSV_USERS = 'UPLOAD_CSV_USERS'
export const UPDATE_CSV_USERS = 'UPDATE_CSV_USERS'
export const SUBMIT_UPLOADED_USERS = 'SUBMIT_UPLOADED_USERS'
export const RESET_UPLOADED_USERS = 'RESET_UPLOADED_USERS'
export const ADD_CSV_UPLOAD_ERROR = 'ADD_CSV_UPLOAD_ERROR'
export const VALIDATE_USERS = 'VALIDATE_USERS'
export const validationTypes = {
  badName: 'badName',
  badEmail: 'badEmail',
  existingEmail: 'existingEmail',
}

// Runs with user validation
export function uploadCsvUsers(payload) {
  return {
    type: UPLOAD_CSV_USERS,
    payload,
  }
}

// Runs without user validation
export function updateCsvUsers(payload) {
  return {
    type: UPDATE_CSV_USERS,
    payload,
  }
}

export function addCsvUploadError(message) {
  return {
    type: ADD_CSV_UPLOAD_ERROR,
    payload: {
      message,
    },
  }
}

export function resetUploadedUsers() {
  return {
    type: RESET_UPLOADED_USERS,
  }
}

export function submitUploadedUsers(payload) {
  return {
    type: SUBMIT_UPLOADED_USERS,
    payload,
  }
}

export function validateUsers(payload) {
  return {
    type: VALIDATE_USERS,
    payload,
  }
}

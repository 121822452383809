import buildService from 'common/utils/build-service'
import { POST } from 'common/requests'

export default buildService({
  type: POST,
  constantsPrefix: 'BOOKMARK',
  http: {
    createApiUrlSuffix: () => '/rpc/bookmark',
    headers: {
      Accept: 'application/json',
    },
  },
})

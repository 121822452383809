export const LIKE_SHOWCASE = 'LIKE_SHOWCASE'
export const TOGGLE_SHOWCASE_STATUS = 'TOGGLE_SHOWCASE_STATUS'

export function likeShowcase({ showcaseId, isStatic = false }) {
  return {
    type: LIKE_SHOWCASE,
    payload: { showcaseId, isStatic },
  }
}

export function toggleShowcaseStatus({ showcaseId, schoolId, status }) {
  return {
    type: TOGGLE_SHOWCASE_STATUS,
    payload: { showcaseId, schoolId, status },
  }
}

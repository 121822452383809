import buildService from 'common/utils/build-service'
import { GET, POST } from 'common/requests'

const select = `select=*,showcase_liked,owner:persons(id,name,photo_url),school:schools(id,name,district:districts(id,name))`
const selectTag = `select=tag,showcase:showcases(*,tags:showcase_tags(tag),showcase_liked,owner:persons(id,name,photo_url))`

export const postShowcaseService = buildService({
  type: POST,
  constantsPrefix: 'SHOWCASE',
  http: {
    createApiUrlSuffix: () => {
      return `/rpc/showcase`
    },
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

export const likeShowcaseService = buildService({
  type: POST,
  constantsPrefix: 'SHOWCASE_LIKE',
  http: {
    createApiUrlSuffix: () => {
      return `/rpc/showcase_like`
    },
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

export const getShowcaseService = buildService({
  type: GET,
  constantsPrefix: 'SHOWCASE',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ schoolId, status = 'published', isStatic = false }) => {
      const school = schoolId ? `&school_id=eq.${schoolId}` : ''
      const community = isStatic ? `&is_static=eq.true` : '&is_static=eq.false'
      const statusFilter = status ? `&status=eq.${status}` : ''
      const order = `&order=created_at.desc`
      return `/showcases?${select}${school}${statusFilter}${community}${order}`
    },
  },
})

export const getShowcaseBySlugService = buildService({
  type: GET,
  constantsPrefix: 'SHOWCASE_BY_SLUG',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ slug }) => {
      return `/showcases?${select}&slug=eq.${slug}`
    },
  },
})

export const getShowcaseByTagService = buildService({
  type: GET,
  constantsPrefix: 'SHOWCASE_BY_TAG',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ tag }) => {
      return `/showcase_tags?${selectTag}&tag=eq.${tag}`
    },
  },
})

export const getTopShowcaseTagsService = buildService({
  type: POST,
  constantsPrefix: 'TOP_SHOWCASE_TAGS',
  isArray: true,
  http: {
    createApiUrlSuffix: () => {
      return `/rpc/top_showcase_tags`
    },
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

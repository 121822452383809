import { Action } from 'redux'
import { StateAction } from 'services/actions'

export const CHANGE_SCHOOL_USER_FORM_FIELD = 'CHANGE_SCHOOL_USER_FORM_FIELD'
export const SUBMIT_SCHOOL_USER = 'SUBMIT_SCHOOL_USER'
export const VALIDATE_SCHOOL_USER_FIELD = 'VALIDATE_SCHOOL_USER_FIELD'
export const ADD_SCHOOL_USER_FIELD_ERROR = 'ADD_SCHOOL_USER_FIELD_ERROR'
export const ADD_SCHOOL_USER_API_ERROR = 'ADD_SCHOOL_USER_API_ERROR'
export const ADD_SCHOOL_USER_FIELDS_ERRORS = 'ADD_SCHOOL_USER_FIELDS_ERRORS'
export const CLEAR_SCHOOL_USER_FIELD_ERROR = 'CLEAR_SCHOOL_USER_FIELD_ERROR'
export const RESET_SCHOOL_USER_FORM_DATA = 'RESET_SCHOOL_USER_FORM_DATA'
export const PREDEFINE_SCHOOL_USER_FORM_DATA = 'PREDEFINE_SCHOOL_USER_FORM_DATA'
export const TOGGLE_SCHOOL_STATUS = 'TOGGLE_SCHOOL_STATUS'
export const BOOKMARK_SCHOOL = 'BOOKMARK_SCHOOL'
export const ADD_MORE_FORMS = 'ADD_MORE_FORMS'
export const EDIT_SCHOOL_DETAILS = 'EDIT_SCHOOL_DETAILS'
export const LOAD_SCHOOL_DETAILS_PAGE = 'LOAD_SCHOOL_DETAILS_PAGE'

export function changeSchoolUserFormField(
  key: string,
  value: string,
  formId: string,
): StateAction<
  typeof CHANGE_SCHOOL_USER_FORM_FIELD,
  { key: string; value: string; formId: string }
> {
  return {
    type: CHANGE_SCHOOL_USER_FORM_FIELD,
    payload: {
      key,
      value,
      formId,
    },
  }
}

/**
 * @param { id, email, fullName, roleId, personTypeId, districtId, schoolId }[] usersData
 */
export function submitSchoolUser(
  usersData: {
    id: string
    email: string
    fullName: string
    roleId: string
    personTypeId: string
    districtId: string
    schoolId: string
  }[],
): StateAction<typeof SUBMIT_SCHOOL_USER, { usersData: typeof usersData }> {
  return {
    type: SUBMIT_SCHOOL_USER,
    payload: {
      usersData,
    },
  }
}

export function addSchoolAPIError(payload: {
  field: string
  error: string
}): StateAction<typeof ADD_SCHOOL_USER_API_ERROR, typeof payload> {
  return {
    type: ADD_SCHOOL_USER_API_ERROR,
    payload,
  }
}

export function addFieldError(payload: {
  field: string
  error: string
  formId?: string
}): StateAction<typeof ADD_SCHOOL_USER_FIELD_ERROR, typeof payload> {
  return {
    type: ADD_SCHOOL_USER_FIELD_ERROR,
    payload,
  }
}

export function addFieldsErrors(
  errors: unknown,
  formId: string,
): StateAction<
  typeof ADD_SCHOOL_USER_FIELDS_ERRORS,
  { errors: typeof errors; formId: typeof formId }
> {
  return {
    type: ADD_SCHOOL_USER_FIELDS_ERRORS,
    payload: {
      errors,
      formId,
    },
  }
}

export function clearFieldError(payload: {
  field: string
  formId: string
}): StateAction<typeof CLEAR_SCHOOL_USER_FIELD_ERROR, typeof payload> {
  return {
    type: CLEAR_SCHOOL_USER_FIELD_ERROR,
    payload,
  }
}

export function resetSchoolUserFormData(): Action<typeof RESET_SCHOOL_USER_FORM_DATA> {
  return {
    type: RESET_SCHOOL_USER_FORM_DATA,
  }
}

export function validateField(payload: {
  field: string
  value: string
  validations: unknown
  formId: string
}): StateAction<typeof VALIDATE_SCHOOL_USER_FIELD, typeof payload> {
  return {
    type: VALIDATE_SCHOOL_USER_FIELD,
    payload,
  }
}

export function predefineSchoolUserForm(payload: {
  id: string
  email: string
  fullName: string
  roleId: string
  personTypeId: string
  schoolId: string
}): StateAction<typeof PREDEFINE_SCHOOL_USER_FORM_DATA, typeof payload> {
  return {
    type: PREDEFINE_SCHOOL_USER_FORM_DATA,
    payload,
  }
}

export function toggleSchoolStatus(payload: {
  id: string
  status: string
}): StateAction<typeof TOGGLE_SCHOOL_STATUS, typeof payload> {
  return {
    type: TOGGLE_SCHOOL_STATUS,
    payload,
  }
}

export function bookmarkSchool(payload: {
  id: string
  bookmarked: boolean
  refreshMethod: () => void
}): StateAction<typeof BOOKMARK_SCHOOL, typeof payload> {
  return {
    type: BOOKMARK_SCHOOL,
    payload,
  }
}

export function addMoreForms(count = 5): StateAction<typeof ADD_MORE_FORMS, { count: number }> {
  return {
    type: ADD_MORE_FORMS,
    payload: { count },
  }
}

export function editSchoolDetails(payload: {
  schoolId: string
  submitSchool: () => void
}): StateAction<typeof EDIT_SCHOOL_DETAILS, typeof payload> {
  return {
    type: EDIT_SCHOOL_DETAILS,
    payload,
  }
}

export function loadSchoolDetailsPage(payload: {
  slug: string
  req: unknown
}): StateAction<typeof LOAD_SCHOOL_DETAILS_PAGE, typeof payload> {
  return {
    type: LOAD_SCHOOL_DETAILS_PAGE,
    payload,
  }
}

// @ts-check

const userRoles = {
  ruler: {
    member: 'ruler_ycei_member',
    yceiAdmin: 'ruler_ycei_admin',
    admin: 'ruler_ruler_admin',
  },
  district: {
    admin: 'ruler_district_admin',
    member: 'ruler_district_member',
    implementation: 'ruler_district_implementation_team',
  },
  school: {
    admin: 'ruler_school_admin',
    member: 'ruler_school_member',
    implementation: 'ruler_school_implementation_team',
  },
  content: {
    manager: 'ruler_content_manager',
  },
}
export default userRoles

import _ from 'lodash'
import { scroller } from 'react-scroll'
import { all, call, takeLatest } from 'redux-saga/effects'
import Router from 'next/router'

import { SCROLL_OPTIONS } from 'common/utils'
import { urls } from 'common/routes'

import { GO_TO_SURVEY_DETAILS, TOGGLE_STAFF_CHARTER_SURVEY_DETAILS } from './actions'
import { getStaffCharterSurveyReportsService } from '../../service'

export function* loadStaffCharterSurvey({ payload: { surveyId } = {} }) {
  if (surveyId !== undefined) {
    yield call(getStaffCharterSurveyReportsService.requestSaga, {
      payload: { data: { survey_id: surveyId } },
    })
    _.defer(() => {
      scroller.scrollTo(surveyId, {
        ...SCROLL_OPTIONS,
        offset: -300,
      })
    })
  }
}

export function* goToSurveyDetailsSaga({ payload: { surveyId } = {} }) {
  if (surveyId !== undefined) {
    yield call(() => {
      Router.push(urls.manageSurveys(surveyId))
    })
  } else {
    yield call(() => {
      Router.push(urls.manageSurveys())
    })
  }
}

export default function* saga() {
  yield all([
    takeLatest(TOGGLE_STAFF_CHARTER_SURVEY_DETAILS, loadStaffCharterSurvey),
    takeLatest(GO_TO_SURVEY_DETAILS, goToSurveyDetailsSaga),
  ])
}

export const CHANGE_REGISTER_FORM_FIELD = 'CHANGE_REGISTER_FORM_FIELD'
export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_REQUEST_SUCCESS = 'REGISTER_REQUEST_SUCCESS'
export const REGISTER_REQUEST_FAILURE = 'REGISTER_REQUEST_FAILURE'
export const VALIDATE_REGISTER_FIELD = 'VALIDATE_REGISTER_FIELD'
export const ADD_REGISTER_FIELD_ERROR = 'ADD_REGISTER_FIELD_ERROR'
export const CLEAR_REGISTER_FIELD_ERROR = 'CLEAR_REGISTER_FIELD_ERROR'

export function changeRegisterFormField(key, value) {
  return {
    type: CHANGE_REGISTER_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function registerRequest({ fullName, email, password, registrationCode }) {
  return {
    type: REGISTER_REQUEST,
    payload: {
      fullName,
      email,
      password,
      registrationCode,
    },
  }
}

export function registerFailure(errors) {
  return {
    type: REGISTER_REQUEST_FAILURE,
    errors,
  }
}

export function registerSuccess(userData) {
  return {
    type: REGISTER_REQUEST_SUCCESS,
    payload: {
      userData,
    },
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_REGISTER_FIELD_ERROR,
    payload: {
      field,
      error,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_REGISTER_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_REGISTER_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

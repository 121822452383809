import buildService from 'common/utils/build-service'
import { GET } from 'common/requests'

export const modulesService = buildService({
  type: GET,
  constantsPrefix: 'MODULES',
  isArray: true,
  http: {
    createApiUrlSuffix: () => `/modules`,
  },
})

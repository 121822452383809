import buildService from 'common/utils/build-service'
import { GET } from 'common/requests'

export default buildService({
  type: GET,
  constantsPrefix: 'GRADES',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/grades?select=id,name',
  },
})

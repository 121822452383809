export const MOOD_METER_COLORS = {
  BLUE: '#1F477A',
  GREEN: '#5E8542',
  YELLOW: '#FFC552',
  RED: '#EE5F44',
}

export const computeQuadrantColorName = (pleasantness, energy) => {
  if (pleasantness === 0) {
    return energy > 0 ? 'yellow' : 'green'
  }
  if (energy === 0) {
    return pleasantness > 0 ? 'yellow' : 'red'
  }
  if (pleasantness > 0) {
    return energy > 0 ? 'yellow' : 'green'
  }
  return energy > 0 ? 'red' : 'blue'
}

export const computeQuadrantColor = (pleasantness, energy) => {
  const colorName = computeQuadrantColorName(pleasantness, energy).toUpperCase()
  return MOOD_METER_COLORS[colorName]
}

export const SUBMIT_MOOD_METER_INGROUP_PLOT = 'SUBMIT_MOOD_METER_INGROUP_PLOT'
export const CHANGE_MOOD_METER_INGROUP_FORM_FIELD = 'CHANGE_MOOD_METER_INGROUP_FORM_FIELD'
export const ADD_MOOD_METER_INGROUP_FIELDS_ERRORS = 'ADD_MOOD_METER_INGROUP_FIELDS_ERRORS'
export const RESET_MOOD_METER_INGROUP_FORM_DATA = 'RESET_MOOD_METER_INGROUP_FORM_DATA'
export const CLEAR_MOOD_METER_INGROUP_FIELD_ERROR = 'CLEAR_MOOD_METER_INGROUP_FIELD_ERROR'

export function changeMoodMeterInGroupFormField(key, value) {
  return {
    type: CHANGE_MOOD_METER_INGROUP_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function resetMoodMeterInGroupFormData() {
  return {
    type: RESET_MOOD_METER_INGROUP_FORM_DATA,
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_MOOD_METER_INGROUP_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError(field) {
  return {
    type: CLEAR_MOOD_METER_INGROUP_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

import _ from 'lodash'
import Cookie from 'js-cookie'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import lang from 'lang'
import { httpRequestWithoutRedirectionOnFailure } from 'services/saga'
import { getApiUrl } from 'services/selectors'
import { validateField, validateFields } from 'common/validator'
import { requests } from 'common/requests'
import { loginSaga } from 'common/services/login/saga'

import {
  ACTIVATION_REQUEST,
  activationFailure,
  activationSuccess,
  addFieldError,
  clearFieldError,
  VALIDATE_ACTIVATION_FIELD,
} from './actions'

function* activationSaga({ payload }) {
  if (payload.password.value !== payload.password2.value) {
    return yield put(activationFailure({ base: 'Passwords mismatch!' }))
  }

  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const apiURL = yield select(getApiUrl)
    try {
      const { data } = yield call(httpRequestWithoutRedirectionOnFailure, {
        req: requests.post,
        url: `${apiURL}/rpc/activate_account`,
        data: _.mapValues(_.omit(payload, 'token'), 'value'),
        headers: {
          Authorization: `Bearer ${payload.token.value}`,
          Prefer: 'params=single-object',
        },
      })
      Cookie.set('ruler-jwt', data.token)
      yield put(activationSuccess(data))
      yield call(loginSaga, {
        payload: {
          email: { value: data.me.email },
          password: { value: payload.password.value },
          rememberMe: { value: true },
        },
      })
    } catch (error) {
      yield put(
        activationFailure({
          base: _.get(error, 'response.data.message', lang.unhandledServerError),
        }),
      )
    }
  } else {
    yield put(activationFailure(errors))
  }
}

export function* authenticateUser({ payload: { jwt, res } }) {
  const apiURL = yield select(getApiUrl)
  try {
    const { data } = yield call(httpRequestWithoutRedirectionOnFailure, {
      req: requests.post,
      url: `${apiURL}/rpc/me`,
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    })
    // Activation cookie is valid and the user has already been activated => redirect to login
    if (data.status !== 'disabled') {
      res.writeHead(302, { Location: '/login' })
      res.end()
    }
  } catch (err) {
    // Invalid cookie, we won't do anything for the user
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeLatest(ACTIVATION_REQUEST, activationSaga),
    takeEvery(VALIDATE_ACTIVATION_FIELD, validateFieldSaga),
  ])
}

import _ from 'lodash'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { deleteUserService, moveUserService } from 'common/services/users'
import { changeModal } from 'services/actions'
import { getDistrictUsersService } from 'page-modules/dashboard/district-details/service'
import { getSchoolUsersService } from 'page-modules/dashboard/school-details/service'
import { getYceiUsersService } from 'page-modules/dashboard/ycei-members/service'
import personTypesService from 'services/person-types'
import { validateField, validateFields } from 'common/validator'
import { defaultFormData } from 'page-modules/dashboard/users/service/reducer'
import { labels, roles } from 'common/utils/determine-user'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  DELETE_USER_FROM_DISTRICTS_PAGE,
  DELETE_USER_FROM_SCHOOLS_PAGE,
  DELETE_USER_FROM_YCEI_PAGE,
  MOVE_USER_FROM_DISTRICTS_PAGE,
  MOVE_USER_FROM_SCHOOLS_PAGE,
  resetMoveUserFormData,
  VALIDATE_MOVE_USER_FORM_FIELD,
} from './actions'

function* deleteUserFromSchoolsPageSaga({ payload: { id } }) {
  yield call(deleteUserService.requestSaga, {
    payload: {
      urlParams: { id },
    },
  })
  yield put(getSchoolUsersService.actions.removeItem({ id }))
}

function* deleteUserFromDistrictsPageSaga({ payload: { id } }) {
  yield call(deleteUserService.requestSaga, {
    payload: {
      urlParams: { id },
    },
  })
  yield put(getDistrictUsersService.actions.removeItem({ id }))
}

function* deleteUserFromYceiPageSaga({ payload: { id } }) {
  yield call(deleteUserService.requestSaga, {
    payload: {
      urlParams: { id },
    },
  })
  yield put(getYceiUsersService.actions.removeItem({ id }))
}

function* getPersonTypeByScope(name, scope) {
  const personTypes = yield call(personTypesService.requestSaga, {
    payload: {
      urlParams: { scope },
    },
  })
  return _.find(personTypes, { name })
}

// A district user is being transformed to school user
function* prepareDistrictToSchoolRoleSwitch(districtId, schoolId) {
  const userData = { district_id: districtId.value }

  if (schoolId.value && schoolId.value !== defaultFormData().schoolId) {
    const defaultPersonType = yield getPersonTypeByScope(labels.staff, 'school')
    userData.school_id = schoolId.value
    userData.role = roles.school.member
    userData.person_type_id = defaultPersonType.id
  }

  return userData
}

// A school user is being transformed to district user
function* prepareSchoolToDistrictRoleSwitch(districtId, schoolId, initialDistrictId) {
  const userData = {}
  if (districtId.value !== initialDistrictId.value) {
    userData.district_id = districtId.value
  }
  if (schoolId.value && schoolId.value !== defaultFormData().schoolId) {
    userData.school_id = schoolId.value
  } else {
    const defaultPersonType = yield getPersonTypeByScope(labels.staff, 'district')
    userData.role = roles.district.member
    userData.person_type_id = defaultPersonType.id
  }

  return userData
}

function* moveUserFromDistrictsPageSaga({
  payload: { id, districtId, schoolId, initialDistrictId },
}) {
  // Front validations
  const errors = validateFields({ districtId, schoolId })
  if (!_.isEmpty(errors)) {
    return yield put(addFieldsErrors(errors))
  }

  const userData = yield prepareDistrictToSchoolRoleSwitch(districtId, schoolId)
  // Update district
  const response = yield call(moveUserService.requestSaga, {
    payload: {
      data: userData,
      urlParams: { id: id.value },
    },
  })
  if (response.error) return

  // Close modal
  yield put(changeModal({ isOpen: false }))
  yield put(resetMoveUserFormData())

  // Refresh districts in background
  if (initialDistrictId) {
    yield put(
      getDistrictUsersService.actions.request({
        urlParams: { districtId: initialDistrictId.value },
      }),
    )
  }
}

function* moveUserFromSchoolsPageSaga({
  payload: { id, districtId, schoolId, initialDistrictId, initialSchoolId },
}) {
  // Front validations
  const errors = validateFields({ districtId, schoolId })
  if (!_.isEmpty(errors)) {
    return yield put(addFieldsErrors(errors))
  }

  const userData = yield prepareSchoolToDistrictRoleSwitch(districtId, schoolId, initialDistrictId)
  // Update school and district
  const response = yield call(moveUserService.requestSaga, {
    payload: {
      data: userData,
      urlParams: { id: id.value },
    },
  })

  if (response.error) return

  // Close modal
  yield put(changeModal({ isOpen: false }))
  yield put(resetMoveUserFormData())

  // Refresh schools in background
  if (initialSchoolId) {
    yield put(
      getSchoolUsersService.actions.request({
        urlParams: { schoolId: initialSchoolId.value },
      }),
    )
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeLatest(DELETE_USER_FROM_SCHOOLS_PAGE, deleteUserFromSchoolsPageSaga),
    takeLatest(DELETE_USER_FROM_DISTRICTS_PAGE, deleteUserFromDistrictsPageSaga),
    takeLatest(DELETE_USER_FROM_YCEI_PAGE, deleteUserFromYceiPageSaga),
    takeLatest(MOVE_USER_FROM_DISTRICTS_PAGE, moveUserFromDistrictsPageSaga),
    takeLatest(MOVE_USER_FROM_SCHOOLS_PAGE, moveUserFromSchoolsPageSaga),
    takeEvery(VALIDATE_MOVE_USER_FORM_FIELD, validateFieldSaga),
  ])
}

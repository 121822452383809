import _ from 'lodash'
import { put } from 'redux-saga/effects'

import lang from 'lang'
import buildService from 'common/utils/build-service'
import { addFieldError } from 'page-modules/settings/password/service/actions'
import { changeModal } from 'services/actions'

export const changePasswordService = buildService({
  type: 'POST',
  constantsPrefix: 'CHANGE_PASSWORD',
  http: {
    createApiUrlSuffix: () => '/rpc/change_password',
    headers: {
      Accept: 'application/vnd.pgrst.object+json',
    },
    *successHandler() {
      yield put(changeModal({ isOpen: false }))
    },
    *failureHandler(error) {
      const message = _.get(error, 'response.data.message', lang.unhandledServerError)
      yield put(addFieldError({ field: 'base', error: message }))
    },
  },
})

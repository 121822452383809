import buildService from 'common/utils/build-service'

export type ShortUrlDecoded = {
  id: string
  name: string
  short_url: string
  audience_type_id: string
  collected_moods: number
  start_time: string
  duration: string
  active_during: string[]
  owner_id: string
  closed: boolean
  created_at: string
  can_reopen: boolean
  owner_name: string
  owner_school_id: string | null
  shared_in: string
}

// Pass short_code as the POST data
export const getMoodMeterGroupByShortCodeService = buildService<
  { short_code: string },
  ShortUrlDecoded
>({
  type: 'POST',
  constantsPrefix: 'MOOD_METER_GROUP_BY_SHORT_CODE',
  http: {
    createApiUrlSuffix: () => `/rpc/short_url_decode`,
  },
  suppressProgress: true,
})

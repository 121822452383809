import { all, call, put, takeLatest } from 'redux-saga/effects'

import { changeModal } from 'services/actions'

import countries, { patchCountryService } from 'services/countries'
import { ToggleCountryStatusPayload, TOGGLE_COUNTRY_STATUS } from './actions'

export function* toggleCountryStatusSaga({
  payload: { code, status },
}: {
  payload: ToggleCountryStatusPayload
}) {
  // @ts-expect-error no saga types
  const countryResult = yield call(patchCountryService.requestSaga, {
    payload: {
      data: { code, status },
      urlParams: {
        code,
      },
    },
  })
  if (countryResult.error) {
    // handle error
  } else {
    // @ts-expect-error no saga types
    yield call(countries.requestSaga, { payload: { urlParams: { enabled: false } } })

    yield put(
      changeModal({
        isOpen: false,
      }),
    )
  }
}

export default function* saga() {
  // @ts-expect-error no saga types
  yield all([takeLatest(TOGGLE_COUNTRY_STATUS, toggleCountryStatusSaga)])
}

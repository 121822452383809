import buildService, { NoParams } from 'common/utils/build-service'
import { GET, PATCH, POST } from 'common/requests'
import { State, UUID } from 'common/types'

export default buildService<{ countryId?: UUID }, State>({
  type: GET,
  constantsPrefix: 'STATES',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ countryId }) => {
      if (countryId) {
        return `/states?select=id,abbr,name&order=name.asc&country=eq.${countryId}`
      }
      return '/states?select=id,abbr,name&order=name.asc'
    },
  },
})

export const postStateService = buildService<NoParams, State>({
  type: POST,
  constantsPrefix: 'CREATE_STATE',
  http: {
    createApiUrlSuffix: () => '/states',
    headers: {
      Prefer: 'params=single-object',
      // Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const patchStateService = buildService<{ id?: UUID }, State>({
  type: PATCH,
  constantsPrefix: 'STATE',
  http: {
    createApiUrlSuffix: ({ id }) => `/states?id=eq.${id}`,
  },
})

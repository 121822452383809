export const CHANGE_JOURNAL_FORM_FIELD = 'CHANGE_JOURNAL_FORM_FIELD'
export const SUBMIT_JOURNAL = 'SUBMIT_JOURNAL'
export const VALIDATE_JOURNAL_FIELD = 'VALIDATE_JOURNAL_FIELD'
export const ADD_JOURNAL_FIELD_ERROR = 'ADD_JOURNAL_FIELD_ERROR'
export const ADD_JOURNAL_FIELDS_ERRORS = 'ADD_JOURNAL_FIELDS_ERRORS'
export const CLEAR_JOURNAL_FIELD_ERROR = 'CLEAR_JOURNAL_FIELD_ERROR'
export const RESET_JOURNAL_FORM_DATA = 'RESET_JOURNAL_FORM_DATA'
export const PREDEFINE_JOURNAL_FORM_DATA = 'PREDEFINE_JOURNAL_FORM_DATA'
export const LOAD_JOURNAL_DETAILS_PAGE_DATA = 'LOAD_JOURNAL_DETAILS_PAGE_DATA'

export function changeJournalFormField(key, value) {
  return {
    type: CHANGE_JOURNAL_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}
export function submitJournal({ id, name, content, currentUserId }) {
  return {
    type: SUBMIT_JOURNAL,
    payload: {
      id,
      name,
      content,
      currentUserId,
    },
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_JOURNAL_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_JOURNAL_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_JOURNAL_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetJournalFormData() {
  return {
    type: RESET_JOURNAL_FORM_DATA,
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_JOURNAL_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

export function predefineJournalForm({ id, name, content }) {
  return {
    type: PREDEFINE_JOURNAL_FORM_DATA,
    payload: {
      id,
      name,
      content,
    },
  }
}

export function loadJournalPageData(payload) {
  return {
    type: LOAD_JOURNAL_DETAILS_PAGE_DATA,
    payload,
  }
}

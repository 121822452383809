import _ from 'lodash'

const trimData = <T>(data: T): T => {
  if (typeof data === 'string') {
    // @ts-expect-error improve types
    return _.trim(data)
  }
  if (Array.isArray(data)) {
    // @ts-expect-error improve types
    return _.map(data, trimData)
  }
  // @ts-expect-error improve types
  return _.isObject(data) ? _.mapValues(data, trimData) : data
}

export default trimData

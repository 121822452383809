import _ from 'lodash'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import lang from 'lang'
import { translateResponseError } from 'common/utils'
import { validateField, validateFields } from 'common/validator'
import { changeModal } from 'services/actions'
import subjectsService, {
  deleteSubjectService,
  patchSubjectService,
  postSubjectService,
} from 'services/subjects'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  DELETE_SUBJECT,
  resetSubjectForm,
  SUBMIT_SUBJECT,
  VALIDATE_SUBJECT_FIELD,
} from './actions'

const prepareData = (payload) => {
  const data = {
    name: payload.name.value,
  }

  if (payload.id && payload.id.value) {
    data.id = payload.id.value
  }

  return data
}

export function* submitSubjectSaga({ payload }) {
  const errors = validateFields(payload)
  if (_.isEmpty(errors)) {
    const data = prepareData(payload)
    let subjectResult
    if (data.id) {
      subjectResult = yield call(patchSubjectService.requestSaga, {
        payload: {
          data,
          urlParams: { id: data.id },
        },
      })
    } else {
      subjectResult = yield call(postSubjectService.requestSaga, {
        payload: {
          data,
        },
      })
    }
    if (subjectResult.error) {
      const status = _.get(subjectResult, 'error.response.status')
      if (status === 409) {
        yield put(
          addFieldError({
            field: 'base',
            error: lang.page.administration.subjects.subjectForm.nameAlreadyExistsError,
          }),
        )
      } else {
        yield put(
          addFieldError({
            field: 'base',
            error: translateResponseError(subjectResult.error),
          }),
        )
      }
    } else {
      yield put(
        changeModal({
          isOpen: false,
        }),
      )
      yield put(resetSubjectForm())
      yield put(subjectsService.actions.request())
    }
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export function* deleteSubjectSaga({ payload: { subjectId } }) {
  const result = yield call(deleteSubjectService.requestSaga, {
    payload: { urlParams: { id: subjectId } },
  })

  if (!result.error) {
    yield put(
      changeModal({
        isOpen: false,
      }),
    )
    yield put(subjectsService.actions.request())
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_SUBJECT_FIELD, validateFieldSaga),
    takeLatest(SUBMIT_SUBJECT, submitSubjectSaga),
    takeLatest(DELETE_SUBJECT, deleteSubjectSaga),
  ])
}

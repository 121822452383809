import React from 'react'
import PropTypes from 'prop-types'
import NextHead from 'next/head'
import { withRouter } from 'next/router'

import lang from 'lang'

@withRouter
export default class Head extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  }

  render() {
    return (
      <NextHead>
        <meta charSet="UTF-8" />
        <title>
          {`${this.props.title} | ` || ''}
          {lang.rulerAcronym}
        </title>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta className="foundation-mq" />
        <meta
          property="og:title"
          content={`${this.props.title} | ${lang.rulerAcronym}` || lang.rulerAcronym}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <link
          rel="icon"
          href="https://s3.amazonaws.com/ruleronlineimgs/media/cropped-ruler-favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          href="https://s3.amazonaws.com/ruleronlineimgs/media/cropped-ruler-favicon-192x192.png"
          sizes="192x192"
        />
        <link
          rel="apple-touch-icon-precomposed"
          href="https://s3.amazonaws.com/ruleronlineimgs/media/cropped-ruler-favicon-180x180.png"
        />
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.5/css/select2.min.css"
          rel="stylesheet"
          integrity="sha256-xJOZHfpxLR/uhh1BwYFS5fhmOAdIRQaiOul5F/b7v3s="
          crossOrigin="anonymous"
        />
        <link rel="stylesheet" type="text/css" href="/static/nprogress.css" />
        <meta
          name="msapplication-TileImage"
          content="https://s3.amazonaws.com/ruleronlineimgs/media/cropped-ruler-favicon-270x270.png"
        />
        {this.props.children}
      </NextHead>
    )
  }
}

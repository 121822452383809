import { PERSIST_HTML_CONTENT, persistHtmlContent, requestHtmlContent } from './actions'

export const initialState = () => ({} as Record<string, string>)

type HtmlContentAction =
  | ReturnType<typeof requestHtmlContent>
  | ReturnType<typeof persistHtmlContent>

export default function htmlContentReducer(state = initialState(), action: HtmlContentAction) {
  switch (action.type) {
    case PERSIST_HTML_CONTENT:
      return {
        ...state,
        [action.payload.key]: action.payload.content,
      }

    default:
      return state
  }
}

import _ from 'lodash'
import React from 'react'
import Parser, { domToReact } from 'html-react-parser'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { requestHtmlContent } from 'common/services/html-content/actions'
import actionLinks from 'common/action-links'
import { replaceMediaLinksInContent } from 'common/utils'

import ContentLoader from './svg-text-loader'

@connect(
  (state) => ({
    htmlContentStore: state.htmlContent,
  }),
  (dispatch) => ({
    requestHtmlContent: (payload) => dispatch(requestHtmlContent(payload)),
  }),
)
export default class ExternalHtml extends React.Component {
  static propTypes = {
    slug: PropTypes.string.isRequired,
    urlParams: PropTypes.object,
    htmlContentStore: PropTypes.object,
    requestHtmlContent: PropTypes.func,
    replaceLinkContent: PropTypes.func,
    hideLoader: PropTypes.bool,
    skipEmpty: PropTypes.bool,
    skipParagraphs: PropTypes.bool,
  }

  static defaultProps = {
    hideLoader: false,
    skipEmpty: false,
    skipParagraphs: false,
    replaceLinkContent: undefined,
  }

  componentDidMount() {
    const { slug } = this.props
    this.props.requestHtmlContent({ keys: [slug] })
  }

  get pureHtml() {
    return replaceMediaLinksInContent(_.get(this.props.htmlContentStore, this.props.slug) || '')
  }

  renderNoParagraphs() {
    return Parser(this.pureHtml, {
      replace: ({ name, children }) => {
        if (name === 'p') {
          return <>{domToReact(children)}</>
        }
      },
    })
  }

  renderCustomLinks() {
    return Parser(this.pureHtml, {
      replace: ({ name, attribs, children }) => {
        if (name === 'a') {
          return this.props.replaceLinkContent({
            attribs,
            contents: domToReact(children),
          })
        }
      },
    })
  }

  renderActionLinks() {
    const { urlParams = {} } = this.props
    return Parser(this.pureHtml, {
      replace: ({ name, attribs, children }) => {
        if (name === 'a') {
          const link = _.find(actionLinks, { action: attribs.href })
          return link && <a href={link.url(urlParams)}>{domToReact(children)}</a>
        }
      },
    })
  }

  renderContents() {
    // Replace <p> on the top level
    if (this.props.skipParagraphs) {
      return this.renderNoParagraphs()
    }

    // Replace links with custom components
    if (this.props.replaceLinkContent) {
      return this.renderCustomLinks()
    }

    // Replace href in action links
    return this.renderActionLinks()
  }

  render() {
    const { slug, hideLoader, skipEmpty } = this.props
    if (!this.pureHtml) {
      if (skipEmpty) {
        return null
      }
      return <data value={slug}>{!hideLoader ? <ContentLoader /> : null}</data>
    }

    return <data value={slug}>{this.renderContents()}</data>
  }
}

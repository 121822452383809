import buildService from 'common/utils/build-service'
import { DELETE, GET, PATCH, POST } from 'common/requests'

export default buildService({
  type: GET,
  constantsPrefix: 'EMOTION_WORDS',
  isArray: true,
  http: {
    createApiUrlSuffix: (urlParams) => {
      if (urlParams.onlyMoodMeter) {
        return '/emotion_words?order=name.asc&mood_meter=eq.true'
      }
      if (urlParams.onlyCharter) {
        return '/emotion_words?order=name.asc&charter=eq.true'
      }
      return `/emotion_words?order=name.asc`
    },
  },
})

export const postEmotionWordService = buildService({
  type: POST,
  constantsPrefix: 'EMOTION_WORD',
  http: {
    createApiUrlSuffix: () => {
      return `/emotion_words`
    },
  },
})

export const deleteEmotionWordService = buildService({
  type: DELETE,
  constantsPrefix: 'EMOTION_WORD',
  http: {
    createApiUrlSuffix: ({ id }) => {
      return `/emotion_words?id=eq.${id}`
    },
  },
})

export const patchEmotionWordService = buildService({
  type: PATCH,
  constantsPrefix: 'EMOTION_WORD',
  http: {
    createApiUrlSuffix: ({ id }) => `/emotion_words?id=eq.${id}`,
  },
})

import _ from 'lodash'
import validator from 'validator'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { roles } from 'common/utils/determine-user'
import { postUsersService, userExistenceService } from 'services/users'
import { getSchoolUsersService } from 'page-modules/dashboard/school-details/service'
import lang from 'lang'

import {
  addCsvUploadError,
  resetUploadedUsers,
  SUBMIT_UPLOADED_USERS,
  updateCsvUsers,
  UPLOAD_CSV_USERS,
  VALIDATE_USERS,
  validateUsers,
  validationTypes,
} from './actions'

const allowedRoles = [roles.school.member, roles.school.implementation]

function constructUserData({ users, school }) {
  return _.map(users, ({ name, email, role, personTypeId }) => ({
    name,
    email,
    district_id: school.district.id,
    school_id: school.id,
    role: _.includes(allowedRoles, role) ? role : roles.school.member,
    person_type_id: personTypeId,
  }))
}

function* submitUsersFromCsvSaga({ payload: { users } }) {
  if (_.isEmpty(users)) {
    yield put(addCsvUploadError(lang.dashboard.userForm.uploadCsv.noUsersSelectedError))
    return
  }

  const school = yield select((state) => state.school.details.item)

  // Create all users at once
  const userResponse = yield call(postUsersService.requestSaga, {
    payload: {
      data: constructUserData({ users, school }),
    },
  })

  // Handle errors
  if (userResponse.error) {
    const status = _.get(userResponse, 'error.response.status')
    const error =
      status === 409 ? lang.dashboard.userForm.uploadCsv.duplicateError : lang.unhandledServerError
    yield put(addCsvUploadError(error))
  } else {
    yield put(resetUploadedUsers())
    yield put(
      getSchoolUsersService.actions.request({
        urlParams: { schoolId: school.id },
      }),
    )
  }
}

function* uploadCsvUsersSaga({ payload: { users } }) {
  yield put(validateUsers(users))
}

function* confirmEmailExistence({ emails }) {
  // Don't start XHR if no emails are provided
  // This call will return a response with empty result anyway
  if (_.isEmpty(emails)) {
    return []
  }
  const userResponse = yield call(userExistenceService.requestSaga, {
    payload: { data: { emails } },
  })
  if (userResponse.error) {
    return []
  }
  return _.map(_.filter(userResponse, 'exists'), 'email')
}

function applyUserError(user, error) {
  return {
    ...user,
    selected: false,
    errors: [...user.errors, error],
  }
}

function* validateUsersSaga({ payload }) {
  // Send emails to backend for validation of existence
  const existingEmails = yield confirmEmailExistence({ emails: _.map(payload, 'email') })

  const users = _.map(payload, (user, index) => {
    // Check if name is not empty
    if (_.isEmpty(user.name)) {
      user = applyUserError(user, {
        row: index,
        type: validationTypes.badName,
        message: lang.validator.nonEmpty,
      })
    }
    // Check if email is valid format
    if (_.isEmpty(user.email) || !validator.isEmail(user.email)) {
      user = applyUserError(user, {
        row: index,
        type: validationTypes.badEmail,
        message: lang.validator.email,
      })
    }
    // Check if email exists
    if (_.includes(existingEmails, user.email)) {
      user = applyUserError(user, {
        row: index,
        type: validationTypes.existingEmail,
        message: lang.validator.existingEmail,
      })
    }
    return user
  })

  yield put(updateCsvUsers({ users }))
}

export default function* saga() {
  yield all([
    takeLatest(VALIDATE_USERS, validateUsersSaga),
    takeLatest(UPLOAD_CSV_USERS, uploadCsvUsersSaga),
    takeLatest(SUBMIT_UPLOADED_USERS, submitUsersFromCsvSaga),
  ])
}

import buildService, { NoParams } from 'common/utils/build-service'
import { GET, PATCH, POST } from 'common/requests'
import { City, UUID } from 'common/types'

export default buildService<{ stateId: string }, City>({
  type: GET,
  constantsPrefix: 'CITIES',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ stateId }) => {
      if (stateId) {
        return `/cities?state_id=eq.${stateId}&select=id,name,county`
      }
      return `/cities?select=id,name,county`
    },
  },
})

export const postCityService = buildService<NoParams, City>({
  type: POST,
  constantsPrefix: 'CREATE_CITY',
  http: {
    createApiUrlSuffix: () => '/cities',
    headers: {
      Prefer: 'params=single-object',
      // Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const patchCityService = buildService<{ id?: UUID }, City>({
  type: PATCH,
  constantsPrefix: 'CITY',
  http: {
    createApiUrlSuffix: ({ id }) => `/cities?id=eq.${id}`,
  },
})

import _ from 'lodash'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import statesService from 'services/states'
import districtsService from 'services/districts'
import countriesService from 'services/countries'

import { initialState } from './reducer'
import {
  CHANGE_DISTRICTS_COUNTRY_FILTER,
  CHANGE_DISTRICTS_PAGE,
  CHANGE_DISTRICTS_STATE_FILTER,
  changeDistrictsFilters,
  LOAD_DISTRICTS,
  LOAD_DISTRICTS_PAGE,
} from './actions'

export const defaultItemsPerPage = 20

function* loadDistricts() {
  const { countryCode, stateId, page } = yield select((state) => state.districtsFilters)
  const from = page * defaultItemsPerPage

  yield put(
    districtsService.actions.request({
      range: { from, to: from + defaultItemsPerPage - 1 },
      urlParams: { countryCode, stateId },
    }),
  )
}

function* changeDistrictsCountryFilterSaga({ payload }) {
  yield put(statesService.actions.request({ urlParams: { countryId: payload } }))
  const initialFields = initialState()
  yield put(changeDistrictsFilters(_.pick(initialFields, ['page', 'stateId'])))
  yield call(loadDistricts)
}

function* changeDistrictsStateFilterSaga() {
  const initialFields = initialState()
  yield put(changeDistrictsFilters(_.pick(initialFields, ['page'])))
  yield call(loadDistricts)
}

function* loadDistrictsPageSaga({ payload: { req, range } }) {
  yield put(districtsService.actions.requestSuccess([]))
  yield all([
    call(districtsService.requestSaga, {
      payload: {
        req,
        range,
      },
    }),
    call(countriesService.requestSaga, {
      payload: {
        req,
      },
    }),
  ])
}

export default function* saga() {
  yield all([
    takeEvery(CHANGE_DISTRICTS_COUNTRY_FILTER, changeDistrictsCountryFilterSaga),
    takeEvery(CHANGE_DISTRICTS_STATE_FILTER, changeDistrictsStateFilterSaga),
    takeEvery(CHANGE_DISTRICTS_PAGE, loadDistricts),
    takeEvery(LOAD_DISTRICTS, loadDistricts),
    takeEvery(LOAD_DISTRICTS_PAGE, loadDistrictsPageSaga),
  ])
}

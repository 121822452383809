export const SUBMIT_GROUP_MOOD_METER_PLOT = 'SUBMIT_GROUP_MOOD_METER_PLOT'
export const SUBMIT_PERSONAL_MOOD_METER_PLOT = 'SUBMIT_PERSONAL_MOOD_METER_PLOT'
export const CHANGE_MOOD_METER_FORM_FIELD = 'CHANGE_MOOD_METER_FORM_FIELD'
export const ADD_MOOD_METER_FIELDS_ERRORS = 'ADD_MOOD_METER_FIELDS_ERRORS'
export const RESET_MOOD_METER_FORM_DATA = 'RESET_MOOD_METER_FORM_DATA'
export const CLEAR_MOOD_METER_FIELD_ERROR = 'CLEAR_MOOD_METER_FIELD_ERROR'

export const START_MOOD_METER_GROUP_PLOT = 'START_MOOD_METER_GROUP_PLOT'
export const CHANGE_MOOD_METER_GROUP_FORM_FIELD = 'CHANGE_MOOD_METER_GROUP_FORM_FIELD'
export const ADD_MOOD_METER_GROUP_FORM_FIELDS_ERRORS = 'ADD_MOOD_METER_GROUP_FORM_FIELDS_ERRORS'
export const RESET_MOOD_METER_GROUP_FORM_DATA = 'RESET_MOOD_METER_GROUP_FORM_DATA'
export const CLEAR_MOOD_METER_GROUP_FORM_FIELD_ERROR = 'CLEAR_MOOD_METER_GROUP_FIELD_ERROR'
export const VALIDATE_MOOD_METER_GROUP_FORM_FIELD = 'VALIDATE_MOOD_METER_GROUP_FORM_FIELD'
export const ADD_MOOD_METER_GROUP_FORM_FIELD_ERROR = 'ADD_MOOD_METER_GROUP_FORM_FIELD_ERROR'
export const PREDEFINE_MOOD_METER_GROUP_FORM_DATA = 'PREDEFINE_MOOD_METER_GROUP_FORM_DATA'

export const LOAD_MOOD_METER_GROUP_PAGE = 'LOAD_MOOD_METER_GROUP_PAGE'

export function changeMoodMeterGroupFormField(key, value) {
  return {
    type: CHANGE_MOOD_METER_GROUP_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function resetMoodMeterGroupFormData() {
  return {
    type: RESET_MOOD_METER_GROUP_FORM_DATA,
  }
}

export function addGroupFormFieldError({ field, error }) {
  return {
    type: ADD_MOOD_METER_GROUP_FORM_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addGroupFormFieldsErrors(errors) {
  return {
    type: ADD_MOOD_METER_GROUP_FORM_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearGroupFormFieldError({ field }) {
  return {
    type: CLEAR_MOOD_METER_GROUP_FORM_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function startMoodMeterGroupPlot(payload) {
  return {
    type: START_MOOD_METER_GROUP_PLOT,
    payload,
  }
}

export function validateMoodMeterGroupFormField({ field, value, validations }) {
  return {
    type: VALIDATE_MOOD_METER_GROUP_FORM_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

export function changeMoodMeterFormField(key, value) {
  return {
    type: CHANGE_MOOD_METER_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function predefineMoodMeterGroupFormData(payload) {
  return {
    type: PREDEFINE_MOOD_METER_GROUP_FORM_DATA,
    payload,
  }
}

export function resetMoodMeterFormData() {
  return {
    type: RESET_MOOD_METER_FORM_DATA,
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_MOOD_METER_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError(field) {
  return {
    type: CLEAR_MOOD_METER_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function submitGroupMoodMeterPlot(groupId) {
  return {
    type: SUBMIT_GROUP_MOOD_METER_PLOT,
    payload: {
      groupId,
    },
  }
}

export function submitPersonalMoodMeterPlot(groupId) {
  return {
    type: SUBMIT_PERSONAL_MOOD_METER_PLOT,
    payload: {
      groupId,
    },
  }
}

export function loadMoodMeterGroupPage({ req, shortCode }) {
  return {
    type: LOAD_MOOD_METER_GROUP_PAGE,
    payload: {
      req,
      shortCode,
    },
  }
}

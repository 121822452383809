export const MARK_STREAM_AS_READ = 'MARK_STREAM_AS_READ'

export function markStreamAsRead(data) {
  return {
    type: MARK_STREAM_AS_READ,
    payload: {
      data,
    },
  }
}

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import lang from 'lang'
import { addMoreForms } from 'page-modules/dashboard/school-details/service/actions'
import SchoolUsersForm from 'page-modules/dashboard/school-details/users-form/form'

@connect(
  (state) => ({
    schoolUserFormData: state.school.addSchoolUser.form,
  }),
  (dispatch) => ({
    addMoreForms: () => dispatch(addMoreForms()),
  }),
)
class SchoolUserMultipleForms extends React.Component {
  static propTypes = {
    schoolUserFormData: PropTypes.object,
    addMoreForms: PropTypes.func,
  }

  render() {
    return (
      <>
        {_.map(this.props.schoolUserFormData, (value, key) => (
          <SchoolUsersForm key={`user_${key}`} formId={key} />
        ))}
        <button type="button" className="button clear" onClick={() => this.props.addMoreForms()}>
          {lang.dashboard.userForm.addMoreForms}
        </button>
      </>
    )
  }
}

export default SchoolUserMultipleForms

import _ from 'lodash'

import {
  ADD_CHARTER_SURVEY_FIELD_ERROR,
  ADD_CHARTER_SURVEY_FIELDS_ERRORS,
  CHANGE_CHARTER_SURVEY_FORM_FIELD,
  CLEAR_CHARTER_SURVEY_FIELD_ERROR,
  PREDEFINE_CHARTER_SURVEY_FORM_DATA,
  RESET_CHARTER_SURVEY_FORM_DATA,
} from './actions'

const defaultFormData = (predefined = {}) =>
  _.merge({ emotionWords: [], actions: [] }, _.pickBy(predefined, _.identity))

const initialState = () => ({
  data: defaultFormData(),
  errors: {},
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_CHARTER_SURVEY_FORM_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.key]: action.payload.value,
        },
      }

    case ADD_CHARTER_SURVEY_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      }

    case ADD_CHARTER_SURVEY_FIELDS_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      }

    case CLEAR_CHARTER_SURVEY_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: undefined,
        },
      }

    case RESET_CHARTER_SURVEY_FORM_DATA:
      return {
        ...state,
        data: defaultFormData(),
        errors: {},
      }

    case PREDEFINE_CHARTER_SURVEY_FORM_DATA:
      return {
        ...state,
        data: defaultFormData(action.payload),
        errors: {},
      }

    default:
      return state
  }
}

export const TOGGLE_STAFF_CHARTER_SURVEY_DETAILS = 'TOGGLE_STAFF_CHARTER_SURVEY_DETAILS'
export const GO_TO_SURVEY_DETAILS = 'GO_TO_SURVEY_DETAILS'

export function toggleStaffCharterSurveyDetails({ surveyId } = {}) {
  return {
    type: TOGGLE_STAFF_CHARTER_SURVEY_DETAILS,
    payload: { surveyId },
  }
}

export function goToSurveyDetails({ surveyId } = {}) {
  return {
    type: GO_TO_SURVEY_DETAILS,
    payload: { surveyId },
  }
}

import _ from 'lodash'
import React from 'react'
import { format } from 'date-fns'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { validateField, validateFields } from 'common/validator'
import { changeModal } from 'services/actions'
import {
  getTotalPeopleService,
  patchUserService,
  postUserService,
  postUsersService,
} from 'services/users'
import { postSchoolService } from 'services/schools'
import {
  getSchoolUsersService,
  schoolDetailsService,
} from 'page-modules/dashboard/school-details/service'
import { predefineSchoolForm } from 'page-modules/dashboard/school-creation/service/actions'
import SchoolFormModal from 'common/components/modals/school-form-modal'
import postBookmarkService from 'common/services/bookmark'
import { defaultFormData } from 'page-modules/dashboard/school-creation/service/reducer'

import {
  addFieldError,
  addFieldsErrors,
  BOOKMARK_SCHOOL,
  clearFieldError,
  EDIT_SCHOOL_DETAILS,
  LOAD_SCHOOL_DETAILS_PAGE,
  resetSchoolUserFormData,
  SUBMIT_SCHOOL_USER,
  TOGGLE_SCHOOL_STATUS,
  VALIDATE_SCHOOL_USER_FIELD,
} from './actions'

export function* submitSchoolUserSaga({ payload }) {
  const errors = _.map(payload.usersData, (data) => ({
    formId: data.formId,
    errors: validateFields(_.omit(data, 'formId')),
  }))

  if (_.some(errors, (e) => !_.isEmpty(e.errors))) {
    yield all(_.map(errors, (e) => put(addFieldsErrors(e.errors, e.formId))))
  } else {
    const data = _.map(
      payload.usersData,
      ({ email, fullName, districtId, roleId, personTypeId, schoolId }) => ({
        email: email.value,
        name: fullName.value,
        district_id: districtId.value,
        role: roleId.value,
        person_type_id: personTypeId.value || null,
        school_id: schoolId.value,
        office_phone: null,
        mobile_phone: null,
      }),
    )
    const userId = _.first(payload.usersData).id.value
    if (userId) {
      const userDataTobeEdited = _.first(data)
      // When updating users there is no need to change their school or district
      delete userDataTobeEdited.school_id
      delete userDataTobeEdited.district_id
      yield put(
        patchUserService.actions.request({
          data: userDataTobeEdited,
          urlParams: {
            id: userId,
          },
        }),
      )
    } else {
      if (payload.usersData.length === 1) {
        const userResponse = yield call(postUserService.requestSaga, {
          payload: {
            data: _.first(data),
          },
        })
        if (userResponse.error) return
      } else {
        const userResponse = yield call(postUsersService.requestSaga, {
          payload: { data },
        })
        if (userResponse.error) return
      }
      yield put(resetSchoolUserFormData())
      yield call(getSchoolUsersService.requestSaga, {
        payload: {
          urlParams: { schoolId: _.first(data).school_id },
        },
      })
      yield put(
        getTotalPeopleService.actions.request({
          data: { scope: 'school', school_id: _.first(data).school_id },
          urlParams: { v_role: '_school_roles_total', v_type: '_all_types_total' },
        }),
      )
    }
  }
}

export function* toggleSchoolStatusSaga({ payload }) {
  const schoolResult = yield call(postSchoolService.requestSaga, {
    payload: {
      data: {
        id: payload.id,
        status: payload.status,
      },
    },
  })
  if (schoolResult.error) return
  yield put(
    changeModal({
      isOpen: false,
    }),
  )
  yield call(schoolDetailsService.requestSaga, {
    payload: { urlParams: { slug: schoolResult.slug } },
  })
}

export function* bookmarkSchoolSaga({ payload: { id, bookmarked, refreshMethod } }) {
  const bookmarkResult = yield call(postBookmarkService.requestSaga, {
    payload: {
      data: {
        what: 'school',
        what_id: id,
        mark_it: bookmarked,
      },
    },
  })
  if (bookmarkResult.error) return

  // Perform refresh of background items
  if (_.isFunction(refreshMethod)) {
    // The passed function is already "dispatched". Wrapping it in a `put` or `call` will fire an additional(duplicate) action.
    refreshMethod()
  }
}

export function* editSchoolDetailsSaga({ payload: { schoolId, submitSchool } }) {
  const school = yield call(schoolDetailsService.requestSaga, {
    payload: { urlParams: { id: schoolId } },
  })

  if (school.error) return

  yield put(
    predefineSchoolForm({
      schoolName: school.name,
      id: school.id,
      schoolDistrict: school.district.id,
      schoolDistrictName: school.district.name,
      offlineTrainings: _.map(school.school_offline_trainings, (training) =>
        _.pick(training, ['offline_training_id', 'training_attended_at']),
      ),
      city: school.city.id,
      country: school.city.state.country.code,
      state: school.city.state.id,
      address: school.location.address,
      address2: school.location.address2,
      postalCode: school.location.postal_code,
      websiteUrl: school.location.website,
      phoneNumber: school.location.phone,
      started_ruler_at: format(new Date(school.started_ruler_at), 'yyyy'),
      started_ruler: school.started_ruler,
      // Some schools have null for total_staff, but we are using total_staff as number in the front end.
      // Use the default value for the field if null(or 0(thx JS :|))
      total_staff: school.total_staff || defaultFormData().total_staff,
      coachName: school.coach_name,
      coachEmail: school.coach_email,
      schoolGrades: _.map(school.grades, (grade) => grade.id),
      phase: school.phase_id,
      logoUrl: school.logo_url,
    }),
  )
  yield put(
    changeModal({
      isOpen: true,
      aria: {
        labelledby: 'add-new-school-header',
      },
      className: 'add-school full',
      content: <SchoolFormModal submitSchool={submitSchool} />,
    }),
  )
}

export function* validateFieldSaga({ payload: { field, value, validations, formId } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error, formId }))
  } else {
    yield put(clearFieldError({ field, formId }))
  }
}

export function* loadSchoolDetailsPageSaga({ payload: { slug, req } }) {
  const school = yield call(schoolDetailsService.requestSaga, {
    payload: { req, urlParams: { slug } },
  })
  yield call(getTotalPeopleService.requestSaga, {
    payload: {
      req,
      data: { scope: 'school', school_id: school.id },
      urlParams: { v_role: '_school_roles_total', v_type: '_all_types_total' },
    },
  })
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_SCHOOL_USER_FIELD, validateFieldSaga),
    takeLatest(SUBMIT_SCHOOL_USER, submitSchoolUserSaga),
    takeLatest(TOGGLE_SCHOOL_STATUS, toggleSchoolStatusSaga),
    takeLatest(BOOKMARK_SCHOOL, bookmarkSchoolSaga),
    takeLatest(EDIT_SCHOOL_DETAILS, editSchoolDetailsSaga),
    takeLatest(LOAD_SCHOOL_DETAILS_PAGE, loadSchoolDetailsPageSaga),
  ])
}

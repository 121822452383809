export const DELETE_USER_FROM_SCHOOLS_PAGE = 'DELETE_USER_FROM_SCHOOLS_PAGE'
export const DELETE_USER_FROM_DISTRICTS_PAGE = 'DELETE_USER_FROM_DISTRICTS_PAGE'
export const DELETE_USER_FROM_YCEI_PAGE = 'DELETE_USER_FROM_YCEI_PAGE'
export const CHANGE_MOVE_USER_FORM_FIELD = 'CHANGE_MOVE_USER_FORM_FIELD'
export const VALIDATE_MOVE_USER_FORM_FIELD = 'VALIDATE_MOVE_USER_FORM_FIELD'
export const MOVE_USER_FROM_DISTRICTS_PAGE = 'MOVE_USER_FROM_DISTRICTS_PAGE'
export const MOVE_USER_FROM_SCHOOLS_PAGE = 'MOVE_USER_FROM_SCHOOLS_PAGE'
export const ADD_MOVE_USER_FIELD_ERROR = 'ADD_MOVE_USER_FIELD_ERROR'
export const ADD_MOVE_USER_FIELDS_ERRORS = 'ADD_MOVE_USER_FIELDS_ERRORS'
export const CLEAR_MOVE_USER_FIELD_ERROR = 'CLEAR_MOVE_USER_FIELD_ERROR'
export const RESET_MOVE_USER_FORM_DATA = 'RESET_MOVE_USER_FORM_DATA'

export function changeMoveUserFormField(key, value) {
  return {
    type: CHANGE_MOVE_USER_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_MOVE_USER_FORM_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

export function resetMoveUserFormData() {
  return {
    type: RESET_MOVE_USER_FORM_DATA,
  }
}

export function moveUserFromDistrictsPage(payload) {
  return {
    type: MOVE_USER_FROM_DISTRICTS_PAGE,
    payload,
  }
}

export function moveUserFromSchoolsPage(payload) {
  return {
    type: MOVE_USER_FROM_SCHOOLS_PAGE,
    payload,
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_MOVE_USER_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_MOVE_USER_FIELD_ERROR,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_MOVE_USER_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function deleteUserFromSchoolsPage(payload) {
  return {
    type: DELETE_USER_FROM_SCHOOLS_PAGE,
    payload,
  }
}

export function deleteUserFromDistrictsPage(payload) {
  return {
    type: DELETE_USER_FROM_DISTRICTS_PAGE,
    payload,
  }
}

export function deleteUserFromYceiPage(payload) {
  return {
    type: DELETE_USER_FROM_YCEI_PAGE,
    payload,
  }
}

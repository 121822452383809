import _ from 'lodash'
import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects'

import {
  initEmotionWords,
  predefineApproveContributionForm,
} from '../approve-contributions-form/service/actions'
import {
  LOAD_STAFF_CHARTER_APPROVAL,
  SUBMIT_STAFF_CHARTER_CONTRIBUTIONS,
  THROTTLED_LOAD_STAFF_CHARTER_REVISION,
} from './actions'

import {
  staffCharterContributeService,
  staffCharterContributionsService,
  staffCharterRevisionService,
} from './index'

function* submitCharterContributionsSaga({ payload }) {
  const data = payload
  const result = yield call(staffCharterContributeService.requestSaga, {
    payload: {
      data,
    },
  })
  if (result.error) return
  put(staffCharterContributionsService.actions.request())
}

function* loadStaffCharterApprovalSaga() {
  const contributions = yield call(staffCharterContributionsService.requestSaga, {})
  const charter = yield call(staffCharterRevisionService.requestSaga, {})

  // Merge the emotion words from the charter and from the contributions.
  // Some imperative ... pleasantness is going on here
  // The end result data should look like:
  //
  // [
  //   {
  //     emotionWord: {
  //       id: '48937882-d392-4988-8d7b-ef49aa79aa85',
  //       ...
  //     },
  //     actions: [
  //       'eat more'
  //     ]
  //   },
  //   {
  //     emotionWord: {
  //       id: '0b3a7035-8318-4823-9bf8-f44b7298edd5',
  //       ...
  //     },
  //     actions: [
  //       'fire in the sky'
  //     ],
  //     totalContributors: 3, // added only in the case the word is coming from a contribution. Not in the case the word comes from staff charter.
  //   },
  //   ...
  // ]

  const mergedEmotionWords = []
  _.each(charter.emotion_words, (emotionWord) => {
    mergedEmotionWords.push({
      emotionWord: _.omit(emotionWord, ['actions']),
      actions: emotionWord.actions || [],
    })
  })

  _.each(contributions.emotion_words, (word) => {
    const existingEmotionWord = _.find(mergedEmotionWords, ['emotionWord.id', word.emotion_word_id])

    if (existingEmotionWord) {
      existingEmotionWord.actions = _.union(
        existingEmotionWord.actions,
        _.flatMap(word.contributions, 'actions'),
      )
      existingEmotionWord.totalContributors = word.total_contributors
    } else {
      mergedEmotionWords.push({
        emotionWord: word.emotion_word,
        actions: _.flatMap(word.contributions, 'actions'),
        totalContributors: word.total_contributors,
      })
    }
  })

  yield put(initEmotionWords(mergedEmotionWords))

  yield put(
    predefineApproveContributionForm({
      schoolId: contributions.school_id,
      approvedEmotionWords: _.map(
        _.filter(mergedEmotionWords, (word) => {
          return !!_.find(charter.emotion_words, { id: word.emotionWord.id })
        }),
        (word) => {
          const actions = [...new Array(word.actions.length)] // creates an array with nulls/undefineds. "Empty array"

          const charterWord = _.find(charter.emotion_words, { id: word.emotionWord.id })
          _.each(charterWord.actions, (action) => {
            const indexInContributions = word.actions.indexOf(action)
            if (indexInContributions >= 0) {
              actions[indexInContributions] = action
            }
          })

          return {
            emotionWordId: word.emotionWord.id,
            actions,
          }
        },
      ),
    }),
  )
}

export default function* saga() {
  yield all([
    takeLatest(SUBMIT_STAFF_CHARTER_CONTRIBUTIONS, submitCharterContributionsSaga),
    takeLatest(LOAD_STAFF_CHARTER_APPROVAL, loadStaffCharterApprovalSaga),
    takeLeading(THROTTLED_LOAD_STAFF_CHARTER_REVISION, staffCharterRevisionService.requestSaga),
  ])
}

import {
  ADD_REGISTER_FIELD_ERROR,
  CHANGE_REGISTER_FORM_FIELD,
  CLEAR_REGISTER_FIELD_ERROR,
  REGISTER_REQUEST,
  REGISTER_REQUEST_FAILURE,
  REGISTER_REQUEST_SUCCESS,
} from './actions'

export const initialState = () => ({
  formData: {
    fullName: '',
    email: '',
    password: '',
    registrationCode: '',
  },
  errors: {},
  loading: false,
})

export default function reducer(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_REGISTER_FORM_FIELD:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.key]: action.payload.value,
        },
      }

    case REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
      }

    case REGISTER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case REGISTER_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      }

    case ADD_REGISTER_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      }

    case CLEAR_REGISTER_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: undefined,
        },
      }

    default:
      return state
  }
}

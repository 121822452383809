import buildService from 'common/utils/build-service'

export const coursesService = buildService({
  type: 'GET',
  constantsPrefix: 'COURSES',
  isArray: true,
  http: {
    createApiUrlSuffix: () => `/all_courses`,
  },
})

export const courseService = buildService({
  type: 'GET',
  constantsPrefix: 'COURSE_DETAILS',
  http: {
    createApiUrlSuffix: ({ slug, id }) => {
      if (id) {
        return `/course_modules?id=eq.${id}`
      }
      return `/course_modules?slug=eq.${slug}`
    },
    headers: {
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

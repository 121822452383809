export const CHANGE_ONBOARDING_FORM_FIELD = 'CHANGE_ONBOARDING_FORM_FIELD'
export const SUBMIT_ONBOARDING = 'SUBMIT_ONBOARDING'
export const VALIDATE_ONBOARDING_FIELD = 'VALIDATE_ONBOARDING_FIELD'
export const ADD_ONBOARDING_FIELD_ERROR = 'ADD_ONBOARDING_FIELD_ERROR'
export const ADD_ONBOARDING_FIELDS_ERRORS = 'ADD_ONBOARDING_FIELDS_ERRORS'
export const CLEAR_ONBOARDING_FIELD_ERROR = 'CLEAR_ONBOARDING_FIELD_ERROR'
export const RESET_ONBOARDING_FORM_DATA = 'RESET_ONBOARDING_FORM_DATA'

export function changeOnboardingFormField(key, value) {
  return {
    type: CHANGE_ONBOARDING_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function submitOnboarding(data) {
  return {
    type: SUBMIT_ONBOARDING,
    payload: data,
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_ONBOARDING_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_ONBOARDING_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_ONBOARDING_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetOnboardingFormData() {
  return {
    type: RESET_ONBOARDING_FORM_DATA,
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_ONBOARDING_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

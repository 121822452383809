import { CHANGE_CHARTER_ORDER } from './actions'

export const DEFAULT_CHARTER_ORDER = {
  fieldName: 'created_at',
  direction: 'desc',
}

export const CHARTERS_ORDERS = {
  latest_earliest: DEFAULT_CHARTER_ORDER,
  earliest_latest: {
    fieldName: 'created_at',
    direction: 'asc',
  },
  title_a_z: {
    fieldName: 'name',
    direction: 'asc',
  },
  title_z_a: {
    fieldName: 'name',
    direction: 'desc',
  },
}

export const initialState = () => ({
  order: DEFAULT_CHARTER_ORDER,
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_CHARTER_ORDER:
      return {
        order: action.payload,
      }

    default:
      return state
  }
}

export const PUBLISH_NUDGE = 'PUBLISH_NUDGE'
export const UNPUBLISH_NUDGE = 'UNPUBLISH_NUDGE'
export const COMPLETE_NUDGE = 'COMPLETE_NUDGE'
export const LOAD_MY_NUDGE_SETS = 'LOAD_MY_NUDGE_SETS'
export const LOAD_ALL_NUDGE_SETS = 'LOAD_ALL_NUDGE_SETS'

export function loadAllNudgeSets(payload) {
  return {
    type: LOAD_ALL_NUDGE_SETS,
    payload: {
      ...payload,
    },
  }
}

export function loadMyNudgeSets(payload) {
  return {
    type: LOAD_MY_NUDGE_SETS,
    payload: {
      ...payload,
    },
  }
}

export function completeNudge(payload) {
  return {
    type: COMPLETE_NUDGE,
    payload: {
      ...payload,
    },
  }
}

export function publishNudge(payload) {
  return {
    type: PUBLISH_NUDGE,
    payload: {
      ...payload,
    },
  }
}

export function unpublishNudge(payload) {
  return {
    type: UNPUBLISH_NUDGE,
    payload: {
      ...payload,
    },
  }
}

export const CHANGE_EMOTION_WORDS_FORM_FIELD = 'CHANGE_EMOTION_WORDS_FORM_FIELD'
export const SUBMIT_EMOTION_WORDS = 'SUBMIT_EMOTION_WORDS'
export const VALIDATE_EMOTION_WORDS_FIELD = 'VALIDATE_EMOTION_WORDS_FIELD'
export const ADD_EMOTION_WORDS_FIELD_ERROR = 'ADD_EMOTION_WORDS_FIELD_ERROR'
export const ADD_EMOTION_WORDS_FIELDS_ERRORS = 'ADD_EMOTION_WORDS_FIELDS_ERRORS'
export const CLEAR_EMOTION_WORDS_FIELD_ERROR = 'CLEAR_EMOTION_WORDS_FIELD_ERROR'
export const RESET_EMOTION_WORDS_FORM_DATA = 'RESET_EMOTION_WORDS_FORM_DATA'
export const PREDEFINE_EMOTION_WORDS_FORM_DATA = 'PREDEFINE_EMOTION_WORDS_FORM_DATA'

export function changeEmotionWordsFormField(key, value) {
  return {
    type: CHANGE_EMOTION_WORDS_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function submitEmotionWords(data) {
  return {
    type: SUBMIT_EMOTION_WORDS,
    payload: {
      loading: true,
      ...data,
    },
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_EMOTION_WORDS_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_EMOTION_WORDS_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_EMOTION_WORDS_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetEmotionWordsFormData() {
  return {
    type: RESET_EMOTION_WORDS_FORM_DATA,
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_EMOTION_WORDS_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

export function predefineEmotionWordsForm(data) {
  return {
    type: PREDEFINE_EMOTION_WORDS_FORM_DATA,
    payload: data,
  }
}

import buildService from 'common/utils/build-service'
import { GET } from 'common/requests'

export const getMyStrategiesService = buildService({
  type: GET,
  constantsPrefix: 'MY_STRATEGIES',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/my_strategies',
  },
})

import { CREATE_STAFF_CHARTER_SURVEY_PREVIEW, TOGGLE_STAFF_CHARTER_SURVEY_PREVIEW } from './actions'

const initialState = () => ({
  emotionWords: [],
  actions: [],
  shown: false,
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case TOGGLE_STAFF_CHARTER_SURVEY_PREVIEW:
      return {
        ...state,
        shown: action.payload,
      }

    case CREATE_STAFF_CHARTER_SURVEY_PREVIEW:
      return {
        ...state,
        emotionWords: [...action.payload.emotionWords],
        actions: [...action.payload.actions],
        shown: true,
      }

    default:
      return state
  }
}

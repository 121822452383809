import _ from 'lodash'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import lang from 'lang'
import { validateField, validateFields } from 'common/validator'
import {
  staffCharterContributeService,
  staffCharterMyContributionsService,
  staffCharterWordCloudContributionsService,
} from 'page-modules/charter/staff-charter/service'
import { loadStaffCharterApproval } from 'page-modules/charter/staff-charter/service/actions'
import emotionWordsService from 'services/emotion-words'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  LOAD_EMOTION_WORDS_CONTRIBUTION,
  predefineEmotionWordsContributionForm,
  SUBMIT_EMOTION_WORDS_CONTRIBUTION,
  SUBMIT_EMOTION_WORDS_CONTRIBUTION_IN_MANAGE_SURVEY,
  VALIDATE_EMOTION_WORDS_CONTRIBUTION_FIELD,
} from './actions'

export function* loadEmotionWordsSaga() {
  const contributions = yield call(staffCharterMyContributionsService.requestSaga, {})
  yield call(emotionWordsService.requestSaga, { payload: { urlParams: { onlyCharter: true } } })
  yield put(predefineEmotionWordsContributionForm({ emotionWords: contributions }))
}

function* saveEmotionWords(payload) {
  // Add emotion words to request payload
  const data = {
    emotion_words: _.map(payload.emotionWords.value, (word) => ({
      emotion_word_id: word.id,
      actions: (word && word.actions && _.compact(word.actions)) || [],
    })),
  }

  const result = yield call(staffCharterContributeService.requestSaga, {
    payload: {
      data,
    },
  })

  const message = _.get(result, 'response.data.message', lang.unhandledServerError)
  if (_.isEmpty(result.emotion_words) && !_.isEmpty(message)) {
    yield put(addFieldError({ field: 'base', error: message }))
  }
}

export function* saveEmotionWordsSaga({ payload }) {
  const errors = validateFields(payload)

  if (!_.isEmpty(errors)) {
    yield put(addFieldsErrors(errors))
  } else {
    yield saveEmotionWords(payload)

    // Update word cloud and top emotion words
    const school = yield select((state) => state.school.details.item)
    if (!_.isEmpty(school)) {
      yield put(
        staffCharterWordCloudContributionsService.actions.request({
          payload: { data: { school_id: school.id } },
        }),
      )
    }

    yield put(staffCharterMyContributionsService.actions.request())
  }
}

export function* saveEmotionWordsSagaInManageSurveySaga({ payload }) {
  const errors = validateFields(payload)

  if (!_.isEmpty(errors)) {
    yield put(addFieldsErrors(errors))
  } else {
    yield saveEmotionWords(payload)

    // Update the contributions in the approve form
    yield put(loadStaffCharterApproval())

    yield put(staffCharterMyContributionsService.actions.request())
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_EMOTION_WORDS_CONTRIBUTION_FIELD, validateFieldSaga),
    takeLatest(LOAD_EMOTION_WORDS_CONTRIBUTION, loadEmotionWordsSaga),
    takeLatest(SUBMIT_EMOTION_WORDS_CONTRIBUTION, saveEmotionWordsSaga),
    takeLatest(
      SUBMIT_EMOTION_WORDS_CONTRIBUTION_IN_MANAGE_SURVEY,
      saveEmotionWordsSagaInManageSurveySaga,
    ),
  ])
}

import _ from 'lodash'

import { CONNECT_TO_SOCKET_EVENT, DISCONNECT_FROM_SOCKET_EVENT } from './actions'

export const initialState = () => ({
  listeners: [],
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case CONNECT_TO_SOCKET_EVENT: {
      let { listeners } = state
      if ((_.find(listeners), { eventType: action.payload.eventType }) !== undefined) {
        listeners = [...listeners, action.payload]
      }
      return {
        ...state,
        listeners,
      }
    }

    case DISCONNECT_FROM_SOCKET_EVENT: {
      const index = _.findIndex(state.listeners, { eventType: action.payload.eventType })
      return {
        ...state,
        listeners: _.reject(state.listeners, (v, i) => i === index),
      }
    }

    default:
      return state
  }
}

import _ from 'lodash'
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects'

import lang from 'lang'
import buildService from 'common/utils/build-service'
import { translateResponseError } from 'common/utils'
import { updateUser } from 'services/actions'
import { validateField, validateFields } from 'common/validator'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  pristineProfileSettingsForm,
  SUBMIT_PHOTO_URL,
  SUBMIT_PROFILE_SETTINGS,
  VALIDATE_PROFILE_SETTINGS_FIELD,
} from './actions'

export const updateUserSettingsService = buildService({
  type: 'POST',
  constantsPrefix: 'UPDATE_USER_SETTINGS',
  http: {
    createApiUrlSuffix: () => '/rpc/profile',
    headers: {
      Prefer: 'params=single-object',
    },
    *successHandler(data) {
      yield put(updateUser(data))
      yield put(pristineProfileSettingsForm())
    },
    *failureHandler(error) {
      yield put(
        addFieldError({
          field: 'base',
          error: translateResponseError(error, lang.dashboard.userForm),
        }),
      )
    },
  },
})

export function* submitProfileSettingsSaga({ payload }) {
  const errors = validateFields(payload)
  if (!_.isEmpty(errors)) {
    return yield put(addFieldsErrors(errors))
  }

  const data = _.pickBy(
    {
      name: payload.name.value,
      email: payload.email.value,
      mobile_phone: payload.mobilePhone.value,
      office_phone: payload.officePhone.value,
      job_title_id: payload.jobTitle.value,
      subjects: payload.subjects.value,
      grades: payload.grades.value,
    },
    _.identity,
  )

  yield put(
    updateUserSettingsService.actions.request({
      data,
    }),
  )
}

export function* submitPhotoUrlSaga({ payload: { photoUrl } }) {
  if (!_.isEmpty(photoUrl)) {
    yield put(
      updateUserSettingsService.actions.request({
        data: {
          photo_url: photoUrl,
        },
      }),
    )
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_PROFILE_SETTINGS_FIELD, validateFieldSaga),
    takeLatest(SUBMIT_PROFILE_SETTINGS, submitProfileSettingsSaga),
    takeLatest(SUBMIT_PHOTO_URL, submitPhotoUrlSaga),
  ])
}

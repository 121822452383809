import buildService from 'common/utils/build-service'
import { DELETE, GET, PATCH, POST } from 'common/requests'

export default buildService({
  type: GET,
  constantsPrefix: 'JOB_TITLES',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/job_titles',
  },
})

export const postJobTitleService = buildService({
  type: POST,
  constantsPrefix: 'JOB_TITLES',
  http: {
    createApiUrlSuffix: () => {
      return `/job_titles`
    },
  },
})

export const deleteJobTitleService = buildService({
  type: DELETE,
  constantsPrefix: 'JOB_TITLES',
  http: {
    createApiUrlSuffix: ({ id }) => {
      return `/job_titles?id=eq.${id}`
    },
  },
})

export const patchJobTitleService = buildService({
  type: PATCH,
  constantsPrefix: 'JOB_TITLES',
  http: {
    createApiUrlSuffix: ({ id }) => `/job_titles?id=eq.${id}`,
  },
})

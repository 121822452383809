import buildService from 'common/utils/build-service'
import { GET } from 'common/requests'

export default buildService({
  type: GET,
  constantsPrefix: 'PERSON_TYPES',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ scope, name }) => {
      const nameQuery = name ? `&name=eq.${name}` : ''
      if (scope) {
        return `/person_types?scope=cs.{${scope}}${nameQuery}`
      }
      return `/person_types?${nameQuery}`
    },
  },
})

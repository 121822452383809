import _ from 'lodash'

import {
  ADD_JOURNAL_FIELD_ERROR,
  ADD_JOURNAL_FIELDS_ERRORS,
  CHANGE_JOURNAL_FORM_FIELD,
  CLEAR_JOURNAL_FIELD_ERROR,
  PREDEFINE_JOURNAL_FORM_DATA,
  RESET_JOURNAL_FORM_DATA,
} from './actions'

const defaultFormData = (predefined = {}) =>
  _.merge(
    {
      id: '',
      name: '',
      content: '',
    },
    _.pickBy(predefined),
  )

export const initialState = () => ({
  formData: defaultFormData(),
  errors: {},
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_JOURNAL_FORM_FIELD:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.key]: action.payload.value,
        },
      }

    case ADD_JOURNAL_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      }

    case ADD_JOURNAL_FIELDS_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      }

    case CLEAR_JOURNAL_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: undefined,
        },
      }

    case RESET_JOURNAL_FORM_DATA:
      return {
        ...state,
        formData: defaultFormData(),
        errors: {},
      }

    case PREDEFINE_JOURNAL_FORM_DATA:
      return {
        ...state,
        formData: defaultFormData(action.payload),
        errors: {},
      }

    default:
      return state
  }
}

export const CHANGE_SCHOOL_FORM_FIELD = 'CHANGE_SCHOOL_FORM_FIELD'
export const SUBMIT_SCHOOL_FROM_SCHOOL_DETAILS_PAGE = 'SUBMIT_SCHOOL_FROM_SCHOOL_DETAILS_PAGE'
export const SUBMIT_SCHOOL_FROM_SCHOOL_LISTING_PAGE = 'SUBMIT_SCHOOL_FROM_SCHOOL_LISTING_PAGE'
export const SUBMIT_SCHOOL_FROM_DISTRICT_DETAILS_PAGE = 'SUBMIT_SCHOOL_FROM_DISTRICT_DETAILS_PAGE'
export const SUBMIT_SCHOOL_FROM_DASHBOARD = 'SUBMIT_SCHOOL_FROM_DASHBOARD'
export const VALIDATE_SCHOOL_FIELD = 'VALIDATE_SCHOOL_FIELD'
export const ADD_SCHOOL_FIELD_ERROR = 'ADD_SCHOOL_FIELD_ERROR'
export const ADD_SCHOOL_FIELDS_ERRORS = 'ADD_SCHOOL_FIELDS_ERRORS'
export const CLEAR_SCHOOL_FIELD_ERROR = 'CLEAR_SCHOOL_FIELD_ERROR'
export const RESET_SCHOOL_FORM_DATA = 'RESET_SCHOOL_FORM_DATA'
export const PREDEFINE_SCHOOL_FORM_DATA = 'PREDEFINE_SCHOOL_FORM_DATA'

const passOnlySchoolFields = ({
  schoolName,
  websiteUrl,
  phoneNumber,
  address,
  address2,
  city,
  state,
  country,
  postalCode,
  phase,
  schoolDistrict,
  schoolGrades,
  logoUrl,
  id,
  started_ruler_at,
  started_ruler,
  total_staff,
  offlineTrainings,
  coachName,
  coachEmail,
}) => ({
  schoolName,
  websiteUrl,
  phoneNumber,
  address,
  address2,
  city,
  state,
  country,
  postalCode,
  phase,
  schoolDistrict,
  schoolGrades,
  logoUrl,
  id,
  started_ruler_at,
  started_ruler,
  total_staff,
  offlineTrainings,
  coachName,
  coachEmail,
})

export function changeSchoolFormField(key, value) {
  return {
    type: CHANGE_SCHOOL_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function predefineSchoolForm(payload) {
  return {
    type: PREDEFINE_SCHOOL_FORM_DATA,
    payload,
  }
}

export function submitSchoolFromSchoolDetailsPage({
  schoolName,
  websiteUrl,
  phoneNumber,
  address,
  address2,
  city,
  state,
  postalCode,
  phase,
  schoolDistrict,
  schoolGrades,
  logoUrl,
  id,
  started_ruler_at,
  started_ruler,
  total_staff,
  offlineTrainings,
  coachName,
  coachEmail,
}) {
  return {
    type: SUBMIT_SCHOOL_FROM_SCHOOL_DETAILS_PAGE,
    payload: {
      schoolName,
      websiteUrl,
      phoneNumber,
      address,
      address2,
      city,
      state,
      postalCode,
      phase,
      schoolDistrict,
      schoolGrades,
      logoUrl,
      id,
      started_ruler_at,
      started_ruler,
      total_staff,
      offlineTrainings,
      coachName,
      coachEmail,
    },
  }
}

export function submitSchoolFromSchoolListingPage(payload) {
  return {
    type: SUBMIT_SCHOOL_FROM_SCHOOL_LISTING_PAGE,
    payload: passOnlySchoolFields(payload),
  }
}

export function submitSchoolFromDistrictDetailsPage(payload) {
  return {
    type: SUBMIT_SCHOOL_FROM_DISTRICT_DETAILS_PAGE,
    payload: passOnlySchoolFields(payload),
  }
}

export function submitSchoolFromDashboardPage(payload) {
  return {
    type: SUBMIT_SCHOOL_FROM_DASHBOARD,
    payload: passOnlySchoolFields(payload),
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_SCHOOL_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_SCHOOL_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_SCHOOL_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetSchoolFormData() {
  return {
    type: RESET_SCHOOL_FORM_DATA,
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_SCHOOL_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

import {
  CHANGE_SCHOOLS_COUNTRY_FILTER,
  CHANGE_SCHOOLS_DISTRICT_FILTER,
  CHANGE_SCHOOLS_FILTERS,
  CHANGE_SCHOOLS_PAGE,
  CHANGE_SCHOOLS_STATE_FILTER,
} from './actions'

export const initialState = () => ({
  countryCode: '',
  stateId: '',
  districtId: '',
  page: 0,
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_SCHOOLS_COUNTRY_FILTER:
      return {
        ...state,
        countryCode: action.payload,
      }

    case CHANGE_SCHOOLS_STATE_FILTER:
      return {
        ...state,
        stateId: action.payload,
      }

    case CHANGE_SCHOOLS_DISTRICT_FILTER:
      return {
        ...state,
        districtId: action.payload,
      }

    case CHANGE_SCHOOLS_FILTERS:
      return {
        ...state,
        ...action.payload,
      }

    case CHANGE_SCHOOLS_PAGE:
      return {
        ...state,
        page: action.payload,
      }

    default:
      return state
  }
}

import React from 'react'

import lang from 'lang'
import { urls } from 'common/routes'

const SuccessfulCompletionModal = () => (
  <div className="modal-wrapper">
    <div className="modal-header">
      <h2>{lang.thankYou}</h2>
      <p>{lang.page.moodMeter.plotSaved}</p>
    </div>
    <div className="modal-footer">
      <button
        type="button"
        className="button save"
        onClick={() => {
          window.location = urls.moodMeter()
        }}>
        {lang.closeButton}
      </button>
    </div>
  </div>
)
export default SuccessfulCompletionModal

import buildService from 'common/utils/build-service'

export const getLatestStaffCharterSurveyService = buildService({
  type: 'POST',
  constantsPrefix: 'LATEST_CHARTER_SURVEY_GET',
  http: {
    createApiUrlSuffix: () => '/rpc/staff_charter_survey_get',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const createStaffCharterSurveyService = buildService({
  type: 'POST',
  constantsPrefix: 'CHARTER_SURVEY_CREATE',
  http: {
    createApiUrlSuffix: () => '/rpc/staff_charter_survey_create',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const submitStaffCharterSurveyService = buildService({
  type: 'POST',
  constantsPrefix: 'CHARTER_SURVEY_SUBMIT',
  http: {
    createApiUrlSuffix: () => '/rpc/staff_charter_survey_submit',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const getStaffCharterSurveysService = buildService({
  type: 'POST',
  constantsPrefix: 'CHARTER_SURVEYS_GET',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/rpc/staff_charter_surveys',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const getStaffCharterSurveyReportsService = buildService({
  type: 'POST',
  constantsPrefix: 'CHARTER_SURVEYS_REPORT',
  http: {
    createApiUrlSuffix: () => '/rpc/staff_charter_survey_report',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

import querystring from 'querystring'
import _ from 'lodash'
import Cookie, { CookieAttributes } from 'js-cookie'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import { trackEvent } from 'lib/gtm'
import mixpanel, { EVENTS } from 'lib/mixpanel'
import { httpRequest } from 'services/saga'
import { getApiUrl } from 'services/selectors'
import { validateField, validateFields } from 'common/validator'
import { requests } from 'common/requests'
import lang from 'lang'
import { parseJwt, translateResponseError } from 'common/utils'
import { ApiResponseError } from 'common/utils/build-service'
import { FormResults } from 'components/form'
import { PartialUser } from 'common/types'
import { AFTER_LOGIN_POPUP_SHOWN_KEY } from 'components/after-login-popup'

import {
  addFieldError,
  clearFieldError,
  LOGIN_REQUEST,
  loginFailure,
  LoginFields,
  loginSuccess,
  VALIDATE_LOGIN_FIELD,
} from './actions'

export type LoginResponse = {
  me: PartialUser
  token: string
}
type LoginPayload = FormResults<LoginFields>
export function* loginSaga({ payload }: { payload: LoginPayload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const apiURL: ReturnType<typeof getApiUrl> = yield select(getApiUrl)
    try {
      const { data }: AxiosResponse<LoginResponse> = yield call(httpRequest, {
        req: requests.post,
        url: `${apiURL}/rpc/login`,
        headers: {
          Prefer: 'params=single-object',
        },
        data: _.mapValues(payload, 'value'),
      })

      const { exp } = parseJwt(data.token)
      const options: CookieAttributes = {}

      if (_.get(payload, 'rememberMe.value', false) && !_.isUndefined(exp)) {
        options.expires = new Date(exp * 1000)
      }

      Cookie.set('ruler-jwt', data.token, options)
      trackEvent({
        event: 'LoginSuccess',
        email: data.me.email,
        role: data.me.role_name,
        person_type: data.me.person_type_name,
      })
      if (localStorage.setItem) {
        localStorage.setItem(AFTER_LOGIN_POPUP_SHOWN_KEY, JSON.stringify(false))
      }
      yield put(loginSuccess(data))
      yield call(() => {
        const params = querystring.parse(window.location.search.substring(1))
        window.location.href =
          !_.isUndefined(params.next) && params.next ? (params.next as string) : '/dashboard'
      })
    } catch (error) {
      trackEvent({ event: 'LoginFailure' })
      mixpanel.track(EVENTS.LOGIN)
      yield put(
        loginFailure({
          base: translateResponseError(
            error as ApiResponseError,
            lang.auth.login,
            lang.connectivityError,
          ),
        }),
      )
    }
  } else {
    yield put(loginFailure(errors))
  }
}

// @ts-expect-error no validate field types
export function* validateFieldSaga({ payload: { field, value, validations } }) {
  // @ts-expect-error no validate field types
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* Saga() {
  yield all([
    // @ts-expect-error no saga types
    takeLatest(LOGIN_REQUEST, loginSaga),
    // @ts-expect-error no saga types
    takeEvery(VALIDATE_LOGIN_FIELD, validateFieldSaga),
  ])
}

import { ADD_ACTIVITY_STREAM_ITEM } from 'common/services/notifications'

const activityStreamTransformer = ({ payload, event_key }) => {
  const { id, scope } = payload
  return {
    payload,
    id,
    scope,
    event_key,
    created_at: new Date(),
    seen_at: null,
  }
}

const eventMap = {
  'mood.plot': {
    eventType: 'mood.plot',
    actionType: 'NEW_MOOD_METER_PLOT',
    transformData: (data, eventKey) => {
      return { ...data, event_key: eventKey }
    },
  },
  'school.insert': {
    eventType: 'school.insert',
    actionType: ADD_ACTIVITY_STREAM_ITEM,
    transformData: activityStreamTransformer,
  },
  'district.insert': {
    eventType: 'district.insert',
    actionType: ADD_ACTIVITY_STREAM_ITEM,
    transformData: activityStreamTransformer,
  },
  'staff_charter.insert': {
    eventType: 'staff_charter.insert',
    actionType: ADD_ACTIVITY_STREAM_ITEM,
    transformData: activityStreamTransformer,
  },
  'staff_charter_survey.insert': {
    eventType: 'staff_charter_survey.insert',
    actionType: ADD_ACTIVITY_STREAM_ITEM,
    transformData: activityStreamTransformer,
  },
  'mood_meter_group.insert': {
    eventType: 'mood_meter_group.insert',
    actionType: ADD_ACTIVITY_STREAM_ITEM,
    transformData: activityStreamTransformer,
  },
  'resource.insert': {
    eventType: 'resource.insert',
    actionType: ADD_ACTIVITY_STREAM_ITEM,
    transformData: activityStreamTransformer,
  },
  'course.insert': {
    eventType: 'course.insert',
    actionType: ADD_ACTIVITY_STREAM_ITEM,
    transformData: activityStreamTransformer,
  },
  'message.post': {
    eventType: 'message.post',
    actionType: ADD_ACTIVITY_STREAM_ITEM,
    transformData: activityStreamTransformer,
  },
  'person.insert_self_signup': {
    eventType: 'person.insert_self_signup',
    actionType: ADD_ACTIVITY_STREAM_ITEM,
    transformData: activityStreamTransformer,
  },
}

export const type = (object, action) => {
  return `${object}.${action}`
}
export const event = (object, action) => {
  const found = eventMap[type(object, action)]
  if (!found) {
    throw new Error(`Unknown event: '${type(object, action)}'`)
  }
  return found
}

export default eventMap

export const CHANGE_FORGOT_PASSWORD_FORM_FIELD = 'CHANGE_FORGOT_PASSWORD_FORM_FIELD'
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_REQUEST_SUCCESS = 'FORGOT_PASSWORD_REQUEST_SUCCESS'
export const FORGOT_PASSWORD_REQUEST_FAILURE = 'FORGOT_PASSWORD_REQUEST_FAILURE'
export const VALIDATE_FORGOT_PASSWORD_FIELD = 'VALIDATE_FORGOT_PASSWORD_FIELD'
export const ADD_FORGOT_PASSWORD_FIELD_ERROR = 'ADD_FORGOT_PASSWORD_FIELD_ERROR'
export const CLEAR_FORGOT_PASSWORD_FIELD_ERROR = 'CLEAR_FORGOT_PASSWORD_FIELD_ERROR'

export function changeForgotPasswordFormField(key, value) {
  return {
    type: CHANGE_FORGOT_PASSWORD_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function forgotPasswordRequest({ email }) {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    payload: {
      email,
    },
  }
}

export function forgotPasswordFailure(errors) {
  return {
    type: FORGOT_PASSWORD_REQUEST_FAILURE,
    errors,
  }
}

export function forgotPasswordSuccess() {
  return {
    type: FORGOT_PASSWORD_REQUEST_SUCCESS,
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_FORGOT_PASSWORD_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_FORGOT_PASSWORD_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_FORGOT_PASSWORD_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

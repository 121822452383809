export const CHANGE_YCEI_USER_FORM_FIELD = 'CHANGE_YCEI_USER_FORM_FIELD'
export const VALIDATE_YCEI_USER_FIELD = 'VALIDATE_YCEI_USER_FIELD'
export const SUBMIT_YCEI_USER = 'SUBMIT_YCEI_USER'
export const ADD_YCEI_USER_FIELD_ERROR = 'ADD_YCEI_USER_FIELD_ERROR'
export const ADD_YCEI_USER_API_ERROR = 'ADD_YCEI_USER_API_ERROR'
export const ADD_YCEI_USER_FIELDS_ERRORS = 'ADD_YCEI_USER_FIELDS_ERRORS'
export const CLEAR_YCEI_USER_FIELD_ERROR = 'CLEAR_YCEI_USER_FIELD_ERROR'
export const RESET_YCEI_USER_FORM_DATA = 'RESET_YCEI_USER_FORM_DATA'
export const PREDEFINE_YCEI_USER_FORM_DATA = 'PREDEFINE_YCEI_USER_FORM_DATA'

export function changeYceiUserFormField(key, value) {
  return {
    type: CHANGE_YCEI_USER_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_YCEI_USER_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

export function submitYceiUser({ id, email, fullName, roleId, personTypeId }) {
  return {
    type: SUBMIT_YCEI_USER,
    payload: {
      id,
      email,
      fullName,
      roleId,
      personTypeId,
    },
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_YCEI_USER_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_YCEI_USER_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_YCEI_USER_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetYceiUserFormData() {
  return {
    type: RESET_YCEI_USER_FORM_DATA,
  }
}

export function predefineYceiUserForm({ id, email, fullName, roleId, personTypeId }) {
  return {
    type: PREDEFINE_YCEI_USER_FORM_DATA,
    payload: {
      id,
      email,
      fullName,
      roleId,
      personTypeId,
    },
  }
}

import {
  addFieldError,
  ADD_LOGIN_FIELD_ERROR,
  changeLoginFormField,
  CHANGE_LOGIN_FORM_FIELD,
  clearFieldError,
  CLEAR_LOGIN_FIELD_ERROR,
  loginFailure,
  loginRequest,
  loginSuccess,
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAILURE,
  LOGIN_REQUEST_SUCCESS,
  validateField,
} from './actions'

export const initialState = () => {
  return {
    formData: {
      email: '',
      password: '',
      rememberMe: false,
    },
    errors: {},
    loading: false,
  }
}

type LoginActions =
  | ReturnType<typeof changeLoginFormField>
  | ReturnType<typeof loginRequest>
  | ReturnType<typeof loginFailure>
  | ReturnType<typeof loginSuccess>
  | ReturnType<typeof addFieldError>
  | ReturnType<typeof clearFieldError>
  | ReturnType<typeof validateField>

export default function reducer(state = initialState(), action: LoginActions) {
  switch (action.type) {
    case CHANGE_LOGIN_FORM_FIELD:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.key]: action.payload.value,
        },
      }

    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        errors: {},
      }

    case LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case LOGIN_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      }

    case ADD_LOGIN_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      }

    case CLEAR_LOGIN_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: undefined,
        },
      }

    default:
      return state
  }
}

import _ from 'lodash'

import {
  ADD_EMOTION_WORD_FIELD_ERROR,
  ADD_EMOTION_WORD_FIELDS_ERRORS,
  CHANGE_EMOTION_WORD_FIELD,
  CLEAR_EMOTION_WORD_FIELD_ERROR,
  PREDEFINE_EMOTION_WORD_FORM_DATA,
  RESET_EMOTION_WORD_FORM_DATA,
} from './actions'

export const defaultFormData = (predefined = {}) =>
  _.merge(
    {
      id: null,
      name: '',
      definition: '',
      energyLower: 0,
      energyHigher: 0,
      pleasantnessLower: 0,
      pleasantnessHigher: 0,
      moodMeter: false,
      charter: false,
    },
    predefined,
  )

export const initialState = () => ({
  formData: defaultFormData(),
  errors: {},
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_EMOTION_WORD_FIELD:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.key]: action.payload.value,
        },
      }

    case ADD_EMOTION_WORD_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      }

    case ADD_EMOTION_WORD_FIELDS_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      }

    case CLEAR_EMOTION_WORD_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: undefined,
        },
      }

    case RESET_EMOTION_WORD_FORM_DATA:
      return {
        ...state,
        formData: defaultFormData(),
        errors: {},
      }

    case PREDEFINE_EMOTION_WORD_FORM_DATA:
      return {
        ...state,
        formData: defaultFormData(action.payload),
        errors: {},
      }

    default:
      return state
  }
}

export const CHANGE_SCHOOLS_COUNTRY_FILTER = 'CHANGE_SCHOOLS_COUNTRY_FILTER'
export const CHANGE_SCHOOLS_STATE_FILTER = 'CHANGE_SCHOOLS_STATE_FILTER'
export const CHANGE_SCHOOLS_DISTRICT_FILTER = 'CHANGE_SCHOOLS_DISTRICT_FILTER'
export const CHANGE_SCHOOLS_FILTERS = 'CHANGE_SCHOOLS_FILTERS'
export const CHANGE_SCHOOLS_PAGE = 'CHANGE_SCHOOLS_PAGE'
export const LOAD_SCHOOLS = 'LOAD_SCHOOLS'

export function changeSchoolsCountryFilter(country) {
  return {
    type: CHANGE_SCHOOLS_COUNTRY_FILTER,
    payload: country,
  }
}

export function changeSchoolsStateFilter(state) {
  return {
    type: CHANGE_SCHOOLS_STATE_FILTER,
    payload: state,
  }
}

export function changeSchoolsDistrictFilter(state) {
  return {
    type: CHANGE_SCHOOLS_DISTRICT_FILTER,
    payload: state,
  }
}

export function changeSchoolsFilters(filters) {
  return {
    type: CHANGE_SCHOOLS_FILTERS,
    payload: filters,
  }
}

export function changeSchoolsPage(page) {
  return {
    type: CHANGE_SCHOOLS_PAGE,
    payload: page,
  }
}

export function loadSchools() {
  return {
    type: LOAD_SCHOOLS,
  }
}

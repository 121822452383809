import _ from 'lodash'

import buildService from 'common/utils/build-service'
import { GET } from 'common/requests'

export default buildService({
  type: GET,
  constantsPrefix: 'DISTRICTS',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ select, countryCode, stateId }) => {
      const country = countryCode ? `country_code=eq.${countryCode}&` : ''
      const state = stateId ? `state_id=eq.${stateId}&` : ''
      if (select) {
        return `/ext_districts?${country}${state}select=${select}`
      }
      return `/ext_districts?${country}${state}select=*,schools(id)`
    },
  },
  forceProgress: true,
})

export const searchDistrictsService = buildService({
  type: GET,
  constantsPrefix: 'SEARCH_DISTRICTS',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ searchString }) => {
      searchString = _.join(
        _.map(_.words(searchString), (string) => `name=plfts.${string}&`),
        '',
      )
      return `/ext_districts?${searchString}select=name,slug`
    },
  },
})

import buildService from 'common/utils/build-service'

export const saveEmotionWordsService = buildService({
  type: 'POST',
  constantsPrefix: 'SAVE_EMOTION_WORDS',
  http: {
    createApiUrlSuffix: () => '/rpc/profile',
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

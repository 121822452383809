import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import lang from 'lang'
import { changeModal } from 'services/actions'
import { submitDistrictUser } from 'page-modules/dashboard/district-details/service/actions'

import DistrictUserForm from './district-user-form'

@connect(
  (state) => ({
    districtUserFormData: state.district.addDistrictUser.form.data,
    loading: state.users.post.loading,
  }),
  (dispatch) => ({
    changeModal: (params) => dispatch(changeModal(params)),
    submitDistrictUser: (districtUserFormData) =>
      dispatch(submitDistrictUser(districtUserFormData)),
  }),
)
export default class DistrictUserModal extends React.Component {
  static propTypes = {
    edit: PropTypes.bool,
    districtUserFormData: PropTypes.object,
    loading: PropTypes.bool,
    district: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    changeModal: PropTypes.func.isRequired,
    submitDistrictUser: PropTypes.func,
  }

  createAddDistrictUserHandler() {
    return () => {
      const userData = _.mapValues(this.props.districtUserFormData, (value, key) => {
        return {
          value,
          validations: DistrictUserForm.validations[key],
        }
      })
      userData.districtId = {
        value: this.props.district.id,
        validations: 'nonEmpty',
      }
      this.props.submitDistrictUser(userData)
    }
  }

  closeModal() {
    this.props.changeModal({ isOpen: false })
  }

  render() {
    return (
      <div className="modal-wrapper">
        <div className="modal-header">
          <h2 id="add-new-district-user-header">
            {this.props.edit
              ? lang.dashboard.userForm.editTitle
              : `${lang.dashboard.userForm.title} ${this.props.district.name}`}
          </h2>
        </div>
        <div className="modal-container">
          <DistrictUserForm />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="button save"
            disabled={this.props.loading}
            onClick={this.createAddDistrictUserHandler()}>
            {lang.saveButton}
          </button>
          <button type="button" className="button clear cancel" onClick={() => this.closeModal()}>
            {lang.cancelButton}
          </button>
        </div>
      </div>
    )
  }
}

export const TEST_EVENT_FROM_SOCKET = 'TEST_EVENT_FROM_SOCKET'
export const CONNECT_TO_SOCKET_EVENT = 'CONNECT_TO_SOCKET_EVENT'
export const DISCONNECT_FROM_SOCKET_EVENT = 'DISCONNECT_FROM_SOCKET_EVENT'

export function fireTestEventFromSocket(payload) {
  return {
    type: TEST_EVENT_FROM_SOCKET,
    payload: { payload },
  }
}

export function connectToSocketEvent(payload) {
  return {
    type: CONNECT_TO_SOCKET_EVENT,
    payload,
  }
}

export function disconnectFromSocketEvent(payload) {
  return {
    type: DISCONNECT_FROM_SOCKET_EVENT,
    payload,
  }
}

import _ from 'lodash'
import buildService from 'common/utils/build-service'
import { GET } from 'common/requests'
import { ExtSchool } from 'common/types'

export default buildService<
  { districtId: string; stateId: string; countryCode: string },
  ExtSchool[]
>({
  type: GET,
  constantsPrefix: 'SCHOOLS',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ districtId, stateId, countryCode }) => {
      const district = districtId ? `district_id=eq.${districtId}&` : ''
      const state = stateId ? `state_id=eq.${stateId}&` : ''
      const country = countryCode ? `country_code=eq.${countryCode}&` : ''
      return `/ext_schools?${district}${state}${country}select=*,people_in_school`
    },
  },
  forceProgress: true,
})

export const searchSchoolsService = buildService<{ searchString: string }, ExtSchool[]>({
  type: GET,
  constantsPrefix: 'SEARCH_SCHOOLS',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ searchString }) => {
      searchString = _.join(
        _.map(_.words(searchString), (string) => `name=plfts.${string}&`),
        '',
      )
      return `/ext_schools?${searchString}select=id,name,slug`
    },
  },
})

export const CHANGE_DISTRICT_FORM_FIELD = 'CHANGE_DISTRICT_FORM_FIELD'
export const SUBMIT_DISTRICT_FROM_DISTRICTS_PAGE = 'SUBMIT_DISTRICT_FROM_DISTRICTS_PAGE'
export const SUBMIT_DISTRICT_FROM_DISTRICT_DETAILS_PAGE =
  'SUBMIT_DISTRICT_FROM_DISTRICT_DETAILS_PAGE'
export const SUBMIT_DISTRICT_FROM_DASHBOARD = 'SUBMIT_DISTRICT_FROM_DASHBOARD'
export const VALIDATE_DISTRICT_FIELD = 'VALIDATE_DISTRICT_FIELD'
export const ADD_DISTRICT_FIELD_ERROR = 'ADD_DISTRICT_FIELD_ERROR'
export const ADD_DISTRICT_FIELDS_ERRORS = 'ADD_DISTRICT_FIELDS_ERRORS'
export const CLEAR_DISTRICT_FIELD_ERROR = 'CLEAR_DISTRICT_FIELD_ERROR'
export const RESET_DISTRICT_FORM_DATA = 'RESET_DISTRICT_FORM_DATA'
export const PREDEFINE_DISTRICT_FORM_DATA = 'PREDEFINE_DISTRICT_FORM_DATA'
export const EDIT_DISTRICT_DETAILS = 'EDIT_DISTRICT_DETAILS'

const passOnlyDistrictFields = ({
  id,
  districtName,
  websiteUrl,
  phoneNumber,
  address,
  address2,
  city,
  country,
  state,
  postalCode,
  slug,
  started_ruler_at,
  started_ruler,
  offlineTrainings,
  coachName,
  coachEmail,
}) => ({
  id,
  districtName,
  websiteUrl,
  phoneNumber,
  address,
  address2,
  city,
  country,
  state,
  postalCode,
  slug,
  started_ruler_at,
  started_ruler,
  offlineTrainings,
  coachName,
  coachEmail,
})

export function changeDistrictFormField(key, value) {
  return {
    type: CHANGE_DISTRICT_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function submitDistrictFromDistrictsPage(payload) {
  return {
    type: SUBMIT_DISTRICT_FROM_DISTRICTS_PAGE,
    payload: passOnlyDistrictFields(payload),
  }
}

export function submitDistrictFromDistrictDetailsPage(payload) {
  return {
    type: SUBMIT_DISTRICT_FROM_DISTRICT_DETAILS_PAGE,
    payload: passOnlyDistrictFields(payload),
  }
}

export function submitDistrictFromDashboard(payload) {
  return {
    type: SUBMIT_DISTRICT_FROM_DASHBOARD,
    payload: passOnlyDistrictFields(payload),
  }
}

export function predefineDistrictForm(payload) {
  return {
    type: PREDEFINE_DISTRICT_FORM_DATA,
    payload: passOnlyDistrictFields(payload),
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_DISTRICT_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_DISTRICT_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_DISTRICT_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetDistrictFormData() {
  return {
    type: RESET_DISTRICT_FORM_DATA,
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_DISTRICT_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

export function editDistrictDetails({ districtId, submitDistrict }) {
  return {
    type: EDIT_DISTRICT_DETAILS,
    payload: { districtId, submitDistrict },
  }
}

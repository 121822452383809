import _ from 'lodash'

import buildService from 'common/utils/build-service'
import { GET } from 'common/requests'

export const getGlossaryService = buildService({
  type: GET,
  constantsPrefix: 'GLOSSARY',
  isArray: true,
  http: {
    createApiUrlSuffix: () => `/glossary_items`,
  },
})

export const getCollectionsService = buildService({
  type: GET,
  constantsPrefix: 'COLLECTIONS',
  isArray: true,
  http: {
    createApiUrlSuffix: () => `/all_collections?order=category_weight.asc,weight.asc`,
  },
})

export const getFavoritesService = buildService({
  type: GET,
  constantsPrefix: 'RESOURCE_FAVORITES',
  isArray: true,
  http: {
    createApiUrlSuffix: () => `/my_favorite_resources`,
  },
})

export const getCollectionDetailsService = buildService({
  type: GET,
  constantsPrefix: 'COLLECTION_DETAILS',
  http: {
    createApiUrlSuffix: (id) => `/collections?slug=eq.${id}`,
    headers: {
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const getResourceDetailsService = buildService({
  type: GET,
  constantsPrefix: 'RESOURCE_DETAILS',
  http: {
    createApiUrlSuffix: (id) => `/resources?slug=eq.${id}`,
    headers: {
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const searchResourcesService = buildService({
  type: GET,
  constantsPrefix: 'SEARCH_RESOURCES',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ searchString, limit }) => {
      searchString = _.join(
        _.map(_.words(searchString), (string) => `&name=fts.${string}`),
        '',
      )
      const limitString = limit ? `&limit=${limit}` : ''
      return `/available_resources?select=name,slug,collection_name${searchString}${limitString}`
    },
  },
})

export const getCollectionResourcesService = buildService({
  type: GET,
  constantsPrefix: 'COLLECTION_RESOURCES',
  isArray: true,
  http: {
    createApiUrlSuffix: (id) =>
      `/resources_in_collection?status=eq.published&collection_slug=eq.${id}&order=weight.asc`,
  },
})

export const postVisitResourceService = buildService({
  type: 'POST',
  constantsPrefix: 'RESOURCE_VISIT',
  http: {
    createApiUrlSuffix: () => '/rpc/visit',
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

export const postFavoriteService = buildService({
  type: 'POST',
  constantsPrefix: 'RESOURCE_FAVORITE',
  http: {
    createApiUrlSuffix: () => '/rpc/favorite',
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

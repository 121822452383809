import _ from 'lodash'

import {
  ADD_YCEI_USER_FIELD_ERROR,
  ADD_YCEI_USER_FIELDS_ERRORS,
  CHANGE_YCEI_USER_FORM_FIELD,
  CLEAR_YCEI_USER_FIELD_ERROR,
  PREDEFINE_YCEI_USER_FORM_DATA,
  RESET_YCEI_USER_FORM_DATA,
} from './actions'

const defaultFormData = (predefined = {}) =>
  _.merge(
    {
      id: '',
      email: '',
      fullName: '',
      roleId: '',
      personTypeId: '',
    },
    _.pickBy(predefined, _.identity),
  )

export const initialState = () => ({
  data: defaultFormData(),
  errors: {},
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_YCEI_USER_FORM_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.key]: action.payload.value,
        },
      }

    case ADD_YCEI_USER_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      }

    case ADD_YCEI_USER_FIELDS_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      }

    case CLEAR_YCEI_USER_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: undefined,
        },
      }

    case RESET_YCEI_USER_FORM_DATA:
      return {
        ...state,
        data: defaultFormData(),
        errors: {},
      }

    case PREDEFINE_YCEI_USER_FORM_DATA:
      return {
        ...state,
        data: defaultFormData(action.payload),
        errors: {},
      }

    default:
      return state
  }
}

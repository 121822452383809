import React, { ChangeEventHandler } from 'react'
import { StateAction } from 'services/actions'
import { Validations } from 'common/validator-types'
import { ActionType } from '@redux-saga/types'

// Not to be used directly.
// Use it by extending it first.
// Requires providing `changeFormField` and `validateField` props to the extended components.

export type FieldNames = string
export type FormValue = string | number | boolean | undefined
export type FormValues<F extends FieldNames> = {
  [key in F]: FormValue
}
export type FormResults<F extends FieldNames> = {
  [key in F]: {
    value: FormValue
    validations?: keyof Validations
  }
}
export type ValidateProps<F extends FieldNames> = {
  field: F
  value: FormValue
  validations: keyof Validations
}
export type FormProps<F extends FieldNames> = {
  changeFormField?: (key: string, value: FormValue) => StateAction<ActionType, any>
  validateField?: (props: ValidateProps<F>) => StateAction<ActionType, any>
} & FormValues<F>

export type FormWithProps<F extends FieldNames, P extends FormValues<F>> = FormProps<F> & P

class Form<
  F extends FieldNames,
  P extends FormProps<F> = FormProps<F>,
  S = {},
> extends React.Component<P, S> {
  createFieldHandler(fieldName: string): ChangeEventHandler<HTMLInputElement> {
    return (e) => {
      if (this.props.changeFormField) {
        this.props.changeFormField(fieldName, e.target.value)
      }
    }
  }

  createFieldValidationHandler(field: F, validations: keyof Validations) {
    return () => {
      if (this.props.validateField) {
        this.props.validateField({ field, value: this.props[field], validations })
      }
    }
  }
}

export default Form

import _ from 'lodash'
import validator from 'validator'
import { isValid, parseISO } from 'date-fns'

import lang from 'lang'

const optionalValidationString = 'optional'

// Backend is having specific regex for checking if a phone number is valid.
// https://github.com/liveSTORYBOARD/RULER/blob/89c29db3cebd165f04e2105eeee3bdc9c36f890b/db/src/libs/public/phone_us_domain.sql
// We need to mimick that so our validations don't deviate a lot.
const phoneNumberRegex = /^[0-9a-z +().-]{7,}$/
const numberRegex = /^-?[0-9]+$/

const trim = (value) => {
  if (_.isString(value)) {
    return value.trim()
  }
  return value
}

const validations = {
  isArray: (field, value) => {
    return _.isArray(value)
  },
  email: (field, value) => {
    if (validator.isEmail(trim(value))) {
      return true
    }
    return lang.validator.email
  },
  nonEmpty: (field, value) => {
    value = trim(value)
    if (_.isArray(value) && !_.isEmpty(value)) {
      return true
    }
    if (_.isString(value) && !validator.isEmpty(value)) {
      return true
    }
    if (_.isNumber(value)) {
      return true
    }
    return lang.validator.nonEmpty
  },
  alphanumeric: (field, value) => {
    if (validator.isAlphanumeric(value)) {
      return true
    }
    return lang.validator.alphanumeric
  },
  alphanumericWithAccents: (field, value) => {
    if (/[A-zÀ-ÿ0-9\\.\s]$/.test(value)) {
      return true
    }
    return lang.validator.alphanumeric
  },
  url: (field, value) => {
    if (validator.isURL(trim(value))) {
      return true
    }
    return lang.validator.url
  },
  phoneNumber: (field, value) => {
    if (phoneNumberRegex.test(trim(value))) {
      return true
    }
    return lang.validator.phoneNumber
  },
  postalCode: (field, value) => {
    if (validator.isLength(trim(value), { min: 3 })) {
      return true
    }
    return lang.validator.postalCode
  },
  length160: (field, value) => {
    if (validator.isLength(value, { min: 0, max: 160 })) {
      return true
    }

    return lang.validator.length
  },
  length256: (field, value) => {
    if (validator.isLength(value, { min: 0, max: 256 })) {
      return true
    }

    return lang.validator.length256
  },
  lengthMin8: (field, value) => {
    if (validator.isLength(value, { min: 8 })) {
      return true
    }

    return lang.validator.lengthMin8
  },
  number: (field, value) => {
    if (numberRegex.test(trim(value))) {
      return true
    }
    return lang.validator.number
  },
  date: (field, value) => {
    if (isValid(parseISO(value))) {
      return true
    }
    return lang.validator.date
  },
}

export default validations

export const validateField = ({ field, value, fieldValidations, input, index }) => {
  // Recursive object props validation
  // { self: 'isArray', field1: 'notEmpty', field2: 'number', field3: 'date'}
  if (_.isObject(fieldValidations) && fieldValidations.self !== undefined) {
    const { self } = fieldValidations
    const children = _.omit(fieldValidations, ['self', 'errorMessage'])

    // Validate parent object
    const parentValidation = validateField({ field, value, fieldValidations: self })
    if (parentValidation !== null) {
      return _.get(fieldValidations, 'errorMessage', parentValidation)
    }

    // Trigger child validations only if there are children
    const validationResults = []
    if (!_.isEmpty(children)) {
      _.each(value, (childValue, idx) => {
        const childResult = {}
        _.each(children, (childRule, childKey) => {
          childResult[childKey] = validateField({
            field: childKey,
            value: childValue[childKey],
            fieldValidations: childRule,
            index: idx,
            input,
          })
        })
        // Do not filter empty child validations, so we can keep their relative position
        validationResults.push(childResult)
      })
    }

    // Pass self validations only if all the children validations are empty
    if (_.isEmpty(_.filter(validationResults, (result) => !_.isEmpty(_.filter(result))))) {
      return null
    }
    return validationResults
  }

  // Run validation callback
  if (_.isFunction(fieldValidations)) {
    return fieldValidations({ validations, field, value, input, index })
  }

  const validationNames = _.isString(fieldValidations) && fieldValidations.split(' ')
  let error = null
  let isOptional = false
  _.each(validationNames, (validationName) => {
    if (validationName === optionalValidationString) {
      isOptional = true
      return true
    }

    // If the validation is optional and we have an "empty" value, don't run any validations
    if (
      isOptional &&
      (_.isNull(value) || _.isUndefined(value) || value === '' || _.isEmpty(value))
    ) {
      // Reset isOptional for the next validation
      isOptional = false
      return true
    }

    // If the validation is optional, but the value is non "empty", run the validations and reset the isOptional
    if (isOptional) {
      isOptional = false
    }

    const validationResult = validations[validationName](field, value)
    if (validationResult !== true) {
      error = validationResult
      return false
    }
  })

  return error
}

// Args is an object in the form of:
//  {
//    key: { // the field name
//      value: <the value of the field>,
//      fieldValidations: <string listing the validations>
//    }
//  }
// or
//  {
//    key: { // the field name
//      value: <the value of the field>,
//      fieldValidations: {
//        self: <string listing the validations>,
//        errorMessage: <string with the error message to show if the validations don't pass>
//      }
//    }
//  }

// Validation callback
// ({
//   validations, // list of predefined custom validators
//   field, // field name
//   value, // field value
//   input, // all field names/values values
//   index, // index of the validated object in the array
// }) => 'error message or null'
export const validateFields = (args) => {
  const errors = {}

  _.each(args, (value, key) => {
    if (value.validations) {
      const error = validateField({
        field: key,
        value: value.value,
        fieldValidations: value.validations,
        input: args,
      })

      if (error) {
        errors[key] = error
      }
    }
  })

  return errors
}

import _ from 'lodash'
import { buffers } from 'redux-saga'
import { actionChannel, all, call, put, select, spawn, take } from 'redux-saga/effects'

import htmlContentService from 'services/html-content/index'

import { persistHtmlContent, REQUEST_HTML_CONTENT } from './actions'

export function* requestHtmlContentSaga({ payload }) {
  const { keys, req } = payload
  const availableContent = yield select((state) => state.htmlContent)
  const newContent = _.differenceBy(keys, _.keys(availableContent))
  // Don't trigger XHR if all keys are available
  if (_.isEmpty(newContent)) {
    return
  }
  const responseKeys = yield call(htmlContentService.requestSaga, {
    payload: {
      unauthorized: true,
      req,
      urlParams: { keys: newContent },
    },
  })
  yield all(_.map(responseKeys, (pair) => put(persistHtmlContent(pair))))
}

function* listenForHtmlContentRequestsSaga() {
  const channel = yield actionChannel(REQUEST_HTML_CONTENT, buffers.sliding(5))
  while (true) {
    const { payload } = yield take(channel)
    // This relies on `requestHtmlContentSaga` to finish after the resulting html content piece has been added to the redux state.
    // Why the usage of yield* ? - take a look https://redux-saga.js.org/docs/advanced/SequencingSagas.html
    yield* requestHtmlContentSaga({ payload })
  }
}

export default function* saga() {
  yield all([spawn(listenForHtmlContentRequestsSaga)])
}

export const SUBMIT_CHARTER_FROM_DETAILS_PAGE = 'SUBMIT_CHARTER_FROM_DETAILS_PAGE'
export const SUBMIT_CHARTER_FROM_LISTING_PAGE = 'SUBMIT_CHARTER_FROM_LISTING_PAGE'
export const CHANGE_CHARTER_FORM_FIELD = 'CHANGE_CHARTER_FORM_FIELD'
export const VALIDATE_CHARTER_FIELD = 'VALIDATE_CHARTER_FIELD'
export const ADD_CHARTER_FIELD_ERROR = 'ADD_CHARTER_FIELD_ERROR'
export const ADD_CHARTER_FIELDS_ERRORS = 'ADD_CHARTER_FIELDS_ERRORS'
export const CLEAR_CHARTER_FIELD_ERROR = 'CLEAR_CHARTER_FIELD_ERROR'
export const RESET_CHARTER_FORM_DATA = 'RESET_CHARTER_FORM_DATA'
export const PREDEFINE_CHARTER_FORM_DATA = 'PREDEFINE_CHARTER_FORM_DATA'
export const CHANGE_CHARTER_ORDER = 'CHANGE_CHARTER_ORDER'

export function submitCharterFromDetailsPage(data) {
  return {
    type: SUBMIT_CHARTER_FROM_DETAILS_PAGE,
    payload: data,
  }
}

export function submitCharterFromListingPage(data) {
  return {
    type: SUBMIT_CHARTER_FROM_LISTING_PAGE,
    payload: data,
  }
}

export function changeCharterFormField(key, value) {
  return {
    type: CHANGE_CHARTER_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_CHARTER_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_CHARTER_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_CHARTER_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetCharterFormData() {
  return {
    type: RESET_CHARTER_FORM_DATA,
  }
}

export function predefineCharterFormData({
  id,
  name,
  type,
  emotionWords,
  grade,
  content,
  template,
  existingCharterUrl,
}) {
  return {
    type: PREDEFINE_CHARTER_FORM_DATA,
    payload: {
      id,
      name,
      type,
      emotionWords,
      grade,
      content,
      template,
      existingCharterUrl,
    },
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_CHARTER_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

export function changeCharterOrder(order) {
  return {
    type: CHANGE_CHARTER_ORDER,
    payload: { ...order },
  }
}

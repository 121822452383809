import _ from 'lodash'
import flatten from 'flat'
import ERROR_STRINGS from 'common/error-strings'
import lang from 'lang'

import { ApiResponseError } from './build-service'

type I18nValue = I18n | string
type I18n = {
  [key: string]: I18nValue
}
/*
  Helper function which accepts *axios response error* and optional i18n strings object.
  If no i18n strings object is provided it uses our language strings by converting them to:
  {
    'validator.email': 'This email is invalid.',
    'auth.register.invalidEmail': 'Please enter a valid email address.',
    'unhandledServerError': 'Sorry! Unhandled Server error.'
  }
  and uses the first found match by using our ERROR_STRINGS map
*/
const flatLang: I18n = flatten(lang)
const translateResponseError = (
  error: ApiResponseError,
  i18nStrings: I18n = flatLang,
  defaultErrorMessage: string = lang.unhandledServerError,
): string => {
  let message = _.get(error, 'response.data.message', defaultErrorMessage)

  _.each(ERROR_STRINGS, (value, key) => {
    if (_.includes(message, key)) {
      const i18nKey = _.find(_.keys(i18nStrings), (k) => _.includes(k, value))
      message = i18nKey && i18nKey in i18nStrings ? i18nStrings[i18nKey] : defaultErrorMessage

      return false // break _.each
    }
  })
  return message
}

export default translateResponseError

export const SUBMIT_FAVORITE_FROM_FAVORITES_PAGE = 'SUBMIT_FAVORITE_FROM_FAVORITES_PAGE'
export const SUBMIT_FAVORITE_FROM_RESOURCE_PAGE = 'SUBMIT_FAVORITE_FROM_RESOURCE_PAGE'
export const SUBMIT_FAVORITE_FROM_COLLECTION_PAGE = 'SUBMIT_FAVORITE_FROM_COLLECTION_PAGE'
export const LOAD_RESOURCE_DETAILS_PAGE = 'LOAD_RESOURCE_DETAILS_PAGE'
export const LOAD_COLLECTION_DETAILS_PAGE = 'LOAD_COLLECTION_DETAILS_PAGE'

export function submitFavoriteFromFavoritesPage(payload) {
  return {
    type: SUBMIT_FAVORITE_FROM_FAVORITES_PAGE,
    payload,
  }
}

export function submitFavoriteFromResourcePage(payload) {
  return {
    type: SUBMIT_FAVORITE_FROM_RESOURCE_PAGE,
    payload,
  }
}

export function submitFavoriteFromCollectionPage(payload) {
  return {
    type: SUBMIT_FAVORITE_FROM_COLLECTION_PAGE,
    payload,
  }
}

export function loadResourceDetailsPage({ slug, req }) {
  return {
    type: LOAD_RESOURCE_DETAILS_PAGE,
    payload: { slug, req },
  }
}

export function loadCollectionDetailsPage({ slug, req }) {
  return {
    type: LOAD_COLLECTION_DETAILS_PAGE,
    payload: { slug, req },
  }
}

export const CHANGE_DISTRICTS_COUNTRY_FILTER = 'CHANGE_DISTRICTS_COUNTRY_FILTER'
export const CHANGE_DISTRICTS_STATE_FILTER = 'CHANGE_DISTRICTS_STATE_FILTER'
export const CHANGE_DISTRICTS_FILTERS = 'CHANGE_DISTRICTS_FILTERS'
export const CHANGE_DISTRICTS_PAGE = 'CHANGE_DISTRICTS_PAGE'
export const LOAD_DISTRICTS = 'LOAD_DISTRICTS'
export const LOAD_DISTRICTS_PAGE = 'LOAD_DISTRICTS_PAGE'

export function changeDistrictsCountryFilter(country) {
  return {
    type: CHANGE_DISTRICTS_COUNTRY_FILTER,
    payload: country,
  }
}

export function changeDistrictsStateFilter(state) {
  return {
    type: CHANGE_DISTRICTS_STATE_FILTER,
    payload: state,
  }
}

export function changeDistrictsFilters(filters) {
  return {
    type: CHANGE_DISTRICTS_FILTERS,
    payload: filters,
  }
}

export function changeDistrictsPage(page) {
  return {
    type: CHANGE_DISTRICTS_PAGE,
    payload: page,
  }
}

export function loadDistricts() {
  return {
    type: LOAD_DISTRICTS,
  }
}

export function loadDistrictsPage({ req, range }) {
  return {
    type: LOAD_DISTRICTS_PAGE,
    payload: { req, range },
  }
}

export const CHANGE_EMOTION_WORDS_CONTRIBUTION_FORM_FIELD =
  'CHANGE_EMOTION_WORDS_CONTRIBUTION_FORM_FIELD'
export const SUBMIT_EMOTION_WORDS_CONTRIBUTION = 'SUBMIT_EMOTION_WORDS_CONTRIBUTION'
export const SUBMIT_EMOTION_WORDS_CONTRIBUTION_IN_MANAGE_SURVEY =
  'SUBMIT_EMOTION_WORDS_CONTRIBUTION_IN_MANAGE_SURVEY'
export const VALIDATE_EMOTION_WORDS_CONTRIBUTION_FIELD = 'VALIDATE_EMOTION_WORDS_CONTRIBUTION_FIELD'
export const ADD_EMOTION_WORDS_CONTRIBUTION_FIELD_ERROR =
  'ADD_EMOTION_WORDS_CONTRIBUTION_FIELD_ERROR'
export const ADD_EMOTION_WORDS_CONTRIBUTION_FIELDS_ERRORS =
  'ADD_EMOTION_WORDS_CONTRIBUTION_FIELDS_ERRORS'
export const CLEAR_EMOTION_WORDS_CONTRIBUTION_FIELD_ERROR =
  'CLEAR_EMOTION_WORDS_CONTRIBUTION_FIELD_ERROR'
export const RESET_EMOTION_WORDS_CONTRIBUTION_FORM_DATA =
  'RESET_EMOTION_WORDS_CONTRIBUTION_FORM_DATA'
export const PREDEFINE_EMOTION_WORDS_CONTRIBUTION_FORM_DATA =
  'PREDEFINE_EMOTION_WORDS_CONTRIBUTION_FORM_DATA'
export const LOAD_EMOTION_WORDS_CONTRIBUTION = 'LOAD_EMOTION_WORDS_CONTRIBUTION'

export function changeEmotionWordsContributionFormField(key, value) {
  return {
    type: CHANGE_EMOTION_WORDS_CONTRIBUTION_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function submitEmotionWordsContribution(data) {
  return {
    type: SUBMIT_EMOTION_WORDS_CONTRIBUTION,
    payload: data,
  }
}

export function submitEmotionWordsContributionInManageSurvey(data) {
  return {
    type: SUBMIT_EMOTION_WORDS_CONTRIBUTION_IN_MANAGE_SURVEY,
    payload: data,
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_EMOTION_WORDS_CONTRIBUTION_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function addFieldsErrors(errors) {
  return {
    type: ADD_EMOTION_WORDS_CONTRIBUTION_FIELDS_ERRORS,
    payload: {
      errors,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_EMOTION_WORDS_CONTRIBUTION_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function resetEmotionWordsContributionFormData() {
  return {
    type: RESET_EMOTION_WORDS_CONTRIBUTION_FORM_DATA,
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_EMOTION_WORDS_CONTRIBUTION_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

export function predefineEmotionWordsContributionForm(data) {
  return {
    type: PREDEFINE_EMOTION_WORDS_CONTRIBUTION_FORM_DATA,
    payload: data,
  }
}

export function loadInitialEmotionWords() {
  return {
    type: LOAD_EMOTION_WORDS_CONTRIBUTION,
  }
}

import { Country } from 'common/types'

export const TOGGLE_COUNTRY_STATUS = 'TOGGLE_COUNTRY_STATUS'

export type ToggleCountryStatusPayload = {
  code: string
  status: Country['status']
}

export function toggleCountryStatus(payload: ToggleCountryStatusPayload) {
  return {
    type: TOGGLE_COUNTRY_STATUS,
    payload,
  }
}

export const OPEN_NAV_DROPDOWN = 'OPEN_NAV_DROPDOWN'
export const CLOSE_NAV_DROPDOWN = 'CLOSE_NAV_DROPDOWN'

export function openNavDropdown(dropdownName) {
  return {
    type: OPEN_NAV_DROPDOWN,
    payload: dropdownName,
  }
}

export function closeNavDropdown() {
  return {
    type: CLOSE_NAV_DROPDOWN,
  }
}

import _ from 'lodash'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { staffCharterRevisionService } from 'page-modules/charter/staff-charter/service'
import { getSchoolUsersService } from 'page-modules/dashboard/school-details/service/'

import {
  CLOSE_STAFF_CHARTER_SURVEY,
  CREATE_STAFF_CHARTER_SURVEY,
  createStaffCharterSurveyPreview,
  LOAD_STAFF_CHARTER_SURVEYS,
  PREVIEW_STAFF_CHARTER_SURVEY,
  toggleStaffCharterSurveyPreview,
} from './actions'
import { toggleStaffCharterSurveyDetails } from '../list/service/actions'

import {
  createStaffCharterSurveyService,
  getLatestStaffCharterSurveyService,
  getStaffCharterSurveysService,
} from './index'

export function* loadStaffCharterSurveys() {
  const schoolId = yield select((state) => state.root.user.school_id)

  yield all([
    call(getStaffCharterSurveysService.requestSaga, {}),
    call(getSchoolUsersService.requestSaga, {
      payload: {
        urlParams: { schoolId },
      },
    }),
  ])
}

export function* createStaffCharterSurveySaga({ payload: { data } }) {
  yield call(createStaffCharterSurveyService.requestSaga, {
    payload: { data },
  })
  const survey = yield call(getLatestStaffCharterSurveyService.requestSaga, {})
  yield put(toggleStaffCharterSurveyPreview())
  yield loadStaffCharterSurveys()
  yield put(toggleStaffCharterSurveyDetails({ surveyId: survey.id }))
}

export function* closeStaffCharterSurveySaga({ payload: { data } }) {
  const response = yield call(createStaffCharterSurveyService.requestSaga, {
    payload: { data },
  })
  yield loadStaffCharterSurveys()
  if (_.get(response, 'error.response.status') === 400) {
    // No active survey
    return
  }
  yield put(getLatestStaffCharterSurveyService.actions.request({ payload: {} }))
}

export function* previewStaffCharterSurveySaga() {
  const charter = yield call(staffCharterRevisionService.requestSaga, {})
  const surveyPreviewData = _.reduce(
    charter.emotion_words,
    (result, word) => {
      result.emotionWords.push({
        emotion_word_id: word.id,
        name: word.name,
      })
      _.each(word.actions, (action) => {
        result.actions.push({
          emotion_word_id: word.id,
          action,
        })
      })
      return result
    },
    { emotionWords: [], actions: [] },
  )
  yield put(createStaffCharterSurveyPreview(surveyPreviewData))
}

export default function* saga() {
  yield all([
    takeLatest(CLOSE_STAFF_CHARTER_SURVEY, closeStaffCharterSurveySaga),
    takeLatest(CREATE_STAFF_CHARTER_SURVEY, createStaffCharterSurveySaga),
    takeLatest(PREVIEW_STAFF_CHARTER_SURVEY, previewStaffCharterSurveySaga),
    takeLatest(LOAD_STAFF_CHARTER_SURVEYS, loadStaffCharterSurveys),
  ])
}

import buildService from 'common/utils/build-service'
import { GET } from 'common/requests'

export default buildService({
  type: GET,
  constantsPrefix: 'AUDIENCE_TYPES',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ audienceId }) => {
      if (audienceId) {
        return `/audience_types?id=eq.${audienceId}`
      }
      return `/audience_types`
    },
  },
})

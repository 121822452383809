import _ from 'lodash'
import Cookie from 'js-cookie'
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'

import lang from 'lang'
import { validateField, validateFields } from 'common/validator'
import { httpRequest } from 'services/saga'
import { getApiUrl } from 'services/selectors'
import { requests } from 'common/requests'
import { translateResponseError } from 'common/utils'

import {
  addFieldError,
  clearFieldError,
  REGISTER_REQUEST,
  registerFailure,
  registerSuccess,
  VALIDATE_REGISTER_FIELD,
} from './actions'

function* registerSaga({ payload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const apiUrl = yield select(getApiUrl)
    try {
      const { data } = yield call(httpRequest, {
        req: requests.post,
        url: `${apiUrl}/rpc/signup`,
        headers: {
          Prefer: 'params=single-object',
        },
        data: {
          name: payload.fullName.value,
          email: payload.email.value,
          password: payload.password.value,
          registration_code: payload.registrationCode.value,
        },
      })
      Cookie.set('ruler-jwt', data.token)
      yield put(registerSuccess(data))
      yield call(() => {
        window.location = '/dashboard'
      })
    } catch (error) {
      yield put(
        registerFailure({
          base: translateResponseError(error, lang.auth.register),
        }),
      )
    }
  } else {
    yield put(registerFailure(errors))
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* Saga() {
  yield all([
    takeLatest(REGISTER_REQUEST, registerSaga),
    takeEvery(VALIDATE_REGISTER_FIELD, validateFieldSaga),
  ])
}

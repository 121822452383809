import _ from 'lodash'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { validateField, validateFields } from 'common/validator'
import { downloadCsv } from 'common/utils/download-csv'
import { patchUserService, postUserService } from 'services/users'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  resetYceiUserFormData,
  SUBMIT_YCEI_USER,
  VALIDATE_YCEI_USER_FIELD,
} from './actions'

import {
  downloadMemberListService,
  getAdminUsersStatistics,
  getYceiUsersService,
  getYceiUsersStatistics,
} from './index'

export function* submitYceiUserSaga({ payload }) {
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const data = {
      email: payload.email.value,
      name: payload.fullName.value,
      role: payload.roleId.value,
      person_type_id: payload.personTypeId.value || null,
      district_id: null,
      school_id: null,
    }

    if (payload.id.value) {
      yield put(
        patchUserService.actions.request({
          data,
          urlParams: {
            id: payload.id.value,
          },
        }),
      )
    } else {
      const userResponse = yield call(postUserService.requestSaga, {
        payload: { data },
      })
      if (userResponse.error) return
      yield put(resetYceiUserFormData())
      yield call(getYceiUsersService.requestSaga, {
        payload: {
          urlParams: { districtId: userResponse.me.district_id },
        },
      })
      yield call(getYceiUsersStatistics.requestSaga, {
        payload: {
          data: {
            scope: 'global',
          },
        },
      })
      yield call(getAdminUsersStatistics.requestSaga, {
        payload: {
          data: {
            scope: 'global',
          },
        },
      })
    }
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export function downloadMemberListSaga(actionData) {
  const csvData = actionData.payload.data
  downloadCsv(csvData, 'ruler-member-list.csv')
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_YCEI_USER_FIELD, validateFieldSaga),
    takeLatest(SUBMIT_YCEI_USER, submitYceiUserSaga),
    takeLatest(downloadMemberListService.constants.REQUEST_SUCCESS, downloadMemberListSaga),
  ])
}

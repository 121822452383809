import _ from 'lodash'

import {
  ADD_SHOWCASE_FIELD_ERROR,
  ADD_SHOWCASE_FIELDS_ERRORS,
  CHANGE_SHOWCASE_FORM_FIELD,
  CLEAR_SHOWCASE_FIELD_ERROR,
  PREDEFINE_SHOWCASE_FORM_DATA,
  RESET_SHOWCASE_FORM_DATA,
} from './actions'

const defaultFormData = (predefined = {}) =>
  _.merge(
    {
      id: null, // for promote showcase only
      slug: null, // for promote showcase only
      body: '',
      author: '',
      authorPhotoUrl: null, // for promote showcase only
      fileUrl: '',
      fileType: '',
      isStatic: false,
    },
    _.pickBy(predefined, _.identity),
  )

const initialState = () => ({
  data: defaultFormData(),
  errors: {},
})

export default function reducer(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_SHOWCASE_FORM_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.key]: action.payload.value,
        },
      }

    case ADD_SHOWCASE_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      }

    case ADD_SHOWCASE_FIELDS_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      }

    case CLEAR_SHOWCASE_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: undefined,
        },
      }

    case RESET_SHOWCASE_FORM_DATA:
      return {
        ...state,
        data: defaultFormData(),
        errors: {},
      }

    case PREDEFINE_SHOWCASE_FORM_DATA:
      return {
        ...state,
        data: defaultFormData(action.payload),
        errors: {},
      }

    default:
      return state
  }
}

import { all, call, takeEvery } from 'redux-saga/effects'

import mixpanel from 'lib/mixpanel'
import { District, School, User } from 'common/types'
import { schoolDetailsService } from 'page-modules/dashboard/school-details/service'
import { getDistrictDetailsService } from 'page-modules/dashboard/district-details/service'

import { BOOT_MIXPANEL } from './actions'

function* bootMixpanelSaga({ payload }: { payload: { user: User } }) {
  const user = payload.user

  let school: School | undefined
  let district: District | undefined
  if (user.school_id) {
    // @ts-expect-error no saga types
    school = yield call(schoolDetailsService.requestSaga, {
      payload: { urlParams: { id: user.school_id } },
    })
  }
  if (user.district_id) {
    // @ts-expect-error no saga types
    district = yield call(getDistrictDetailsService.requestSaga, {
      payload: { urlParams: { id: user.district_id } },
    })
  }
  mixpanel.boot(user, school, district)
}

export default function* saga() {
  // @ts-expect-error no saga types
  yield all([takeEvery(BOOT_MIXPANEL, bootMixpanelSaga)])
}

import _ from 'lodash'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  initLessonSections,
  LOAD_CATEGORY_DETAILS_PAGE_DATA,
  LOAD_GRADE_DETAILS_PAGE_DATA,
  LOAD_LESSON_DETAILS_PAGE_DATA,
  showUpToStep,
} from './actions'
import {
  EarlyChildhoodCategorySlug,
  ElementaryCategorySlug,
  MiddleSchoolCategorySlug,
} from '../static-category-slugs'

import {
  classroomGradeService,
  classroomGradesService,
  classroomGradeUnitsService,
  classroomLessonService,
  earlyChildhoodMaterialsService,
  elementarySchoolMaterialsService,
  middleSchoolsMaterialsService,
  schoolCategoryService,
} from './index'

export function* loadCategoryPageDataSaga({ payload: { categorySlug, req } }) {
  const category = yield call(schoolCategoryService.requestSaga, {
    payload: { req, urlParams: { slug: categorySlug } },
  })
  yield call(classroomGradesService.requestSaga, {
    payload: { req, urlParams: { categoryId: category.id } },
  })
  if (categorySlug === EarlyChildhoodCategorySlug) {
    yield call(earlyChildhoodMaterialsService.requestSaga, {
      payload: { req, unauthorized: true },
    })
  }
}

export function* loadGradePageDataSaga({ payload: { gradeSlug, categorySlug, req } }) {
  yield all([
    call(classroomGradeService.requestSaga, { payload: { req, urlParams: { gradeSlug } } }),
    call(classroomGradeUnitsService.requestSaga, { payload: { req, urlParams: { gradeSlug } } }),
  ])
  if (categorySlug === ElementaryCategorySlug) {
    yield call(elementarySchoolMaterialsService.requestSaga, {
      payload: { req, unauthorized: true },
    })
  } else if (categorySlug === MiddleSchoolCategorySlug) {
    yield call(middleSchoolsMaterialsService.requestSaga, {
      payload: { req, unauthorized: true },
    })
  }
}

export function* loadLessonPageDataSaga({ payload: { lessonSlug, gradeSlug, req } }) {
  yield put(classroomGradeService.actions.request({ req, urlParams: { gradeSlug } }))
  yield put(classroomGradeUnitsService.actions.request({ req, urlParams: { gradeSlug } }))
  const lesson = yield call(classroomLessonService.requestSaga, {
    payload: { req, urlParams: { lessonSlug } },
  })
  yield put(initLessonSections({ sections: lesson.classroom_sections }))
  if (!_.isEmpty(lesson.user_progress) && !_.isEmpty(lesson.user_progress.last_step_id)) {
    yield put(showUpToStep({ stepId: lesson.user_progress.last_step_id }))
  }
}

export default function* saga() {
  yield all([
    takeLatest(LOAD_CATEGORY_DETAILS_PAGE_DATA, loadCategoryPageDataSaga),
    takeLatest(LOAD_GRADE_DETAILS_PAGE_DATA, loadGradePageDataSaga),
    takeLatest(LOAD_LESSON_DETAILS_PAGE_DATA, loadLessonPageDataSaga),
  ])
}

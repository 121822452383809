import _ from 'lodash'
import React from 'react'
import Parser from 'html-react-parser'
import ReactDOMServer from 'react-dom/server'

const REGEX_HASHTAG = /(?:^|\s)(?:#)([a-zA-Z\d_-]+)/gmu

export const extractHashtags = (source) => {
  let match
  const matches = []
  // eslint-disable-next-line no-cond-assign
  while ((match = REGEX_HASHTAG.exec(source))) {
    matches.push(match[1])
  }
  return matches
}

export const replaceHashtags = (source, Processor) => {
  return Parser(
    _.replace(source, REGEX_HASHTAG, (match, hashtag) => {
      return ReactDOMServer.renderToStaticMarkup(<Processor match={match} hashtag={hashtag} />)
    }),
  )
}

import buildService from 'common/utils/build-service'
import { DELETE, GET, POST } from 'common/requests'

export const getMyNudgeSetsService = buildService({
  type: GET,
  constantsPrefix: 'NUDGE_SETS',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/my_nudge_sets',
  },
})

export const getAllNudgeSetsService = buildService({
  type: GET,
  constantsPrefix: 'NUDGE_SETS',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/all_nudge_sets',
  },
})

export const completeNudgeService = buildService({
  type: POST,
  constantsPrefix: 'NUDGE_COMPLETE',
  http: {
    createApiUrlSuffix: () => '/rpc/nudge_complete',
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

export const getSchoolNudgeSetService = buildService({
  type: GET,
  constantsPrefix: 'SCHOOL_NUDGE_SET',
  isArray: true,
  http: {
    createApiUrlSuffix: () => `/school_nudge_sets`,
  },
})

export const postSchoolNudgeSetService = buildService({
  type: POST,
  constantsPrefix: 'SCHOOL_NUDGE_SET',
  http: {
    createApiUrlSuffix: () => '/school_nudge_sets',
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

export const deleteSchoolNudgeSetService = buildService({
  type: DELETE,
  constantsPrefix: 'SCHOOL_NUDGE_SET',
  http: {
    createApiUrlSuffix: ({ nudge_set_id, school_id }) =>
      `/school_nudge_sets?nudge_set_id=eq.${nudge_set_id}&school_id=eq.${school_id}`,
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

export const getTopNudgeSetService = buildService({
  type: GET,
  constantsPrefix: 'TOP_NUDGE_SET',
  isArray: true,
  http: {
    createApiUrlSuffix: () =>
      `/rpc/top_nudge_sets?select=id,total_completed,total_nudges,total_persons,completed_nudge_set`,
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

export const getTopNudgeStatisticsService = buildService({
  type: POST,
  constantsPrefix: 'TOP_NUDGE_SET_STATS',
  isArray: true,
  http: {
    createApiUrlSuffix: () => `/rpc/top_nudge_sets`,
    headers: {
      Accept: 'application/json',
      Prefer: 'params=single-object',
    },
  },
})

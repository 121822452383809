import { all, call, put, takeLatest } from 'redux-saga/effects'

import {
  LOAD_COLLECTION_DETAILS_PAGE,
  LOAD_RESOURCE_DETAILS_PAGE,
  SUBMIT_FAVORITE_FROM_COLLECTION_PAGE,
  SUBMIT_FAVORITE_FROM_FAVORITES_PAGE,
  SUBMIT_FAVORITE_FROM_RESOURCE_PAGE,
} from './actions'

import {
  getCollectionDetailsService,
  getCollectionResourcesService,
  getFavoritesService,
  getResourceDetailsService,
  postFavoriteService,
} from './index'

function* submitFavoriteFromFavoritesPageSaga({ payload }) {
  const favoriteResult = yield call(postFavoriteService.requestSaga, { payload })
  if (favoriteResult.error) return
  yield call(getFavoritesService.requestSaga, {})
}

function* submitFavoriteFromCollectionPageSaga({ payload }) {
  const queryId = payload.data.collection_slug

  const favoriteResult = yield call(postFavoriteService.requestSaga, { payload })
  if (favoriteResult.error) return
  yield call(getCollectionResourcesService.requestSaga, { payload: { urlParams: queryId } })
}

function* submitFavoriteFromResourcePageSaga({ payload }) {
  const queryId = payload.data.resource_slug

  const favoriteResult = yield call(postFavoriteService.requestSaga, { payload })
  if (favoriteResult.error) return
  yield call(getResourceDetailsService.requestSaga, {
    payload: { urlParams: queryId },
  })
}

function* loadResourceDetailsPageSaga({ payload: { req, slug } }) {
  yield call(getResourceDetailsService.requestSaga, {
    payload: { req, urlParams: slug },
  })
}

function* loadCollectionDetailsPageSaga({ payload: { req, slug } }) {
  yield put(getCollectionDetailsService.actions.requestSuccess({}))
  yield put(getCollectionResourcesService.actions.requestSuccess([]))

  yield all([
    call(getCollectionDetailsService.requestSaga, {
      payload: { req, urlParams: slug },
    }),
    call(getCollectionResourcesService.requestSaga, {
      payload: { req, urlParams: slug },
    }),
  ])
}

export default function* saga() {
  yield all([
    takeLatest(SUBMIT_FAVORITE_FROM_FAVORITES_PAGE, submitFavoriteFromFavoritesPageSaga),
    takeLatest(SUBMIT_FAVORITE_FROM_COLLECTION_PAGE, submitFavoriteFromCollectionPageSaga),
    takeLatest(SUBMIT_FAVORITE_FROM_RESOURCE_PAGE, submitFavoriteFromResourcePageSaga),
    takeLatest(LOAD_RESOURCE_DETAILS_PAGE, loadResourceDetailsPageSaga),
    takeLatest(LOAD_COLLECTION_DETAILS_PAGE, loadCollectionDetailsPageSaga),
  ])
}

import _ from 'lodash'
import tabs from './navigation-tabs'

export { tabs }

export enum SettingsTabs {
  profile = 'profile',
  emotionWords = 'emotion-words',
}

export enum StatisticsTabs {
  howWeWantToFeel = 'how-we-want-to-feel',
  roadmapStatistics = 'roadmap-statistics',
  courseStatistics = 'course-statistics',
  resourceStatistics = 'resource-statistics',
  toolsStatistics = 'tools-statistics',
}

export enum ResourcesTabs {
  collections = 'collections',
  glossary = 'glossary',
  favorites = ' favorites',
}

export enum MoodMeterTabs {
  personal = 'personal',
  myGroup = 'my-group',
  sharedGroup = 'shared-group',
}

export enum AdministrationTabs {
  emotionWords = 'emotion-words',
  jobTitles = 'job-titles',
  subjects = 'subjects',
  countries = 'countries',
  states = 'states',
  cities = 'cities',
}

export const urls = {
  root: '/',
  roadmap: '/roadmap',
  resources: (tab?: string) => {
    if (!tab) {
      return '/resources'
    }
    return `/resources/${tab}`
  },
  announcements: '/announcements',
  administration: (tab?: AdministrationTabs) => {
    if (!tab) {
      return '/administration'
    }
    return `/administration/${tab}`
  },
  resourceFavorites: '/resources/favorites',
  resourceDetails: (slug: string) => `/resources/${slug}`,
  collectionDetails: (slug: string) => `/resources/collection/${slug}`,
  dashboard: '/dashboard',
  districts: '/dashboard/districts',
  districtDetails: (id: string, tab?: string) => {
    if (!tab) {
      return `/dashboard/districts/${id}`
    }
    return `/dashboard/districts/${id}/${tab}`
  },
  schools: '/dashboard/schools',
  schoolDetails: (id: string, tab?: string) => {
    if (!tab) {
      return `/dashboard/schools/${id}`
    }
    return `/dashboard/schools/${id}/${tab}`
  },
  settings: (tab?: SettingsTabs) => {
    if (!tab) {
      return `/settings`
    }
    return `/settings/${tab}`
  },
  statistics: (tab?: StatisticsTabs) => {
    if (!tab) {
      return '/dashboard/statistics'
    }
    return `/dashboard/statistics/${tab}`
  },
  moodMeter: (tab?: MoodMeterTabs) => {
    if (!tab) {
      return '/mood-meter'
    }
    return `/mood-meter/${tab}`
  },
  moodMeterGroupDetails: (shortCode = '') => `/mood-meter/plot/group/${shortCode}`,
  moodMeterPlotYour: '/mood-meter/plot/your',
  moodMeterPlotInGroup: (shortCode: string) => `/mood-meter/plot/in-group/${shortCode}`,
  moodMeterPlotInGroupSuccess: (shortCode: string) =>
    `/mood-meter/plot/in-group/${shortCode}/successful`,
  ycei: '/dashboard/ycei',
  courses: '/courses',
  courseDetails: (id: string) => `/courses/${id}`,
  moduleDetails: (id: string) => `/module/${id}`,
  assessmentDetails: (id: string) => `/assessment/${id}`,
  journal: '/journal',
  login: '/login',
  showcase: (tab?: string, slug?: string) => {
    let route = `/showcase`
    if (tab) {
      route = `/showcase/${tab}`
    }
    if (slug) {
      route += `?slug=${slug}`
    }
    return route
  },
  showcaseExplore: (tag: string, slug?: string) => {
    if (!slug) {
      return `/showcase/${tabs.showcase.explore}/${tag}`
    }
    return `/showcase/${tabs.showcase.explore}/${tag}?slug=${slug}`
  },
  notifications: `/notifications`,
  tools: '/tools',
  curriculum: '/curricula',
  rulerize: '/rulerize',
  rulerizeAll: '/rulerize/all',
  charters: (tab?: string) => {
    if (!tab) {
      return '/charters'
    }
    return `/charters/${tab}`
  },
  manageSurveys: (id: string) => `/charters/manage-surveys/${id}`,
  staffCharterContributions: '/charters/contributions',
  charterDetails: (id: string) => `/charters/my/${id}`,
  classroomMaterialsCategory: (id: string) => `/curricula/${id}`,
  classroomMaterialsCategoryUnits: (category: string, id: string) => `/curricula/${category}/${id}`,
  classroomMaterialsCategoryLesson: (category: string, unit: string, id: string) =>
    `/curricula/${category}/${unit}/${id}`,
  browserNotSupported: '/browser-not-supported',
}

// routes
//   .add({ name: 'module', pattern: '/module/:id', page: '/module' })
//   .add({ name: 'course-details', pattern: '/courses/:id', page: '/courses/course-details' })
//   .add({
//     name: 'statistics-details',
//     pattern:
//       '/dashboard/statistics/:tab(how-we-want-to-feel|roadmap-statistics|course-statistics|resource-statistics|tools-statistics)?',
//     page: '/dashboard/statistics',
//   })
//   .add({
//     name: 'mood-meter-details',
//     pattern: '/mood-meter/:tab(personal|my-group|shared-group)?',
//     page: '/mood-meter',
//   })
//   .add({
//     name: 'resources-details',
//     pattern: '/resources/:tab(collections|glossary|favorites)?',
//     page: '/resources',
//   })
//   .add({
//     name: 'administration',
//     pattern: `/administration/:tab(${_.values(tabs.administration).join('|')})?`,
//     page: '/administration',
//   })
//   .add({
//     name: 'settings',
//     pattern: '/settings/:tab(profile|emotion-words)?',
//     page: '/settings',
//   })
//   .add({
//     name: 'showcase',
//     pattern: `/showcase/:tab(${_.values(tabs.showcase).join('|')})?`,
//     page: '/showcase',
//   })
//   .add({
//     name: 'showcase-explore',
//     pattern: `/showcase/:tab/:tag`,
//     page: '/showcase',
//   }) // another route is used because of the inability to nest an optional group in a custom matching group. More info - https://github.com/pillarjs/path-to-regexp/issues/113
//   .add({
//     name: 'district-details',
//     pattern: '/dashboard/districts/:id/:tab(district-users|district-schools)?',
//     page: '/dashboard/district-details',
//   })
//   .add({
//     name: 'school-details',
//     pattern:
//       '/dashboard/schools/:id/:tab(school-users-list|staff-charter|how-we-want-to-feel|roadmap-statistics|course-statistics|resource-statistics|tools-statistics|school-implementation-team-list)?',
//     page: '/dashboard/school-details',
//   })
//   .add({
//     name: 'collection-details',
//     pattern: '/resources/collection/:id',
//     page: '/resources/collection-details',
//   })
//   .add({
//     name: 'resource-details',
//     pattern: '/resources/:id',
//     page: '/resources/resource-details',
//   })
//   .add({
//     name: 'mood-meter-group-details',
//     pattern: '/mood-meter/plot/group/:shortCode',
//     page: '/mood-meter/plot/group',
//   })
//   .add({
//     name: 'mood-meter-plot-in-group',
//     pattern: '/mood-meter/plot/in-group/:shortCode',
//     page: '/mood-meter/plot/in-group',
//   })
//   .add({
//     name: 'mood-meter-plot-in-group-successful',
//     pattern: '/mood-meter/plot/in-group/:shortCode/successful',
//     page: 'mood-meter/plot/in-group-successful',
//   })
//   .add({
//     name: 'assessment',
//     pattern: '/assessment/:id',
//     page: '/assessment',
//   })
//   .add({
//     name: 'charters',
//     pattern: `/charters/:tab(${_.values(tabs.charter).join('|')})?`,
//     page: '/charters',
//   })
//   .add({
//     name: 'manage-survey',
//     pattern: '/charters/:tab/:survey',
//     page: '/charters',
//   }) // another route is used because of the inability to nest an optional group in a custom matching group. More info - https://github.com/pillarjs/path-to-regexp/issues/113
//   .add({
//     name: 'charter-details',
//     pattern: '/charters/my/:id',
//     page: '/charters/charter-details',
//   })
//   .add({
//     name: 'curriculum-lesson',
//     pattern: '/curricula/:category/:grade/:lesson',
//     page: '/curricula/category/lesson',
//   })
//   .add({
//     name: 'curriculum-category-grade',
//     pattern: '/curricula/:category/:grade',
//     page: '/curricula/category/grade',
//   })
//   .add({
//     name: 'curriculum-category-details',
//     pattern: '/curricula/:slug',
//     page: '/curricula/category',
//   })

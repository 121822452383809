import _ from 'lodash'
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { changeModal } from 'services/actions'
import { validateField, validateFields } from 'common/validator'
import { postShowcaseService } from 'services/showcase'
import { loadShowcasesSaga } from 'services/showcase/filter/saga'
import { extractHashtags } from 'common/utils/parse-hashtag'

import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  SUBMIT_SHOWCASE,
  VALIDATE_SHOWCASE_FIELD,
} from './actions'

export function* submitShowcaseSaga({ payload: data }) {
  const errors = validateFields(data)
  if (!_.isEmpty(errors)) {
    yield put(addFieldsErrors(errors))
    return
  }

  const showcaseData = {
    name: '', // name is required in the backend
    body: data.body.value,
    tags: _.uniq(extractHashtags(data.body.value)),
    file_url: data.fileUrl.value,
    is_static: data.isStatic.value,
    metadata: {
      file_type: data.fileType.value,
      author: data.author.value,
    },
  }

  if (data.id && data.id.value) {
    showcaseData.metadata.original_post_id = data.id.value
  }

  if (data.slug && data.slug.value) {
    showcaseData.metadata.original_post_slug = data.slug.value
  }

  if (data.authorPhotoUrl && data.authorPhotoUrl.value) {
    showcaseData.metadata.author_photo_url = data.authorPhotoUrl.value
  }

  const result = yield call(postShowcaseService.requestSaga, { payload: { data: showcaseData } })
  const message = _.get(result, 'error.response.data.message')
  if (!_.isEmpty(message)) {
    yield put(addFieldError({ field: 'base', error: message }))
  }

  yield* loadShowcasesSaga()
  yield put(changeModal({ isOpen: false }))
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeLatest(SUBMIT_SHOWCASE, submitShowcaseSaga),
    takeEvery(VALIDATE_SHOWCASE_FIELD, validateFieldSaga),
  ])
}

import { tabs } from 'common/routes'

import {
  CHANGE_SHOWCASE_FILTER,
  CHANGE_SHOWCASE_FILTERS,
  CHANGE_SHOWCASE_PAGE,
  CHANGE_SHOWCASE_SCHOOL,
  CHANGE_SHOWCASE_STATUS,
  CHANGE_SHOWCASE_TAG,
  LOAD_COMMUNITY_SHOWCASES_PAGE,
  LOAD_REVIEW_SHOWCASES_PAGE,
  LOAD_SCHOOL_SHOWCASES_PAGE,
  LOAD_TAG_SHOWCASES_PAGE,
  RESET_SHOWCASE_FILTERS,
  RESET_SHOWCASE_SCHOOL,
} from './actions'

const initialState = () => ({
  schoolId: '',
  status: 'published',
  isStatic: false,
  page: 0,
  tab: '',
  tag: '',
})

export default function reducer(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_SHOWCASE_FILTER:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      }

    case RESET_SHOWCASE_FILTERS:
      return initialState()

    case CHANGE_SHOWCASE_FILTERS:
      return {
        ...state,
        ...action.payload,
      }

    case CHANGE_SHOWCASE_PAGE:
      return {
        ...state,
        page: action.payload,
      }

    case CHANGE_SHOWCASE_STATUS:
      return {
        ...state,
        status: action.payload,
        page: initialState().page,
      }

    case RESET_SHOWCASE_SCHOOL:
      return {
        ...state,
        schoolId: initialState().schoolId,
        page: initialState().page,
      }

    case CHANGE_SHOWCASE_SCHOOL:
      return {
        ...state,
        schoolId: action.payload,
        page: initialState().page,
      }

    case CHANGE_SHOWCASE_TAG:
      return {
        ...state,
        tag: action.payload,
        page: initialState().page,
      }

    case LOAD_COMMUNITY_SHOWCASES_PAGE:
      return {
        ...state,
        schoolId: initialState().schoolId,
        tab: tabs.showcase.rulerCommunity,
        isStatic: true,
        page: initialState().page,
        status: initialState().status,
      }

    case LOAD_REVIEW_SHOWCASES_PAGE:
      return {
        ...state,
        schoolId: initialState().schoolId,
        tab: tabs.showcase.review,
        isStatic: false,
        page: initialState().page,
        status: initialState().status,
      }

    case LOAD_SCHOOL_SHOWCASES_PAGE:
      return {
        ...state,
        schoolId: action.payload.schoolId,
        tab: tabs.showcase.mySchool,
        isStatic: false,
        page: initialState().page,
        status: initialState().status,
      }

    case LOAD_TAG_SHOWCASES_PAGE:
      return {
        ...state,
        tag: action.payload.tag,
        tab: tabs.showcase.explore,
        status: initialState().status,
        page: initialState().page,
      }

    default:
      return state
  }
}

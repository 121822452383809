import _ from 'lodash'

import {
  INIT_LESSON_SECTIONS,
  LESSON_SHOW_UP_TO_STEP,
  SHOW_LESSON_OUTRO,
  SHOW_LESSON_SECTION,
} from './actions'

import { postLessonProgressService } from './index'

export const initialState = () => ({
  sections: [],
  outroShown: false,
})

export default function reducer(state = initialState(), action) {
  switch (action.type) {
    case INIT_LESSON_SECTIONS: {
      return {
        ...state,
        sections: _.map(action.payload.sections, (section) => ({
          id: section.id,
          lastStepId: _.get(_.last(section.steps), 'id'),
          shown: false,
          viewed: false,
        })),
        outroShown: false,
      }
    }

    case postLessonProgressService.constants.REQUEST:
      return {
        ...state,
        sections: _.map(state.sections, (section) => {
          if (section.lastStepId === action.payload.data.step_id) {
            return {
              ...section,
              viewed: true,
            }
          }
          return section
        }),
      }

    case SHOW_LESSON_SECTION: {
      return {
        ...state,
        sections: _.map(state.sections, (section) => {
          if (section.id === action.payload.sectionId) {
            return {
              ...section,
              shown: true,
            }
          }
          return section
        }),
      }
    }

    // Shows all sections up to a section which has it's last step id matching with the passed id(action.payload.stepId)
    // The section which has the matching last step id is also shown.
    // This relies on the fact that only last step ids of sections can be passed in the payload.
    case LESSON_SHOW_UP_TO_STEP: {
      let targetStepReached = false

      return {
        ...state,
        sections: _.map(state.sections, (section) => {
          const tranformedSection = { ...section }
          // Show the section and mark it as viewed(it has been viewed by the user in a previous session).
          if (!targetStepReached) {
            tranformedSection.shown = true
            tranformedSection.viewed = true

            if (section.lastStepId === action.payload.stepId) {
              targetStepReached = true
            }
          }
          return tranformedSection
        }),
      }
    }

    case SHOW_LESSON_OUTRO:
      return {
        ...state,
        outroShown: true,
      }

    default:
      return state
  }
}

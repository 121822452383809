import getConfig from 'next/config'

import buildService from 'common/utils/build-service'

const { publicRuntimeConfig } = getConfig()
const getStaticCurriculaEnv = () => {
  if (publicRuntimeConfig.SERVER_ENV === 'production') {
    return 'production'
  }
  return 'staging'
}

export const schoolCategoriesService = buildService({
  type: 'GET',
  constantsPrefix: 'SCHOOL_CATEGORIES',
  isArray: true,
  http: {
    createApiUrlSuffix: () => '/school_categories?order=weight.asc',
  },
})

export const schoolCategoryService = buildService({
  type: 'GET',
  constantsPrefix: 'SCHOOL_CATEGORY_DETAILS',
  http: {
    createApiUrlSuffix: ({ slug }) => `/school_categories?slug=eq.${slug}`,
    headers: {
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const classroomGradesService = buildService({
  type: 'GET',
  constantsPrefix: 'CLASSROOM_GRADES',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ categoryId }) =>
      `/classroom_grades?school_category_id=eq.${categoryId}&select=*,grade:grades(*)&order=weight.asc`,
  },
})

export const classroomGradeService = buildService({
  type: 'GET',
  constantsPrefix: 'CLASSROOM_GRADE',
  http: {
    createApiUrlSuffix: ({ gradeSlug }) =>
      `/classroom_grades?slug=eq.${gradeSlug}&select=*,school_category:school_categories(*)`,
    headers: {
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const classroomGradeUnitsService = buildService({
  type: 'GET',
  constantsPrefix: 'CLASSROOM_GRADE_UNITS',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ gradeSlug }) =>
      `/classroom_grade_units?classroom_grade_slug=eq.${gradeSlug}&order=weight.asc`,
  },
})

export const classroomLessonService = buildService({
  type: 'GET',
  constantsPrefix: 'CLASSROOM_LESSON',
  http: {
    createApiUrlSuffix: ({ lessonSlug }) => `/classroom_lesson_details?slug=eq.${lessonSlug}`,
    headers: {
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const postLessonProgressService = buildService({
  type: 'POST',
  constantsPrefix: 'LESSON_PROGRESS',
  http: {
    createApiUrlSuffix: () => '/rpc/classroom_lesson_progress',
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

export const elementarySchoolMaterialsService = buildService({
  type: 'GET',
  constantsPrefix: 'ELEMENTARY_SCHOOL_STRUCTURE',
  http: {
    apiUrl: 'https://ruleronlineimgs.s3.amazonaws.com',
    createApiUrlSuffix: () => `/curricula/${getStaticCurriculaEnv()}/k-5/structure.json`,
    headers: {
      Accept: 'application/json',
    },
  },
})

export const earlyChildhoodMaterialsService = buildService({
  type: 'GET',
  constantsPrefix: 'EARLY_CHILDHOOD_STRUCTURE',
  http: {
    apiUrl: 'https://ruleronlineimgs.s3.amazonaws.com',
    createApiUrlSuffix: () => `/curricula/${getStaticCurriculaEnv()}/pre-k/structure.json`,
    headers: {
      Accept: 'application/json',
    },
  },
})

export const middleSchoolsMaterialsService = buildService({
  type: 'GET',
  constantsPrefix: 'MIDDLE_SCHOOL_STRUCTURE',
  http: {
    apiUrl: 'https://ruleronlineimgs.s3.amazonaws.com',
    createApiUrlSuffix: () => `/curricula/${getStaticCurriculaEnv()}/middle-school/structure.json`,
    headers: {
      Accept: 'application/json',
    },
  },
})

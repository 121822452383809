import { User } from 'common/types'
import { StateAction } from 'services/actions'

export const BOOT_MIXPANEL = 'BOOT_MIXPANEL'

export function bootMixpanel(user: User): StateAction<typeof BOOT_MIXPANEL, { user: User }> {
  return {
    type: BOOT_MIXPANEL,
    payload: { user },
  }
}

// @ts-check

const userLabels = {
  teacher: 'Teacher',
  staff: 'Staff',
  leadership: 'Leadership',
  ycei_leadership: 'YCEI Leadership',
  ycei_coach: 'YCEI Coach',
  ycei_customer_support: 'YCEI Customer Support',
  ycei_research: 'YCEI Research',
}

export default userLabels

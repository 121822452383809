import React from 'react'
import _ from 'lodash'
import cn from 'classnames'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import lang from 'lang'
import {
  changeSchoolUserFormField,
  validateField,
} from 'page-modules/dashboard/school-details/service/actions'

@connect(
  (state, ownProps) => ({
    errors: state.school.addSchoolUser.form[ownProps.formId].errors,
    email: state.school.addSchoolUser.form[ownProps.formId].formData.email,
    fullName: state.school.addSchoolUser.form[ownProps.formId].formData.fullName,
    roleId: state.school.addSchoolUser.form[ownProps.formId].formData.roleId,
    personTypeId: state.school.addSchoolUser.form[ownProps.formId].formData.personTypeId,
    personRoles: state.personRoles.items,
    personTypes: state.personTypes.items,
  }),
  (dispatch) => ({
    changeFormField: (key, value, formId) =>
      dispatch(changeSchoolUserFormField(key, value, formId)),
    validateField: (field, value, validations, formId) =>
      dispatch(validateField({ field, value, validations, formId })),
  }),
)
class SchoolUsersForm extends React.Component {
  static propTypes = {
    errors: PropTypes.object,
    email: PropTypes.string,
    fullName: PropTypes.string,
    roleId: PropTypes.string,
    personTypeId: PropTypes.string,
    personRoles: PropTypes.array,
    personTypes: PropTypes.array,
    changeFormField: PropTypes.func,
    validateField: PropTypes.func,
    formId: PropTypes.string,
  }

  static validations = {
    email: 'nonEmpty email',
    fullName: 'nonEmpty alphanumericWithAccents',
    roleId: 'nonEmpty',
    personTypeId: 'nonEmpty',
  }

  fieldHandler(fieldName) {
    return (e) => {
      this.props.changeFormField(fieldName, e.target.value, this.props.formId)
    }
  }

  fieldValidationHandler(field, validations) {
    return () => {
      this.props.validateField(field, this.props[field], validations, this.props.formId)
    }
  }

  renderEmailField() {
    return (
      <>
        <label htmlFor="email" className="show-for-sr">
          {lang.email}
        </label>
        <input
          id="email"
          placeholder="Email"
          type="text"
          value={this.props.email}
          onChange={this.fieldHandler('email')}
          onBlur={this.fieldValidationHandler('email', SchoolUsersForm.validations.email)}
        />
        <span className={cn('form-error', { 'is-visible': this.props.errors.email })}>
          {this.props.errors.email}
        </span>
      </>
    )
  }

  renderFullNameField() {
    return (
      <>
        <label htmlFor="full-name" className="show-for-sr">
          {lang.fullName}
        </label>
        <input
          id="full-name"
          placeholder={lang.fullName}
          type="text"
          value={this.props.fullName}
          onChange={this.fieldHandler('fullName')}
          onBlur={this.fieldValidationHandler('fullName', SchoolUsersForm.validations.fullName)}
        />
        <span className={cn('form-error', { 'is-visible': this.props.errors.fullName })}>
          {this.props.errors.fullName}
        </span>
      </>
    )
  }

  roleChangeHandler(roleId) {
    return () => {
      // Here we're implementing toggling of a role radio button
      // because users may accidentally click on a radio button when creating multiple users
      // and there's no way to unclick it, thus validations will deny submitting the entire form
      if (this.props.roleId === roleId) {
        this.props.changeFormField('roleId', '', this.props.formId)
      } else {
        this.props.changeFormField('roleId', roleId, this.props.formId)
      }
    }
  }

  renderPersonRoles() {
    return _.map(_.filter(this.props.personRoles, { ui: true }), (role) => {
      const key = `${this.props.formId}-${role.id}`
      return (
        <label key={key} htmlFor={`role-${key}`}>
          <input
            name="ruler-role"
            id={`role-${key}`}
            role-id={role.id}
            type="radio"
            value={role.id}
            checked={this.props.roleId === role.id}
            onClick={this.roleChangeHandler(role.id)}
            onChange={this.roleChangeHandler(role.id)}
          />{' '}
          {lang.userRoles[role.id]}
        </label>
      )
    })
  }

  renderPersonRoleField() {
    return (
      <>
        <fieldset className="inline-controls">
          <legend>{lang.dashboard.schoolUserForm.rulerOnlineRole}</legend>
          {this.renderPersonRoles()}
        </fieldset>
        <span className={cn('form-error', { 'is-visible': this.props.errors.roleId })}>
          {this.props.errors.roleId}
        </span>
      </>
    )
  }

  personTypeChangeHandler(personTypeId) {
    return () => {
      if (this.props.personTypeId === personTypeId) {
        this.props.changeFormField('personTypeId', '', this.props.formId)
      } else {
        this.props.changeFormField('personTypeId', personTypeId, this.props.formId)
      }
    }
  }

  renderPersonTypes() {
    return _.map(this.props.personTypes, (personType) => {
      const typeIndex = personType.name.toLowerCase()
      const key = `${this.props.formId}-${personType.id}`
      return (
        <label key={key} htmlFor={`person-type-${key}`}>
          <input
            name="ruler-person-type"
            id={`person-type-${key}`}
            person-type={typeIndex}
            type="radio"
            value={personType.id}
            checked={this.props.personTypeId === personType.id}
            onChange={this.personTypeChangeHandler(personType.id)}
            onBlur={this.fieldValidationHandler(
              'personTypeId',
              SchoolUsersForm.validations.personTypeId,
            )}
          />{' '}
          {lang.personTypes[typeIndex]}
        </label>
      )
    })
  }

  renderPersonTypeField() {
    return (
      <>
        <fieldset className="inline-controls">
          <legend>{lang.dashboard.schoolUserForm.rulerOnlinePersonType}</legend>
          {this.renderPersonTypes()}
        </fieldset>
        <span className={cn('form-error', { 'is-visible': this.props.errors.personTypeId })}>
          {this.props.errors.personTypeId}
        </span>
      </>
    )
  }

  render() {
    return (
      <form>
        <span className={cn('form-error', { 'is-visible': this.props.errors.base })}>
          {this.props.errors.base}
        </span>

        <div className="school-users-add-list">
          <div className="grid-x grid-margin-x">
            <div className="medium-6 cell">{this.renderEmailField()}</div>
            <div className="medium-6 cell">{this.renderFullNameField()}</div>
            <div className="small-12 cell" id="schoolUserRoles">
              {this.renderPersonRoleField()}
            </div>
            <div className="small-12 cell" id="schoolUserTypes">
              {this.renderPersonTypeField()}
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default SchoolUsersForm

import buildService from 'common/utils/build-service'
import { GET } from 'common/requests'

export default buildService({
  type: GET,
  constantsPrefix: 'PERSON_ROLES',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ memberOf, like, scope }) => {
      if (memberOf) {
        return `/person_roles?memberof=cs.{${memberOf}}`
      }
      if (scope) {
        return `/person_roles?scope=ov.{${scope}}`
      }
      if (like) {
        return `/person_roles?id=like.*${like}*`
      }
      return '/person_roles'
    },
  },
})

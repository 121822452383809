import { put } from 'redux-saga/effects'

import buildService from 'common/utils/build-service'
import { changeModal } from 'services/actions'
import {
  addFieldError,
  resetSchoolFormData,
} from 'page-modules/dashboard/school-creation/service/actions'
import { translateResponseError } from 'common/utils'
import { POST } from 'common/requests'

export const postSchoolService = buildService({
  type: POST,
  constantsPrefix: 'SCHOOL',
  http: {
    createApiUrlSuffix: () => '/rpc/school',
    *successHandler() {
      yield put(changeModal({ isOpen: false }))
      yield put(resetSchoolFormData())
    },
    *failureHandler(error) {
      yield put(
        addFieldError({
          field: 'base',
          error: translateResponseError(error),
        }),
      )
    },
    headers: {
      Prefer: 'params=single-object',
    },
  },
})

export const getTotalSchoolsService = buildService({
  type: POST,
  constantsPrefix: 'TOTAL_SCHOOLS',
  http: {
    createApiUrlSuffix: () => '/rpc/total_schools_by_type',
    headers: {
      Accept: 'application/vnd.pgrst.object+json',
      Prefer: 'params=single-object',
    },
  },
})

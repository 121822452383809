import _ from 'lodash'
import { combineReducers } from 'redux'

import {
  ADD_APPROVE_CONTRIBUTIONS_FIELD_ERROR,
  ADD_APPROVE_CONTRIBUTIONS_FIELDS_ERRORS,
  CHANGE_APPROVE_CONTRIBUTIONS_FORM_FIELD,
  CLEAR_APPROVE_CONTRIBUTIONS_FIELD_ERROR,
  INIT_STAFF_CHARTER_APPROVAL_EMOTION_WORDS,
  PREDEFINE_APPROVE_CONTRIBUTIONS_FORM_DATA,
  RESET_APPROVE_CONTRIBUTIONS_FORM_DATA,
} from './actions'

const defaultFormData = (predefined = {}) =>
  _.merge({ schoolId: null, approvedEmotionWords: [] }, _.pickBy(predefined, _.identity))

const initialState = () => ({
  data: defaultFormData(),
  errors: {},
})

const formReducer = (state = initialState(), action) => {
  switch (action.type) {
    case CHANGE_APPROVE_CONTRIBUTIONS_FORM_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.key]: action.payload.value,
        },
      }

    case ADD_APPROVE_CONTRIBUTIONS_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      }

    case ADD_APPROVE_CONTRIBUTIONS_FIELDS_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      }

    case CLEAR_APPROVE_CONTRIBUTIONS_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: undefined,
        },
      }

    case RESET_APPROVE_CONTRIBUTIONS_FORM_DATA:
      return {
        ...state,
        data: defaultFormData(),
        errors: {},
      }

    case PREDEFINE_APPROVE_CONTRIBUTIONS_FORM_DATA:
      return {
        ...state,
        data: defaultFormData(action.payload),
        errors: {},
      }

    default:
      return state
  }
}

const emotionWordsReducer = (state = { items: [] }, action) => {
  switch (action.type) {
    case INIT_STAFF_CHARTER_APPROVAL_EMOTION_WORDS:
      return {
        ...state,
        items: action.payload,
      }

    default:
      return state
  }
}

export default combineReducers({
  form: formReducer,
  emotionWords: emotionWordsReducer,
})

import {
  ADD_ONBOARDING_FIELD_ERROR,
  ADD_ONBOARDING_FIELDS_ERRORS,
  CHANGE_ONBOARDING_FORM_FIELD,
  CLEAR_ONBOARDING_FIELD_ERROR,
  RESET_ONBOARDING_FORM_DATA,
} from './actions'

export const defaultFormData = () => ({
  emotionWords: [],
  subjects: [],
  grades: [],
})

export const initialState = () => ({
  formData: defaultFormData(),
  errors: {},
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_ONBOARDING_FORM_FIELD:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.key]: action.payload.value,
        },
      }

    case ADD_ONBOARDING_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      }

    case ADD_ONBOARDING_FIELDS_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      }

    case CLEAR_ONBOARDING_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: undefined,
        },
      }

    case RESET_ONBOARDING_FORM_DATA:
      return {
        ...state,
        formData: defaultFormData(),
        errors: {},
      }

    default:
      return state
  }
}

import React from 'react'
import _ from 'lodash'
import cn from 'classnames'
import { connect } from 'react-redux'

import lang from 'lang'
import Form from 'common/components/form'
import {
  changeDistrictUserFormField,
  validateField,
} from 'page-modules/dashboard/district-details/service/actions'

@connect(
  (state) => ({
    id: state.district.addDistrictUser.form.data.id,
    email: state.district.addDistrictUser.form.data.email,
    fullName: state.district.addDistrictUser.form.data.fullName,
    officePhone: state.district.addDistrictUser.form.data.officePhone,
    mobilePhone: state.district.addDistrictUser.form.data.mobilePhone,
    roleId: state.district.addDistrictUser.form.data.roleId,
    personTypeId: state.district.addDistrictUser.form.data.personTypeId,
    loading: state.district.addDistrictUser.form.loading,
    errors: state.district.addDistrictUser.form.errors,
    personRoles: state.personRoles.items,
    personTypes: state.personTypes.items,
  }),
  (dispatch) => ({
    changeFormField: (key, value) => dispatch(changeDistrictUserFormField(key, value)),
    validateField: (field, value, validations) =>
      dispatch(validateField(typeof field === 'string' ? { field, value, validations } : field)),
  }),
)
export default class DistrictUserForm extends Form {
  static validations = {
    email: 'nonEmpty email',
    fullName: 'nonEmpty alphanumericWithAccents',
    officePhone: 'optional nonEmpty',
    mobilePhone: 'optional nonEmpty',
    roleId: 'nonEmpty',
    personTypeId: 'nonEmpty',
  }

  createRoleHandler() {
    return (e) => {
      this.props.changeFormField('roleId', e.target.value)
    }
  }

  createPersonTypeHandler() {
    return (e) => {
      this.props.changeFormField('personTypeId', e.target.value)
    }
  }

  renderPersonRoles() {
    return _.map(_.filter(this.props.personRoles, { ui: true }), (role) => {
      return (
        <label htmlFor={`role-${role.id}`} key={role.id}>
          <input
            name="ruler-role"
            id={`role-${role.id}`}
            type="radio"
            value={role.id}
            checked={this.props.roleId === role.id}
            onChange={this.createRoleHandler()}
            onBlur={this.createFieldValidationHandler(
              'roleId',
              DistrictUserForm.validations.roleId,
            )}
          />{' '}
          {lang.userRoles[role.id]}
        </label>
      )
    })
  }

  renderPersonTypes() {
    return _.map(this.props.personTypes, (personType) => {
      const typeIndex = personType.name.toLowerCase()
      return (
        <label htmlFor={`person-type-${typeIndex}`} key={personType.id}>
          <input
            name="ruler-person-type"
            id={`person-type-${typeIndex}`}
            type="radio"
            value={personType.id}
            checked={this.props.personTypeId === personType.id}
            onChange={this.createPersonTypeHandler()}
            onBlur={this.createFieldValidationHandler(
              'personTypeId',
              DistrictUserForm.validations.personTypeId,
            )}
          />{' '}
          {lang.personTypes[typeIndex]}
        </label>
      )
    })
  }

  render() {
    return (
      <form action="">
        <span className={cn('form-error', { 'is-visible': this.props.errors.base })}>
          {this.props.errors.base}
        </span>

        <div className="grid-x grid-margin-x">
          <div className="small-12 cell">
            <label htmlFor="email">{lang.email}</label>
            <input
              id="email"
              type="text"
              value={this.props.email}
              onChange={this.createFieldHandler('email')}
              onBlur={this.createFieldValidationHandler(
                'email',
                DistrictUserForm.validations.email,
              )}
            />
            <span className={cn('form-error', { 'is-visible': this.props.errors.email })}>
              {this.props.errors.email}
            </span>
          </div>
          <div className="small-12 cell">
            <label htmlFor="full-name">{lang.fullName}</label>
            <input
              id="full-name"
              type="text"
              value={this.props.fullName}
              onChange={this.createFieldHandler('fullName')}
              onBlur={this.createFieldValidationHandler(
                'fullName',
                DistrictUserForm.validations.fullName,
              )}
            />
            <span className={cn('form-error', { 'is-visible': this.props.errors.fullName })}>
              {this.props.errors.fullName}
            </span>
          </div>
          <div className="small-12 cell">
            <label htmlFor="office-number">
              {lang.dashboard.districtUserForm.officePhoneNumber}
              <span className="option">{lang.optional}</span>
            </label>
            <input
              id="office-number"
              type="text"
              value={this.props.officePhone}
              onChange={this.createFieldHandler('officePhone')}
              onBlur={this.createFieldValidationHandler(
                'officePhone',
                DistrictUserForm.validations.officePhone,
              )}
            />
            <span className={cn('form-error', { 'is-visible': this.props.errors.officePhone })}>
              {this.props.errors.officePhone}
            </span>
          </div>
          <div className="small-12 cell">
            <label htmlFor="mobile-number">
              {lang.dashboard.districtUserForm.mobilePhoneNumber}
              <span className="option">{lang.optional}</span>
            </label>
            <input
              id="mobile-number"
              type="text"
              value={this.props.mobilePhone}
              onChange={this.createFieldHandler('mobilePhone')}
              onBlur={this.createFieldValidationHandler(
                'mobilePhone',
                DistrictUserForm.validations.mobilePhone,
              )}
            />
            <span className={cn('form-error', { 'is-visible': this.props.errors.mobilePhone })}>
              {this.props.errors.mobilePhone}
            </span>
          </div>
        </div>
        <fieldset className="block-controls">
          <legend>{lang.dashboard.districtUserForm.rulerOnlineRole}</legend>
          {this.renderPersonRoles()}
        </fieldset>
        <span className={cn('form-error', { 'is-visible': this.props.errors.roleId })}>
          {this.props.errors.roleId}
        </span>
        <fieldset className="block-controls">
          <legend>{lang.dashboard.districtUserForm.rulerOnlinePersonType}</legend>
          {this.renderPersonTypes()}
        </fieldset>
        <span className={cn('form-error', { 'is-visible': this.props.errors.personTypeId })}>
          {this.props.errors.personTypeId}
        </span>
      </form>
    )
  }
}

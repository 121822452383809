import _ from 'lodash'
import { put } from 'redux-saga/effects'

import lang from 'lang'
import buildService from 'common/utils/build-service'
import { changeModal } from 'services/actions'

import { addFieldError, resetCharterFormData } from './actions'

export const getChartersService = buildService({
  type: 'GET',
  constantsPrefix: 'CHARTERS',
  isArray: true,
  http: {
    createApiUrlSuffix: (params) => {
      let order = ''
      if (params && !_.isEmpty(params.order)) {
        order = `&order=${params.order.fieldName}.${params.order.direction}`
      }
      return `/ext_charters?owner_id=eq.${params.ownerId}${order}`
    },
  },
})

export const getCharterDetailsService = buildService({
  type: 'GET',
  constantsPrefix: 'CHARTER_DETAILS',
  http: {
    createApiUrlSuffix: ({ slug }) => {
      return `/ext_charters?slug=eq.${slug}`
    },
    headers: {
      Accept: 'application/vnd.pgrst.object+json',
    },
  },
})

export const deleteCharterService = buildService({
  type: 'DELETE',
  constantsPrefix: 'CHARTER',
  http: {
    createApiUrlSuffix: ({ id }) => `/charters?id=eq.${id}`,
    *successHandler(data, { id }) {
      yield put(changeModal({ isOpen: false }))
      yield put(getChartersService.actions.removeItem({ id }))
    },
  },
})

export const getCharterTemplatesService = buildService({
  type: 'GET',
  constantsPrefix: 'CHARTER_TEMPLATES',
  isArray: true,
  http: {
    createApiUrlSuffix: () => {
      return `/charter_templates`
    },
  },
})

export const postCharterService = buildService({
  type: 'POST',
  constantsPrefix: 'CHARTER',
  http: {
    createApiUrlSuffix: () => '/rpc/charter',
    headers: {
      Prefer: 'params=single-object',
      Accept: 'application/vnd.pgrst.object+json',
    },
    *successHandler() {
      yield put(changeModal({ isOpen: false }))
      yield put(resetCharterFormData())
    },
    *failureHandler(error) {
      yield put(
        addFieldError({
          field: 'base',
          error: _.get(error, 'response.data.message', lang.unhandledServerError),
        }),
      )
    },
  },
})

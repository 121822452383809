import _ from 'lodash'
import { combineReducers } from 'redux'
import reduceReducers from 'reduce-reducers'

import { getMyStrategiesService } from 'services/strategies'

import {
  ANSWER_ACTIVITY_QUESTION,
  INIT_SECTIONS,
  SHOW_CONGRATULATIONS,
  SHOW_FROM_STEP,
  SHOW_OUTRO,
  SHOW_RULERIZE,
  SHOW_UP_TO_STEP,
  TOGGLE_STRATEGY,
} from './actions'
import postStrategiesSignUpReducer from './post-strategies-sign-up-reducer'

import { moduleService, postActivityResponse, postModuleProgressService } from './index'

export const initialState = () => ({
  sections: [],
  answers: {},
  pageScrollPercentage: 0,
  congratulationsShown: false,
  rulerizeShown: false,
  outroShown: false,
  strategies: [],
})

const progress = (state = initialState(), action) => {
  switch (action.type) {
    case INIT_SECTIONS: {
      const answers = {}
      _.each(action.payload.sections, (section) => {
        _.each(section.steps, (step) => {
          if (step !== null && !_.isEmpty(step.activities) && step.activities[0] !== null) {
            _.each(_.get(step.activities[0], 'questions.list', []), (question, index) => {
              answers[question.id] = {
                stepId: step.id,
                activityId: step.activities[0].id,
                questionId: question.id,
                indexInActivity: index,
                answered: false,
                content: null,
              }
            })
          }
        })
      })

      return {
        ...state,
        congratulationsShown: false,
        rulerizeShown: false,
        outroShown: false,
        answers,
        sections: _.map(action.payload.sections, (section) => ({
          id: section.id,
          weight: section.weight,
          steps: _.map(section.steps, (step) => ({
            id: step.id,
            shown: false,
            viewed: false,
            hasActivity: !_.isEmpty(step.activities),
          })),
        })),
      }
    }

    case postModuleProgressService.constants.REQUEST:
      return {
        ...state,
        sections: _.map(state.sections, (section) => ({
          ...section,
          steps: _.map(section.steps, (step) => {
            if (step.id === action.payload.data.step_id) {
              return {
                ...step,
                viewed: true,
              }
            }
            return step
          }),
        })),
      }

    // Displays the steps from
    //    the step with step id in action.payload.id
    // to
    //    the next step that has an activity in it or is the last step in the section.
    case SHOW_FROM_STEP: {
      let startingStepReached = false
      let nextActvityStepReached = false

      return {
        ...state,
        sections: _.map(state.sections, (section) => ({
          ...section,
          steps: _.map(section.steps, (step, index) => {
            const transformedStep = { ...step }
            if (step.id === action.payload.id) {
              startingStepReached = true
            }
            if (startingStepReached && !nextActvityStepReached) {
              transformedStep.shown = true
            }
            if (startingStepReached && (step.hasActivity || section.steps.length === index + 1)) {
              nextActvityStepReached = true
            }
            return transformedStep
          }),
        })),
      }
    }

    // Displays the steps from
    //    the beggining of the section
    // to
    //    the next step that, has an activity in it or is the last step in the section, AFTER the step with step id in action.payload.id
    case SHOW_UP_TO_STEP: {
      let targetStepReached = false
      let actvityStepReached = false

      return {
        ...state,
        sections: _.map(state.sections, (section) => ({
          ...section,
          steps: _.map(section.steps, (step, index) => {
            const transformedStep = { ...step }
            if (!targetStepReached || !actvityStepReached) {
              transformedStep.shown = true
            }
            // Mark all steps before the target steps as viewed, because the user has already viewed them in a previious session.
            if (!targetStepReached) {
              transformedStep.viewed = true
            }
            if (step.id === action.payload.id) {
              targetStepReached = true
            }
            if (targetStepReached && !actvityStepReached && step.hasActivity) {
              actvityStepReached = true
            }
            if (targetStepReached && !actvityStepReached && section.steps.length === index + 1) {
              actvityStepReached = true
            }
            return transformedStep
          }),
        })),
      }
    }

    case ANSWER_ACTIVITY_QUESTION:
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.payload.question.id]: {
            ...state.answers[action.payload.question.id],
            answered: true,
            content: action.payload.answer,
          },
        },
      }

    case SHOW_CONGRATULATIONS:
      return {
        ...state,
        congratulationsShown: true,
      }

    case SHOW_RULERIZE:
      return {
        ...state,
        rulerizeShown: true,
      }

    case SHOW_OUTRO:
      return {
        ...state,
        outroShown: true,
      }

    case TOGGLE_STRATEGY:
      return {
        ...state,
        strategies: _.xor(state.strategies, [action.payload.id]),
      }

    default:
      return state
  }
}

export default reduceReducers(
  combineReducers({
    details: moduleService.reducer,
    requests: combineReducers({
      postModuleProgress: postModuleProgressService.reducer,
      postActivityResponse: postActivityResponse.reducer,
      postStrategiesSignUp: postStrategiesSignUpReducer,
    }),
    progress,
  }),
  (state, action) => {
    switch (action.type) {
      case getMyStrategiesService.constants.REQUEST_SUCCESS: {
        let newStrategies = []
        if (!_.isEmpty(action.payload.data)) {
          const userActiveStrategiesIds = _.map(
            _.filter(action.payload.data, (s) => !s.completed_at),
            'id',
          )
          newStrategies = _.intersection(
            _.map(state.details.item.strategies, 'id'),
            userActiveStrategiesIds,
          )
        }
        return {
          ...state,
          progress: {
            ...state.progress,
            strategies: newStrategies,
          },
        }
      }

      default:
        return state
    }
  },
)

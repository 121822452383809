export const CHANGE_RESET_PASSWORD_FORM_FIELD = 'CHANGE_RESET_PASSWORD_FORM_FIELD'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_REQUEST_SUCCESS = 'RESET_PASSWORD_REQUEST_SUCCESS'
export const RESET_PASSWORD_REQUEST_FAILURE = 'RESET_PASSWORD_REQUEST_FAILURE'
export const VALIDATE_RESET_PASSWORD_FIELD = 'VALIDATE_RESET_PASSWORD_FIELD'
export const ADD_RESET_PASSWORD_FIELD_ERROR = 'ADD_RESET_PASSWORD_FIELD_ERROR'
export const CLEAR_RESET_PASSWORD_FIELD_ERROR = 'CLEAR_RESET_PASSWORD_FIELD_ERROR'

export function changeResetPasswordFormField(key, value) {
  return {
    type: CHANGE_RESET_PASSWORD_FORM_FIELD,
    payload: {
      key,
      value,
    },
  }
}

export function resetPasswordRequest({ password, jwt }) {
  return {
    type: RESET_PASSWORD_REQUEST,
    payload: {
      password,
      jwt,
    },
  }
}

export function resetPasswordFailure(errors) {
  return {
    type: RESET_PASSWORD_REQUEST_FAILURE,
    errors,
  }
}

export function resetPasswordSuccess() {
  return {
    type: RESET_PASSWORD_REQUEST_SUCCESS,
  }
}

export function addFieldError({ field, error }) {
  return {
    type: ADD_RESET_PASSWORD_FIELD_ERROR,
    payload: {
      error,
      field,
    },
  }
}

export function clearFieldError({ field }) {
  return {
    type: CLEAR_RESET_PASSWORD_FIELD_ERROR,
    payload: {
      field,
    },
  }
}

export function validateField({ field, value, validations }) {
  return {
    type: VALIDATE_RESET_PASSWORD_FIELD,
    payload: {
      field,
      value,
      validations,
    },
  }
}

import {
  ADD_MOOD_METER_FIELDS_ERRORS,
  CHANGE_MOOD_METER_FORM_FIELD,
  CLEAR_MOOD_METER_FIELD_ERROR,
  RESET_MOOD_METER_FORM_DATA,
} from './actions'

const defaultFormData = () => ({
  pleasantness: 0,
  energy: 0,
  reason: '',
  chosenEmotionWord: '',
})

export const initialState = () => {
  return {
    data: defaultFormData(),
    errors: {},
  }
}

export default function root(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_MOOD_METER_FORM_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.key]: action.payload.value,
        },
      }

    case ADD_MOOD_METER_FIELDS_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      }
    case CLEAR_MOOD_METER_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: undefined,
        },
      }

    case RESET_MOOD_METER_FORM_DATA:
      return {
        ...state,
        data: defaultFormData(),
        errors: {},
      }

    default:
      return state
  }
}

import buildService from 'common/utils/build-service'
import { GET } from 'common/requests'

export const getAssessmentsService = buildService({
  type: GET,
  constantsPrefix: 'ASSESSMENTS',
  isArray: true,
  http: {
    createApiUrlSuffix: ({ courseId }) => {
      return `/assessments?select=*,assessment_questions(*,assessment_choices(id,title,assessment_question_id))&course_id=eq.${courseId}&assessment_questions.order=weight.asc&assessment_questions.assessment_choices.order=weight.asc`
    },
  },
})

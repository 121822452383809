import _ from 'lodash'
import io from 'socket.io-client'
import wildcard from 'socketio-wildcard'
import { eventChannel } from 'redux-saga'
import { all, call, put, select, spawn, take } from 'redux-saga/effects'
import getConfig from 'next/config'

import { getJWT } from 'common/utils'

import { fireTestEventFromSocket } from './actions'
import eventMap from './event-map'

const { publicRuntimeConfig } = getConfig()

function createSocketChannel(socket) {
  // `eventChannel` takes a subscriber function
  // the subscriber function takes an `emit` argument to put messages onto the channel
  return eventChannel((emit) => {
    const eventHandler = (event) => {
      const {
        data: [type, payload],
      } = event
      // puts event payload into the channel
      // this allows a Saga to take this payload from the returned channel
      emit({ type, payload })
    }

    const errorHandler = (errorEvent) => {
      // create an Error object and put it into the channel
      emit(new Error(errorEvent.reason))
    }

    // setup the subscription
    socket.on('*', eventHandler)
    socket.on('connect_error', errorHandler)

    // the subscriber must return an unsubscribe function
    // this will be invoked when the saga calls `channel.close` method
    const unsubscribe = () => {
      socket.off('*', eventHandler)
    }

    return unsubscribe
  })
}

let socketHandlingInitialzied = false
export function* handleSocketEvents() {
  if (!process.browser || socketHandlingInitialzied) {
    return
  }

  const jwt = getJWT()
  const options = {
    transports: ['websocket', 'polling'],
  }
  if (jwt !== undefined) {
    options.query = `auth_token=${jwt}`
  }

  const socketUrl = publicRuntimeConfig.WS_URL
  const socket = io(socketUrl, options)

  // Patch the socket so it supports wildcard/catch-all event handlers
  wildcard(io.Manager)(socket)
  const socketChannel = yield call(createSocketChannel, socket)

  socketHandlingInitialzied = true
  while (true) {
    try {
      // An error from socketChannel will cause the saga jump to the catch block
      const event = yield take(socketChannel)
      const actions = yield select((state) =>
        _.filter(state.socketCommunication.listeners, { eventType: event.type }),
      )
      const transformData = _.get(
        _.find(eventMap, { eventType: event.type }),
        'transformData',
        _.identity,
      )
      // loop over actions and fire them
      yield all(
        _.map(actions, (action) => {
          return put({ type: action.actionType, payload: transformData(event.payload, event.type) })
        }),
      )
      yield put(fireTestEventFromSocket(event))
    } catch (err) {
      // console.error('socket error:', err)
      // socketChannel is still open in catch block
      // if we want end the socketChannel, we need close it explicitly
      // socketChannel.close()
    }
  }
}

export default function* saga() {
  yield all([spawn(handleSocketEvents)])
}

import _ from 'lodash'

import {
  ADD_SCHOOL_FIELD_ERROR,
  ADD_SCHOOL_FIELDS_ERRORS,
  CHANGE_SCHOOL_FORM_FIELD,
  CLEAR_SCHOOL_FIELD_ERROR,
  PREDEFINE_SCHOOL_FORM_DATA,
  RESET_SCHOOL_FORM_DATA,
} from './actions'

export const defaultFormData = (predefined = {}) =>
  _.merge(
    {
      schoolName: '',
      websiteUrl: '',
      phoneNumber: '',
      address: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      logoUrl: '',
      phase: '',
      schoolDistrict: '',
      schoolGrades: [],
      id: '',
      schoolDistrictName: '',
      coachName: '',
      coachEmail: '',
      offlineTrainings: [],
      status: 'enabled',
      started_ruler_at: '',
      started_ruler: true,
      total_staff: 0,
    },
    predefined,
  )

export const initialState = () => ({
  formData: defaultFormData(),
  errors: {},
})

export default function root(state = initialState(), action) {
  switch (action.type) {
    case CHANGE_SCHOOL_FORM_FIELD:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.key]: action.payload.value,
        },
      }

    case ADD_SCHOOL_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: action.payload.error,
        },
      }

    case ADD_SCHOOL_FIELDS_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      }

    case CLEAR_SCHOOL_FIELD_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.field]: undefined,
        },
      }

    case RESET_SCHOOL_FORM_DATA:
      return {
        ...state,
        formData: defaultFormData(),
        errors: {},
      }

    case PREDEFINE_SCHOOL_FORM_DATA:
      return {
        ...state,
        formData: defaultFormData(action.payload),
        errors: {},
      }

    default:
      return state
  }
}

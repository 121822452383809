import _ from 'lodash'
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { validateField, validateFields } from 'common/validator'
import lang from 'lang'
import {
  addFieldError,
  addFieldsErrors,
  clearFieldError,
  SUBMIT_CHANGE_PASSWORD,
  VALIDATE_CHANGE_PASSWORD_FIELD,
} from 'page-modules/settings/password/service/actions'
import { changePasswordService } from 'page-modules/settings/password/service'

export function* submitChangePasswordSaga({ payload }) {
  if (payload.newPassword.value !== payload.confirmPassword.value) {
    return yield put(addFieldsErrors({ base: lang.page.settings.passwordMismatch }))
  }
  const errors = validateFields(payload)

  if (_.isEmpty(errors)) {
    const data = {
      old_pass: payload.oldPassword.value,
      new_pass: payload.newPassword.value,
    }

    yield put(
      changePasswordService.actions.request({
        data,
      }),
    )
  } else {
    yield put(addFieldsErrors(errors))
  }
}

export function* validateFieldSaga({ payload: { field, value, validations } }) {
  const error = validateField({ field, value, fieldValidations: validations })

  if (error) {
    yield put(addFieldError({ field, error }))
  } else {
    yield put(clearFieldError({ field }))
  }
}

export default function* saga() {
  yield all([
    takeEvery(VALIDATE_CHANGE_PASSWORD_FIELD, validateFieldSaga),
    takeLatest(SUBMIT_CHANGE_PASSWORD, submitChangePasswordSaga),
  ])
}

import { END } from '@redux-saga/core'
import React, { ErrorInfo } from 'react'
import * as Sentry from '@sentry/nextjs'
import App, { AppContext } from 'next/app'
import { showReportDialog } from '@sentry/react'
import lang from 'lang'

import { SagaStore, wrapper } from 'common/store'
import Head from 'common/components/head'
import { gtmInitialize } from 'lib/gtm'
import mixpanel from 'lib/mixpanel'

import 'flatpickr/dist/themes/airbnb.css'
import 'styles/scss/style.scss'

// Init mixpanel when on the client
if (typeof window === 'object') {
  mixpanel.init()
}

class RulerApp extends App {
  state = {
    hasError: false,
  }

  public static getInitialProps = wrapper.getInitialAppProps(
    (store) => async (appContext: AppContext) => {
      const appProps = (await App.getInitialProps(appContext)).pageProps
      const componentProps = appContext.Component.getInitialProps
        ? await appContext.Component.getInitialProps({ ...appContext.ctx, store })
        : {}

      // 1. Wait for all page actions to dispatch
      const pageProps = {
        // https://nextjs.org/docs/advanced-features/custom-app#caveats
        ...appProps,
        ...componentProps,
      }

      // 2. Stop the saga if on server
      if (appContext.ctx.req) {
        store.dispatch(END)
        await (store as SagaStore).sagaTask.toPromise()
      }

      // 3. Return props
      return { pageProps }
    },
  )

  componentDidMount() {
    gtmInitialize()
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Display fallback UI
    this.setState({ hasError: true })
    // Capture the error
    Sentry.withScope((scope) => {
      scope.setExtra('componentStack', errorInfo.componentStack)
      Sentry.captureException(error)
    })
  }

  render() {
    if (this.state.hasError) {
      return <AppError />
    }
    const { Component, pageProps } = this.props
    return <Component {...pageProps} />
  }
}

export default wrapper.withRedux(RulerApp)

const AppError = () => (
  <div className="content grid-container">
    <Head title={lang.errors.exception.title} />
    <div className="inner-content grid-x grid-padding-x">
      <main className="main small-12 cell" role="main">
        <div className="content-not-found">
          <section className="entry-content text-center">
            <h1>{lang.errors.exception.title}</h1>
            <p className="lead">{lang.errors.exception.description1}</p>
            <p className="lead">{lang.errors.exception.description2}</p>
            <p>
              <button
                type="button"
                onClick={() => window.location.reload()}
                className="button hollow">
                {lang.errors.exception.reloadButton}
              </button>
            </p>
            <p>
              <button
                type="button"
                onClick={() => {
                  showReportDialog({
                    title: lang.errors.exception.reportTitle,
                    labelSubmit: lang.errors.exception.reportSubmitButton,
                  })
                }}
                className="button">
                {lang.errors.exception.report}
              </button>
            </p>
          </section>
        </div>
      </main>
    </div>
  </div>
)

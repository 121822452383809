const reduxSentryMiddleware = (Sentry) => () => (next) => (action) => {
  Sentry.addBreadcrumb({
    message: action.type,
    category: 'redux-action',
    level: 'info',
    data: {
      payload: action.payload,
    },
  })
  return next(action)
}

export default reduxSentryMiddleware
